import { HttpClient } from "@angular/common/http";
import { LoginService } from '../login/login.service';
import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { AppConfigService } from '../configurations/app-config.service';
import { EventEmitter, Injectable } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})

export class interfaceLabAdminServices {
  config: NgxUiLoaderConfig;
  protected apiURL: string = AppConfigService.settings.WebApiURL;
  header = { withCredentials: true }
  headers = { 'Content-Type': 'application/json charset = utf-8', withCredentials: true };
  constructor(private httpService: HttpClient,
    private loginService: LoginService,private datePipe: DatePipe,
    private ngxUiLoaderService: NgxUiLoaderService) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
  }

  GetFailedMessages(body) {
    return this.httpService.post<any>(`${this.apiURL}api/LabAdminInterface/GetFailedMessages`, body, this.header)
  }

  GetSearchInterface(body) {
    return this.httpService.post<any>(`${this.apiURL}api/LabAdminInterface/GetSearchInterface`, body, { withCredentials: true })
  }

  getapiurl(urlterm, deploymentkey) {
    const headers = { 'Conn': deploymentkey };
    // headers["Content-Type"] = "application/json";
    // headers["Access-Control-Allow-Origin"] = "*";
    // headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";
    return this.httpService.get<any>(this.apiURL + urlterm, { headers })
  }

  GetMessageHeaderCounts(body) {
    return this.httpService.post<any>(`${this.apiURL}api/LabAdminInterface/GetMessageHeaderCounts`, body, { withCredentials: true })
  }

  FailedMessageReprocess(body) {
    return this.httpService.post<any>(`${this.apiURL}api/LabAdminInterface/FailedMessageReprocess`, body, { withCredentials: true })
  }

  UpdateLocationCode(body) {
    return this.httpService.post<any>(`${this.apiURL}api/LabAdminInterface/UpdateLocationCode`, body, { withCredentials: true })
  }

  GetFileContentByFileID(input: any) {

    return this.httpService.post<any>(`${this.apiURL}api/FileServices/GetFileContentByFileID`, input, { withCredentials: true })
  }

  GetLoginedLabadminData(body) {
    return this.httpService.post<any>(`${this.apiURL}api/LabAdminInterface/GetLoginedLabadminData`, body, { withCredentials: true })
  }


  public interfacelistpagedisplay = new EventEmitter<boolean>(); 
 

  formatDate(userDate: string): string {
    const dateObject = new Date(userDate);
    return this.datePipe.transform(dateObject, 'MM-dd-yyyy');
  }

}
