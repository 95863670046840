import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator'; 
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Drilldown from 'highcharts/modules/drilldown';
More(Highcharts);
Drilldown(Highcharts);
// Load the exporting module.
import Data from 'highcharts/modules/data';
import Exporting from 'highcharts/modules/exporting';
import { VarulesComponent } from 'src/app/varules/varules.component';
import { forEach } from 'jszip';
import { error } from 'selenium-webdriver';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { FtpfilesComponent } from 'src/app/ftpfiles/ftpfiles.component';
import { V, X } from '@angular/cdk/keycodes';
import { XmlmappingsComponent } from 'src/app/xmlmappings/xmlmappings.component';
import { TryitoutComponent } from 'src/app/tryitout/tryitout.component';
import { Routeversion1Component } from 'src/app/routeversion1/routeversion1.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// Initialize exporting module.
Exporting(Highcharts);
// Initialize Data module.
Data(Highcharts);
declare var $;
declare const copyToClipboard: any;


@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent implements OnInit {

  public reactiveFormroute: FormGroup;
  public reactiveFormpa: FormGroup;
  public reactiveFormmsgtype: FormGroup;
  public reactiveFormdirection: FormGroup;
  public reactiveFormvendor: FormGroup
  public reactiveFormentity: FormGroup
  public reactiveFormse: FormGroup
  reactiveFormProject: FormGroup
  public reactiveFormde: FormGroup
  public showexportbutton: boolean = false;

  keywordsearch = 'displayname';
  keywordsearchdir = 'name'
  selectedIndexTab = 0;
  keywordsearchvendar = "name";
  keywordsearcha = "LocationName";
  keywordsearchProj = "projectName";
  RouteNameListPipeline: any[] = [];
  isLoadingResult: boolean
  notfounddata: boolean
  isadvancefilter: boolean

  interfacedetails = {}
  Highcharts: typeof Highcharts = Highcharts;
  selectedRoutGUID: any;
  selectedRouteName: any;
  category: any;
  //Details
  showSourceTarget: boolean=true;
  selectedSeqName: string;

  //datavalidation
  InterfaceValidationHistory: any = [];
  InterfaceMessageDataByMessageid: any = [];
  dataSources: any[];
  displayedColumns: string[] = ['pipeline', 'generatedby', 'generatedon', 'status'];
  currentItem = 'Televi;sion';
  validatedDataResponse: any;
  callingComponent: any = '';
  maxItemsToShow = 4;
  tripfilter = {
    PipelineName: '',
    Fromdate: '',
    Todate: '',
    Generated_By: '',
    Generated_On: '',
    Status: ''
  }
  cardfilter = {
    Accession: '',
    Order: '',
    DateofBirth: '',
    PatientName: '',
    MessageId: ''
  }

  //datavalidation
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  @ViewChild('ValidationContent', { static: false }) ValidationContent: any;

  // @ViewChild('reValidationPopup', { static: false }) reValidationPopup: any;

  [x: string]: any;
  config = {
    displayKey: 'lookupvalue', //if objects array passed which key to be displayed defaults to description
    search: true,
    height: '50%',
    width: '80%',
    // width: '1202'px,
    /* height: 507px; */
  }
  showInterfaceData: boolean = false;
  filteredInterfaceRevalidationData: any = [];
  searchtextData: string = '';
  selectedFromDate: any;
  pipelineGUID: any;
  pipelineName: any;
  backupPipelineName: any;
  id: any;
  Pipeline: any;
  PipelineGUID: any;
  backupPipelineGUID: any;
  backupPipeline: any;
  piplineguid: any;
  responseLength: any;
  selectedToDate: any;
  today: any = new Date();
  pipeline: string;
  generatedby: string;
  Generatedon: number;
  Status: string;
  statusMessage: string;
  reponseList: any = [];
  reponseListBackup: any = [];
  Validationcomponent: any = {};
  validationItem: any = {};
  FilertData = [];
  interfacePipelineDisplayname: any = '';
  backupInterfacePipelineDisplayname
  inputDataInModalComponent: any = [];
  selectedCardIndex: number = 0;
  searchData: any;
  modalReference = null;

  messagereprocessdata: any = [];
  messagereprocess: any = [];
  failedMessageNote :string ='';
  successmsglist:any[]=[];
  selectedrouteId: string = ""
  selectedrouteDisplayName: string
  failurereason: any[] = [];
  isdate: boolean
  reason: string = ''
  count: string = ''
  fromdatepop: string = ''
  todatepop: string = ''
  selecteddate: any
  failurereasonBackup: any
  fromdate: any = ""
  todate: any = ""
  status: boolean = true
  direction: any = "Inbound"
  interfacelist: any[] = []
  issearched: boolean
  directionCtrl = new FormControl("");
  response: any
  chartOptions = {}
  pipeLineDetails = {}
  isselect: string = ''
  sourcedeploy: string = ''
  targetdeploy: string = ''
  sourcetype: string = ''
  targettype: string = ''
  sequence: any[] = []
  connseqdatalist: any[] = []
  mode: string = ''
  base64data: string
  ishide: boolean = true
  isLoadingpa: boolean = false;
  ProcessorAgentNameList: any[] = []
  ProcessorList: any[] = []
  isChecked: boolean
  selectedProcessorAgentguid: string = ''
  selectedProcessorAgentName: string = ''
  isLoadingmessagetype: boolean = false;
  messageTypeAllList: any = [];
  messageTypeList: any = [];
  directionlist: any[] = [];

  isLoadingVendorName: Boolean = false;
  Vendornamelist: any[] = []
  VendornameBackuplist: any[] = []
  vendorname: string = ''
  vendorid: string = ''
  isLoadingvendor: boolean
  selectedEntityguid: string
  selectedEntityName: string
  isLoadingentity: boolean
  EntityNameList: any[] = []
  selectedSourceEntityguid: string = ''
  selectedSourceEntityName: string = ''
  selectedSourcedeploymentkey: string = ''
  selectedSourceAssociateAaccountids: string = ''
  selectedSourceEntitytype: string = "";
  isLoadingse: boolean
  SourceEntityNameList: any[] = []
  selectedTargetEntityguid: string = ''
  selectedTargetEntityName: string = ''
  selectedtargetedeploymentkey: string = ''
  selectedEntitydeploymentkey: string = ''
  selectedEntitytype: string = ''
  selectedtargetAssociateAaccountids: string = ''
  selectedTargetEntitytype: string = "";
  isLoadingde: boolean
  TargetEntityNameList: any[] = []
  selectedprojId: string = ''
  selectedProjName: string = ''
  pagenumber: any = 1
  totalrecords: number
  dateoption: string = "Modified"
  isLoadingProjects: boolean
  notfoundProj: boolean
  ProjectNames = []
  pendingfilecount: number = 0
  pendingfiledate: any = ''
  DiagnosticList: any[] = []
  showdiagnostics: boolean
  seqdataarr: any[] = []
  issuccessmsgpop:string=''
  jobid:any[]=[];
  sendToChild1:any
  sendToChild2:string=''
  isviewpassword: boolean
  event: any
  PageSize = 15;
  isopenpopup:boolean=true;
  reactiveFormuser:FormGroup;
  Templatearray: any[];
  public dataToModal: any;
  fileUploadFTP:boolean;
  enableAccessionSearch:boolean;
  isftpuser:boolean;
  selectedusername:string=''
  userlist:any[]=[];
  userlistbackup:any[]=[];
  selecteduserid:string='';
  mappedXml: string = '';
  isLoadinguser:boolean;

  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private _fb: FormBuilder,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
    this.reactiveFormroute = this._fb.group({ routename: '' });
    this.reactiveFormpa = _fb.group({ paname: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormdirection = this._fb.group({ direction: '' });
    this.reactiveFormvendor = _fb.group({ vendorname: '' });

    if (this.vendorname) {
      this.reactiveFormvendor.patchValue({
        vendorname: this.vendorname
      })
    }
    this.reactiveFormentity = _fb.group({ entityname: '' });
    this.reactiveFormse = _fb.group({ sename: '' });
    this.reactiveFormde = _fb.group({ dename: '' });
    this.reactiveFormProject = _fb.group({ projectName: '' });
    this.reactiveFormuser = _fb.group({ user: '' });
  }
  todaydate: any
  pagerolepermisions:any=[];
  pipelineObj: any;
  keywordsearchuser = 'UserName';

  ngOnInit() {
    this.showSourceTarget = true;
    const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'interface').MenuId;
    const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
    actionitems.forEach(element => {
      this.pagerolepermisions[element.MenuName] = true;
     // //console.log(this.pagerolepermisions)
    });
    this.showdiagnostics = false;
    this.isselect = ''
    this.issearched = false
    this.ishide = true
    this.todaydate =this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.getMessagetypelist('msgtype');
    this.directionlist = [
      { 'name': 'Inbound' }, { 'name': 'Outbound' }
    ]
    this.getVendornames();
    this.getuserdetails();
    
  }
  //Datavalidation
  pipelineguid(event: any) {
    this.pipelineGUID = event.target.value;
    this.pipeline = event.target.value;
  }

  getRouteName(event: any) {
    var val = event;
    if (val) {
      if (Number(val.length) + Number(1) > 3) {
        this.isLoadingResult = true;
        var url = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
        this.service.getapiurl(url).subscribe({
          next: data => {
            this.isLoadingResult = false;
            this.RouteNameListPipeline = data;
            this.RouteNameListPipeline.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
            this.selectedrouteDisplayName = val;
            if (data.length == 0) {
              this.notfounddata = true;
            } else {
              this.notfounddata = false
            }
          },
          error: error => {
            this.isLoadingResult = false;
            this.notfounddata = false
            this.toastr.error("No result found", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        });
      }
    }

  }

  selectedinterfaceEvent(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.getpipelinelist(true, false);
  }
  buildpiplinetitle(name, id) {
    if (name !== undefined && name != '') {
      if (name.includes("(")) {
        return name
      } else {
        let fullname = name + '(' + id + ')';
        if (id) {
          return fullname;
        }
        else {
          return
        }
      }
    }

  }
  async searchRouteNameCleared(event) {
    this.pagenumber = 1
    this.status = true
    this.isChecked = false
    this.issearched = false
    this.interfacelist = []
    this.RouteNameListPipeline = []
    this.interfacedetails = {}
    this.selectedrouteId = ''
    this.selectedrouteDisplayName = ''
    if (this.selectedrouteId !== "") {
      this.reactiveFormroute.patchValue({
        routename: ''
      });
    }
  }
  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }


  respopdate(value) {
    if (value.includes('/')) {
      value = value.split("/")
      return value[2] + "-" + value[0] + "-" + value[1]
    }
    else {
      value = value.split('-');
      return value[2] + "-" + value[1] + "-" + value[0]
    }
  }

  async  failurerespopup(value: any, isdate: boolean, failurerespopup) {
    this.isdate = isdate;
    this.ngxUiLoaderService.start();
    this.reason = value['FailureReason'];
    this.count = isdate ? "" : value['Count']
    this.failurereason = [];
    if (isdate) {
      this.fromdatepop = this.respopdate(value);
      this.todatepop = this.respopdate(value)
    }
    else {
      let date: Date = new Date();
      date.setDate(date.getDate() - 6);
      this.fromdatepop = this.datepipe.transform(date, 'yyyy-MM-dd');
      this.todatepop = this.todaydate;
      value = null
    }
    this.selecteddate = value;
    let res = await this.GetDatewiseFailureReasonList(value, false);
    this.modalService
      .open(failurerespopup, {
        windowClass: "failuremsgpop",
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => { });
  }

  async GetDatewiseFailureReasonList(value, isdatesearch) {
    let obj = {
      "UniqueID": this.isselect,
      "Allfailurecount": 0,
      "DatewiseFailure": this.isdate ? 1 : 0,
      "FailureReason": this.isdate ? null : this.reason,
      "FromDate": isdatesearch || this.isdate ? this.fromdatepop + ' 00:00:00' : null,
      "ToDate": isdatesearch || this.isdate ? this.todatepop + ' 23:59:59' : null
    }
    let isretval = false;
    this.ngxUiLoaderService.start();
    this.service.GetDatewiseFailureReasonList(obj).subscribe((data) => {
      if (data['status'].toLowerCase() == 'success') {
        this.failurereason = JSON.parse(data['responsebody']);
        this.failurereasonBackup = this.failurereason;
        this.ngxUiLoaderService.stop();
        isretval = true;
      }
      else {
        this.toastr.error("No data found", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.ngxUiLoaderService.stop();
        isretval = false;
      }
    }), error => {
      this.toastr.error("No data found", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
      isretval = false;
    }
    return isretval;
  }
  closepopup(c) {
    c('close modal');
    this.isheaderchecked = false
    this.isopenpopup=true;
    this.failurereason = [];
    this.issuccessmsgpop='';
    let date: Date = new Date();
          date.setDate(date.getDate() - 6);
          this.fromdatepop = this.datepipe.transform(date, 'yyyy-MM-dd');
          this.todatepop = this.todaydate;
  }
  // to close Datavalidation popup screen
  closepopupdata(content: any) {

    this.showInterfaceData = false;
    this.modalService.dismissAll(content)
    this.searchtextData = '';
    this.getFilter();
    this.searchData = '';
    this.getFilterDetails();
    //this.getFilter();
    //this.getFilterDetails();
    this.selectedFromDate = '';
    this.selectedToDate = '';
  }

  isheaderchecked: boolean
  headerAction(event) {
    this.isheaderchecked = event['checked'];
    this.failurereason.forEach(V => {
      V['ischecked'] = event['checked'];
    })
  }
  rowAction(mid: any, event) {
    this.failurereason.forEach(V => {
      if (V['MessageRefernceId'] == mid['MessageRefernceId'] && V['TransactionId'] == mid['TransactionId'] && V['OrderNumber'] == mid['OrderNumber']) {
        V['ischecked'] = event['checked'];
      }
    })
  }


  async  getpipelinelist(issearch, isscroll) {
    if (!isscroll) {
      this.interfacelist = []
      this.isselect = ''
      this.issearched = issearch
    }
    this.ngxUiLoaderService.start();
    this.interfacedetails = []
    if (this.fromdate != '' && this.todate == '') {
      this.toastr.warning("Unable to search", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
    else {
      let obj = {}
      if (this.selectedrouteId != '') {
        obj = {
          "id": this.selectedrouteId,
        }
      }
      else {
          if (this.selectedSourceEntityguid == '' && this.reactiveFormse.get('sename').value) {
          this.selectedSourceEntityguid = this.reactiveFormse.get('sename').value.id;
        }

        if (this.selectedTargetEntityguid == '' && this.reactiveFormde.get('dename').value) {
          this.selectedTargetEntityguid = this.reactiveFormde.get('dename').value.id;
        }

        if (this.selectedEntityguid == '' && this.reactiveFormentity.get('entityname').value) {
          if (this.selectedSourceEntityguid == '' && this.selectedTargetEntityguid == '') {
            this.selectedEntityguid = this.reactiveFormentity.get('entityname').value.id;
          }
          else {
            this.selectedEntityguid = '';
          }
        }
        else if (this.selectedSourceEntityguid !== '' || this.selectedTargetEntityguid !== '') {
          this.selectedEntityguid = '';
        }

        if (this.selectedProcessorAgentguid == '' && this.reactiveFormpa.get('paname').value) {
          this.selectedProcessorAgentguid = this.reactiveFormpa.get('paname').value.id;
        }
        
        if (issearch) {
          this.paginator.firstPage();
          this.pagenumber = 1;
        }
        obj = {
          "messagetype": this.selectedmessagetype,
          "id": this.selectedrouteId,
          "PAID": this.selectedProcessorAgentguid,
          "sourceagentid": "",
          "SEGUID": this.selectedSourceEntityguid,
          "destinationagentid": "",
          "DEGUID": this.selectedTargetEntityguid,
          "vendor": this.vendorid,
          "entityid": this.selectedEntityguid,
          "pagenumber": this.pagenumber,
          "PageSize": this.PageSize,
          "userType": (this.isChecked) ? 2 : '',
          "type": 'not',
          "projectId": this.selectedprojId,
          "assignedUserId": (this.isChecked) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : '',
          "status": this.status ? "active" : "passive",
          "FromDate": this.fromdate,
          "ToDate": this.todate,
          "Direction": this.selecteddirectiontype,
          "DateType": this.fromdate != '' && this.todate != '' ? this.dateoption : '',
          "SETYPE": this.selectedSourceEntitytype,
          "DETYPE": this.selectedTargetEntitytype,
          "SEDEPLOYMENTKEY": this.selectedSourcedeploymentkey,
          "DEDEPLOYMENTKEY": this.selectedtargetedeploymentkey,
          "EETYPE": this.selectedEntitytype,
          "EEDEPLOYMENTKEY": this.selectedEntitydeploymentkey
        }
      }
      var apiname = 'api/Routes/GetSearchDetails';
      this.response = await this.service.searchpostapi(apiname, obj);

      if (this.response.length == 0) {
        this.toastr.error("No data found", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.interfacelist = []
        this.totalrecords = 0
        this.paginator.firstPage();
      } else {
        this.interfacelist = []
        this.interfacelist = this.response as any[]
        this.totalrecords = parseInt(this.response[0].totalpipelinecount.toString());
        if (this.pagenumber > 0 && this.paginator != undefined) {
          this.paginator.length = this.totalrecords;
        }
      }
      this.ngxUiLoaderService.stop();
    }
  }
  datesplit(value) {
    if (value !== undefined) {
      value = value.split(" ")[0]
      if (value.includes("-")) {
        value = value.split("-")
        return value[1] + "/" + value[0] + "/" + value[2]
      }
      else if (value.includes("/")) {
        value = value.split("/")
        return value[1] + "/" + value[0] + "/" + value[2]
      }
    }
  }
  interfaceData:any;
  valueData:any;
  getpipelinedetails(interfaceData: any, value) {
    this.interfaceData=interfaceData
    this.valueData=value
    if (value == 'home') {
      this.DiagnosticList = []
      this.connseqdatalist = []
      this.seqdataarr = []
      this.isselect = interfaceData.UniqueID
      this.selectedRoutGUID = interfaceData.id;
      this.selectedRouteName = interfaceData.displayname;
      this.sourcedeploy = interfaceData['SEDEPLOYMENTKEY'];
      this.targetdeploy = interfaceData['DEDEPLOYMENTKEY'];
      this.sourcetype = interfaceData['SETYPE'];
      this.targettype = interfaceData['DETYPE'];
      this.mode = interfaceData['mode']
      this.ngxUiLoaderService.start();
    }
    this.issuccessmsgpop=''
    this.service.GetPipeleDetailsByUniqueID(interfaceData.UniqueID).subscribe((data) => {
      if (data['status'].toLowerCase() == 'success' && (data['statusmessage'] == null || data['statusmessage'].toLowerCase() != 'no data found')) {
        this.ishide = false
        this.interfacedetails = JSON.parse(data['responsebody']);
        this.pipeLineDetails = this.interfacedetails['pipeLineDetails'][0];
        this.interfacedetails['connectivityDetails']['sequenceDataList'] != null && this.interfacedetails['connectivityDetails']['sequenceDataList'][0] != undefined ? this.sequence = this.interfacedetails['connectivityDetails']['sequenceDataList'] : this.sequence = [];

         this.getsuccessmsglist('');
        this.getpendingfilesndiagnostics(this.selectedRoutGUID);
        if (this.sequence.length > 0) {
          let obj = {}

          this.sequence.forEach(V => {

            let backupSeq = JSON.parse(
              JSON.stringify(
                this.interfacedetails['connectivityDetails']['connectivityData'] != null
                  ? this.interfacedetails['connectivityDetails']['connectivityData']
                  : []
              )
            );
            if (this.connseqdatalist.length > 0) {
              this.connseqdatalist.forEach(X => {
                backupSeq = backupSeq.filter(element => element['TypeName'] !== X['Name']);
              })
            }
            if (backupSeq.length > 0) {
              let flag = 1;
              backupSeq.forEach(X => {
                if (X['Name'] == V['TypeName']) {
                  if (flag) {
                    obj = {
                      'Name': 'Description',
                      'Value': X['Description']
                    }
                    X['Data'].push(obj)
                  }
                  if(X['Name']=='VA-VBJOBS'){
                    obj = {
                      'Name': 'VB Diagnostics',
                      'Value': 'Run'
                    }
                    X['Data'].unshift(obj);
                    this.jobid=X['Data'].filter(element => element['Name'].toLowerCase() == 'jobid');
                  }
                  flag = 0
                  obj = {
                    'Name': V['TypeName'],
                    'Data': X['Data']
                  }
                  this.connseqdatalist.push(obj)
                }
              });
            }
          })
        }
        // if (this.connseqdatalist.length == 0) {
        //   this.sequence = []
        // }
        this.category = []
        this.interfacedetails['DateWiseFailureCount'].forEach(Element => {
          this.category.push(Element['Date'])
        });
        // let fail=[]
        // this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
        //   fail.push(parseInt(Element['StatusCount']['FailureCcount']))
        // });
        // let ignore=[]
        // this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
        //   ignore.push(parseInt(Element['StatusCount']['IgnoreCount']))
        // });
        // let success=[]
        // this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
        //   success.push(parseInt(Element['StatusCount']['SuccessCount']))
        // });
        // let resolved=[]
        // this.interfacedetails['DateWiseFailureCount'].forEach(Element =>{
        //   resolved.push(parseInt(Element['StatusCount']['ResolvedCount']))
        // });
        // this.chartOptions={
        //   chart: {
        //     type: 'column',
        //   },
        //   title: {
        //     text: ''
        //   },
        //   exporting: {
        //     enabled: false
        //   },
        //   xAxis: {

        //     categories: this.category,
        //     labels: {
        //       style: {
        //           color: 'black',
        //           fontSize:'9px'
        //       }
        //   },
        //     crosshair: true
        //   },
        //   yAxis: {
        //     title: {
        //       text: ''
        //     }
        //   },
        //   tooltip: {
        //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        //     pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //       '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        //     footerFormat: '</table>',
        //     shared: true,
        //     useHTML: true
        //   },
        //   plotOptions: {
        //     column: {
        //       pointPadding: 0.2,
        //       borderWidth: 0
        //     },
        //   },
        //   series: [{
        //     name: 'Failure',
        //     data: fail

        //   }, {
        //     name: 'Ignored',
        //     data: ignore

        //   }, {
        //     name: 'Success',
        //     data: success

        //   }, {
        //     name: 'Resolved',
        //     data: resolved
        //   }],
        //   credits: {
        //     enabled: false
        // },
        // }

        this.connseqdatalist.length > 0 ? this.getseqdata(this.connseqdatalist[0]['Name']) :this.sequence.length > 0 ? this.getseqdata(this.sequence[0]['TypeName']):'';
      }
      else {
        this.toastr.error("Unable to fetch pipeline details", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();
    }), error => {
      this.toastr.error("Unable to fetch pipeline details", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
  }
  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }

  }
  reprocessdata(c,ciismessagereprocess){
    let selectedmsgs=this.failurereason.filter(element => element["ischecked"]==true);
    if(selectedmsgs.length>0){
      this.modalService.open(ciismessagereprocess,{windowClass: "modalConfig",backdrop: 'static', keyboard: false});
    }
  }

  reportexport(value: string) {
    this.ngxUiLoaderService.start()
    let reporttype = ''
    value != '' ? reporttype = 'default' : this.isdate ? reporttype = 'datewise' : reporttype = 'failurewise'
    let obj = {
      "routeID": this.pipeLineDetails['Id'],
      "direction": this.pipeLineDetails['Direction'],
      "displayname": this.pipeLineDetails['DisplayName'],
      "messageType": this.pipeLineDetails['Messagetype'],
      "lab": (this.pipeLineDetails['Direction'].toLowerCase()).includes('Inbound') ? this.pipeLineDetails['DestinationEntity'] : this.pipeLineDetails['SourceEntityName'],
      "vendor": this.pipeLineDetails['VendorName'],
      "connectivity": "",
      "user": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
      "reportType": reporttype,
      "failureDate": reporttype=='datewise'?this.selecteddate:null,
      "failureReason": this.reason,
      "uniqueId": this.isselect,
      "fromDate":this.fromdatepop,
      "toDate": this.todatepop
    }

    this.service.ciisreportexport(obj).subscribe(data => {
      if (data['status'] != null && data['status'].toLowerCase() == 'success') {
        this.base64data = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + data['responsecontent'];
        this.downloadfile();
      }
      else {
        this.toastr.error("Unable to export report", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();
    }), error => {
      this.toastr.error("Unable to export report", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
  }
  downloadfile() {
    const source = this.base64data;
    const link = document.createElement("a");
    link.href = source;
    link.download = 'InterfaceHistory_' + this.todaydate;
    link.click();
  }


  backtolist() {
    this.interfacedetails = []
    this.ishide = true
    this.showdiagnostics = false;
    this.fromdatepop='';
    this.todatepop='';
    this.failurereason=[];
    this.successmsglist=[];
  }
  myinterface(event: any) {
    this.isChecked = event.checked;
  }

  async getProcessorAgentNames(event: any) {
    this.isLoadingpa = true;
    var apiname = "api/Agents/GetByDisplayName?name=" + '&agentid=0&type=Processor&filterflag=false';

    await this.service.getapiurl(apiname).subscribe({
      next: async data => {
        this.ProcessorAgentNameList = data
        this.ProcessorAgentNameList.map(obj => { obj.name = obj.displayname, obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}` });
        this.ProcessorList = this.ProcessorAgentNameList;
        this.isLoadingpa = false;
        if (data.length == 0) {
          this.notfounddata = true;
        } else {
          if (this.ProcessorList.length > 0) {
            this.ProcessorList = this.service.transformAlphabetically(this.ProcessorList, 'displayname');
          }
          this.notfounddata = false;
        }
      },
      error: error => {
        this.isLoadingpa = false;
        this.notfounddata = true;
      }
    });
  }

  selectedProcessorAgents(item) {
    this.selectedProcessorAgentguid = item.id;
    this.selectedProcessorAgentName = item.displayname;
  }
  searchProcessorAgentCleared(event) {
    this.isLoadingpa = false;
    // this.ProcessorAgentNameList = this.ProcessorList;
    this.ProcessorAgentNameList = [];
    this.selectedProcessorAgentguid = '';
    this.notfounddata = false;
  }
  selectedmessagetype: string = '';
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }
  selecteddirectiontype: string = ''
  selecteddirectiontypeevent(item) {
    this.selecteddirectiontype = item.name;
  }
  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    // this.messageTypeList = this.messageTypeAllList;
    this.messageTypeList = [];
  }
  Cleardirectiontype(event) {
    this.selecteddirectiontype = '';
    // this.messageTypeList = this.messageTypeAllList;
    this.directionlist = [];
  }


  getMessagetypelist(event) {
    this.isLoadingmessagetype = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmessagetype = false;
    }
    else {

      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingmessagetype = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmessagetype = false;
          this.notfounddata = true;
        }
      });
    }

  }
  reset() {
    this.showSourceTarget=true;
    this.isopenpopup=true;
    this.issuccessmsgpop='';
    this.failurereason=[];
    this.successmsglist=[];
    this.paginator.pageIndex = 0
    this.isviewpassword = false;
    this.pagenumber = 1
    this.interfacelist = []
    this.reactiveFormdirection.reset();
    this.isChecked = false
    this.status = true
    this.fromdate = ''
    this.todate = ''
    this.fromdatepop = ''
    this.todatepop = ''
    this.reactiveFormProject.reset();
    this.isadvancefilter = false;
    this.showexportbutton = false;
    this.reactiveFormroute.reset();
    this.reactiveFormpa.reset();
    this.reactiveFormde.reset();
    this.reactiveFormse.reset();
    this.selectedProcessorAgentguid = "";
    this.selectedrouteId = "";
    this.selectedmessagetype = '';
    this.selectedSourceEntityguid = "";
    this.selectedTargetEntityguid = "";
    this.selectedEntityguid = "";
    this.vendorname = "";
    this.reactiveFormvendor.reset();
    this.reactiveFormvendor.setValue({ "vendorname": '' });
    this.Vendornamelist = this.VendornameBackuplist;
    this.ProcessorAgentNameList = this.ProcessorList;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.reactiveFormmsgtype.reset();
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.reactiveFormdirection.setValue({ "direction": '' });
    this.pagenumber = 0;
    if (!this.showSourceTarget) {
      this.showSourceTarget = true;
    }
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormentity.reset();
    this.selectedEntityName = '';
    this.totalrecords = 0;
    this.selectedprojId = '';
  }

  getVendornames() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelist = data
        this.Vendornamelist = this.service.transformAlphabetically(this.Vendornamelist, 'name');
        this.VendornameBackuplist = this.Vendornamelist;
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  selectedVendor(item) {
    this.vendorname = item.name;
    this.vendorid = item.Id
  }
  searchVendorCleared() {
    this.isLoadingvendor = false;
    this.vendorname = "";
    this.Vendornamelist = this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
  }
  getVendorName(event) {
    this.isLoadingvendor = false;
    if (this.Vendornamelist.length > 0) {
      this.filtervendorlist(event);
    }
    else {
      this.Vendornamelist = this.VendornameBackuplist;
      this.filtervendorlist(event);
    }
  }
  filtervendorlist(event) {
    this.Vendornamelist = this.Vendornamelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelist.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendor = false;
  }

  selectedEntity(item) {
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntityName = item.LocationName;
    this.selectedEntitytype = item.TypeName;
    this.selectedEntitydeploymentkey =item.deploymentkey;
  }
  EntityNameListCleare() {
    this.selectedEntityguid = '';
    this.selectedEntityName = '';
    this.selectedEntitytype = '';
    this.selectedEntitydeploymentkey ='';
  }


  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
  }
  entityCustomFilter = function (EntityNameList: any[], query: string): any[] {
    return EntityNameList;
  };
  async getEntityNames(event: any) {

    var valuesa = event;
    let highlighted;
    if (event.includes(":")) {
      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    if (highlighted != "") {
      highlighted = event;
    }
    this.selectedEntityguid = '';
    this.reactiveFormentity.patchValue({
      entityname: event
    })
    if (valuesa != '' && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      // var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingentity = true;
      let obj = {
        lSearch: valueToSendToApiWithOutWhiteSpace
      }
      //let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.EntityNameList = [];
          this.isLoadingentity = false;
          this.notfounddata = true;
          this.toastr.error("Unable to fetch Entity Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.EntityNameList = response;
            this.EntityNameList = this.service.transformAlphabetically(this.EntityNameList, 'LocationName');
            this.EntityNameList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }

          if (response.length == 0) {
            this.EntityNameList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedEntityguid = '';
          this.isLoadingentity = false;
        }
      })
    }
    else {
      this.EntityNameList = [];
      this.notfounddata = true;
    }
  }
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = names[2] !== 'null)' && names[2] !== undefined ? `${names[0]} <br> (${names[1]} <br> (${names[2]}` : `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }

  selectedSourceEntity(item) {
    this.selectedSourceEntityguid = item.LocationGUID;
    this.selectedSourceEntityName = item.LocationName;
    this.selectedSourcedeploymentkey = item.deploymentkey;
    this.selectedSourceAssociateAaccountids = item.associatedaccountids;
    this.selectedSourceEntitytype = item.TypeName;
  }

  searchSourceEntityCleared(event) {
    this.isLoadingse = false;
    this.SourceEntityNameList = [];
    this.selectedSourceEntityguid = '';
    this.notfounddata = false;
    this.selectedSourcedeploymentkey = '';
    this.selectedSourceAssociateAaccountids = '';
  }
  sourceCustomFilter = function (SourceEntityNameList: any[], query: string): any[] {
    return SourceEntityNameList;
  };

  async getSourceEntityNames(event: any) {
    var valuesa = event;
    let highlightedSource;
    if (event.includes(":")) {
      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    if (highlightedSource != "") {
      highlightedSource = event;
    }
    this.selectedSourceEntityguid = '';
    this.reactiveFormse.patchValue({
      sename: event
    })
    if (valuesa != '' && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingse = true;

      //let response = await this.service.getapi(createSearch);
      let obj = {
        lSearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedTargetEntityguid,
        deploymentkey: this.selectedtargetedeploymentkey,
        associatedaccountids: this.selectedtargetAssociateAaccountids
      }
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.SourceEntityNameList = [];
          this.notfounddata = true;
          this.isLoadingse = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.SourceEntityNameList = response;
            this.SourceEntityNameList = this.service.transformAlphabetically(this.SourceEntityNameList, 'LocationName');
            this.SourceEntityNameList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.SourceEntityNameList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedSourceEntityguid = '';
          this.isLoadingse = false;
        }
      })
    }
    else {
      this.SourceEntityNameList = [];
      this.notfounddata = true;
    }
  }

  selectedTargetEntity(item) {
    this.selectedTargetEntityguid = item.LocationGUID;
    this.selectedTargetEntityName = item.LocationName;
    this.selectedtargetedeploymentkey = item.deploymentkey;
    this.selectedtargetAssociateAaccountids = item.associatedaccountids;
    this.selectedTargetEntitytype = item.TypeName;
  }

  searchTargetEntityCleared(event) {
    this.isLoadingde = false;
    this.TargetEntityNameList = [];
    this.selectedTargetEntityguid = '';
    this.notfounddata = false;
    this.selectedtargetedeploymentkey = '';
    this.selectedtargetAssociateAaccountids = '';

  }
  targetCustomFilter = function (TargetEntityNameList: any[], query: string): any[] {
    return TargetEntityNameList;
  };

  async getTargetEntityNames(event: any) {
    var valueta = event;
    let highlightedTarget;
    if (event.includes(":")) {
      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    if (highlightedTarget != "") {
      highlightedTarget = event;
    }
    this.selectedTargetEntityguid = '';
    this.reactiveFormde.patchValue({
      dename: event
    })
    if (valueta != '' && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingde = true;
      let obj = {
        lSearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedSourceEntityguid,
        deploymentkey: this.selectedSourcedeploymentkey,
        associatedaccountids: this.selectedSourceAssociateAaccountids
      }
      //let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.TargetEntityNameList = [];
          this.notfounddata = true;
          this.isLoadingde = false;
          this.toastr.error("Unable to fetch Target Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.TargetEntityNameList = response as any[];
            //this.TargetEntityNameList = this.service.transformAlphabetically(this.TargetEntityNameList, 'LocationName');
            this.TargetEntityNameList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.TargetEntityNameList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedTargetEntityguid = '';
          this.isLoadingde = false;
        }
      })
    }
    else {
      this.TargetEntityNameList = [];
      this.notfounddata = true;
    }
  }

  selectedProject(item) {
    this.selectedprojId = item.id,
      this.selectedProjName = item.projectName
    this.pagenumber = 1;
    this.totalrecords = 0;
  }

  getProjectNames(event: any) {

    var val = event;
    if (val) {
      this.isLoadingProjects = true;
      // var apiname = "api/Projects/GetProjectNames?searchStr="+ val ;
      this.service.getProjList(val).subscribe(data => {
        if (data.statusmessage.length == 0 || data.statusmessage == "No data found") {
          this.notfoundProj = true;
          this.isLoadingProjects = false;
        } else if (data.statusmessage.length > 0) {
          this.ProjectNames = JSON.parse(data.statusmessage);
          this.ProjectNames.find(obj => obj.projectName = `${obj.projectName} ${'(' + obj.projectId + ')'}`);
          this.isLoadingProjects = false;
          //   if(filterd.length>0){
          //   for(let i=0;i<filterd.length;i++){
          //   this.reactiveFormProject.patchValue({projectName:filterd[i].projectName})
          //   }
          // }
        }
        else {
          this.notfoundProj = false;
          this.isLoadingProjects = false;
        }
      }, error => {
        this.isLoadingProjects = false;
        this.notfoundProj = true;
      })
    }
  }

  searchProjectCleared(event) {
    this.isLoadingProjects = false;
    this.ProjectNames = [];
    this.selectedProjName = '';
    this.selectedprojId = '';
    this.notfoundProj = false;
  }
  openvarulepopup() {
    var dpkeyname = '';
    if ((this.pipeLineDetails['Direction'].toLowerCase()).includes('inbound')) {
      dpkeyname = this.targetdeploy;
    }
    else {
      dpkeyname = this.sourcedeploy;
    }

    let dataToruleModal = {
      routename: this.pipeLineDetails['DisplayName'],
      islocked: this.pipeLineDetails['Islocked'] != null ? this.pipeLineDetails['Islocked'] : false,
      deploymentkey: dpkeyname
    };
    if (this.sourcetype.toLowerCase() == 'organization') {
      dataToruleModal["LabOrgId"] = this.pipeLineDetails['SourceEntityId'];
    }
    else if (this.sourcetype.toLowerCase() == 'account') {
      dataToruleModal["LabId"] = this.pipeLineDetails['SourceEntityId'];
    }

    if (this.targettype.toLowerCase() == 'organization') {
      dataToruleModal["OrdFacOrgId"] = this.pipeLineDetails['DestinationEntityId'];
    }
    else if (this.targettype.toLowerCase() == 'account') {
      dataToruleModal["OrdFacilityId"] = this.pipeLineDetails['DestinationEntityId'];
    }
    else if (this.targettype.toLowerCase() == 'facility') {
      dataToruleModal["OrdFacilityId"] = this.pipeLineDetails['DestinationEntityId'];
    }
    const modalRef = this.modalService.open(VarulesComponent,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToruleModal;
    modalRef.result.then(() => { });
  }

  getpendingfilesndiagnostics(rid) {
    this.pendingfiledate = this.datesplit((this.datepipe.transform(new Date(), 'dd-MM-yyyy')).toString());
    this.service.GetStatistics(rid).subscribe(data => {
      if (data.length > 0) {
        let backupSeq = JSON.parse(JSON.stringify(this.sequence));
        data.forEach(V => {
          if (V['Header'].toLowerCase().includes('ftp') || V['Header'].toLowerCase().includes('vpn')) {
            V['LableValue'].forEach(X => {
              if ((X['labelName'].toLowerCase()) == 'pendingfile') {
                this.pendingfilecount = X['count'];
              }
            });
          }
          this.checkStatus(V);
          backupSeq = backupSeq.filter(X => X['TypeName'] !== V['Header']);
        });
        this.DiagnosticList = []
        this.DiagnosticList = data;
        if (backupSeq.length > 0) {
          backupSeq.forEach(V => {
            let obj = {
              "Header": V['TypeName'],
              "SequenceNumber": parseInt(V['Id']),
              "LableValue": [],
              "status": "default"
            }
            this.DiagnosticList.splice(V['Id'] - 1, 0, obj);
          })
        }
      }
    },
      (error) => {
        console.error();
        this.ngxUiLoaderService.stop();
        this.toastr.error(error.statusText, "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    )
  }
  checkStatus(connectivity: any) {
    if (connectivity !== undefined) {
      let val = connectivity.LableValue.filter(
        (value) => value.status === false
      );
      if (val.length > 0) {
        connectivity["status"] = "Failed";
      } else {
        connectivity["status"] = "Success";
      }
    }
  }
  getdiagnostics() {
    this.ngxUiLoaderService.start();
    if (this.connseqdatalist.length > 0 && this.DiagnosticList.length > 0) {
      this.connseqdatalist.forEach(V => {
        this.DiagnosticList.forEach(X => {
          if (V['Name'] == X['Header']) {
            V['status'] = X['status']
          }
        });
      });
    }
    this.ngxUiLoaderService.stop();
  }

  getseqdata(value: string) {
    this.seqdataarr=[];
    this.selectedSeqName = value
    let seqdata =  this.connseqdatalist.length > 0 ?this.connseqdatalist.filter(V => V['Name'] == value):[];
    if(seqdata.length>0){
      let data=[]
      seqdata.forEach((V,index)=>{
        let obj={}
        obj['Details'+(index+1)]=[...V['Data']]
        data.push(obj);
      });

      this.seqdataarr.push({
        "Name": seqdata[0]['Name'],
        "Data": data
      });

    }
  }

  async exportreport() {
    var searchData = {
      "messagetype": this.selectedmessagetype,
      "id": this.selectedrouteId,
      "PAID": this.selectedProcessorAgentguid,
      "sourceagentid": "",
      "SEGUID": this.selectedSourceEntityguid,
      "destinationagentid": "",
      "DEGUID": this.selectedTargetEntityguid,
      "vendor": this.vendorid,
      "entityid": this.selectedEntityguid,
      "pagenumber": 0,
      "PageSize": this.PageSize,
      "userType": (this.isChecked) ? 2 : '',
      "type": 'not',
      "projectId": this.selectedprojId,
      "assignedUserId": (this.isChecked) ? JSON.parse(sessionStorage.getItem('rolewithmenus'))[0].uniqueId : '',
      "status": this.status ? "active" : "passive",
      "FromDate": this.fromdate,
      "ToDate": this.todate,
      "Direction": this.selecteddirectiontype,
      "DateType": this.fromdate != '' && this.todate != '' ? this.dateoption : '',
      "SETYPE": this.selectedSourceEntitytype,
      "DETYPE": this.selectedTargetEntitytype,
      "SEDEPLOYMENTKEY": this.selectedSourcedeploymentkey,
      "DEDEPLOYMENTKEY": this.selectedtargetedeploymentkey,
      "EETYPE": this.selectedEntitytype,
      "EEDEPLOYMENTKEY": this.selectedEntitydeploymentkey
    };
    var apiname = 'api/Routes/GetSearchDetails';
    this.ngxUiLoaderService.start();

    let response = await this.service.searchpostapi(apiname, searchData);

    const fileName = 'PipelineList.xlsx';
    this.exportToExcel(response, fileName);
    this.ngxUiLoaderService.stop();
  }

  exportToExcel(items: any[], fileName: string): void {

    const selectedItems = items.map(item => {
      return {
        "Pipeline Name": item.displayname,
        "GUID": item.id,
        "Source Entity": item.SENAME,
        "Destination Entity": item.DENAME,
        "Source Agent": item.SourceAgent,
        "Destination Agent": item.DestinationAgent,
        "Processor Type": item.PANAME,
        "Message Type": item.messagetype,
        "Direction": item.Direction,
        "Mode": item.mode,
        "Connectivity": item.Connectivity,
        "Vendor": item.vendor,
        "Created Date ": item.CreatedDate,
        "Go-Live Date": item.GoLiveDate,
        "Last Transaction Date": item.LastTransactionDate,
        "Status": item.status
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    //saveAs(blob, fileName);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

  }

  getSearchbyAccessionorOrderNumber(event) {
    let searchtext = event.target.value.toLowerCase();
    if (searchtext !== '') {
      this.failurereason = this.failurereasonBackup.filter(s => s.OrderNumber.toLowerCase().includes(searchtext) || s.AccessionNumber.toLowerCase().includes(searchtext));
    }
    else {
      this.failurereason = JSON.parse(JSON.stringify(this.failurereasonBackup));
    }
  }

  //The below method will be called when the user clicks on the data validation button under drop down
  OnValidation(content: any, data: any) {
    this.getInterfaceValidationHistoryInfo();
    this.pipelineName = data['displayname'];
    this.pipelineGUID = data.id;
    this.interfacePipelineDisplayname = this.pipelineName + "(" + this.pipelineGUID + ")";
    this.backupInterfacePipelineDisplayname = this.interfacePipelineDisplayname;
    const modalRef = this.modalService.open(content,
      { windowClass: "ValidationPopupScreen", backdrop: 'static', keyboard: false });
    modalRef.result.then(() => { });
  }

  //The below method will fetch the record from ValidationMaster table and stores the data into InterfaceValidationHistory
  getInterfaceValidationHistoryInfo() {
    this.ngxUiLoaderService.start();
    this.service.GetInterfaceValidationHistory().subscribe((data: any) => {
      console.log(data);
      this.ngxUiLoaderService.stop();
      this.InterfaceValidationHistory = data;
      for (let i = 0; i < this.InterfaceValidationHistory.length; i++) {
        this.InterfaceValidationHistory[i].FromDate = this.InterfaceValidationHistory[i].FromDate.split(" ")[0];
        this.InterfaceValidationHistory[i].ToDate = this.InterfaceValidationHistory[i].ToDate.split(" ")[0];
      }
      this.InterfaceValidationHistory.sort((a, b) => {
        if (a.Status === 'In-Progress' && b.Status !== 'In-Progress') {
          return -1; // a comes before b
        } else if (a.Status !== 'In-Progress' && b.Status === 'In-Progress') {
          return 1; // a comes after b
        } else {
          return 0; // preserve original order
        }
      });

      this.searchtextData = this.pipelineName;
      this.getFilter();
    }, error => {
      this.toastr.error("Unable to fetch pipeline details", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.InterfaceValidationHistory = []
      console.error(error);
      this.ngxUiLoaderService.stop();
    });
  }

  checkValidationMasterExists(reValidation) {
    let filtereddata: any;

    if (this.InterfaceValidationHistory[0].FromDate.includes("/")) {
      filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName &&
        va.PipelineGUID == this.pipelineGUID
        && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')
        && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')
        && va.Status == 'In-Progress');
    } else {
      filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName &&
        va.PipelineGUID == this.pipelineGUID
        && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'dd-MM-yyyy')).toString()
        && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'dd-MM-yyyy')).toString()
        && va.Status == 'In-Progress');
    }
    if (filtereddata.length > 0) {
      this.toastr.success("Data Validation is In-Progress for the selected date range", " ", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      return false;
    }
    else {
      if (this.InterfaceValidationHistory[0].FromDate.includes("/")) {
        filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName &&
          va.PipelineGUID == this.pipelineGUID
          && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')
          && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'MM/dd/yyyy')).toString().replace(/\b0/g, '')
          && va.Status == 'completed');
      } else {
        filtereddata = this.InterfaceValidationHistory.filter(va => va.PipelineName == this.pipelineName &&
          va.PipelineGUID == this.pipelineGUID
          && va.FromDate == (this.datepipe.transform(this.selectedFromDate, 'dd-MM-yyyy')).toString()
          && va.ToDate == (this.datepipe.transform(this.selectedToDate, 'dd-MM-yyyy')).toString()
          && va.Status == 'completed');
      }
      if (filtereddata.length > 0) {
        //calling revalidation method
        this.filteredInterfaceRevalidationData = filtereddata;
        this.reValidation(reValidation);
        return false;
      } else
        return true;
    }
  }
  isDateRangeValid(): boolean {

    let isactie = false;
    let today = new Date()
    if (today >= this.selectedFromDate && today >= this.selectedToDate) {
      if (this.selectedFromDate <= this.selectedToDate) {
        return true
      }
    }
    return isactie
  }
  //sends the selected interface information to the ValidationMaster table and then to topic
  sendDataForDataValidation(reValidation) {
    let requestBody;
    if (this.calculateDiff() < 7) {
      if (this.checkValidationMasterExists(reValidation)) {
        requestBody = {
          PipelineGUID: this.pipelineGUID,
          PipelineName: this.pipelineName,
          FromDate: this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00',
          ToDate: this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59',
          Generated_By: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
          Status: 'In-Progress'
        };
        this.service.InsertInterfaceValidationHistory(requestBody).subscribe((ReturnResponse: any) => {
          if (ReturnResponse.status == "Success") {
            this.toastr.success("Successfully sent interface information for Data Validation, it might take few minutes to process the validation.", " ", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          } else {
            console.log("sendDataForDataValidation() returnResponse from validationmaster insertion:: " + JSON.stringify(ReturnResponse));
          }
        }, (error) => {
          console.error("sendDataForDataValidation() error occured :: " + error);
          this.toastr.error(error, "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        });
      }
    }
    else {
      this.toastr.error("Date range should not exceed 7 days", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  sendDataForDataValidationTopic(requestBody: any) {
    this.ngxUiLoaderService.start();
    this.service.sendToTopic(requestBody).subscribe((response: any) => {
      this.ngxUiLoaderService.stop();
      console.log(response);
      this.getInterfaceValidationHistoryInfo();
    });
  }

  calculateDiff() {

    let todayDate = new Date(this.selectedToDate);
    let sentOnDate = new Date(this.selectedFromDate);
    sentOnDate.setDate(sentOnDate.getDate());
    let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  reValidation(reValidation) {

    this.modalReference = this.modalService.open(reValidation,
      { windowClass: "ReValidationPopupScreen", backdrop: 'static', keyboard: false });
    this.modalReference.result.then(() => { });
  }

  //Checks the status of the validationmaster entry
  isCompletedStatus(status: string): boolean {
    return status === 'completed';
  }

  //onClick of each validationmaster row entry the below method will get called and fetches record from the entitysearch table
  displayInterfaceValidationList(item: any) {


    // this.backupPipelineName = item['PipelineName'];
    // this.backupPipelineGUID = item.PipelineGUID;
    this.pipelineName = item['PipelineName'];
    this.pipelineGUID = item.PipelineGUID;
    // this.fromdate=item.FromDate;
    // this.todate=item.toDate;


    this.interfacePipelineDisplayname = '';
    setTimeout(() => {
      this.interfacePipelineDisplayname = item.PipelineName + "(" + item.PipelineGUID + ")";
    })


    this.getResponseDatabyMessageIdInfo(item);
  }

  transformDate(value: any) {
    if (value !== undefined) {
      let dates = value.split("-");
      if (dates.length > 0) {
        value = dates[1] + '-' + dates[0] + '-' + dates[2]
      }
      return value;

    }
  }

  //Fetches record from entitysearch table against pipelineguid
  getResponseDatabyMessageIdInfo(item: any) {
    let fromDate;
    let toDate;
    this.ngxUiLoaderService.start();
    this.callingComponent = 'Interface'
    if (item.FromDate.includes("/")) {
      fromDate = this.datepipe.transform(item.FromDate, 'yyyy-MM-dd');
      toDate = this.datepipe.transform(item.ToDate, 'yyyy-MM-dd');
    }
    else {
      fromDate = this.transformDate(item.FromDate);
      toDate = this.transformDate(item.ToDate);
    }
    this.service.GetDatabyMessageIdInfo(item.PipelineGUID, fromDate + ' 00:00:00', toDate + ' 23:59:59').subscribe((result: any) => {
      console.log(result);
      this.ngxUiLoaderService.stop();
      if (result.length > 0) {

        this.showInterfaceData = true;

        setTimeout(() => {
          this.interfacePipelineDisplayname = this.pipelineName + "(" + this.pipelineGUID + ")";
        })
        for (let i = 0; i < result.length; i++) {
          let ovj = {
            "Accession": null,
            "Order": null,
            "DateofBirth": null,
            "PatientName": null,
            "MessageId": null
          }
          result[i] = Object.assign(ovj, result[i]);

        }
        this.dataSources = result;
        console.log("datasource value is " + JSON.stringify(this.dataSources));
        this.selectedCard(this.dataSources[0], 0);
        this.responseLength = result.length;

      }
      else {
        this.ngxUiLoaderService.stop();
        this.toastr.error("No messages were found for data validation", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }, (error) => {
      this.ngxUiLoaderService.stop();
    });
  }

  //Calls GetInterfaceMessageDataByMessageId method and displays the validation data of particular message
  selectedCard(item: any, index: number) {
    this.selectedCardIndex = index;
    this.GetInterfaceMessageDataByMessageId(item);
  }

  //For a particular selected message gets the data validation response from messagevalidation table and shows the difference
  GetInterfaceMessageDataByMessageId(item) {
    let messageid = item.MessageId;
    this.ngxUiLoaderService.start();
    this.service.GetMessageDataByMessageId(messageid).subscribe((result: any) => {
      this.ngxUiLoaderService.stop();
      console.log(result);
      if (result[0] !== undefined) {
        this.validatedDataResponse = JSON.parse(result[0].StatusMessage);
      }
      else {
        this.validatedDataResponse = [];
      }
    });
  }

  //The below method is used for search of interface validation history data
  getFilter() {
    this.tripfilter.PipelineName = this.searchtextData;
    this.tripfilter.Fromdate = this.searchtextData;
    this.tripfilter.Todate = this.searchtextData;
    this.tripfilter.Generated_By = this.searchtextData;
    this.tripfilter.Generated_On = this.searchtextData;
    this.tripfilter.Status = this.searchtextData;
  }

  //The below method is used for search of  selectedcard data(Accession#,Order#,patientname)
  getFilterDetails() {
    this.cardfilter.Accession = this.searchData;
    this.cardfilter.Order = this.searchData;
    this.cardfilter.PatientName = this.searchData;
    this.cardfilter.DateofBirth = this.searchData;
  }

  //The below method will be called when clicked on the back button, and loads the data
  loadInterfaceDataValidationScreen() {
    this.ngxUiLoaderService.start();
    this.showInterfaceData = false;
    this.interfacePipelineDisplayname = this.backupInterfacePipelineDisplayname;
    this.responseLength = 0;
    this.searchData = '';
    this.getFilter();
    this.getFilterDetails();
    this.ngxUiLoaderService.stop();
  }

  performRevalidation() {
    let requestBody;
    let fromdate;
    let todate;
    fromdate = this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00';
    todate = this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59';
    this.ngxUiLoaderService.start();
    this.service.DeleteInterfaceValidatedMessages(this.pipelineGUID, fromdate, todate)
      .pipe(
        // Handle first message
        map((message) => requestBody = {
          "Id": this.filteredInterfaceRevalidationData[0].Id,
          "PipelineGUID": this.filteredInterfaceRevalidationData[0].PipelineGUID,
          "Generated_By": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
          "Generated_On": new Date().toString(),
          "Status": "In-Progress"
        })

        // Trigger second call and handle data
        , mergeMap(() => this.service.UpdateValidationMaster(requestBody).pipe(catchError(() => of('There was an error but I fixed it!'))))
        , map((message) => requestBody = {
          fromDate: this.selectedFromDate == undefined ? '' : this.datepipe.transform(this.selectedFromDate, 'yyyy-MM-dd') + ' 00:00:00',
          toDate: this.selectedToDate == undefined ? '' : this.datepipe.transform(this.selectedToDate, 'yyyy-MM-dd') + ' 23:59:59',
          pipelineGUID: this.pipelineGUID,
        })

        // // Trigger third call and handle data
        // , mergeMap(() => this.service.sendToTopic(requestBody))
        // , map((message) => console.log(message))
      ).subscribe(data => {
        console.log('Chain executed successfully. Good job :)')
        this.toastr.success("Successfully sent interface information for Data Validation, it might take few minutes to process the validation.", " ", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        })
        this.modalReference.close();
        this.getInterfaceValidationHistoryInfo();
        this.ngxUiLoaderService.stop();

      }, error => {
        this.ngxUiLoaderService.stop();
        console.error(error);
      })
  }

  dismissRevalidation(content: any) {
    this.modalReference.close();
    this.filteredInterfaceRevalidationData = [];
  }

  // changeDateFormat(givendate) {
  //   if (givendate.includes("-")) {
  //     let datearray = givendate.split("-")
  //     if (datearray.length > 0) {
  //       return datearray[1] + '-' + datearray[0] + '-' + datearray[2]
  //     }
  //   } else {
  //     return givendate;
  //   }
  // }
  openFTPfilespopup() {
    const dataToFTPFilesModal = {
      routeId: this.selectedRoutGUID,
      sourceagent: this.pipeLineDetails['SourceAgentid'],
      name: this.selectedRouteName,
      uniqid: this.isselect,
      selectflag: false
    };
    const modalRef = this.modalService.open(FtpfilesComponent,
      { windowClass: "myCustomModalClassFTP", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToFTPFilesModal;
    modalRef.result.then(() => { });
  }


  viewpassword() {

    if (this.isviewpassword) {
      this.service.auditsave("Add", "", "Viewed FTP password for pipeline :- " + this.pipeLineDetails['DisplayName'], "Pipeline", this.isselect, "Viewed Password (CIIS)");
    }
  }

  getPageDetails(event: { pageIndex: number; pageSize: number }) {
    if (event.pageIndex == 0) {
      this.pagenumber = 1;
    } else {
      this.pagenumber = event.pageIndex + 1;
    }

    this.PageSize = 15
    this.getpipelinelist(false, true);
  }
  failuresearchbydate() {
    if(this.issuccessmsgpop=='view'){
      this.getsuccessmsglist('');
    }else{
      let retval = this.GetDatewiseFailureReasonList(null, true);
    }
  }
  async clearnotepopupvalues(){
    this.failedMessageNote='';
  }

  async closenote(h) {
    await this.clearnotepopupvalues();
    h('close modal');
  }

  async saveNoteReprocess(h){
    this.ngxUiLoaderService.start();
    let checkedids = ""
    this.failurereason.forEach(V => {
      if (V['ischecked'] == true) {
        checkedids += V['MessageRefernceId'] + ','
      }
    })
    checkedids != '' ? checkedids.slice(0, -1) : checkedids;
    var datacons ={
      "messagerefernceid" : checkedids,
      "note" : this.failedMessageNote,
      "action" : "Reprocessed",
      "modifiedby" : JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    }
    this.messagereprocessdata.push(datacons);
      this.service.reprocessBymsgID(this.messagereprocessdata).subscribe((response: any) => {
        if (response['status'].toLowerCase() == 'success')
        {
          this.closenote(h);
          this.toastr.success("done successfully", "Reprocess", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          this.getpipelinedetails({ 'UniqueID': this.isselect }, 'pop');
          this.closepopup(h);
        }
        else
        {
          this.closepopup(h);
          this.toastr.error("Failed", "Reprocess", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        this.ngxUiLoaderService.stop();
    })
  }
  getsuccessmsglist(failurerespopup){
    let obj={
      "UniqueID": this.isselect,
      "FromDate":  this.fromdatepop!=''?this.fromdatepop + ' 00:00:00':null,
      "ToDate":this.todatepop!=''?this.todatepop + ' 23:59:59':null
    }
    this.ngxUiLoaderService.start();
    this.service.GetSuccessMessageList(obj).subscribe((data) => {
      if (data['status'].toLowerCase() == 'success') {
        let resbody=JSON.parse(data['responsebody']);
        if(this.issuccessmsgpop==''){
        this.successmsglist=resbody==null ||resbody==''?[]:resbody;
        }
        if(this.fromdatepop==''){
          let date: Date = new Date();
          date.setDate(date.getDate() - 6);
          this.fromdatepop = this.datepipe.transform(date, 'yyyy-MM-dd');
          this.todatepop = this.todaydate;
        }
        if(this.issuccessmsgpop=='view'){
          this.failurereason = resbody==null?[]:resbody
          this.failurereasonBackup = this.failurereason
          if(this.isopenpopup){
            this.isopenpopup=false;
            this.modalService
        .open(failurerespopup, {
          windowClass: "failuremsgpop",
          backdrop: "static",
          keyboard: false,
        })
        .result.then((result) => { });
          }
        }
        
      }
      this.ngxUiLoaderService.stop();
    }), error => {
      this.toastr.error("No data found", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    }
  }

  vbdiagnostic(){
    let obj={
      "AgentId": this.pipeLineDetails['SourceAgentid'],
      "JobId": this.jobid.length>0 && (this.jobid[0]['Value']!=undefined || this.jobid[0]['Value']!=null)?this.jobid[0]['Value']:'',
      "RouteId": this.selectedRoutGUID,
      "UserId": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    }
    this.ngxUiLoaderService.start();
    this.service.getvbdiagnostic(JSON.stringify(obj)).subscribe((data) => {
      if(data["status"]=="success"){
        let vburl=data["responsebody"];
        let params = `width=1000,height=500,left=250,top=150`;
        open(vburl, 'VBDiagnoistic', params);
      }
      this.ngxUiLoaderService.stop();
    }), error => {
      this.toastr.error("No data found", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
      this.ngxUiLoaderService.stop();
    };
  }
  viewmessages(routeid, inputstatus, msgcnttoday: number, msgcntyes: number) {
    // if (msgcnttoday + msgcntyes > 0) {
      var data = {
        "id": routeid,
        "status": inputstatus,
      };
      sessionStorage.setItem('hubmessagedata', JSON.stringify(data));
      this.router.navigate(['/home/messages']);
    // }
    // else {
    //   this.toastr.warning('There are no messages for selected Pipeline and status', "", {
    //     timeOut: 4000,
    //     positionClass: 'toast-bottom-right'
    //   });
    // }
  }


  openMapping(dataitem) {
    if (dataitem.id != '' && dataitem.PAID != '') {

      this.ngxUiLoaderService.start();
      var url = "api/GetMappings/Get?routeid=" + dataitem.id + "&processorid=" + dataitem.PAID + "&processorapikey=" + dataitem.PAAPIKEY;
      this.service.getapiurl(url).subscribe({
        next: responce => {

          this.Templatearray = responce as any[];
          this.dataToModal = {
            templateid: this.Templatearray["template"],
            templatelist: this.Templatearray["templatelist"],
            MappedXml: this.Templatearray["mappedxml"],
            resultJson: this.Templatearray["resultjson"],
            routeid: dataitem.id,
            processorid: dataitem.PAID,
            processorapikey: dataitem.PAAPIKEY,
            routeName: dataitem.displayname,
            ProcessorName: dataitem.PANAME,
            uniqueid: dataitem.UniqueID,
            routedetails: this.Templatearray["routeDetails"],
            CoreConfigVersionList: this.Templatearray["CoreConfigVersionList"],
            lockXMLmap: dataitem.IsLocked !== undefined && dataitem.IsLocked == "True" ? dataitem.IsLocked : false
          };

          if (this.Templatearray["mappedxml"] != null) {
            const modalRef = this.modalService.open(XmlmappingsComponent,
              { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
            modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
            modalRef.result.then(() => { });
          }
          else {
            this.modalService.dismissAll();
            this.toastr.error("Template configurations not found", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        },
        error: error => {
          this.ngxUiLoaderService.stop();
          this.toastr.error("Unable to fetch the Mappings", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      });
    }
    else {
      this.toastr.error("Pipeline and Processor are mandatory", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

    //Tryitout modal start

    async opentryitout(dataitem) {
      if (dataitem.id != '' && dataitem.PAID != '') {
        this.ngxUiLoaderService.start();
        var response = await this.getFileConfig(dataitem.id, dataitem.PAID);
        if (response != "") {
          var jsonData = JSON.parse(response[0]["json"]);
          var vaconfigdata = jsonData.processorconfig["vaconfig"];
          var interfacetype = jsonData.processorconfig["interfacetype"];
  
          if (dataitem.messagetype === "order") {
            this.fileUploadFTP = true;
            this.enableAccessionSearch = false;
          } else if (dataitem.messagetype === "result") {
            this.fileUploadFTP = false;
            this.enableAccessionSearch = true;
          }
          else {
            this.fileUploadFTP = false;
            this.enableAccessionSearch = false;
          }
  
          this.dataToModal = {
            RouteIdtry: dataitem.id,
            ProcessorIdtry: dataitem.PAID,
            ProcessorDisplayNametry: dataitem.PANAME,
            RouteDisplayNametry: dataitem.displayname,
            Processorapikey: dataitem.PAAPIKEY,
            Popupflag: true,
            MappedXmlTemp: '',
            isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
            isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
            interfacetype: interfacetype,
            enableAccessionSearch: this.enableAccessionSearch,
            fileUploadFTP: this.fileUploadFTP,
            sourcedeploymentkey: dataitem.SEDEPLOYMENTKEY,
            targetdeploymentkey: dataitem.DEDEPLOYMENTKEY,
            sourceaccounttype: dataitem.sourcetype,
            targetaccounttype: dataitem.targettype,
            seguid: dataitem.SEGUID,
            deguid: dataitem.DEGUID,
            sourceagent: dataitem.sourceagentid
          };
  
  
  
        }
        this.ngxUiLoaderService.stop();
        const modalRef = this.modalService.open(TryitoutComponent,
          { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
  
        modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
        modalRef.result.then(() => { });
      }
      else {
        this.toastr.warning("Pipeline and Processor are mandatory", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
  
    }
    async getFileConfig(routeid: string, ProcessorId: string) {
      var dataPost = {
        agentid: ProcessorId,
        guids: routeid,
      };
      return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);
  
    }
    editPipeline(item) {
      if (item.Version == '2') {
        sessionStorage.setItem('pipelineid', JSON.stringify(item.id));
        this.router.navigate(['/home/createpipeline']);
      }
      else {
        const modalRef = this.modalService.open(Routeversion1Component,
          { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
        modalRef.componentInstance.inputDataInModalComponent = item;
        modalRef.result.then((data) => {
          this.isChecked = false;
          if (data == 'save') { this.search(false, true); }
        });
      }
    }

    openassignusermodal(modal, obj) {
      if (this.pagerolepermisions.assignuser) {
        this.pipelineObj = '';
        this.pipelineObj = obj;
        if (this.pipelineObj.assignedUserId) {
          this.selecteduserid = this.pipelineObj.assignedUserId;
          this.isftpuser = true;
  
          this.selectedusername = this.pipelineObj.assignedUserName;
          this.reactiveFormuser.patchValue({
            user: this.selectedusername
          })
        }
      }
      const modalRef = this.modalService.open(modal,
        { windowClass: "assignuser", backdrop: 'static', keyboard: false });
      modalRef.result.then(() => { });
    }

    getuserdetails() {
      this.ngxUiLoaderService.start();
      var url = "api/UserRoleConfig/GetInterfaceAssignUsers?userid=" + JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'];
      this.service.getapiurl(url).subscribe(data => {
        if (data) {
          this.ngxUiLoaderService.stop();
          this.userlist = data as any[];
          this.userlistbackup = this.userlist;
        }
      });
    }
    assignuser(c) {
  
      let interfacearray = <any>[];
      interfacearray.push(this.pipelineObj.displayname);
      if (this.selecteduserid) {
        if (this.pipelineObj.assignedUserId != this.selecteduserid) {
          this.ngxUiLoaderService.start();
          let obj = {
            userid: this.selecteduserid,
            username: this.selectedusername,
            modifiedby: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
            routeuniqueid: this.pipelineObj.UniqueID,
            pipelines: interfacearray,
            isFtpAvailableForUser: this.isftpuser
          }
          let url = "api/Routes/AssignUserToInterface";
          this.service.postapi(url, obj).then(data => {
            if (data) {
              this.ngxUiLoaderService.stop();
              if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
                this.ngxUiLoaderService.stop();
                this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              }
              else {
                if (data.status.toLowerCase() == "success") {
                  let displaymessage = '';
                  displaymessage = 'Pipeline has been assign to ' + this.selectedusername;
  
                  this.service.auditsave("Add", '', displaymessage, "Pipeline", this.pipelineObj.UniqueID,'Pipeline has been assigned Successfully').then(data => {
                    if (data['status'] && data['statusText']) {
                      this.toastr.error("Unable to Audit the transaction", "", {
                        timeOut: 4000,
                        positionClass: 'toast-bottom-right'
                      });
                    }
                  })
  
                  this.toastr.success(data.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
                  this.search(false, true);
                  this.closeusermodal(c);
                }
                else {
                  this.toastr.error(data.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
                }
              }
            }
          })
        }
        else {
          this.toastr.warning("User already assigned", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
      else {
        this.toastr.warning("Please select the valid user", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
  
    }
    closeusermodal(c) {
      this.resetuserdetails();
      c('close modal');
    }
    resetuserdetails() {
      this.userlist = this.userlistbackup;
      this.reactiveFormuser.reset();
      this.selecteduserid = '';
      this.selectedusername = '';
      this.isftpuser = false;
      this.isLoadinguser = false;
    }
    selecteduser(event) {
      this.selecteduserid = event.UserId;
      this.selectedusername = event.UserName;
      this.isftpuser = event.IsFtpAvailableForUser;
    }
    searchuserCleared() {
      this.selecteduserid = '';
      this.isftpuser = false;
      this.userlist = this.userlistbackup;
      this.reactiveFormuser.setValue({ "user": '' });
    }
  
    getuserName(event) {
      this.isLoadinguser = false;
      this.selecteduserid = '';
      if (this.userlist.length > 0) {
        this.filteruserlist(event);
      }
      else {
        this.userlist = this.userlistbackup;
        this.filteruserlist(event);
      }
    }
    filteruserlist(event) {
      this.userlist = this.userlist.filter(t => t.UserName.toLowerCase().includes(event.toLowerCase()));
      if (this.userlist.length == 0) {
  
        this.notfounddata = true;
  
      } else {
        this.notfounddata = false;
      }
      this.isLoadinguser = false;
    }
    openValidationMapping(dataitem) {
      if (dataitem.id != '' && dataitem.PAID != '') {
  
        this.ngxUiLoaderService.start();
        var url = "api/GetMappings/Get?routeid=" + dataitem.id + "&processorid=" + dataitem.PAID + "&processorapikey=" + dataitem.PAAPIKEY;
        this.service.getapiurl(url).subscribe({
          next: responce => {
  
            this.Templatearray = responce as any[];
            const coreConfigVersionList = this.Templatearray["CoreConfigVersionList"];
            const liveConfig = coreConfigVersionList.find(config => config.islive === true);
           if (liveConfig &&  liveConfig.json.processorconfig.validationconfig) {
            this.mappedXml = liveConfig.json.processorconfig.validationconfig.mappedxml;
          } else {
              this.mappedXml = this.Templatearray["mappedxml"];
          }
            this.dataToModal = {
              templateid: this.Templatearray["template"],
              templatelist: this.Templatearray["templatelist"],
              MappedXml: this.mappedXml, 
              resultJson: this.Templatearray["resultjson"],
              routeid: dataitem.id,
              processorid: dataitem.PAID,
              processorapikey: dataitem.PAAPIKEY,
              routeName: dataitem.displayname,
              ProcessorName: dataitem.PANAME,
              uniqueid: dataitem.UniqueID,
              routedetails: this.Templatearray["routeDetails"],
              CoreConfigVersionList: coreConfigVersionList,             
              lockXMLmap: dataitem.IsLocked !== undefined && dataitem.IsLocked == "True" ? dataitem.IsLocked : false,
              isValidation:true
            };
  
            if (this.Templatearray["mappedxml"] != null) {
              const modalRef = this.modalService.open(XmlmappingsComponent,
                { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
              modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
              modalRef.result.then(() => { });
            }
            else {
              this.modalService.dismissAll();
              this.toastr.error("Template configurations not found", "", {
                timeOut: 4000,
                positionClass: 'toast-bottom-right'
              });
            }
          },
          error: error => {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Unable to fetch the Validation Mappings", "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        });
      }
      else {
        this.toastr.error("Pipeline and Processor are mandatory", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
}
