import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interfaceLabAdminServices } from 'src/app/services/InterfaceLabAdmin.services';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-interfacemessagecountheader',
  templateUrl: './interfacemessagecountheader.component.html',
  styleUrls: ['./interfacemessagecountheader.component.scss']
})
export class InterfacemessagecountheaderComponent {

  @Input() inputDataInModal: any;
  MessagecountsArray: any;
  ContentContainer: boolean = false;
  direction: string = "";
  messagestatuscolour: string = "";

  constructor(private ngxUiLoaderService: NgxUiLoaderService,
    private service: interfaceLabAdminServices,
    private toastr: ToastrService,
    public cmmservice: CommonService) {

  }

  ngOnInit() {
  }
  ngOnChanges() {
    if (Object.entries(this.inputDataInModal.labadminLoginInput).length !== 0) {
      this.getmessagescounts();
      this.onClickmessagecountcolorinbound = false;
      this.onClickmessagecountcoloroutbound = false;
      this.Outboundresolvedcolorflag = false;
      this.Inboundresolvedcolorflag = false;
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;
    }

    if (this.direction != '' && this.messagestatuscolour != '' && this.inputDataInModal.dashboardcountflag == true) {
      this.RedirecttoMessagelist(this.direction, this.messagestatuscolour, false)
      // this.direction="";
      // this.messagestatuscolour="";
    }
    else {
      this.direction = "";
      this.messagestatuscolour = "";
    }
  }

  getmessagescounts() {


    let obj = {
      Deploymentkey: this.inputDataInModal.labadminLoginInput.Deploymentkey,
      Messagetype: this.inputDataInModal.messagetype,
      Fromdate: this.inputDataInModal.fromDate,  // Concate the time
      Todate: this.inputDataInModal.toDate,
      LocationGuidsIncluded: true,
      LocationGuids: this.inputDataInModal.labadminLoginInput.EntityGuid
    }
    if (this.inputDataInModal.loaderfalg) {
      this.ngxUiLoaderService.start();

    }


    this.service.GetMessageHeaderCounts(obj).subscribe((data: any) => {
      this.MessagecountsArray = data;
      if (this.inputDataInModal.messagetype == 'Result') {
        this.isAscending = false;
        this.MessagecountsArraySortBy('Sequence');
      }
      this.ContentContainer = true;
     if(this.inputDataInModal.loaderfalg)
     {
      this.ngxUiLoaderService.stop();

     }

    },
      (error) => {
        this.toastr.warning("Error occured while calling api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxUiLoaderService.stop();
      }
    );
  }

  isAscending: boolean = true;
  MessagecountsArraySortBy(property: string) {
    this.MessagecountsArray.sort((a, b) => {
      const sortOrder = this.isAscending ? 1 : -1;
      if (a[property] < b[property]) return -1 * sortOrder;
      if (a[property] > b[property]) return 1 * sortOrder;
      return 0;
    });
    this.isAscending = !this.isAscending;
  }
  onClickmessagecountcolorinbound: boolean = false;
  onClickmessagecountcoloroutbound: boolean = false;
  Inboundsucesscolorflag: boolean = false;
  Outboundsucesscolorflag: boolean = false;
  Inboundresolvedcolorflag: boolean = false;
  Outboundresolvedcolorflag: boolean = false;
  OutboundInprogresscolorflag: boolean = false;
  InbounInprogresscolorflag: boolean = false;


  RedirecttoMessagelist(directions, messagestatus, emitflag) {
    this.direction = directions;
    this.messagestatuscolour = messagestatus;
    if (messagestatus == "Failed" && directions == "Inbound") {
      this.onClickmessagecountcolorinbound = true;
      this.onClickmessagecountcoloroutbound = false;
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;
    }
    else if (messagestatus == "Failed" && directions == "Outbound") {
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.onClickmessagecountcolorinbound = false;
      this.onClickmessagecountcoloroutbound = true;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;
    }
    else if (messagestatus == "Success" && directions == "Inbound") {
      this.Inboundsucesscolorflag = true;
      this.Outboundsucesscolorflag = false;
      this.onClickmessagecountcolorinbound = false;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = false;
      this.onClickmessagecountcoloroutbound = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;
    }
    else if (messagestatus == "Success" && directions == "Outbound") {
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = true;
      this.onClickmessagecountcolorinbound = false;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = false;
      this.onClickmessagecountcoloroutbound = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;
    }
    else if (messagestatus == "Resolved" && directions == "Inbound") {
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.onClickmessagecountcolorinbound = false;
      this.onClickmessagecountcoloroutbound = false;
      this.Inboundresolvedcolorflag = true;
      this.Outboundresolvedcolorflag = false;
      this.Inboundresolvedcolorflag = true;
      this.Outboundresolvedcolorflag = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;

    }
    else if (messagestatus == "Resolved" && directions == "Outbound") {
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.onClickmessagecountcolorinbound = false;
      this.onClickmessagecountcoloroutbound = false;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = true;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = false;

    }
    else if (messagestatus == "In-progress" && directions == "Outbound") {
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.onClickmessagecountcolorinbound = false;
      this.onClickmessagecountcoloroutbound = false;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = false;
      this.OutboundInprogresscolorflag = true;
      this.InbounInprogresscolorflag = false;
    }
    else if (messagestatus == "In-progress" && directions == "Inbound") {
      this.Inboundsucesscolorflag = false;
      this.Outboundsucesscolorflag = false;
      this.onClickmessagecountcolorinbound = false;
      this.onClickmessagecountcoloroutbound = false;
      this.Inboundresolvedcolorflag = false;
      this.Outboundresolvedcolorflag = false;
      this.OutboundInprogresscolorflag = false;
      this.InbounInprogresscolorflag = true;
    }
    if (emitflag) {
      let obj = {
        "directions": directions,
        "messagestatus": messagestatus

      }
      this.service.interfacelistpagedisplay.emit(false);
      this.interfacedirectionEmitter.emit(obj);
    }

  }

  interfacelistpagedirection(directions) {
    let obj = {
      "directions": directions
    }
    this.service.interfacelistpagedisplay.emit(true);
    this.interfacedirectionEmitter.emit(obj);
    this.direction = "";
    this.messagestatuscolour = "";
  }

  @Output() interfacedirectionEmitter = new EventEmitter<any>();
  // @Output() messagelistdirectionEmitter = new EventEmitter<any>();

}
