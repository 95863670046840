import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
import { TryitoutComponent } from 'src/app/tryitout/tryitout.component';
import { XmlmappingsComponent } from 'src/app/xmlmappings/xmlmappings.component';
import { ProcessorComponent } from '../processor/processor.component';
import { NewauditComponent } from 'src/app/newaudit/newaudit.component';
declare const copyToClipboard: any;

@Component({
  selector: 'app-agentlist',
  templateUrl: './agentlist.component.html',
  styleUrls: ['./agentlist.component.scss']
})
export class AgentlistComponent implements OnInit {
  screenHeight: number;

  constructor(
    private router: Router,
    private service: CommonService,
    private _fb: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService, private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.reactiveFormpa = _fb.group({ paname: '' });
  }
  usernamedisplay: string;
  userrole: string = '';
  pagerolepermisions: any = [];
  cardHeight: Number = window.innerHeight - 222;
  notfounddata: boolean;
  async ngOnInit() {
    this.screenHeight = window.innerHeight - 150
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'agentlist').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });

      if (this.pagerolepermisions == undefined) {
        this.router.navigate(['/login']);
      }

    }
    else {
      this.router.navigate(['/login']);
    }
  }


  AgentNameList: any = [];
  keywordsearch = "displayname";
  reactiveFormpa: FormGroup;
  selectedAgentguid: string = "";
  selectedAgentName: string = "";
  selectedAgentUniqueId: any = '';
  isLoadingpa: boolean = false;
  selectedAgents(item) {
    this.selectedAgentguid = item.id;
    this.selectedAgentName = item.name;
  }
  searchAgentCleared(event) {
    this.isLoadingpa = false;
    this.AgentNameList = [];
    this.selectedAgentguid = '';
    this.notfounddata = false;
  }

  getAgentNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingpa = true;

      var apiname = "api/Agents/GetByDisplayName?name=" + val + '&agentid=0&type=Processor&filterflag=true';
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.AgentNameList = data as any[];
          this.AgentNameList.map(obj => obj.displayname = `${obj.displayname} ${'('} ${obj.id} ${')'} ${'('} ${obj.apikey} ${')'}`);
          this.isLoadingpa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingpa = false;
          this.notfounddata = true;
        }
      });
    }
  }

  reset() {

    this.reactiveFormpa.reset();
    this.selectedAgentguid = '';
    this.selectedAgentName = '';
    this.SearchDetailsList = [];
  }

  getProcessorDisplayname(value) {
    value = value.split('(')
    return `${value[0]} <br>${'('} ${value[1]} <br> ${'('} ${value[2]}`
  }

  FadeOutmainpageMsg() {
    this.statusmessageMainflg = true;
    setTimeout(() => {
      this.statusmessageMainflg = false;
    }, 20000);
  }
  myFlagForSlideToggle: boolean = true;
  classObjalertmain: string = "";
  StatusMessagedisplay: string = "";
  statusmessageMainflg: boolean = false;
  SearchDetailsList: any = [];
  AllAgentNameList: any = [];

  async searchagents() {

    if (this.selectedAgentguid == '') {
      if (!!this.reactiveFormpa.get('paname').value) {
        this.toastr.error('Please check  Procesor name or guid', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        this.SearchDetailsList = [];
        return;
      }
    }

    var searchData = {
      "id": this.selectedAgentguid,
      "type": "Processor"
    };

    var apiname = 'api/Agents/GetSearchDetails';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, searchData);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error('Unable to search the Processor', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });

    } else {

      const resultset = response as any[];
      if (this.selectedAgentguid != '' && resultset.length == 1) {
        this.selectedAgentUniqueId = resultset["UniqueID"];
      }
      const resultsetNew = [];
      const agentID = [];

      resultset.forEach(element => {
        var processoragentconfigdata;
        if (element.jsonconfig) {
          var jsonconfig = JSON.parse(element.jsonconfig);
          processoragentconfigdata = jsonconfig.processoragentconfig;
          if (processoragentconfigdata != undefined) {
            if (processoragentconfigdata.sourceagentid) {
              if (agentID.find(agents => agents == processoragentconfigdata.sourceagentid) == undefined) {
                agentID.push(processoragentconfigdata.sourceagentid);
              }
            }
            if (processoragentconfigdata.destinationagentid) {
              if (agentID.find(agents => agents == processoragentconfigdata.destinationagentid) == undefined) {
                agentID.push(processoragentconfigdata.destinationagentid);
              }
            }
          }
        }
      });

      const searchData = agentID.join(",");
      var apiname = 'api/Agents/GetAllAgentListName?agentid=' + searchData;
      this.AllAgentNameList = await this.service.getapi(apiname);
      resultset.forEach(element => {
        var processoragentconfigdata;
        var eventtype = "";
        var messagetype = "";
        var sourceagentid = "";
        var interfacetype = "";
        var destinationagentid = "";
        var sourceagentname = "";
        var targetagentname = "";
        if (element.jsonconfig) {
          var jsonconfig = JSON.parse(element.jsonconfig);
          processoragentconfigdata = jsonconfig.processoragentconfig;
          var processoragentconfig = jsonconfig.processorconfig;
          if (processoragentconfig?processoragentconfig.interfacetype:false) {
            interfacetype = processoragentconfig.interfacetype;
          }
          if (processoragentconfigdata != undefined) {
            if (processoragentconfigdata.messagetype) {
              messagetype = processoragentconfigdata.messagetype;
            }
            if (processoragentconfigdata?processoragentconfigdata.sourceagentid:false) {
              sourceagentid = processoragentconfigdata.sourceagentid;
              if (this.AllAgentNameList.find(x => x.id == sourceagentid) != undefined)
                sourceagentname = this.AllAgentNameList.find(x => x.id == sourceagentid).displayname;
            }
            if (processoragentconfigdata?processoragentconfigdata.destinationagentid:false) {
              destinationagentid = processoragentconfigdata.destinationagentid;
              if (this.AllAgentNameList.find(x => x.id == destinationagentid) != undefined)
                targetagentname = this.AllAgentNameList.find(x => x.id == destinationagentid).displayname;
            }
          }
        }
        resultsetNew.push({
          eventtype: eventtype,
          description: element.description,
          jsonconfig: element.jsonconfig,
          status: element.status,
          id: element.id,
          UniqueID: element.UniqueID,
          name: element.name,
          displayname: element.displayname,
          apikey: element.apikey,
          messagetype: messagetype,
          sourceagentid: sourceagentid,
          sourceagentname: sourceagentname,
          destinationagentid: destinationagentid,
          destinationagentname: targetagentname,
          interfacetype: interfacetype,
          processortype: element.processortype,
        });
      });

      this.SearchDetailsList = resultsetNew;
      if (this.SearchDetailsList.length == 0) {
        this.toastr.warning('No results found!!', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      this.ngxUiLoaderService.stop();
    }


  }


  async s4() {
    return await Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  async getGuid() {
    var uuid = await this.s4() + await this.s4() + '-' + await this.s4() + '-' + await this.s4() + '-' + await this.s4() + '-' + await this.s4() + await this.s4() + await this.s4();

    return uuid.toUpperCase();
  }

  public dataToModal: any = {
    id: this.getGuid(),
    apikey: this.getGuid()
  };


  openaddoreditagent(item) {
    this.dataToModal = item;
    const modalRef = this.modalService.open(ProcessorComponent,
      { windowClass: "agentmodaledit", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then((data) => {
      if (data == 'save') { this.searchagents(); }
    });
  }

  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  async opentryiytoutagentlist(item) {

    var vaconfigdata = JSON.parse(item.jsonconfig).processorconfig["vaconfig"];
    this.dataToModal = {
      RouteIdtry: "",
      ProcessorIdtry: item.id,
      ProcessorDisplayNametry: item.name,
      RouteDisplayNametry: "",
      Processorapikey: item.apikey,
      Popupflag: true,
      MappedXmlTemp: JSON.parse(item.jsonconfig).processorconfig == undefined ? "" : JSON.parse(item.jsonconfig).processorconfig.xslconfig.mappedxml,
      isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
      isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true
    }

    const modalRef = this.modalService.open(TryitoutComponent,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });

    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then(() => { });

  }



  openmapping(item) {

    this.ngxUiLoaderService.start();
    var url = "api/GetMappings/Get?routeid=0&processorid=" + item.id + "&processorapikey=" + item.apikey;

    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.ngxUiLoaderService.stop();
        const Templatearray = data as any[];
        this.dataToModal = {
          MappedXml: Templatearray["mappedxml"],
          routeid: '',
          processorid: item.id,
          templateid: Templatearray["template"],
          templatelist: Templatearray["templatelist"],
          resultJson: Templatearray["resultjson"],
          routeName: '',
          ProcessorName: item.name,
          processorapikey: item.apikey,
          uniqueid: '',
          isprocessormapping: true
        };

        if (Templatearray["mappedxml"] != null) {

          const modalRef = this.modalService.open(XmlmappingsComponent,
            { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
          modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
          modalRef.result.then(() => { });

        }
        else {
          this.modalService.dismissAll();
          this.toastr.error('Template configuration not available', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error(error.statusText + 'Unable to load the mappings', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  openAuditPopUp(uniqueid: any, processorguid: any,processorname:any) {

    if (uniqueid != '' && processorguid != '') {
      const auditDataToModal = {
        id: uniqueid,
        title: processorname,
        context:"Processor",
        guid: processorguid,
        type:"ID",
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }


}
