import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from "../services/common.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VarulesComponent } from '../varules/varules.component';
import { I } from '@angular/cdk/keycodes';

declare const copyToClipboard: any;
@Component({
  selector: 'app-nonvitalcore',
  templateUrl: './nonvitalcore.component.html',
  styleUrls: ['./nonvitalcore.component.scss']
})
export class NonvitalcoreComponent implements OnInit {


  SearchTypes: string[] = ["Handler", "GroupId", "VendorName"];
  autocompleteForm: any;
  selectedSearchType: any;
  deploymentItemsList = [];
  deploymentSelected: string = '';
  searchTypeSelected: string = '';
  public reactiveFormdeployment: FormGroup;
  public reactiveFormType: FormGroup;
  searchQuery: string = '';
  handlerresponseArray: [];
  routeName = "";
  failedMessageNote: string = '';

  handlerProperties = [
    { name: 'Description', displayName: 'Description'},
    { name: 'Direction', displayName:'Direction'},
    { name: 'localOutboundFolder', displayName: 'Local Outbound Folder'},
    { name: 'localOutboundBckFolder', displayName:'Local Outbound Bck Folder'},
    { name: 'localSourceFolder', displayName: 'Local Source Folder'},
    { name: 'localProcessingFolder', displayName: 'Local Processing Folder'},
    { name: 'localFailedFolder', displayName: 'Local Failed Folder'},
    { name: 'localSuccessFolder', displayName: 'Local Success Folder'},
    { name: 'localIgnoredFolder', displayName: 'Local Ignored Folder'},
    { name: 'dateWiseFolderFormat', displayName: 'DateWiseFolderFormat'},
    { name: 'fileTypes', displayName: 'FileTypes'},
    { name: 'isActive', displayName: 'IsActive'},
    { name: 'groupID', displayName: 'GroupID'},
    { name: 'msh', displayName: 'MSH'},
    { name: 'GoLiveDate', displayName: 'GoLiveDate'},
    { name: 'vendorName', displayName: 'Vendor Name'},
    { name: 'Message', displayName: 'Message'},
    { name: 'Type', displayName: 'Type'},
    { name: 'EndDate', displayName: 'EndDate'},
    { name: 'System', displayName: 'System'},
    { name: 'Projectid', displayName: 'ProjectId'},
    { name: 'isTCPC', displayName: 'IsTCPC'},
    { name: 'TestMode', displayName: 'TestMode'},
    { name: 'MessageSubtype', displayName: 'MessageSubtype'},
    { name: 'OwnerLabOrgID', displayName: 'OwnerLabOrgID'},
    { name: 'FindFileIn', displayName: 'FindFileIn'},
    { name: 'ArchivePath', displayName: 'ArchivePath'},
    { name: 'ShowMessage', displayName: 'ShowMessage'},
    { name: 'Id', displayName: 'Id'},
    { name: 'InterfaceName', displayName: 'InterfaceName'}
  ];


  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    public service: CommonService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal

  ) {
    this.reactiveFormdeployment = this._formBuilder.group({ deploymentname: ['', Validators.required] });
    this.reactiveFormType = this._formBuilder.group({ SearchType: ['', Validators.required] });
  }

  ngOnInit() {
    this.autocompleteForm = this._formBuilder.group({
      searchType: ['']
    })
    this.getDeployments();
  }

  onSearchTypeSelected(selectedValue: any) {
    this.selectedSearchType = selectedValue;
  }
  getDeployments() {
    this.service.getDeployments("Deployments").subscribe(
      (data) => {
        if (data.length > 0) {
          this.deploymentItemsList = data;
        } else if (data.length === 0) {
          this.deploymentItemsList = [];
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }
  selectdeploymentEvent(item: any) {
    this.deploymentSelected = item;
  }
  selectSearchType(item: any) {
    this.searchTypeSelected = item;
  }
  cleareddeployment(event) {
    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
  }
  clearSearchType(event) {
    this.searchTypeSelected = '';
    this.reactiveFormType.reset();
    this.reactiveFormType.patchValue({ "SearchType": '' });
    this.reactiveFormType.patchValue(this.SearchTypes);
  }
  clearSearch(): void {
    this.searchQuery = '';
  }
  async getHandlers() {
    if (this.deploymentSelected != '' && this.searchTypeSelected != "" && this.searchQuery != '' ) {
      let objmapping = {
        "Type": this.searchTypeSelected,
        "SearchKey": this.searchQuery,
        "dep": this.deploymentSelected
      }
      //  if (this.searchQuery.length<=2) {
      //   this.toastr.error('Please enter minimum 3 characters to search', "", {
      //     timeOut: 4000,
      //     positionClass: 'toast-bottom-right'
      //   })
      // }
    if(this.searchQuery.length>=2){
      
    this.ngxUiLoaderService.start();
      this.handlerresponseArray = await this.service.dxpostapi("api/VitalHubDXAPI/GetNonVitalCoreInterfaces", objmapping);
      this.ngxUiLoaderService.stop();
    }
    else  {
      this.toastr.error('Please enter minimum 3 characters to search', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }

     
      if (this.handlerresponseArray.length == 0) {

        this.ngxUiLoaderService.stop();
        this.toastr.error('No Result found for the search criteria', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
        return false;
      }

    }
    else if (this.deploymentSelected == '') {
      this.toastr.error('Deployemt key is mandatory', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
    else if (this.searchTypeSelected == '') {
      this.toastr.error('Please select the search type in Type section', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
    else if (this.searchQuery == '') {
      this.toastr.error('Please enter value in search bar', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }


  }

  handlerPopUp(handlerPopup) {
    this.modalService.open(handlerPopup, { windowClass: "modalConfig", backdrop: 'static', keyboard: false });
  }
  async clearnotepopupvalues() {
    this.failedMessageNote = '';
  }
  async closenote(h) {
    await this.clearnotepopupvalues();
    h('close modal');
  }
  getVaRule(handler: any) {

    let dataToruleModal = {
      routename: handler,
      deploymentkey: this.deploymentSelected,
      enableedit: true
    };
    const modalRef = this.modalService.open(VarulesComponent,
      { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
    modalRef.componentInstance.inputDataInModalComponent = dataToruleModal;
    modalRef.result.then(() => { });

  }
  submitReset() {
    this.searchQuery = '';
    // this.searchTypeSelected='';
    // this.deploymentSelected='';
    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
    this.reactiveFormType.reset();
    this.searchTypeSelected = '';
    this.reactiveFormType.reset();
    this.reactiveFormType.patchValue({ "SearchType": '' });
    this.reactiveFormType.patchValue(this.SearchTypes);
    this.handlerresponseArray = [];
  }

  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }


    
  }


}

