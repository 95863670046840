// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sessionTimeout: 1800000,
  filedownloadurl:"https://file-qc.vitalaxis.net/api/v2/download/",
  headers:{
    "hash":"F2uAmVtgH6Wsahz4R0d9VuCMnlLsiK55+u2pU3JZNZEgD83Ct/eHqR2vrPlkg0sb8k88oZuEWGGZ46HuOyZBFA==",
    "transaction":"20191113150215",
    "senderagent":"B184EDCE-B6BA-4151-8B15-C24348118198"
  },
  headersv4:{
    "transaction":"",
    "hash":"",
    "senderagent":""
  },
  "filedownloadurlv4":"",
  HandlerandRuleCreate: {
    HandlerPostJson: {
        handler:"",
        description:"",
        direction:"",
        fileTypes:"",
        isActive:0,
        groupID:"",
        vendorName:"",
        message:"",
        types:"VitalCore",
        system:"Lab External",
        testMode:0,
        localOutboundFolder:"",
        localOutboundBckFolder:"",
        localSourceFolder:"",
        localProcessingFolder:"",
        localFailedFolder:"",
        localSuccessFolder:"",
        localIgnoredFolder:"",
        messageSubtype:"",
        ownerLabOrgID:"",
        deploymentkey:""
    },
    RulePostJson: {
      RuleName: "",
      RuleDesc: "",
      CaseType: "*",
      Service: -1,
      RuleType: 1,
      Status: 1,
      EffectiveDate: "1/19/2021 10:30:09 AM",
      LabId: "",
      CaseId: -1,
      LabOrgId: "",
      OrdFacilityId: "",
      OrdFacOrgId: "",
      PathologistId: -1,
      PathOrgId: -1,
      PathAccountId: -1,
      OrdPhysician: -1,
      RefPhysician: -1,
      EntityName: "Hl7Integration",
      Mode: 102,
      SurgicalCenter: -1,
      parentruleid: 0,
      ExOrdFacilityId: "",
      CaseStatus: "*",
      ExServices: "",
      ExCaseTypes: "",
      PrimaryPGP: -1,
      VaRuleGUID: "",
      ModifiedBy: "",
      ModifiedDate: "",
      CreatedBy: -1,
      CreatedDate: "1/19/2021 10:30:00 AM",
      RuleConfig: "",
      TestMode: 0,
      deploymentkey: ""
    },
    InterfacePostJson: {
      Handler: "",
      MasterGUID: "",
      InterFaceName: "",
      Description: "",
      ShowToCustomer: 0,
      LastUpdatedDate: "",
      LastUpdatedBy: "",
      LabOrgID: "",
      LabAccountID: "",
      OFAccountID: "",
      OFOrgID: "",
      LabType: "",
      InterfaceType: "",
      deploymentkey: ""
    }
  },


  RouteNameSuggest: [
    {
      "name": "prefix",
      "value": ""
    },
    {
      "name": "SE",
      "value": ""
    },
    {
      "name": "DE",
      "value": ""
    },
    {
      "name": "Vendor",
      "value": ""
    },
    {
      "name": "MT",
      "value": ""
    },
    {
      "name": "Direction",
      "value": ""
    }

  ]

};



export const hl7VASpec = {
  "MSH": [
    {
      "segindex": 1,
      "segfield": "Field Separator",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Encoding Characters",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Sending Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Sending Facility",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Receiving Application",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Receiving Facility",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Message",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Message Type",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Message Control ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Processing ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Version ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Accept Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Application Acknowledgment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Country Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "PID": [
    {
      "segindex": 1,
      "segfield": "Set ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Patient ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Patient Identifier List",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Alternate Patient ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Patient Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Date/Time of Birth",
      "segtype": "DOB",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Patient Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 13,
      "segfield": "Phone Number-Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Phone Number-Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "SSN Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ethnicity",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],

  "EVN":[
    {
      "segindex": 1,
      "segfield": "Event Type Code [ID]*",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
    "segindex": 2,
    "segfield": "Recorded Date/Time [TS]",
    "segtype": "",
    "segvalue": "",
    "segcomponent": []
    }
  ],
  "ZEF":[
    {
      "segindex": 1,
      "segfield": "Set ID - ZEF",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Embedded Content",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Sending Application",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Document Type",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": " Encoding Technology ",

          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Embedded Content",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
    ]
    },

  ],
  "PV1": [
    {
      "segindex": 1,
      "segfield": "Set ID-PV1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
      ]
    },
    {
      "segindex": 7,
      "segfield": "Attending Doctor",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Ordering Physician NPI",
          "segtype": "NOTEMPTY",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Ordering Physician Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Ordering Physician First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Prefix",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 7,
          "segfield": "Degree",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Referring Doctor",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Visit Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN1": [
    {
      "segindex": 1,
      "segfield": "Set ID-IN1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insurance Plan ID",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Insurance Company ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Insurance Company Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Insurance Company Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 8,
      "segfield": "Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Group Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Plan Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Name of Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 17,
      "segfield": "Insured's Relationship to Patient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Insured's Date of Birth",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "Policy Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "Insured's Administrative Sex",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "GT1": [
    {
      "segindex": 1,
      "segfield": "Set ID-GT1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Guarantor Name",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Middle Initial",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 5,
      "segfield": "Guarantor Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Street Address",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Other Designation",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "City",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "State",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Zip",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 6,
      "segfield": "Guarantor Ph Num –Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Guarantor Ph Num Business",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Guarantor Relationship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ORC": [
    {
      "segindex": 1,
      "segfield": "Order Control",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Placer Group Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Parent Order",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Date/Time of Transaction",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 21,
      "segfield": "Ordering Facility Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Ordering Facility Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Ordering Facility Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Ordering Provider Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "OBR": [
    {
      "segindex": 1,
      "segfield": "Set ID - OBR",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Placer Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Filler Order Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Universal Service Identifier",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "Order Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Case Type Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 4,
          "segfield": "Alternate Identifier",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 5,
          "segfield": "Alternate Text",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 6,
          "segfield": "Name of Alternate Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 7,
      "segfield": "Observation Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Collection Volume",
      "segtype": "CollectionDate",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Collector Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "Relevant Clinical Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Specimen Received Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Specimen Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Ordering Provider",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "NPI",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Last Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "First Name",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    },
    {
      "segindex": 18,
      "segfield": "Placer Field 1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Placer Field 2",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Filler Field 1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Filler Field 2",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Results Rpt/Status Chng - Date/Time",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Diagnostic Serv Sect ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Result Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Result Copies To",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Parent Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Technician",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Transcriptionist",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "Number of Sample Containers *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Transport Logistics of Collected Sample",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "Collector's Comment *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "Procedure Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Procedure Code Modifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Placer Supplemental Service Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Filler Supplemental Service Information",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "Number of Sample Containers *",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "DG1": [
    {
      "segindex": 1,
      "segfield": "Set ID-DG1",
      "segtype": "NOTEMPTY",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Diagnosis Coding Method",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Diagnosis Code - DG1",
      "segtype": "",
      "segvalue": "",
      "segcomponent": [
        {
          "segindex": 1,
          "segfield": "ICD Code",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 2,
          "segfield": "Description",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        },
        {
          "segindex": 3,
          "segfield": "Name of Coding System",
          "segtype": "",
          "segvalue": "",
          "segcomponent": []
        }
      ]
    }
  ],
  "OBX": [
    {
      "segindex": 1,
      "segfield": "Set ID-OBX",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Value Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Observation Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Observation Sub-ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Units",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "References Range",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "Abnormal Flags",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "Observation Result status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Date/Time of the Observation",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Observation Identifier",
      "segtype": "Responsible Observer",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Equipment Instance Identifier",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Date/Time of the  Analysis",


      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Performing Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 24,
      "segfield": "Performing Organization Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Performing Organization Medical Director ",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "NTE": [
    {
      "segindex": 1,
      "segfield": "Set ID-NTE",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Source of Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Comment",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Comment Type",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Observation Value",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "ZPS": [
    {
      "segindex": 1,
      "segfield": "Set ID-ZPS",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "Organization Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Address",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Physician Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    }
  ],
  "IN2": [
    {
      "segindex": 1,
      "segfield": "Insured's Employee ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 2,
      "segfield": "Insured's Social Security Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 3,
      "segfield": "	Insured's Employer's Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 4,
      "segfield": "Employer Information Data",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 5,
      "segfield": "Mail Claim Party",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 6,
      "segfield": "Medicare Health Ins Card Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 7,
      "segfield": "Medicaid Case Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 8,
      "segfield": "	Medicaid Case Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 9,
      "segfield": "Military Sponsor Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 10,
      "segfield": "Military ID Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 11,
      "segfield": "	Dependent Of Military Recipient",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 12,
      "segfield": "	Military Organization",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 13,
      "segfield": "	Military Station",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 14,
      "segfield": "Military Service",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 15,
      "segfield": "Military Rank/Grade",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 16,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 17,
      "segfield": "Military Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 18,
      "segfield": "Military Retire Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 19,
      "segfield": "Baby Coverage",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 20,
      "segfield": "Combine Baby Bill",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 21,
      "segfield": "Blood Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 22,
      "segfield": "Special Coverage Approval Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 23,
      "segfield": "Special Coverage Approval Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex":24,
      "segfield": "Non-Covered Insurance Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 25,
      "segfield": "Payor ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 26,
      "segfield": "	Payor Subscriber ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 27,
      "segfield": "Eligibility Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 28,
      "segfield": "Room Coverage Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 29,
      "segfield": "Policy Type/Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 30,
      "segfield": "Daily Deductible",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 31,
      "segfield": "Living Dependency",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 32,
      "segfield": "Ambulatory Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 33,
      "segfield": "Citizenship",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 34,
      "segfield": "Primary Language",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 35,
      "segfield": "Living Arrangement",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 36,
      "segfield": "	Publicity Code",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 37,
      "segfield": "	Protection Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 38,
      "segfield": "Student Indicator",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 39,
      "segfield": "	Religion",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 40,
      "segfield": "Mother's Maiden Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 41,
      "segfield": "Sequence Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 42,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 43,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 44,
      "segfield": "undefined",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 45,
      "segfield": "Employment Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 46,
      "segfield": "Job Title",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 47,
      "segfield": "Job Code/Class",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 48,
      "segfield": "Job Status",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 49,
      "segfield": "Employer Contact Person Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 50,
      "segfield": "Employer Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 51,
      "segfield": "Employer Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 52,
      "segfield": "Insured's Contact Person's Name",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 53,
      "segfield": "Insured's Contact Person Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 54,
      "segfield": "Insured's Contact Person Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 55,
      "segfield": "Relationship to the Patient Start Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 56,
      "segfield": "Relationship to the Patient Stop Date",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 57,
      "segfield": "Insurance Co Contact Reason",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 58,
      "segfield": "Insurance Co Contact Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 59,
      "segfield": "Policy Scope",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 60,
      "segfield": "Policy Source",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 61,
      "segfield": "Patient Member Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 62,
      "segfield": "Guarantor's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 63,
      "segfield": "Insured's Phone Number - Home",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 64,
      "segfield": "Insured's Employer Phone Number",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 65,
      "segfield": "Military Handicapped Program",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 66,
      "segfield": "Suspend Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 67,
      "segfield": "Copay Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 68,
      "segfield": "Stoploss Limit Flag",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 69,
      "segfield": "	Insured Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 70,
      "segfield": "Insured Employer Organization Name and ID",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 71,
      "segfield": "	Race",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 72,
      "segfield": "Patient's Relationship to Insured",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },
    {
      "segindex": 73,
      "segfield": "Co-Pay Amount",
      "segtype": "",
      "segvalue": "",
      "segcomponent": []
    },

  ]
};
export const hl7SampleMessages = [
  {
    "messagetype": "Result",
    "casetypeslist": [
      {
        "casetype": "Anal PAP",
        "hl7samplefilepath": "../assets/Config/Results/Anal PAP/analpap.txt"
      },
      {
        "casetype": "ClinicalChemistry_V2",
        "hl7samplefilepath": "../assets/Config/Results/ClinicalChemistry_V2/cpv2.txt"
      },
      {
        "casetype": "Dermatology",
        "hl7samplefilepath": "../assets/Config/Results/Dermatology/Dermatology Histology.txt"
      },
      {
        "casetype": "General",
        "hl7samplefilepath": "../assets/Config/Results/General/General.txt"
      },
      {
        "casetype": "GeneralHistology",
        "hl7samplefilepath": "../assets/Config/Results/GeneralHistology/GeneralHist.txt"
      },
      {
        "casetype": "GYN Molecular",
        "hl7samplefilepath": "../assets/Config/Results/GYN Molecular/GYN Molecular.txt"
      },
      {
        "casetype": "Molecular Respiratory",
        "hl7samplefilepath": "../assets/Config/Results/Molecular Respiratory/Molecular_Respiratory.txt"
      }
      ,
      {
        "casetype": "Prostate Histology",
        "hl7samplefilepath": "../assets/Config/Results/Prostate Histology/prostateHist.txt"
      }
      ,
      {
        "casetype": "Toxicology",
        "hl7samplefilepath": "../assets/Config/Results/Toxicology/toxicology.txt"
      }
      ,
      {
        "casetype": "UTI_P4",
        "hl7samplefilepath": "../assets/Config/Results/UTI_P4/uti_v2.txt"
      }
      ,
      {
        "casetype": "Vasectomy",
        "hl7samplefilepath": "../assets/Config/Results/Vasectomy/Vasectomy.txt"
      }
    ]
  }
]


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
