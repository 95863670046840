import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FileViewExplorerComponent } from "src/app/file-view-explorer/file-view-explorer.component";
import { CommonService } from "src/app/services/common.service";
@Component({
  selector: "app-casehistory",
  templateUrl: "./casehistory.component.html",
  styleUrls: ["./casehistory.component.scss"],
})
export class CasehistoryComponent implements OnInit {
  @Input() casehistory = [];
  @Input() deploymenytKey: string = "";
  PatientHistory: any[] = [];
  @Input() nodataalert: boolean = false;
  constructor(
    private service: CommonService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}
  ngOnChanges() {
    this.getCaseHistory();
  }
  ngOnInit() {}
  async getCaseHistory() {
    if (this.casehistory.length > 0) {
      this.PatientHistory = [];
      for (let i = 0; i < this.casehistory.length; i++) {
        let obj = {
          CaseGuid: this.casehistory[i].CaseGuid,
          DeploymentKey: this.deploymenytKey==undefined ? '' : this.deploymenytKey,
        };
        this.ngxUiLoaderService.start();
        this.service
          .getCaseXMLHistory(obj)
          .toPromise()
          .then(
            (data) => {
              this.PatientHistory.push(data);
              console.log(this.PatientHistory);
              // console.log(data.Case)
              this.ngxUiLoaderService.stop();
            },
            (ere) => {
              console.error();
              this.ngxUiLoaderService.stop();
            }
          );
      }
    } else {
      this.PatientHistory = [];
      if (this.nodataalert) {
        this.toastr.warning(
          "No Patient History Found",
          "",
          {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          }
        );
        this.nodataalert=false;
      }
    }
  }

  viewCase(item: object) {
    let obj = {
      ...this.service.CaseDataDetails,
      casedata: JSON.stringify(item),
    };
    this.service.updateCaseXMLToComponent(JSON.stringify(obj));
  }

  showRequReport(caseData: any) {
    if (typeof caseData.Attachments.File === "object") {
      this.viewCaseFile(caseData.Attachments.File, caseData);
    } else if (Array.isArray(caseData.Attachments.File)) {
      let datas = caseData.Attachments.File.filter(
        (va) => va.Category === "Requisition"
      );
      if (datas.length > 0) {
        this.viewCaseFile(datas[datas.length - 1], caseData);
      }
    }
  }

  showFinalReport(report: any, caseData: any) {
    if (typeof report === "object") {
      this.viewCaseFile(report.File, caseData);
    } else if (Array.isArray(report)) {
      // for (let i = 0; i < report.length; i++) {
      this.viewCaseFile(report[report.length - 1].File, caseData);
      // }
    }
  }

  viewCaseFile(report, caseData) {
    let location;
    if (caseData["ServiceProvider"].length > 0) {
      location = caseData["ServiceProvider"].find(
        (value) => value.Location.AccountType == "Laboratory"
      );
      if (location !== undefined) {
        location = location.Location;
      }
    } else {
      location = caseData["ServiceProvider"].Location;
    }

    let obj = {
      RequestID: report.PresentationData,
      Type: report.Category,
      RequestGUID: report.FileGUID,
      LabOrgGUID: location.OrganizationGUID,
      CaseGUID: caseData["CaseGUID"],
      DeploymentsKey: this.deploymenytKey == undefined ? '' : this.deploymenytKey,
    };
    this.ngxUiLoaderService.start();
    this.service.viewCases360DXReport(obj).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
        if (
          data.StatusMessage !== null &&
          data.StatusMessage.toLowerCase() !== "failed"
        ) {
          if (data["FileContent"] !== null) {
            "data:application/pdf;base64," + data["FileContent"];
            let namemodal = {
              name: report.ReportName,
              fullfile: data["FileContent"],
            };
            const modalRef = this.modalService.open(FileViewExplorerComponent, {
              windowClass: "myCustomModalViewfile",
              backdrop: "static",
              keyboard: false,
            });
            modalRef.componentInstance.fileData = namemodal;
            modalRef.result.then(() => {});
          } else {
            this.ngxUiLoaderService.stop();
            this.toastr.warning("Data not Found", "Alert", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        } else {
          this.ngxUiLoaderService.stop();
          data.FileContent !== null && data.FileContent !== ""
            ? this.toastr.error(data.FileContent, "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            })
            : this.toastr.warning("Data not Found", "Alert", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
      }
    );
  }
}
