import { SelectionModel } from "@angular/cdk/collections";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppConfigService } from "../configurations/app-config.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../services/common.service";
import {
  CustomFTPDetails,
  inputField,
} from "../create/create.component";
declare const copyToClipboard: any;

@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.scss"],
})
export class LogsComponent implements OnInit {
  @ViewChild("reprocesstextarea", { static: false }) reprocesstextarea;
  reactiveFormmode: FormGroup;
  modeSelected: string = "";
  today: any = new Date();
  keywordaccount = "LocationName";
  LabOrgList = <any>[];
  LogLabOrgList = <any>[];
  XMLLabOrgList = <any>[];
  selectedEvent: string;
  selectedLogs: string;
  selectedRegenerate: string;
  isShowStatus: boolean;
  EventStatus = ["New", "Success", "Failure"];
  EventsStatus = [];
  EventCategory = ["Case", "Claim"];
  selectionRegenerateXml = new SelectionModel<any>(true, []);
  LogStatus = [];
  ReprocessLogStatus = [];

  Hl7LogsList = [];
  caseRegenerateXMlList = [];
  CaseList = [];

  deploymentlist = [];
  EventDataList = [];
  selectedTab: number = 0;

  notfounddata1: boolean;
  notfounddata2: boolean;
  notfounddata3: boolean;
  notfounddata4: boolean;
  notfounddata5: boolean;
  notfounddata6: boolean;
  notfounddata8: boolean;
  EventTypeList = <any>[];
  notfounddata7: boolean;
  eventTypeList = <any>[];
  eventLabOrgList = <any>[];
  eventFromDateList = <any>[];
  eventToDateList = <any>[];
  logHandlerList = <any>[];
  logStatusList = <any>[];
  EventFilterForm: FormGroup;
  LogFilterForm: FormGroup;
  RegenerateFilterForm: FormGroup;
  keywordsearchEventType = "lookupid";
  keywordsearchEventCategory = "eventCategory";
  keywordsearchEventStatus = "eventStatus";
  keywordsearchEventLabOrg = "eventLabOrg";
  isLoadingEventType: boolean;
  isLoadingEventCategory: boolean;
  isLoadingEventStatus: boolean;
  isLoadingEventLabOrg: boolean;
  isLoadingEventFromDate: boolean;
  isLoadingEventToDate: boolean;
  isLoadinglogHandler: boolean;
  isLoadingLogStatus: boolean;
  isLoadingReprocessStatus: boolean;

  keywordsearchLogHandler = "logHandler";
  keywordsearchLogStatus = "lookupid";
  keywordsearchReprocessLogStatus = "lookupid";
  envTarget: string;
  fromprossorSADA: string;
  selectedtargetorgid: any;
  selectedtargetaccountid: any;
  selectedtargetaccountName: any;
  protected RouteNameSuggest: any = AppConfigService.settings.RouteNameSuggest;

  // isLoadingEventLabOrg: boolean;
  notfounddata: boolean;
  rulecreateddisplaylabel: boolean;
  ftpcreateddisplaylabel: boolean;
  vbjobcreateddisplaylabel: boolean;
  vbjobcreatedflag: boolean;
  ftpcreatedflag: boolean;
  handlercreatedflag: boolean;
  rulecreatedflag: boolean;
  updateconfigflagftp: boolean;
  updateconfigflagvb: boolean;
  handlerflag: boolean;
  ruleflag: boolean;
  notebuttonflag: boolean;
  auditbuttonflag: boolean;
  rulebuttonshow: boolean;
  archiveflagcheck: boolean = false
  routeflag: boolean;
  additionalinfoflag: boolean;
  isLocked: boolean;
  sourcedeployementkey: string = "";
  vendarmsgdiv: boolean;
  sourceentitytype: any;
  sourcetypedisplay: any;
  sourcetypedisplayid: string;
  reactiveFormsource: FormGroup;
  isShown: boolean;
  btnshowtryit: boolean;
  copypiprlinrbutton: boolean;
  isshownellkykeys: boolean;
  isShownresultoutbound: boolean;
  ftpflag: boolean;
  ftpalreadycreated: boolean;
  resultsftpchecknone: boolean;
  apiconnectivity: boolean;
  vpncreatedflag: boolean;
  vbjobalreadycreated: boolean;
  vbflag: boolean;
  ftpisDisabled: boolean;
  isShownorderinboundvb: boolean;
  isShownorderinbound: boolean;
  vbfolderpathflag: boolean;
  isShownorderinboundftp: boolean;
  dynamicJSON: inputField[] = [];
  routeconfigflag: boolean;
  additionalinfodataflag: boolean;
  handlercreateddisplaylabel: boolean;
  VBClientmatcard: boolean;
  ftpcheckexsting: boolean;
  copypipelineflag: boolean;
  resetflag: boolean;
  enableconfig: boolean;
  isLoadingagent: boolean;
  isLoadingEventLabOrgagent: boolean;
  array_disableradiobuttons: string[] = [];
  vendarnamevalue: string;
  public reactiveFormvendar: FormGroup;
  sourceentityaddress: string = "";
  sourceentityaddress1: string = "";
  sourceentityaddress2: string = "";
  sourcecity: string = "";
  sourcestate: string = "";
  sourcezip: string = "";
  sourcetype: string = "";
  envSourceProxy: string = "";
  modelvbclient: any = {};
  myFlagForSlideToggle: boolean = false;
  UniqueID: any = "";
  sourcedpkeydisplay: string = "";
  selectedsourceaccountid: string = "";
  selectedsourceaccountName: string = "";
  oldvalue: any = "";
  reactiveFormtarget: FormGroup;
  selectedprocessagentid: string = "";
  selectedprocessdisplayname: string = "";
  selectedprocessapikey: string = "";
  reactiveFormprocess: FormGroup;
  interfacetype: string;
  modelelk: any = {};
  remotepathdisplay: string = "";
  sourcepath: string = "";
  archivepath: string = "";
  errorpath: string = "";
  progresspath: string = "";
  ftpusername: string;
  ftppassword: string;
  ftphost: string;
  ftpport: string;
  vbjobname: string;
  vbtype: string;
  ftpexstingpathdiv: boolean = false;
  suffixfromprocessor: string = "*";
  modelvpn: any = {};
  modelapi: any = {};
  modelradio: any = {};
  modelvbftp: any = {};
  vbjobdisplayexftp: boolean = false;
  vbfolderpathdisplay: string = "";
  customftp = <CustomFTPDetails>{
    username: "",
    password: "",
    host: "",
    port: "",
    folder: "",
    id: "",
  };
  ProcessorAllList: any = [];
  copytitle: string = "";
  reactiveFormmsgtype: FormGroup;
  routepolicyselected: string = "Select";
  selectedsourceagentid: any;
  selectedsourceapikey: any;
  selectedtargetapikey: any;
  selectedtargetagentid: any;
  selectedsourceagentname: any;
  selectedtargetagentname: any;
  public reactiveFormroute: FormGroup;
  public reactiveFormroutepolicy: FormGroup;
  public reactiveFormsourceagent: FormGroup;
  public reactiveFormTargetagent: FormGroup;
  public reactiveFormvbjob: FormGroup;
  ftpremotepath: string = "";
  selectedjobId: string = "";
  selectedjobName: string = "";
  selectedextensionItems: any = [];
  vbjobnameftp: string = "";
  newvalue: any = "";
  ftpexistingpath: string = "";
  oldselectedrouteDisplayName: string = "";
  configjson: string = "";
  configid: string = "";
  configagentid: string = "";
  SourceAgentNameList: any[];
  TargetAgentNameList: any[];
  messagetypeselected: string = "Select";
  VendarsList: any[];
  VendornameBackuplist: any;
  connectivitytypemsg: string = "";
  selectedInterfacedescription: string = "";
  configagentapikey: string = "";
  vendorid: any;
  route: any;
  isvalidSPLchar: boolean;
  invalidlogID: boolean;
  selectedLaborgid: any = "";
  selectedDeployement = "";
  selectedDeployementLogs = "";
  selectedLogLaborgid: any;
  selectedXMLLaborgid: any = "";
  isLoadingLogLabOrg: boolean;
  isLoadingXMLLabOrg: boolean;
  selectedhandler: string;
  selectedLogStatus: any;
  selectedEventStatus: any;
  selecteEventStatus : "";
  selectedReprocessLogStatus: any = "";
  reprocessLogNotes: any;
  ismandatory: boolean;
  Xmkismandatory: boolean;
  ismandatoryEvents: boolean;
  pagerolepermisions: any = [];
  dateFromEvent: string;
  dateToEvent: string;
  dateFromLog: string;
  dateToLog: string;
  dateFromXml: string;
  dateToXml: string;
  selectedXMLStatus: any;
  selectedDeployementXML: string;
  ClaimFilterForm: FormGroup;
  selectedClaim: string;
  claimdeploymentlist =[];
  keywordclaimaccount = "LocationName";
  claimLabOrgList = <any>[];
  isLoadingclaimLabOrg: boolean;
  selectedClaimLaborgid: any = "";
  dateFromClaim: string;
  dateToClaim: string;
  selectionRepopulateclaim = new SelectionModel<any>(false, []);
  caseRepopulateClaimList = [];
  selectedDeployementClaim: string;

  constructor(
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private commonService: CommonService,
    private toastr: ToastrService,
    public service: CommonService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) {}

  async ngOnInit() {
    this.selectedEvent = "Accession Number";
    this.selectedLogs = "Accession Number";
    this.selectedRegenerate = "Accession Number";
    this.selectedClaim = "Accession Number";
    this.EventFilterForm = this._fb.group({
      searchkey: "accessionnumber",
      searchvalue: "",
      category: "",
      Eventtype: "",
      fromdate: "",
      todate: new Date(),
      status: "",
      laborgid: "",
      deploymentKey: "",
      eventsStatus:""
    });
    this.LogFilterForm = this._fb.group({
      logsearchkey: "accessionnumber",
      logsearchvalue: "",
      handler: "",
      logstatus: "",
      logfromdate: "",
      logtodate: "",
      loglaborgid: "",
      logdeploymentKey: "",
    });
    this.RegenerateFilterForm = this._fb.group({
      xmlsearchkey: "accessionnumber",
      xmlsearchvalue: "",
      xmlfromdate: ["", Validators.required],
      xmltodate: ["", Validators.required],
      xmllaborgid: "",
      xmldeploymentKey: ["", Validators.required],
    });

    this.reactiveFormvendar = this._fb.group({ namevendar: "" });
    this.reactiveFormroute = this._fb.group({ Handlername: "" });
    this.reactiveFormsource = this._fb.group({ name: "" });
    this.reactiveFormtarget = this._fb.group({ namet: "" });
    this.reactiveFormprocess = this._fb.group({ namep: "" });
    this.reactiveFormmsgtype = this._fb.group({ msgname: "" });
    this.reactiveFormroutepolicy = this._fb.group({ routepolicy: "" });
    this.reactiveFormvbjob = this._fb.group({ namejob: "" });
    this.reactiveFormsourceagent = this._fb.group({ sourceagent: "" });
    this.reactiveFormTargetagent = this._fb.group({ targetagent: "" });

    var inputId = JSON.parse(sessionStorage.getItem("pipelineid"));
    if (inputId != null) {
      this.selectedrouteId = inputId;
      this.pipelineselectclass = "ng-autocompleteselect";
      this.getRouteDetails();
      this.btnshowtryit = false;
      this.copypiprlinrbutton = false;
      this.route.snapshot.paramMap["params"] = {};
    } else if (this.RouteNameSuggest) {
      this.RouteNameSuggest.forEach((element) => {
        element.value = "";
      });
    }
    this.getSearchEventStatus();
    this.setDateFilter();
    this.getEventTypeLogs();
    this.getSearchLogStatus();
    this.reprocessLogStatus();
    this.getDeployments();

    if ((await this.service.checksession()) == true) {
      const menuid = JSON.parse(sessionStorage.getItem("rolewithmenus"))
        .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
        .menuslist.find((y) => y.MenuDisplayName == "Logs").MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem("rolewithmenus"))
        .find((x) => x.RoleName == sessionStorage.getItem("SelectedUserRole"))
        .menuslist.filter((y) => y.ParentMenuId == menuid);
      actionitems.forEach((element) => {
        this.pagerolepermisions[element.MenuName] = true;
      });
    }

    this.ClaimFilterForm = this._fb.group({
      claimsearchkey: "accessionnumber",
      claimsearchvalue: "",
      claimfromdate: ["", Validators.required],
      claimtodate: ["", Validators.required],
      claimlaborgid: "",
      claimdeploymentKey: ["", Validators.required],
    });
  }

  getDeployments() {
    this.service.getDeployments("Deployments").subscribe(
      (data) => {
        if (data.length > 0) {
          this.deploymentlist = data;
          this.claimdeploymentlist = data;
        } else if (data.length === 0) {
          this.deploymentlist = [];
          this.claimdeploymentlist = [];
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }
  setDateFilter() {
    var curr = new Date(); // get current date
    var first = curr.getDay(); // First day is the day of the month - the day of the week
    var last = curr.getDate(); // last day is the first day + 6
    first = last - curr.getDay();
    ////console.log(curr, first, last);
    first = last - 2;
    let firstday = new Date(curr.setDate(first));
    let lastd = new Date();
    let lastday = new Date(lastd.setDate(last));
    let firstdate, lastdate, firstmonth, lastmonth;
    firstmonth =
      firstday.getMonth() + 1 < 10
        ? "0" + (firstday.getMonth() + 1)
        : firstday.getMonth() + 1;
    lastmonth =
      lastday.getMonth() + 1 < 10
        ? "0" + (lastday.getMonth() + 1)
        : lastday.getMonth() + 1;
    firstdate =
      firstday.getDate() < 10 ? "0" + firstday.getDate() : firstday.getDate();
    lastdate =
      lastday.getDate() < 10 ? "0" + lastday.getDate() : lastday.getDate();

    this.dateFromEvent = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.dateFromLog = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.dateFromXml = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.dateToEvent = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;
    this.dateToLog = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;
    this.dateToXml = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;
    this.dateFromClaim = `${firstday.getFullYear()}-${firstmonth}-${firstdate}`;
    this.dateToClaim = `${lastday.getFullYear()}-${lastmonth}-${lastdate}`;

    this.RegenerateFilterForm.patchValue({
      xmlfromdate: `${firstday.getFullYear()}-${firstmonth}-${firstdate}`,
      xmltodate: `${lastday.getFullYear()}-${lastmonth}-${lastdate}`,
    });
    ////console.log(this.datefilter,this.enddtfilter)
  }

  OnDeploymentChange(item) {
    this.selectedDeployement = item;
  }
  searchDeployementCleared() {
    this.selectedDeployement = "";
  }

  OnLogDeploymentChange(item) {
    this.selectedDeployementLogs = item;
  }
  searchLogDeployementCleared() {
    this.selectedDeployement = "";
  }
  // Events
  // Event Checkbox for reprocess
  checkboxLabel(row?: any) {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    let ui = `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
    return ui;
  }

  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.EventDataList.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.EventDataList.forEach((row) => this.selection.select(row));
  }

  reprocessEvents(reprocessmodal) {
    this.selection.selected;

    this.modalService
      .open(reprocessmodal, {
        windowClass: "messageiotLogs",
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => {});
  }
  saveReprocessEvents(c) {
    let selectedevent = this.selection.selected.reduce((acc, current) => {
      return acc.concat(current.EventId);
    }, []);
    let selectedeventstatus = this.selection.selected.reduce((acc, current) => {
      return acc.concat(current.status);
    }, []);
    let obj = {
      Eventids: selectedevent.join(","),
      CreatedBy: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
      oldstatus: selectedeventstatus.join(","),
    };
    this.ngxUiLoaderService.start();
    this.commonService
      .eventReprocesses(obj, this.selectedDeployement)
      .subscribe(
        (data) => {
          this.ngxUiLoaderService.stop();
          c("close modal");
          if (data.status.toLowerCase() == "success") {
            this.toastr.success(data.statusmessage, data.status, {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
            this.onSearchEvents();
          } else {
            this.toastr.error(data.statusmessage, data.status, {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          }
        },
        (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(error.statusmessage, "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
  }

  // HL7Logs  Checkbox for reprocess
  checkboxLabelLogs(row?: any) {
    if (!row) {
      return `${this.isAllSelectedLogs() ? "deselect" : "select"} all`;
    }
    let ui = `${
      this.selectionLogs.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
    return ui;
  }

  // HL7Logs  Checkbox for reprocess
  checkboxLabelRegenerate(row?: any) {
    if (!row) {
      return `${this.isAllSelectedRegenerate() ? "deselect" : "select"} all`;
    }
    let ui = `${
      this.selectionRegenerateXml.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
    return ui;
  }

  isAllSelectedRegenerate() {
    // const numSelected = this.selectionRegenerateXml.selected.length;
    // const numRows = this.Hl7LogsList.length;
    // return numSelected === numRows;
    const numSelected = this.selectionRegenerateXml.selected.length;
    const numRows = this.caseRegenerateXMlList.length;
    return numSelected === numRows;
  }
  masterToggleRegenerate() {
    this.isAllSelectedRegenerate()
      ? this.selectionRegenerateXml.clear()
      : this.caseRegenerateXMlList.forEach((row) =>
          this.selectionRegenerateXml.select(row)
        );
  }

  selectionLogs = new SelectionModel<any>(true, []);

  isAllSelectedLogs() {
    const numSelected = this.selectionLogs.selected.length;
    const numRows = this.Hl7LogsList.length;
    return numSelected === numRows;
  }
  masterToggleLogs() {
    this.isAllSelectedLogs()
      ? this.selectionLogs.clear()
      : this.Hl7LogsList.forEach((row) => this.selectionLogs.select(row));
  }
  reprocessHl7Logs(reprocessmodal) {
    this.isShowStatus = false;
    this.selectionLogs.selected;
    this.reprocessLogNotes = "";
    this.selectedReprocessLogStatus = "";
    this.modalService
      .open(reprocessmodal, {
        windowClass: "messageiotEvent",
        backdrop: "static",
        keyboard: false,
      })
      .result.then((result) => {});
    setTimeout(() => {
      this.isShowStatus = true;
    });
  }

  saveReprocessHl7Logs(c) {
    let selectedLogsData = this.selectionLogs.selected.reduce(
      (acc, current) => {
        return acc.concat(current.logId);
      },
      []
    );
    let selectedLogsstatus = this.selectionLogs.selected.reduce(
      (acc, current) => {
        return acc.concat(current.status);
      },
      []
    );
    if (typeof this.selectedReprocessLogStatus == "object") {
      this.selectedReprocessLogStatus =
        this.selectedReprocessLogStatus.lookupid;
    }
    let objs = {
      logids: selectedLogsData.join(","),
      notes: this.reprocessLogNotes,
      status: this.selectedReprocessLogStatus,
      oldstatus: selectedLogsstatus.join(","),
      CreatedBy: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
        .userdata["userid"],
    };
    this.ngxUiLoaderService.start();
    this.ngxUiLoaderService.stop();
    this.commonService
      .hl7logsreprocess(objs, this.selectedDeployementLogs)
      .subscribe(
        (data) => {
          this.ngxUiLoaderService.stop();
          c("close modal");
          if (data.status.toLowerCase() == "success") {
            this.toastr.success(data.statusmessage, "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
            this.onSearchLogs();
          }
        },
        (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(error.statusmessage, "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      );
  }

  clearlog() {
    this.LogFilterForm.patchValue({
      logsearchvalue: "",
    });
  }
  clearRegenerate() {
    this.RegenerateFilterForm.patchValue({
      xmlsearchvalue: "",
    });
  }
  clearEvent() {
    this.EventFilterForm.patchValue({
      searchvalue: "",
    });
  }
  closereprocessPopup(c) {
    c("close modal");
  }
  Savereprocess(c) {
    if (this.selectedTab == 0) {
      this.saveReprocessEvents(c);
    } else {
      this.saveReprocessHl7Logs(c);
    }
  }
  onTabChanged(event) {
    this.selectedTab = event.index;
  }
  selectedEvents(value) {
    this.ismandatoryEvents = false;
    switch (value) {
      case "Accession Number":
        this.selectedEvent = value;
        this.EventFilterForm.patchValue({
          searchkey: "accessionnumber",
        });
        // this.ismandatoryEvents = true;
        break;
      case "Case ID":
        this.selectedEvent = value;
        this.EventFilterForm.patchValue({
          searchkey: "caseid",
        });
        break;
      case "Claim Number":
        this.selectedEvent = value;
        this.EventFilterForm.patchValue({
          searchkey: "claimnumber",
        });
        // this.ismandatoryEvents = true;
        break;
      case "Claim ID":
        this.selectedEvent = value;
        this.EventFilterForm.patchValue({
          searchkey: "claimid",
        });
        break;
      case "Order Number":
        this.selectedEvent = value;
        this.EventFilterForm.patchValue({
          searchkey: "ordernumber",
        });
        // this.ismandatoryEvents = true;
        break;
      case "Event ID":
        this.selectedEvent = value;
        this.EventFilterForm.patchValue({
          searchkey: "eventid",
        });
        break;

      default:
        break;
    }
  }

  selectedLog(value) {
    this.ismandatory = false;
    switch (value) {
      case "Accession Number":
        this.selectedLogs = value;
        this.LogFilterForm.patchValue({
          logsearchkey: "accessionnumber",
        });
        // this.ismandatory = true;
        break;
      case "Case ID":
        this.selectedLogs = value;
        this.LogFilterForm.patchValue({
          logsearchkey: "caseid",
        });
        break;
      case "Log ID":
        this.selectedLogs = value;
        this.LogFilterForm.patchValue({
          logsearchkey: "logid",
        });
        break;
      case "Order Number":
        this.selectedLogs = value;
        this.LogFilterForm.patchValue({
          logsearchkey: "ordernumber",
        });
        // this.ismandatory = true;
        break;
      default:
        break;
    }
  }
  selectRegenerateXML(value) {
    this.Xmkismandatory = false;
    switch (value) {
      case "Accession Number":
        this.selectedRegenerate = value;
        this.RegenerateFilterForm.patchValue({
          xmlsearchkey: "accessionnumber",
        });
        // this.ismandatory = true;
        break;
      case "Case ID":
        this.selectedRegenerate = value;
        this.RegenerateFilterForm.patchValue({
          xmlsearchkey: "caseid",
        });
        break;
      default:
        break;
    }
  }

  searchEventTypeCleared(event) {
    this.isLoadingEventType = false;
    // this.EventTypeList = [];
    this.notfounddata1 = false;
    this.selectedEventtype = "";
    this.EventFilterForm.patchValue({
      Eventtype: "",
    });
  }
  searchEventCategory(event) {
    this.EventFilterForm.patchValue({
      category: "",
    });
  }
  searchEventStatus(event) {
    this.EventFilterForm.patchValue({
      status: "",
    });
    // this.isLoadingEventStatus = false;
    // this.EventStatus = [];
    // this.notfounddata3 = false;
  }
  clearedLogStatus() {
    this.selectedLogStatus = "";
    this.LogFilterForm.patchValue({
      logstatus: "",
    });
  }

  clearedEventStatus() {
    this.selectedEventStatus = "";
    this.EventFilterForm.patchValue({
      logstatus: "",
    });
  }

  searchLogHandler(event) {
    this.isLoadinglogHandler = false;
    this.logHandlerList = [];
    this.notfounddata5 = false;
  }
  searchLogStatus(event) {
    this.LogFilterForm.patchValue({
      logstatus: "",
    });
  }
  clearedXMLStatus() {
    this.selectedXMLStatus = "";
    this.RegenerateFilterForm.patchValue({
      xmlstatus: "",
    });
  }
  ClearReprocessLogStatus(event) {
    this.selectedReprocessLogStatus = "";
  }

  ClearReprocessLogNotes(event) {
    this.reprocessLogNotes = "";
  }
  //LabOrgId AutoComplete
  targetdpkeydisplay: string = "";
  targettypedisplay: string = "";
  targetentitytype: string = "";
  targettypedisplayid: string = "";
  targetdeployementkey: string = "";
  targetentityaddress: string = "";
  targetentityaddress1: string = "";
  targetentityaddress2: string = "";
  targetcity: string = "";
  targetstate: string = "";
  targetzip: string = "";
  targettype: string = "";
  targetentityprimaryid: string = "";
  selectedLaborg(
    item //target agent selected Event
  ) {
    this.selectedLaborgid = item.LocationGUID;
    //  this.selectedDeployement = item.deploymentkey
  }

  selectedLogLaborg(
    item //target agent selected Event
  ) {
    this.selectedLogLaborgid = item.LocationGUID;
    //  this.selectedDeployement = item.deploymentkey
  }
  selectedXMlLaborg(
    item //target agent selected Event
  ) {
    ;
    this.selectedXMLLaborgid = item.LocationGUID;
    //  this.selectedDeployement = item.deploymentkey
  }

  async getdeploymentconfiguration() {
    var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=&apikey=";
    var responseconfig = await this.service.getapi(apiname);
    var configs = JSON.parse(responseconfig.responsebody);
    return configs;
  }

  locationString(item, item1) {
    // let names = item2.split("(")
    // item2 = `${names[0]} <br> (${names[1]}`
    return "(" + item + ")" + " " + item1;
  }

  async getlaborgs(event: any) {
    var valueta = event;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    this.EventFilterForm.patchValue({
      laborgid: event,
    });
    this.selectedtargetaccountid = "";
    if (valueta != "" && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      var createSearch =
        "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" +
        valueToSendToApiWithOutWhiteSpace;
      this.isLoadingEventLabOrg = true;
      let response = await this.service.getapi(createSearch);
      if (response["status"] && response["statusText"]) {
        this.isLoadingEventLabOrg = false;
        this.toastr.error("Unable to fetch Lab Details", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.LabOrgList = [];
      } else {
        if (response.length > 0) {
          this.LabOrgList = response as any[];
          // this.LabOrgList = response.filter((va) => va.Type == "O");
          this.LabOrgList = this.service.transformAlphabetically(
            this.LabOrgList,
            "LocationName"
          );
          this.LabOrgList.map(
            (obj) =>
              (obj.LocationName = `  ${"(" + obj.deploymentkey + ")"} ${
                obj.LocationName
              } ${"(" + obj.LocationGUID + ")"}`)
          );
        }
        if (response.length == 0) {
          this.notfounddata4 = true;
          this.LabOrgList = [];
        } else {
          this.notfounddata4 = false;
        }
        this.selectedtargetaccountid = "";
        this.isLoadingEventLabOrg = false;
      }
    } else {
      this.notfounddata4 = true;
    }
  }

  searchTargetNameCleared(event) {
    this.selectedLaborgid = "";
    // this.selectedDeployement = ''
    this.LabOrgList = [];
    this.notfounddata = false;
  }

  LoglocationString(item, item1) {
    // let names = item2.split("(")
    // item2 = `${names[0]} <br> (${names[1]}`
    return "(" + item + ")" + " " + item1;
  }

  sourceCustomFilter = function (
    SourceAccountList: any[],
    query: string
  ): any[] {
    return SourceAccountList;
  };
  XmlLabFilter = function (SourceAccountList: any[], query: string): any[] {
    return SourceAccountList;
  };

  async getloglaborgs(event: any) {
    var valueta = event;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    this.LogFilterForm.patchValue({
      loglaborgid: event,
    });
    this.selectedtargetaccountid = "";
    if (valueta != "" && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      var createSearch =
        "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" +
        valueToSendToApiWithOutWhiteSpace;
      this.isLoadingLogLabOrg = true;
      let response = await this.service.getapi(createSearch);
      if (response["status"] && response["statusText"]) {
        this.selectedtargetaccountid = [];
        this.notfounddata = true;
        this.isLoadingLogLabOrg = false;
        this.toastr.error("Unable to fetch Lab Details", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.LogLabOrgList = [];
      } else {
        if (response.length > 0) {
          this.LogLabOrgList = response as any[];
          //this.LogLabOrgList = response.filter((va) => va.Type == "O");
          this.LogLabOrgList = this.service.transformAlphabetically(
            this.LogLabOrgList,
            "LocationName"
          );
          this.LogLabOrgList.map(
            (obj) =>
              (obj.LocationName = `  ${"(" + obj.deploymentkey + ")"} ${
                obj.LocationName
              } ${"(" + obj.LocationGUID + ")"}`)
          );
        }
        if (response.length == 0) {
          this.notfounddata4 = true;
          this.LogLabOrgList = [];
        } else {
          this.notfounddata4 = false;
        }
        this.selectedtargetaccountid = "";
        this.isLoadingLogLabOrg = false;
      }
    } else {
      this.notfounddata4 = true;
    }
  }
  async getXMLlaborgs(event: any) {
    var valueta = event;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    this.RegenerateFilterForm.patchValue({
      xmllaborgid: event,
    });
    // this.selectedtargetaccountid = "";
    if (valueta != "" && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      var createSearch =
        "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" +
        valueToSendToApiWithOutWhiteSpace;
      this.isLoadingLogLabOrg = true;
      let response = await this.service.getapi(createSearch);
      ;
      if (response["status"] && response["statusText"]) {
        // this.selectedtargetaccountid = [];
        this.notfounddata = true;
        this.isLoadingLogLabOrg = false;
        this.toastr.error("Unable to fetch Lab Details", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.XMLLabOrgList = [];
      } else {
        if (response.length > 0) {
          this.XMLLabOrgList = response as any[];
          //this.LogLabOrgList = response.filter((va) => va.Type == "O");
          this.XMLLabOrgList = this.service.transformAlphabetically(
            this.XMLLabOrgList,
            "LocationName"
          );
          this.XMLLabOrgList.map(
            (obj) =>
              (obj.LocationName = `  ${"(" + obj.deploymentkey + ")"} ${
                obj.LocationName
              } ${"(" + obj.LocationGUID + ")"}`)
          );
        }
        if (response.length == 0) {
          this.notfounddata8 = true;
          this.XMLLabOrgList = [];
        } else {
          this.notfounddata8 = false;
        }
        this.selectedtargetaccountid = "";
        this.isLoadingLogLabOrg = false;
      }
    } else {
      this.notfounddata4 = true;
    }
  }

  searchLogTargetNameCleared(event) {
    this.selectedLogLaborgid = "";
    // this.selectedDeployement = ''
    this.LogLabOrgList = [];
    this.notfounddata = false;
  }
  searchXMLNameCleared(event) {
    this.selectedXMLLaborgid = "";
    // this.selectedDeployement = ''
    this.XMLLabOrgList = [];
    this.notfounddata8 = false;
  }

  //Pipeline names list for logs
  keywordsearch = "displayname";
  keywordsearchprocessor = "name";
  isLoadingResult: boolean = false;
  HandlerNameList = <any>[];
  selectedrouteId = "";
  selectedrouteDisplayName = "";

  getHandlerName(event: any) {
    var val = event;
    if (val) {
      if (Number(val.length) + Number(1) > 3) {
        this.isLoadinglogHandler = true;
        var url = "api/Routes/GetByDisplayName?name=" + val + "&version=2";
        this.service.getapiurl(url).subscribe({
          next: (data) => {
            this.isLoadinglogHandler = false;
            this.HandlerNameList = data as any[];
            this.HandlerNameList.map(
              (obj) =>
                (obj.displayname = `${obj.displayname} ${"(" + obj.id + ")"}`)
            );
            // this.selectedrouteId = '';
            this.selectedrouteDisplayName = val;
            if (data.length == 0) {
              this.notfounddata = true;
            } else {
              this.notfounddata = false;
            }
          },
          error: (error) => {
            this.isLoadinglogHandler = false;
            this.notfounddata = false;
            this.toastr.error("Unable to fetch the Pipelines", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
          },
        });
      }
    }
  }

  async searchRouteNameCleared(event) {
    if (this.selectedrouteId !== "") {
      await this.Resetalldata();
      await this.setfirstcontrol();
    }
  }

  pipelineselectclass: string = "";
  selectedinterfaceEvent(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.pipelineselectclass = "ng-autocompleteselect";
    // this.getRouteDetails();
  }

  async getRouteDetails() {
    //for additional info start
    this.rulecreateddisplaylabel = false;
    this.rulecreateddisplaylabel = false;
    this.ftpcreateddisplaylabel = false;
    this.vbjobcreateddisplaylabel = false;
    this.vbjobcreatedflag = false;
    this.ftpcreatedflag = false;
    this.handlercreatedflag = false;
    this.rulecreatedflag = false;
    //for additional info end

    this.updateconfigflagftp = true;
    this.updateconfigflagvb = true;
    this.array_disableradiobuttons = [];
    if (this.selectedrouteId != "") {
      var url = "api/Routes/get?id=" + this.selectedrouteId;
      this.ngxUiLoaderService.start();
      await this.service.getapiurl(url).subscribe({
        next: async (data) => {
          this.handlerflag = false;
          this.ruleflag = false;
          this.notebuttonflag = true;
          this.auditbuttonflag = true;
          this.rulebuttonshow = false;
          this.routeflag = true;
          this.additionalinfoflag = true;
          if (data["IsLocked"] !== undefined && data["IsLocked"] == "True") {
            this.isLocked = true;
          } else {
            this.isLocked = false;
          }
          if (data["SEDEPLOYMENTKEY"] != "") {
            this.sourcedeployementkey = data["SEDEPLOYMENTKEY"];
          }

          if (data["DEDEPLOYMENTKEY"] != "") {
            this.targetdeployementkey = data["DEDEPLOYMENTKEY"];
          }

          if (data["vendor"]) {
            this.vendarnamevalue = data["vendor"];
            this.vendarmsgdiv = true;
            this.reactiveFormvendar.patchValue({
              namevendar: data["vendor"],
            });
          } else {
            this.vendarmsgdiv = false;
          }

          this.modeSelected = data["mode"] == "" ? "Test Live" : data["mode"];
          this.reactiveFormmode.patchValue({
            modename: this.modeSelected,
          });

          //entity address
          this.sourcetype = data["sourcetype"];
          this.sourceentityaddress1 = data["seaddress1"];
          this.sourceentityaddress2 = data["seaddress2"];
          this.sourceentityaddress =
            data["seaddress1"] +
            "" +
            (data["seaddress2"] == null ? "" : data["seaddress2"]);
          this.sourcecity = data["secity"];
          this.sourcestate = data["sestate"];
          this.sourcezip = data["sezip"];

          this.targettype = data["targettype"];
          this.targetentityaddress1 = data["deaddress1"];
          this.targetentityaddress2 = data["deaddress2"];
          this.targetentityaddress =
            data["deaddress1"] +
            "" +
            (data["deaddress2"] == null ? "" : data["deaddress2"]);
          this.targetcity = data["decity"];
          this.targetstate = data["destate"];
          this.targetzip = data["dezip"];

          this.envSourceProxy = data["sourceagentid"];
          this.modelvbclient.vbclientagnetid = this.envSourceProxy;
          this.envTarget = data["destinationagentid"];
          this.myFlagForSlideToggle = data["status"] == "active" ? true : false;

          if (this.selectedrouteId != "") {
            this.UniqueID = data["UniqueID"];
            this.selectedrouteDisplayName = data["displayname"];
            this.reactiveFormroute.patchValue({
              routename: `${this.selectedrouteDisplayName} `,
            });

            this.RouteNameSuggest[0]["value"] = this.selectedrouteDisplayName
              .split(".")[0]
              .trim()
              .replace("-", "");
          }

          if (data["SENAME"] != "") {
            if (data["SEDEPLOYMENTKEY"] != "") {
              this.sourcedpkeydisplay = data["SEDEPLOYMENTKEY"] + "|";
            }

            this.selectedsourceaccountid = data["SEGUID"];
            this.selectedsourceaccountName = data["SENAME"];
            this.RouteNameSuggest[1]["value"] = this.selectedsourceaccountName
              .split(" ")[0]
              .trim()
              .replace("-", "");
            this.sourceentitytype = data["SETYPE"];
            this.sourcetypedisplay = data["sourcetype"].charAt(0);

            this.sourcetypedisplayid = "|" + this.selectedsourceaccountid;
            this.reactiveFormsource.patchValue({
              name: this.selectedsourceaccountName,
            });
          }

          if (data["DENAME"] != "") {
            if (data["DEDEPLOYMENTKEY"] != "") {
              this.targetdpkeydisplay = data["DEDEPLOYMENTKEY"] + "|";
            }

            this.selectedtargetaccountid = data["DEGUID"];
            this.selectedtargetaccountName = data["DENAME"];
            this.RouteNameSuggest[2]["value"] = this.selectedtargetaccountName
              .split(" ")[0]
              .trim()
              .replace("-", "");
            this.targetentitytype = data["DETYPE"];
            this.targettypedisplay = data["DETYPE"].charAt(0);

            this.targettypedisplayid = "|" + this.selectedtargetaccountid;
            this.reactiveFormtarget.patchValue({
              namet: this.selectedtargetaccountName,
            });
            // + `(${this.selectedtargetaccountid})`
          }

          if (data["PANAME"] != "") {
            this.selectedprocessapikey = data["PAAPIKEY"];
            this.selectedprocessagentid = data["PAID"];
            this.selectedprocessdisplayname = data["PANAME"];
            this.reactiveFormprocess.patchValue({
              namep: this.selectedprocessdisplayname,
            });
            // + `(${this.selectedprocessagentid})`

            this.isShown = true;
            this.btnshowtryit = true;
            this.copypiprlinrbutton = true;
            this.interfacetype = data["INTERFACETYPE"];

            if (this.interfacetype == "EllkayResultOutbound") {
              this.rulebuttonshow = true;
              this.isshownellkykeys = true;
              let jsonresponse = JSON.parse(data["CORECONFIG"]);
              this.modelelk.subscriberkey = jsonresponse[0].subscriberKey;
              this.modelelk.servicekey = jsonresponse[0].serviceKey;
              this.handlercreatedflag = true;
            } else {
              this.isshownellkykeys = false;
            }

            if (
              this.interfacetype == "Outbound" ||
              this.interfacetype == "ResultOutbound"
            ) {
              this.rulebuttonshow = true;
              let jsonresponse = JSON.parse(data["CORECONFIG"]);
              this.isShownresultoutbound = false;
              if (
                jsonresponse != undefined &&
                Object.keys(jsonresponse[0]).length > 0 &&
                jsonresponse[0].userName
              ) {
                this.ftpusername = jsonresponse[0].userName;
                this.ftppassword = jsonresponse[0].password;
                this.ftphost = "sftp://" + jsonresponse[0].host;
                this.ftpport =
                  jsonresponse[0].port == 0 ? "" : jsonresponse[0].port;
                this.suffixfromprocessor = jsonresponse[0].suffix;
                this.sourcepath = jsonresponse[0].sourcePath;
                this.archivepath = jsonresponse[0].archivePath;
                this.progresspath = jsonresponse[0].progressPath;
                this.errorpath = jsonresponse[0].errorPath;
                this.isShownresultoutbound = true;
                this.updateconfigflagftp = false;

                this.remotepathdisplay = jsonresponse[0].remotePath;
                this.ftpexstingpathdiv = false;
                this.ftpflag = true;
                this.ftpalreadycreated = true;
                this.ftpcreatedflag = true;
                this.ftpcreateddisplaylabel = true;
              } else {
                if (
                  jsonresponse[0].connectivityType != undefined &&
                  jsonresponse[0].connectivityType == "None"
                ) {
                  this.ftpexstingpathdiv = true;
                  this.resultsftpchecknone = true;
                } else if (
                  jsonresponse[0].connectivityType != undefined &&
                  jsonresponse[0].connectivityType == "API"
                ) {
                  this.ftpexstingpathdiv = true;
                  this.apiconnectivity = true;
                } else {
                  this.ftpcreatedflag = false;

                  this.ftpalreadycreated = false;
                  this.ftpflag = false;
                  this.ftpexstingpathdiv = true;
                  this.updateconfigflagftp = true;
                  this.toastr.warning(
                    "Pipeline is of type Outbound, Save the pipeline to create the FTP user",
                    "",
                    {
                      timeOut: 4000,
                      positionClass: "toast-bottom-right",
                    }
                  );
                }
              }
            } else {
              this.isShownresultoutbound = false;
            }

            if (this.interfacetype == "Inbound") {
              this.rulebuttonshow = false;
              let jsonresponse = JSON.parse(data["CORECONFIG"]);
              if (jsonresponse != undefined && jsonresponse.length > 0) {
                if (
                  jsonresponse[0] != undefined &&
                  Object.keys(jsonresponse[0]).length > 0 &&
                  jsonresponse[0].connectivityType &&
                  jsonresponse[0].connectivityType == "VPN"
                ) {
                  this.modelradio.option = jsonresponse[0].connectivityType;
                  this.array_disableradiobuttons.push("disable");
                  if (jsonresponse[0].vpnIp) {
                    this.modelvpn.ip = jsonresponse[0].vpnIp;
                    this.modelvpn.port = jsonresponse[0].vpnPort;
                    this.modelvpn.host = jsonresponse[0].vpnHost;
                    this.modelvpn.sourcepath = jsonresponse[0].vpnSourcePath;
                    this.modelvpn.vpninputdisabled = true;
                    this.vpncreatedflag = true;
                  } else {
                    this.vpncreatedflag = false;
                  }
                } else if (
                  jsonresponse[0] != undefined &&
                  Object.keys(jsonresponse[0]).length > 0 &&
                  jsonresponse[0].connectivityType &&
                  jsonresponse[0].connectivityType != "None" &&
                  jsonresponse[0].connectivityType != "API"
                ) {
                  this.vbjobalreadycreated = true;
                  this.vbflag = true;
                  // this.selectedjobId = jsonresponse.vbjob.jobid;
                  // this.selectedjobName = jsonresponse.vbjob.jobname;
                  // this.vbjobname = jsonresponse.vbjob.jobname;
                  this.modelradio.option = jsonresponse[0].connectivityType;
                  this.array_disableradiobuttons.push("disable");

                  this.vbjobcreateddisplaylabel = true;
                  this.vbjobcreatedflag = true;

                  if (this.modelradio.option == "Other") {
                    // this.vbjobnameftp = jsonresponse.vbjob.jobname;
                    this.vbjobdisplayexftp = true;

                    if (
                      jsonresponse[0] != undefined &&
                      Object.keys(jsonresponse[0]).length > 0
                    ) {
                      this.ftpisDisabled = true;
                      this.modelvbftp.sourcepath = jsonresponse[0].sourcePath;
                      this.modelvbftp.archivepath = jsonresponse[0].archivePath;
                      this.modelvbftp.progresspath =
                        jsonresponse[0].progressPath;
                      this.modelvbftp.errorpath = jsonresponse[0].errorPath;
                      this.ftpalreadycreated = true;
                    }
                  } else {
                    this.isShownorderinboundvb = true;
                    this.isShownorderinbound = true;
                    this.updateconfigflagvb = false;
                    if (this.modelradio.option == "VB Client") {
                      var jobdata = await this.getJobName(
                        this.selectedrouteDisplayName
                      );
                      if (jobdata.length > 0) {
                        this.vbfolderpathflag = true;
                        this.vbfolderpathdisplay =
                          jobdata[0].SourcePath.replace("\\source", "").replace(
                            /\//g,
                            "\\"
                          );
                      } else {
                        this.vbfolderpathdisplay = "";
                      }
                    } else {
                      this.vbfolderpathflag = false;
                    }
                  }
                } else {
                  if (
                    jsonresponse[0].connectivityType != undefined &&
                    jsonresponse[0].connectivityType == "None"
                  ) {
                    this.isShownorderinboundvb = false;
                    this.modelradio.option = jsonresponse[0].connectivityType;
                  } else if (
                    jsonresponse[0].connectivityType != undefined &&
                    jsonresponse[0].connectivityType == "API"
                  ) {
                    this.modelradio.option = jsonresponse[0].connectivityType;
                    this.array_disableradiobuttons.push("disable");
                  } else {
                    this.vbjobcreatedflag = false;
                    this.vbjobalreadycreated = false;
                    this.vbflag = false;
                    this.updateconfigflagvb = true;
                    this.isShownorderinboundvb = false;
                    this.toastr.warning(
                      "Pipeline is of type Inbound, Save the pipeline to create the VitalBridge job",
                      "",
                      {
                        timeOut: 4000,
                        positionClass: "toast-bottom-right",
                      }
                    );
                  }
                }
                if (
                  this.modelradio.option == "FTP/VPN" ||
                  this.modelradio.option == "FTP" ||
                  this.modelradio.option == "External FTP"
                ) {
                  if (
                    jsonresponse[0] != undefined &&
                    Object.keys(jsonresponse[0]).length > 0 &&
                    jsonresponse[0].userName
                  ) {
                    this.ftpusername = jsonresponse[0].userName;
                    this.ftppassword = jsonresponse[0].password;
                    this.ftphost = "sftp://" + jsonresponse[0].host;
                    this.ftpport =
                      jsonresponse[0].port == 0 ? "" : jsonresponse[0].port;
                    this.sourcepath = jsonresponse[0].sourcePath;
                    this.archivepath = jsonresponse[0].archivePath;
                    this.progresspath = jsonresponse[0].progressPath;
                    this.errorpath = jsonresponse[0].errorPath;
                    if (
                      this.modelradio.option == "External FTP" &&
                      jsonresponse.length > 1
                    ) {
                      this.customftp.username = jsonresponse[1].userName;
                      this.customftp.password = jsonresponse[1].password;
                      this.customftp.host = "sftp://" + jsonresponse[1].host;
                      this.customftp.port =
                        jsonresponse[1].port == 0 ? "" : jsonresponse[0].port;
                      this.customftp.folder = jsonresponse[1].remotePath;
                      this.customftp.id = jsonresponse[1].id;
                    }
                    this.isShownorderinboundftp = true;
                    this.isShownorderinbound = true;
                    //to show only remote site path
                    //we are not holding saparatly value

                    let TutoGyanServerStatus =
                      jsonresponse[0].sourcePath.split("\\")[
                        jsonresponse[0].sourcePath.split("\\").length - 2
                      ];
                    if (
                      TutoGyanServerStatus === this.selectedrouteDisplayName
                    ) {
                      this.remotepathdisplay =
                        "/" + this.selectedrouteDisplayName + "/";
                    } else {
                      this.remotepathdisplay = "/" + TutoGyanServerStatus + "/";
                    }

                    this.updateconfigflagftp = false;
                    this.ftpflag = true;
                    this.ftpalreadycreated = true;
                    this.ftpcreatedflag = true;
                    this.ftpcreateddisplaylabel = true;
                    this.modelradio.option =
                      this.modelradio.option == "External FTP"
                        ? "External FTP"
                        : "FTP";
                  } else {
                    this.ftpalreadycreated = false;
                    this.ftpflag = false;
                    this.updateconfigflagftp = true;
                    this.isShownorderinboundftp = false;
                    this.toastr.warning(
                      "Pipeline is of type Inbound, Save the pipeline to create the FTP user",
                      "",
                      {
                        timeOut: 4000,
                        positionClass: "toast-bottom-right",
                      }
                    );
                  }
                } else {
                  this.ftpalreadycreated = false;
                  this.updateconfigflagftp = true;
                  this.isShownorderinboundftp = false;
                }
              }
            } else {
              this.isShownorderinbound = false;
            }

            if (data["RouteProcessorData"] != "") {
              this.dynamicJSON = [];
              data["RouteProcessorData"].forEach((element) => {
                let controlname = "";
                let reactiveFormprocess: FormGroup;
                if (this.dynamicJSON.length == 0) {
                  controlname = "prop";
                  reactiveFormprocess = this._fb.group({
                    ["prop"]: `${element.displayname} `,
                  });
                } else {
                  controlname =
                    "prop" +
                    (this.dynamicJSON[this.dynamicJSON.length - 1].sequence +
                      1);
                  reactiveFormprocess = this._fb.group({
                    ["prop" +
                    (this.dynamicJSON[this.dynamicJSON.length - 1].sequence +
                      1)]: `${element.displayname} `,
                  });
                }

                let inputcnt: inputField = {
                  data: this.ProcessorAllList,
                  formcontrolname: controlname,
                  formgroupname: reactiveFormprocess,
                  sequence: this.dynamicJSON.length + 1,
                  mapicon: true,
                  islive: element.ISLIVE,
                };
                this.dynamicJSON.push(inputcnt);
              });
              if (this.dynamicJSON.length > 0) {
                let exists = this.dynamicJSON.filter(
                  (ele) => ele.islive === false
                );
                if (exists.length > 0) {
                  this.routeconfigflag = true;
                } else {
                  this.routeconfigflag = false;
                }
              }
            }
          } else {
            this.btnshowtryit = false;
            this.isShown = false;
            this.copypiprlinrbutton = false;
          }
          this.RouteNameSuggest[3]["value"] = data["messagetype"];
          if (
            this.interfacetype == "EllkayResultOutbound" ||
            this.interfacetype == "Outbound"
          ) {
            this.RouteNameSuggest[4]["value"] = "OutBound";
          }

          if (
            this.interfacetype == "EllkayOrderInbound" ||
            this.interfacetype == "Inbound"
          ) {
            this.RouteNameSuggest[4]["value"] = "InBound";
          }

          //set copy tab title
          if (this.modelradio["option"] == "VB Client") {
            this.copytitle = "Copy Job Name";
          } else if (
            this.modelradio["option"] == "FTP/VPN" ||
            this.modelradio["option"] == "FTP" ||
            this.modelradio["option"] == "External FTP"
          ) {
            this.copytitle = "Copy FTP Details";
          }

          //for audit purpose
          if (data["messagetype"]) {
            this.messagetype = data["messagetype"];
            this.reactiveFormmsgtype.patchValue({
              msgname: this.messagetype,
            });
          }
          if (data["routepolicy"]) {
            this.routepolicyselected = data["routepolicy"];
            this.reactiveFormroutepolicy.patchValue({
              routepolicy: this.routepolicyselected,
            });
          }
          if (data["senderapikey"]) {
            this.selectedsourceagentid = data["senderID"]
              ? data["senderID"]
              : "";
            this.selectedsourceagentname = data["senderdisplayname"]
              ? data["senderdisplayname"]
              : "";
            this.reactiveFormsourceagent.patchValue({
              sourceagent: this.selectedsourceagentname,
            });
          }
          if (data["recieverapikey"]) {
            this.selectedtargetagentid = data["recieverID"]
              ? data["recieverID"]
              : "";
            this.selectedtargetagentname = data["recieverdisplayname"]
              ? data["recieverdisplayname"]
              : "";
            this.reactiveFormTargetagent.patchValue({
              targetagent: this.selectedtargetagentname,
            });
          }

          //loading status of the pipeline start

          await this.gethandlerrulestatus();

          //loading status of the pipeline end

          let routepolicy = "SA..DE";
          if (this.selectedsourceaccountid != "") {
            routepolicy = "SA.SE.DE";
          }

          const insertorupdatepipelineentity = {
            ROUTEID: this.selectedrouteId,
            ROUTENAME: this.selectedrouteDisplayName,
            SEGUID: this.selectedsourceaccountid,
            SENAME: this.selectedsourceaccountName,
            SETYPE: data["SETYPE"],
            DEGUID: this.selectedtargetaccountid,
            DENAME: this.selectedtargetaccountName,
            DETYPE: data["DETYPE"],
            SOURCEPROXY: this.envSourceProxy,
            TARGETPROXY: this.envTarget,
            ROUTEPOLICY: routepolicy,
            MESSAGETYPE: this.messagetype,
            PAID: this.selectedprocessagentid,
            MODIFIERID: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
              .userdata["userid"],
            STATUS: this.myFlagForSlideToggle == true ? "active" : "passive",
            VERSION: "2",
            SEDEPLOYMENTKEY: "",
            DEDEPLOYMENTKEY: "",
            VENDORNAME: this.vendarnamevalue,
          };

          this.oldvalue = JSON.stringify(insertorupdatepipelineentity);
          this.ngxUiLoaderService.stop();
        },
        error: (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(
            "Unable to fetch Pipeline details with ID:" + this.selectedrouteId,
            "",
            {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            }
          );
        },
      });
    }
  }

  messagetype: string = "";
  async getJobName(jobname) {
    const PostFormData = {
      VBAgentID: this.envSourceProxy,
      JobName: jobname,
      SourceEntity: this.selectedsourceaccountid,
      DestinationEntity: this.selectedtargetaccountid,
      MessageType: this.messagetype,
      SourcePath: "",
    };
    return await this.service.postapi("api/VBJobs/GetVbFileJob", PostFormData);
  }

  async gethandlerrulestatus() {
    this.ngxUiLoaderService.start();
    var deploymentleveconfigs = await this.getdeploymentconfiguration();
    var res;
    var dpkey;
    if (
      this.interfacetype == "Outbound" ||
      this.interfacetype == "EllkayResultOutbound" ||
      this.interfacetype == "ResultOutbound"
    ) {
      res = deploymentleveconfigs[this.targetdeployementkey];
      dpkey = this.targetdeployementkey;
    } else {
      res = deploymentleveconfigs[this.sourcedeployementkey];
      dpkey = this.sourcedeployementkey;
    }

    var api =
      "api/VitalHubDXAPI/gethandlerandrule?groupid=" + this.selectedrouteId;
    await this.service.dxgetapi(api, dpkey).subscribe({
      next: (data) => {
        this.additionalinfodataflag = true;
        if (
          this.interfacetype == "EllkayOrderInbound" ||
          this.interfacetype == "Inbound"
        ) {
          this.handlercreateddisplaylabel = true;
          this.rulecreateddisplaylabel = false;
          if (
            this.modelradio["option"] != "API" &&
            this.modelradio["option"] != "VPN" &&
            this.modelradio["option"] != "None"
          ) {
            this.vbjobcreateddisplaylabel = true;
          }

          if (this.modelradio["option"] == "FTP") {
            this.ftpcreateddisplaylabel = true;
          }
        } else if (
          this.interfacetype == "EllkayResultOutbound" ||
          this.interfacetype == "Outbound"
        ) {
          this.handlercreateddisplaylabel = true;
          this.rulecreateddisplaylabel = true;
          this.ftpcreateddisplaylabel = true;
          this.vbjobcreateddisplaylabel = false;
        }

        if (data["Handler"] !== "" && data["Handler"] !== null) {
          this.handlercreatedflag = true;
        } else {
          this.handlercreatedflag = false;
        }

        if (
          data["Handler"] !== "" &&
          data["VaRule"] !== "" &&
          data["Handler"] !== null &&
          data["VaRule"] !== null
        ) {
          this.rulecreatedflag = true;
        } else {
          this.rulecreatedflag = false;
        }
        this.ngxUiLoaderService.stop();
      },
      error: (error) => {
        this.additionalinfodataflag = false;
        // this.toastr.error("failed to get pipeline status", "",
        //   { timeOut: 4000, positionClass: 'toast-bottom-right' });
        this.ngxUiLoaderService.stop();
      },
    });
  }

  async searchHandlerNameCleared(event) {
    if (this.selectedrouteId !== "") {
      await this.Resetalldata();
      await this.setfirstcontrol();
    }
  }

  async Resetalldata() {
    this.isLocked = false;
    this.selectedprocessagentid = "";
    this.selectedsourceaccountid = "";
    this.selectedtargetaccountid = "";
    this.selectedrouteId = "";
    this.UniqueID = "";
    this.selectedrouteDisplayName = "";
    this.selectedsourceaccountName = "";
    this.selectedtargetaccountName = "";
    this.selectedprocessdisplayname = "";
    this.reactiveFormsource.reset();
    this.reactiveFormtarget.reset();
    this.btnshowtryit = false;
    this.reactiveFormvbjob.reset();
    this.selectedprocessapikey = "";
    this.myFlagForSlideToggle = false;
    this.reactiveFormroute.reset();
    this.isShownorderinbound = false;
    this.isShown = false;
    this.isShownresultoutbound = false;
    this.modelelk.subscriberkey = "";
    this.modelelk.servicekey = "";
    this.sourcetypedisplay = "";
    this.sourcetypedisplayid = "";
    this.targettypedisplay = "";
    this.targettypedisplayid = "";
    this.isshownellkykeys = false;
    this.RouteNameSuggest.forEach((element) => {
      element.value = "";
    });
    this.remotepathdisplay = "";
    this.sourcepath = "";
    this.archivepath = "";
    this.errorpath = "";
    this.progresspath = "";
    this.ftpusername = "";
    this.ftppassword = "";
    this.ftpport = "";
    this.ftphost = "";
    this.ftpremotepath = "";
    this.interfacetype = "";
    this.selectedjobId = "";
    this.selectedjobName = "";
    this.vbjobname = "";
    this.modelradio = {};
    this.VBClientmatcard = false;
    this.array_disableradiobuttons = [];
    this.updateconfigflagftp = true;
    this.updateconfigflagvb = true;
    this.vbfolderpathdisplay = "";
    this.selectedextensionItems = [];
    this.modelvbftp = {};
    this.ftpisDisabled = false;
    this.vbjobnameftp = "";
    this.vbjobdisplayexftp = false;
    this.oldvalue = "";
    this.newvalue = "";
    this.vendarmsgdiv = false;
    this.vendarnamevalue = "";
    this.reactiveFormvendar.reset();
    this.sourcedpkeydisplay = "";
    this.targetdpkeydisplay = "";
    this.ftpcheckexsting = false;
    this.ftpexistingpath = "";
    this.pipelineselectclass = "";
    this.ftpexstingpathdiv = false;
    this.copypipelineflag = false;
    this.oldselectedrouteDisplayName = "";
    this.resetflag = false;
    this.copypiprlinrbutton = false;
    this.sourceentityaddress = "";
    this.sourceentityaddress1 = "";
    this.sourceentityaddress2 = "";
    this.sourcecity = "";
    this.sourcestate = "";
    this.sourcezip = "";
    this.sourcetype = "";
    this.targetentityaddress = "";
    this.targetentityaddress1 = "";
    this.targetentityaddress2 = "";
    this.targetcity = "";
    this.targetstate = "";
    this.targetzip = "";
    this.targettype = "";
    this.modeSelected = "";
    this.reactiveFormmode.reset();
    this.rulebuttonshow = false;
    this.auditbuttonflag = false;
    this.notebuttonflag = false;
    this.configid = "";
    this.configjson = "";
    this.routeconfigflag = true;
    this.enableconfig = false;
    this.configagentid = "";
    this.reactiveFormsourceagent.reset();
    this.reactiveFormTargetagent.reset();
    this.SourceAgentNameList = [];
    this.TargetAgentNameList = [];
    this.selectedsourceagentid = "";
    this.selectedtargetagentid = "";
    this.isLoadingagent = false;
    this.isLoadingEventLabOrgagent = false;
    this.selectedsourceagentname = "";
    this.selectedtargetagentname = "";
    this.selectedsourceapikey = "";
    this.selectedtargetapikey = "";
    this.messagetypeselected = "";
    this.routepolicyselected = "";
    this.additionalinfoflag = false;
    this.VendarsList = this.VendornameBackuplist;
    this.connectivitytypemsg = "";
    this.reactiveFormroutepolicy.reset();
    this.reactiveFormmsgtype.reset();
    this.modelvpn.ip = "";
    this.modelvpn.port = "";
    this.modelvpn.host = "";
    this.modelvpn.sourcepath = "";
    this.modelvpn.vpninputdisabled = false;
    this.vpncreatedflag = false;
    this.selectedInterfacedescription = "";
    this.configagentapikey = "";
    this.apiconnectivity = false;
    this.vendorid = "";
    this.customftp = <CustomFTPDetails>{
      username: "",
      password: "",
      host: "",
      port: "",
      folder: "",
      id: "",
    };
  }
  resetformsEvent() {
    this.EventFilterForm.reset();

    this.EventFilterForm.patchValue({
      searchkey: "accessionnumber",
      searchvalue: "",
      category: "",
      Eventtype: "",
      fromdate: "",
      todate: "",
      status: "",
      laborgid: "",
      deploymentKey: "",
      eventsStatus: ""
    });
    this.selectedDeployement = "";
    this.selectedEventtype = "";
    this.EventDataList = [];
    this.selectedEvent = "Accession Number";
    // this.ismandatoryEvents = true;
    this.LabOrgList = [];
    this.selectedLaborgid = "";
    this.archiveflagcheck = false;
  }

  resetformsLogs() {
    this.LogFilterForm.reset();
    this.LogFilterForm.patchValue({
      logsearchkey: "accessionnumber",
      logsearchvalue: "",
      handler: "",
      logfromdate: "",
      logtodate: "",
      logstatus: "",
      loglaborgid: "",
      logdeploymentKey: "",
    });
    this.selectedDeployementLogs = "";
    this.selectedhandler = "";
    this.selectedLogLaborgid = "";
    this.Hl7LogsList = [];
    this.LogLabOrgList = [];
    this.selectedLogs = "Accession Number";
    this.archiveflagcheck = false;
    // this.ismandatory = true;
  }
  resetformsRegenarate() {
    this.dateToXml = "";
    this.dateFromXml = "";
    // this.today='';
    // this.LogFilterForm.reset();
    this.RegenerateFilterForm.reset();
    this.RegenerateFilterForm.patchValue({
      xmlsearchkey: "accessionnumber",
      xmlsearchvalue: "",
      handler: "",
      xmlfromdate: "",
      xmltodate: "",
      xmllaborgid: "",
      xmldeploymentKey: "",
    });

    this.selectedDeployementXML = "";
    this.selectedhandler = "";
    this.selectedLogLaborgid = "";
    this.caseRegenerateXMlList = [];
    this.selectedRegenerate = "Accession Number";
    // this.ismandatory = true;
  }

  async setfirstcontrol() {
    this.dynamicJSON = [];
    if (this.dynamicJSON.length == 0) {
      let reactiveFormprocess: FormGroup = this._fb.group({ prop: "" });
      let inputcnt: inputField = {
        data: this.ProcessorAllList,
        formcontrolname: "prop",
        formgroupname: reactiveFormprocess,
        sequence: 1,
        mapicon: false,
        islive: false,
      };
      this.dynamicJSON.push(inputcnt);
    }
  }

  buildpiplinetitle(name, id) {
    let fullname = name + "(" + id + ")";
    if (id) {
      return fullname;
    } else {
      return;
    }
  }

  getstringRoute(value) {
    let names = value.split("(");
    value = `${names[0]} <br>(${names[1]}`;
    return value;
  }

  checkvalid(value) {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (
      value !== 0 &&
      value !== "_" &&
      value !== "%" &&
      value !== "+" &&
      value !== "-" &&
      !format.test(value)
    ) {
      this.isvalidSPLchar = false;
    } else {
      this.isvalidSPLchar = true;
    }
  }

  onSearchEvents() {
    // let obj=this.MirthFitlerForm.value;
    if (this.EventFilterForm.value.deploymentKey == "") {
      this.selectedDeployement = "";
    } else if (typeof this.EventFilterForm.value.deploymentKey == "string") {
      this.selectedDeployement = this.EventFilterForm.value.deploymentKey;
    } else if (typeof this.EventFilterForm.value.deploymentKey == "object") {
      this.selectedDeployement = this.EventFilterForm.value.Eventtype
        ? this.EventFilterForm.value.Eventtype.deploymentKey
        : "";
    }
    let mandanEvents = false;
    if (this.ismandatoryEvents) {
      if (
        this.EventFilterForm.value.searchvalue !== null &&
        this.EventFilterForm.value.searchvalue !== ""
      ) {
        mandanEvents = true;
      } else {
        mandanEvents = false;
      }
    } else {
      mandanEvents = true;
    }
    if (this.selectedDeployement !== "" && mandanEvents) {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (
        this.EventFilterForm.value.caseid !== "" &&
        format.test(this.EventFilterForm.value.caseid)
      ) {
        this.checkvalid(this.EventFilterForm.value.caseid);
      } else {
        this.isvalidSPLchar = false;
      }
      if (this.EventFilterForm.value.Eventtype == "") {
        this.selectedEventtype = "";
      } else if (typeof this.EventFilterForm.value.Eventtype == "string") {
        this.selectedEventtype = this.EventFilterForm.value.Eventtype;
      } else if (typeof this.EventFilterForm.value.Eventtype == "object") {
        this.selectedEventtype = this.EventFilterForm.value.Eventtype
          ? this.EventFilterForm.value.Eventtype.lookupvalue
          : "";
      }
      
      let newobj = {
        searchkey: this.EventFilterForm.value.searchkey
          ? this.EventFilterForm.value.searchkey
          : "",
        searchvalue: this.EventFilterForm.value.searchvalue
          ? this.EventFilterForm.value.searchvalue
          : "",
        category: this.EventFilterForm.value.category
          ? this.EventFilterForm.value.category
          : "",
        Eventtype: this.selectedEventtype,
        fromdate: this.EventFilterForm.value.fromdate
          ? this.EventFilterForm.value.fromdate + " 00:00:00"
          : "",
        todate: this.EventFilterForm.value.todate
          ? this.EventFilterForm.value.todate + " 23:59:59"
          : "",
        laborgid: this.selectedLaborgid,
        status: this.EventFilterForm.value.eventsStatus.lookupid
        ? this.EventFilterForm.value.eventsStatus.lookupid :"",
          archiveflag:this.archiveflagcheck
      };

      this.ngxUiLoaderService.start();
      this.commonService
        .getEventsList(newobj, this.selectedDeployement)
        .subscribe(
          (data) => {
            this.selection.clear();
            this.ngxUiLoaderService.stop();
            data.responsebody = JSON.parse(data.responsebody);
            if (data.responsebody !== null && data.responsebody.length > 0) {
              this.EventDataList = data.responsebody;
              if(this.EventTypeList.length>0){
              this.EventDataList.map((va) => {
                let exist = this.EventTypeList.filter(
                  (vaa) => vaa.lookupvalue == va.EventType
                );
                if (exist.length > 0) {
                  va["EventName"] = exist[0].lookupid;
                } else {
                  va["EventName"] = va.EventType;
                }
              });
            }else{
              this.toastr.warning("Events Not Found", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
              });
            }
            } else {
              if (data.status == "Failure") {
                this.Hl7LogsList = [];
                this.toastr.error(data.statusmessage, data.status, {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              } else {
                this.EventDataList = data.responsebody;
                this.toastr.warning("Events Not Found", "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              }
            }
          },
          (error) => {
            this.EventDataList = [];
            this.ngxUiLoaderService.stop();
          }
        );
    } else {
      // if(!mandanEvents && this.selectedLaborgid == ''){
      //   this.toastr.warning(`Lab is Mandatory for ${this.selectedEvent} Search`, "", {
      //     timeOut: 4000,
      //     positionClass: "toast-bottom-right",
      //   });
      // }
      if (this.selectedDeployement == "") {
        this.toastr.warning("Deployment Key is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
      // if(this.ismandatoryEvents){{
      //   if( this.EventFilterForm.value.searchvalue == null ||  this.EventFilterForm.value.searchvalue == ''){
      //     this.toastr.warning(`${this.selectedEvent} is Mandatory`, "", {
      //       timeOut: 4000,
      //       positionClass: "toast-bottom-right",
      //     });
      //   }
      // }
      // }
    }
  }

  onSearchLogs() {
    // let obj=this.MirthFitlerForm.value;
    if (this.LogFilterForm.value.handler == "") {
      this.selectedhandler = "";
    } else if (typeof this.LogFilterForm.value.handler == "string") {
      this.selectedhandler = this.LogFilterForm.value.handler;
    } else if (typeof this.LogFilterForm.value.handler == "object") {
      this.selectedhandler = this.LogFilterForm.value.handler
        ? this.LogFilterForm.value.handler.id
        : "";
    }
    if (this.LogFilterForm.value.logdeploymentKey == "") {
      this.selectedDeployementLogs = "";
    } else if (typeof this.LogFilterForm.value.logdeploymentKey == "string") {
      this.selectedDeployementLogs = this.LogFilterForm.value.logdeploymentKey;
    } else if (typeof this.LogFilterForm.value.logdeploymentKey == "object") {
      this.selectedDeployementLogs = this.LogFilterForm.value.logdeploymentKey
        ? this.LogFilterForm.value.logdeploymentKey
        : "";
    }

    if (this.LogFilterForm.value.logstatus == "") {
      this.selectedLogStatus = "";
    } else if (typeof this.LogFilterForm.value.logstatus == "string") {
      this.selectedLogStatus = this.LogFilterForm.value.logstatus;
    } else if (typeof this.LogFilterForm.value.logstatus == "object") {
      this.selectedLogStatus = this.LogFilterForm.value.logstatus.lookupid
        ? this.LogFilterForm.value.logstatus.lookupid
        : "";
    }

    let mandan = false;
    if (this.ismandatory) {
      if (
        this.LogFilterForm.value.logsearchvalue !== null &&
        this.LogFilterForm.value.logsearchvalue !== ""
      ) {
        mandan = true;
      } else {
        mandan = false;
      }
    } else {
      mandan = true;
    }
    if (this.selectedDeployementLogs !== "" && mandan) {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (
        this.LogFilterForm.value.caseid !== "" &&
        format.test(this.LogFilterForm.value.caseid)
      ) {
        this.checkvalid(this.LogFilterForm.value.caseid);
      } else {
        this.isvalidSPLchar = false;
      }
      let logobj = {
        searchkey: this.LogFilterForm.value.logsearchkey
          ? this.LogFilterForm.value.logsearchkey
          : "",
        searchvalue: this.LogFilterForm.value.logsearchvalue
          ? this.LogFilterForm.value.logsearchvalue
          : "",
        handler: this.selectedhandler,
        fromdate: this.LogFilterForm.value.logfromdate
          ? this.LogFilterForm.value.logfromdate + " 00:00:00"
          : "",
        todate: this.LogFilterForm.value.logtodate
          ? this.LogFilterForm.value.logtodate + " 23:59:59"
          : "",
        laborgid: this.selectedLogLaborgid,
        status: this.selectedLogStatus,
        archiveflag:this.archiveflagcheck
      };
      this.ngxUiLoaderService.start();
      this.commonService
        .getHl7LogsList(logobj, this.selectedDeployementLogs)
        .subscribe(
          (data) => {
            this.selectionLogs.clear();
            this.ngxUiLoaderService.stop();
            if (data.responsebody !== null && data.responsebody != '[]'&& data.responsebody !='') {
              if(typeof(data.responsebody).toLowerCase()=='string'){
                this.Hl7LogsList = JSON.parse(data.responsebody);
              }
              else{
                this.Hl7LogsList = data.responsebody
              }
            } else {
              if (data.status == "Failure") {
                this.Hl7LogsList = [];
                this.toastr.error(data.statusmessage, data.status, {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              } else {
                this.Hl7LogsList = [];
                this.toastr.warning("Logs Not Found", "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              }
            }
          },
          (error) => {
            this.Hl7LogsList = [];
            this.ngxUiLoaderService.stop();
          }
        );
    } else {
      if (this.selectedDeployementLogs == "") {
        this.toastr.warning("Deployment Key is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    }
  }
  onSearchRenegenarateXMl() {
    ;
    // let obj=this.MirthFitlerForm.value;
    if (this.RegenerateFilterForm.valid) {
      if (this.RegenerateFilterForm.value.xmldeploymentKey == "") {
        this.selectedDeployementXML = "";
      } else if (
        typeof this.RegenerateFilterForm.value.xmldeploymentKey == "string"
      ) {
        this.selectedDeployementXML =
          this.RegenerateFilterForm.value.xmldeploymentKey;
      } else if (
        typeof this.RegenerateFilterForm.value.xmldeploymentKey == "object"
      ) {
        this.selectedDeployementXML = this.RegenerateFilterForm.value
          .xmldeploymentKey
          ? this.RegenerateFilterForm.value.xmldeploymentKey
          : "";
      }

      if (this.selectedDeployementXML !== "") {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (
          this.RegenerateFilterForm.value.caseid !== "" &&
          format.test(this.RegenerateFilterForm.value.caseid)
        ) {
          this.checkvalid(this.RegenerateFilterForm.value.caseid);
        } else {
          this.isvalidSPLchar = false;
        }

        let logobj = {
          DeploymentKey: this.selectedDeployementXML,
          AccessionNumber:
            this.selectedRegenerate === "Accession Number"
              ? this.RegenerateFilterForm.value.xmlsearchvalue
              : "",
          CaseIds:
            this.selectedRegenerate === "Case ID"
              ? this.RegenerateFilterForm.value.xmlsearchvalue
              : "",
          LabAccountId: this.selectedXMLLaborgid,
          FromDate: this.RegenerateFilterForm.value.xmlfromdate
            ? this.RegenerateFilterForm.value.xmlfromdate
            : "",
          ToDate: this.RegenerateFilterForm.value.xmltodate
            ? this.RegenerateFilterForm.value.xmltodate
            : "",
        };

        this.ngxUiLoaderService.start();

        this.commonService.getRegenarateXML(logobj).subscribe(
          (data) => {
            this.selectionRegenerateXml.clear();
            this.ngxUiLoaderService.stop();
            if (data.responsebody !== null && data.responsebody.length > 0) {
              this.caseRegenerateXMlList = JSON.parse(data.responsebody);
              console.log(this.caseRegenerateXMlList);
            } else {
              if (data.status == "Failure") {
                this.caseRegenerateXMlList = [];
                this.toastr.error(data.statusmessage, data.status, {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              } else {
                this.caseRegenerateXMlList = [];
                this.toastr.warning("Case Not Found", "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              }
            }
          },
          (error) => {
            this.caseRegenerateXMlList = [];
            this.ngxUiLoaderService.stop();
          }
        );
      } else {
        if (this.selectedDeployementLogs == "") {
          this.toastr.warning("Deployment Key is Mandatory", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      }
    } else {
      if (
        this.RegenerateFilterForm.value.xmldeploymentKey === "" ||
        this.RegenerateFilterForm.value.xmldeploymentKey === null
      ) {
        this.toastr.warning("Deployment Key is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
      if (
        this.RegenerateFilterForm.value.xmlfromdate === "" ||
        this.RegenerateFilterForm.value.xmlfromdate === null
      ) {
        this.toastr.warning("From Date is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
      if (
        this.RegenerateFilterForm.value.xmltodate === "" ||
        this.RegenerateFilterForm.value.xmltodate === null
      ) {
        this.toastr.warning("To Date is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    }
  }

  getEventTypeLogs() {
    this.commonService.getEventType().subscribe(
      (data) => {
        if (data.length > 0) {
          this.EventTypeList = this.getUniqueListBy(data, "lookupid");
          // = data as any[];
          // this.EventTypeList = data.filter(va =>va.lookuptype =="Eventtype");
        } else if (data.length === 0) {
          this.EventTypeList = [];
          this.notfounddata7 = true;
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  getSearchLogStatus() {
    this.commonService.searchlogstatus().subscribe(
      (data) => {
        if (data.length > 0) {
          this.LogStatus = this.getUniqueListBy(data, "lookupid");
        } else if (data.length === 0) {
          this.LogStatus = [];
          this.notfounddata7 = true;
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  reprocessLogStatus() {
    this.commonService.logreprocessStatus().subscribe(
      (data) => {
        if (data.length > 0) {
          this.ReprocessLogStatus = this.getUniqueListBy(data, "lookupid");
        } else if (data.length === 0) {
          this.ReprocessLogStatus = [];
          this.notfounddata7 = true;
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  selectedEventtype: string;
  selectedeventtype(item) {
    this.selectedEventtype = item.lookupvalue;
  }
  selectedEventstatus(item) {
    this.selectedEventStatus = item.lookupid;
    this.EventFilterForm.patchValue({
      eventsStatus: item.lookupid,
    });
  }

  selectedlogstatus(item) {
    this.selectedLogStatus = item.lookupid;
    this.LogFilterForm.patchValue({
      logstatus: item.lookupid,
    });
  }
  selectedxmltatus(item) {
    this.selectedXMLStatus = item.lookupid;
    this.RegenerateFilterForm.patchValue({
      xmlstatus: item.lookupid,
    });
  }

  selectedreprocesslogstatus(item) {
    this.selectedReprocessLogStatus = item.lookupid;
  }
  copyToClip(event: any, message) {
    if (event != "") {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }

  RegenerateXMLSave() {
    ;
    let selectedcase = [];
    this.selectionRegenerateXml.selected.forEach((acc) => {
      selectedcase.push(acc.Caseguid
      );
    });
    if (selectedcase.length > 0) {
    }
    let obj = {
      DeploymentKey: this.selectedDeployementXML,
      CaseGUIDs: selectedcase,
    };
    this.ngxUiLoaderService.start();
    this.service.SaveRegenarateXML(obj).subscribe(
      (data) => {
        ;
        if (data) {
          this.toastr.success("Regenerate Successfully", data.status, {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.onSearchRenegenarateXMl();
        }
      },
      (err) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  onSearchClaim() {
    if (this.ClaimFilterForm.valid) {
      if (this.ClaimFilterForm.value.claimdeploymentKey == "") {
        this.selectedDeployementClaim = "";
      } else if (
        typeof this.ClaimFilterForm.value.claimdeploymentKey == "string"
      ) {
        this.selectedDeployementClaim =
          this.ClaimFilterForm.value.claimdeploymentKey;
      } else if (
        typeof this.ClaimFilterForm.value.claimdeploymentKey == "object"
      ) {
        this.selectedDeployementClaim = this.ClaimFilterForm.value.claimdeploymentKey
          ? this.ClaimFilterForm.value.claimdeploymentKey
          : "";
      }

      if (this.selectedDeployementClaim !== "") {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (
          this.ClaimFilterForm.value.caseid !== "" &&
          format.test(this.ClaimFilterForm.value.caseid)
        ) {
          this.checkvalid(this.ClaimFilterForm.value.caseid);
        } else {
          this.isvalidSPLchar = false;
        }

        let logobj = {
          DeploymentKey: this.selectedDeployementClaim,
          AccessionNumber:
            this.selectedClaim === "Accession Number"
              ? this.ClaimFilterForm.value.claimsearchvalue
              : "",
          CaseIds:
            this.selectedClaim === "Case ID"
              ? this.ClaimFilterForm.value.claimsearchvalue
              : "",
          LabAccountId: this.selectedClaimLaborgid,
          FromDate: this.ClaimFilterForm.value.claimfromdate
            ? this.ClaimFilterForm.value.claimfromdate
            : "",
          ToDate: this.ClaimFilterForm.value.claimtodate
            ? this.ClaimFilterForm.value.claimtodate
            : "",
        };

        this.ngxUiLoaderService.start();

        this.commonService.getRegenarateXML(logobj).subscribe(
          (data) => {
            this.selectionRepopulateclaim.clear();
            this.ngxUiLoaderService.stop();
            if (data.responsebody !== null && data.responsebody.length > 0) {
              this.caseRepopulateClaimList = JSON.parse(data.responsebody);
              console.log(this.caseRepopulateClaimList);
            } else {
              if (data.status == "Failure") {
                this.caseRegenerateXMlList = [];
                this.toastr.error(data.statusmessage, data.status, {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              } else {
                this.caseRepopulateClaimList = [];
                this.toastr.warning("Case Not Found", "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
              }
            }
          },
          (error) => {
            this.caseRepopulateClaimList = [];
            this.ngxUiLoaderService.stop();
          }
        );
      } else {
        if (this.selectedDeployementLogs == "") {
          this.toastr.warning("Deployment Key is Mandatory", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      }
    } else {
      if (
        this.ClaimFilterForm.value.claimdeploymentKey === "" ||
        this.ClaimFilterForm.value.claimdeploymentKey === null
      ) {
        this.toastr.warning("Deployment Key is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
      if (
        this.ClaimFilterForm.value.claimfromdate === "" ||
        this.ClaimFilterForm.value.claimfromdate === null
      ) {
        this.toastr.warning("From Date is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
      if (
        this.ClaimFilterForm.value.claimtodate === "" ||
        this.ClaimFilterForm.value.claimtodate === null
      ) {
        this.toastr.warning("To Date is Mandatory", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    }
  }
  resetformsClaim() {
    this.dateToClaim = "";
    this.dateFromClaim = "";
    this.ClaimFilterForm.reset();
    this.ClaimFilterForm.patchValue({
      claimsearchkey: "accessionnumber",
      claimsearchvalue: "",
      handler: "",
      claimfromdate: "",
      claimodate: "",
      claimlaborgid: "",
      claimdeploymentKey: "",
    });

    this.selectedDeployementXML = "";
    this.selectedhandler = "";
    this.selectedLogLaborgid = "";
    this.caseRepopulateClaimList = [];
    this.selectedClaim = "Accession Number";
  }
  repopulateClaims() {
    let selectedcase = [];
    this.selectionRepopulateclaim.selected.forEach((acc) => {
      selectedcase.push(acc.Caseid);
    });
    if (selectedcase.length > 0) {
    }
    let obj = {
      DeploymentKey: this.selectedDeployementClaim,
      CaseGUIDs: selectedcase,
    };
    this.ngxUiLoaderService.start();
    this.service.repopulateCaseId(obj).subscribe((data) => {
        if (data['status']=='Success') {
          this.toastr.success(data.statusmessage, data.status, {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.onSearchClaim();
        }
        else{
          this.toastr.warning(data.statusmessage, data.status, {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.onSearchClaim();
        }
      },
      (err) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }
  clearClaim() {
    this.ClaimFilterForm.patchValue({
      claimsearchvalue: "",
    });
  }

  selectClaim(value) {
    this.Xmkismandatory = false;
    switch (value) {
      case "Accession Number":
        this.selectedClaim = value;
        this.ClaimFilterForm.patchValue({
          claimsearchkey: "accessionnumber",
        });
        break;
      case "Case ID":
        this.selectedClaim = value;
        this.ClaimFilterForm.patchValue({
          claimsearchkey: "caseid",
        });
        break;
      default:
        break;
    }
  }
  OnClaimLogDeploymentChange(item) {
    this.selectedDeployementLogs = item;
  }
  searchClaimLogDeployementCleared() {
    this.selectedDeployement = "";
  }
  async getclaimaborgs(event: any) {
    var valueta = event;
    if (event.includes(":")) {
      let splitValues = event.split(":");
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":");
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":");
      }
    }
    this.ClaimFilterForm.patchValue({
      claimlaborgid: event,
    });
    if (valueta != "" && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      var createSearch =
        "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" +
        valueToSendToApiWithOutWhiteSpace;
      this.isLoadingLogLabOrg = true;
      let response = await this.service.getapi(createSearch);
      ;
      if (response["status"] && response["statusText"]) {
        this.notfounddata = true;
        this.isLoadingLogLabOrg = false;
        this.toastr.error("Unable to fetch Lab Details", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.claimLabOrgList = [];
      } else {
        if (response.length > 0) {
          this.claimLabOrgList = response as any[];
          this.claimLabOrgList = this.service.transformAlphabetically(
            this.claimLabOrgList,
            "LocationName"
          );
          this.claimLabOrgList.map(
            (obj) =>
              (obj.LocationName = `  ${"(" + obj.deploymentkey + ")"} ${
                obj.LocationName
              } ${"(" + obj.LocationGUID + ")"}`)
          );
        }
        if (response.length == 0) {
          this.notfounddata8 = true;
          this.claimLabOrgList = [];
        } else {
          this.notfounddata8 = false;
        }
        this.selectedtargetaccountid = "";
        this.isLoadingLogLabOrg = false;
      }
    } else {
      this.notfounddata4 = true;
    }
  }
  searchclaimNameCleared(event) {
    this.selectedClaimLaborgid = "";
    this.claimLabOrgList = [];
    this.notfounddata8 = false;
  }

  selectedclaimLaborg(item) 
  {
    this.selectedClaimLaborgid = item.entityid;
  }
  claimlocationString(item, item1) {
    return "(" + item + ")" + " " + item1;
  }
  checkboxLabelclaim(row?: any) {
    if (!row) {
      return `${this.isAllSelectedclaim() ? "deselect" : "select"} all`;
    }
    let ui = `${
      this.selectionRepopulateclaim.isSelected(row) ? "deselect" : "select"
    } row ${row.position + 1}`;
    return ui;
  }

  isAllSelectedclaim() {
    const numSelected = this.selectionRepopulateclaim.selected.length;
    const numRows = this.caseRepopulateClaimList.length;
    return numSelected === numRows;
  }
  masterToggleClaim() {
    this.isAllSelectedclaim()
      ? this.selectionRepopulateclaim.clear()
      : this.caseRepopulateClaimList.forEach((row) =>
          this.selectionRepopulateclaim.select(row)
        );
  }

  getSearchEventStatus() {
    this.commonService.searchEventStatus().subscribe(
      (data) => {
        if (data.length > 0) {
          this.EventsStatus = this.getUniqueListBy(data, "lookupid");
        } else if (data.length === 0) {
          this.EventsStatus = [];
          this.notfounddata7 = true;
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }
}
