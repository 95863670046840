import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'idea-pagination',
  templateUrl: './paginationcomponent.component.html',
  styleUrls: ['./paginationcomponent.component.scss']
})
export class PaginationcomponentComponent {

  @Input('isMsgVisible') isMsgVisible: boolean = false
  @Input('msg') msg: string = ''
  @Input('pageSize') pageSize!: number
  @Input('totallength') totallength;
  @Output('currentPage') currentPage: EventEmitter<number> = new EventEmitter<number>()
  selectedPage: number = 0
  maxPageSize!: number
  originalArray: number[] = []
  paginationArr: number[] = []

  ngOnChanges(changes: SimpleChanges) {
     
    if (changes['pageSize'] || changes['totallength']) {
      if ((this.totallength / this.pageSize).toString().includes('.')) {
        this.originalArray = Array.from(
          { length: (this.totallength / this.pageSize) + 1 },
          (_, index) => index + 1
        );
      }
      else {
        this.originalArray = Array.from(
          { length: this.totallength / this.pageSize },
          (_, index) => index + 1
        );
      }

      this.paginationArr = this.originalArray
      this.maxPageSize = Math.floor(this.originalArray.length)
      this.pageSize = Math.floor(this.pageSize)
      if (this.originalArray.length > 5) {
        this.paginationArr = [1, 2, 3, 4, 5, 6]
      }
      this.onPageSelection(0);

    }
  }

  onPageSelection(pageNumber: number) {
    this.selectedPage = pageNumber
    this.currentPage.emit(this.selectedPage)
  }

  nextPage() {
     
    if (this.selectedPage < this.maxPageSize - 1) {
      this.selectedPage = this.selectedPage + 1
      this.maxPageSize > this.selectedPage && this.selectedPage > 5 && this.paginationArr[this.paginationArr.length - 1] < this.maxPageSize
        ? this.paginationArr = this.paginationArr.map((index: number) => index + 1)
        : ''
      this.currentPage.emit(this.selectedPage)
    }
  }

  previousPage() {
    if (this.selectedPage > 0) {
      this.selectedPage -= 1;
      const currentPageIndex = this.paginationArr.findIndex((num) => num === this.selectedPage + 1);
      if (currentPageIndex === -1) {
        this.paginationArr.push(this.selectedPage + 1);
        this.paginationArr.sort((a, b) => a - b);
      }
      if (this.selectedPage < this.maxPageSize - 5 && this.paginationArr[0] > 1) {
      }
      if (this.paginationArr.length > 7 && this.paginationArr[this.paginationArr.length] !== this.maxPageSize && this.paginationArr[this.paginationArr.length - 1] !== (this.selectedPage + 1)) {
        this.paginationArr.pop();
      }
      this.paginationArr = Array.from(new Set(this.paginationArr));
      this.paginationArr = this.paginationArr.filter(num => num <= this.maxPageSize);
      this.currentPage.emit(this.selectedPage);
    }
  }

  forward() {
    if (this.selectedPage + 1 < this.maxPageSize) {
      this.selectedPage = this.selectedPage + 10
      if (this.paginationArr[this.paginationArr.length - 1] + 10 < this.maxPageSize) {
        this.maxPageSize > this.selectedPage && this.selectedPage > 5
          ? this.paginationArr = this.paginationArr.map((index: number) => index + 10) : ''
      } else {
        this.paginationArr = [this.maxPageSize - 5, this.maxPageSize - 4, this.maxPageSize - 3, this.maxPageSize - 2, this.maxPageSize - 1, this.maxPageSize]
        this.paginationArr = this.paginationArr.filter(value => value > 0);
      }
      this.selectedPage = this.maxPageSize - 1;
      this.currentPage.emit(this.selectedPage)
    }
  }

  backward() {
    if (this.selectedPage - 10 > -1) {
      this.selectedPage = this.selectedPage - 10
      if (this.selectedPage < 6) {
        if (this.selectedPage === 0) {
          this.paginationArr = [1, 2, 3, 4, 5, 6]

        }
        else {
          this.paginationArr = [1, 2, 3, 4, 5, 6]
        }
      } else {
        this.paginationArr = [1, 2, 3, 4, 5, 6]
      }
    } else {
      if (this.paginationArr[this.paginationArr.length - 1] > 6) {
        this.selectedPage = 0
        this.paginationArr = [1, 2, 3, 4, 5, 6]
      } else {
        this.selectedPage = 0

      }

    }
    this.selectedPage = 0;
    this.currentPage.emit(this.selectedPage)
  }

  lastPage(lastPageNum: number) {
    this.selectedPage = lastPageNum
    this.currentPage.emit(this.selectedPage)
  }

}
