import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { data } from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-pipelinedataextract',
  templateUrl: './pipelinedataextract.component.html',
  styleUrls: ['./pipelinedataextract.component.scss']
})
export class PipeLineDataExtractComponent implements OnInit {
  keywordsearch = "displayname";
  isLoadingroutes: boolean = false;
  RouteNameList: any = [];
  notfounddata: boolean;
  reactiveFormroute: FormGroup;
  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";
  selectedrouteUniqueID: Number = 0;
  fromdate: any;
  todate: any;
  direction :string = "";

  constructor(public service: CommonService,
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService) {
    this.reactiveFormroute = _fb.group({ routename: '' });
   }

  ngOnInit() {
    this.fromdate = this.datePipe.transform(this.getSeventhDay(new Date()), 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetRouteListByIDS?ids=" + val;
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname} ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if(data.length==0){
            this.notfounddata=true;
          }
          else{
            this.notfounddata=false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata=true;
        }
      });
    }
  }

  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
    this.selectedrouteUniqueID = item.UniqueID;
    this.direction = item.direction;
  }

  getstringRoute (value){
    let names=value.split("(")
    value = `${names[0]} <br>(${names[1]}`
  return value
  }

  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.selectedrouteUniqueID = 0;
    this.notfounddata=false;
    this.direction ='';
  }

  getSeventhDay(date = new Date()) {
    const seventhday = new Date(date.getTime());
    seventhday.setDate(date.getDate() - 6);
    return seventhday;
  }

  async exportreport(){
    let datacons ={
      "id":this.selectedrouteId,
      "fromDate": this.fromdate+" 00:00:00",
      "toDate": this.todate+" 23:59:59",
      "direction" : this.direction
    };
    var apiname = 'api/Routes/ExportPipelineFileData';
    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi(apiname, datacons);
    if(response == null){
      this.ngxUiLoaderService.stop();
        this.toastr.error("Given input data got the empty response", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
    }
    else
    {
      const fileName = 'DataExtract_'+this.todate+'.xlsx';
      this.exportToExcel(response, fileName);
      this.ngxUiLoaderService.stop();
      this.toastr.success("File downloaded successfully", "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    });
    }
    this.ngxUiLoaderService.stop();
  }

  exportToExcel(items: any[], fileName: string): void {
    
    let finalArrya=[];let selectedItems=[]; 
    for(let [key,value] of Object.entries(items)){
      let obj={
        messageid:key.split(":")[1],
        dataValue:value
      }
      finalArrya.push(obj)
    }
    finalArrya.forEach(element=>{
      let messageid = element.messageid;
      if(element.messageid == messageid){
        let messageobj ={
          "messageid" : element.messageid
        }
        selectedItems.push(messageobj);
        if(element.dataValue !== null){
          element.dataValue.forEach(item => {
            let keyvalue ={
               "key" : item.key,
               "value" : item.value
              }
              selectedItems.push(keyvalue);
            });
          }
        }
      });
    const finalResult = selectedItems.map(item => {
      return {
        "MessageId" :item.messageid,
        "Key" : item.key,
        "Value" : item.value
      };
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(finalResult);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    //saveAs(blob, fileName);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

  }
}
