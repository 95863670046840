import { Router } from '@angular/router';
import { CommonService } from './../services/common.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { W700servicesService } from '../services/w700services.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import * as FileSaver from 'file-saver';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { COMMA, ENTER, V } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, startWith } from 'rxjs/operators';
declare var $
@Component({
  selector: "app-compendium",
  templateUrl: "./compendium.component.html",
  styleUrls: ["./compendium.component.scss"],
})
export class CompendiumComponent implements OnInit {

  public reactiveAccount: FormGroup;
  userActivity;
  userInactive: Subject<any> = new Subject();
  Accounts = <any>[];
  CaseTypes = [];
  keywordsearch_Account = "Account";
  keywordsearch_CaseType = "CaseType";
  OrganizationID: number = 0;
  AccountID: number = 0;
  CaseType: string = "";
  dropdownList = [];
  selectedItems = [];
  dropdownSettingsforcasetype = {};
  dropdownSettingsforaoe = {};
  OrderCode: string = "";
  selectedCaseType = [];
  selectedAoeType: any[] = [];
  OptionalAOE: boolean = true;

  ctrlAccounts = new FormControl();
  AOE = "";
  ctrlOrganization: string = "";
  usernamedisplay: any;
  userrole: any;
  aoelist: any[] = [
    "Jars",
    "Services",
    "Collection Method",
    "Sites",
    "Clinical History",
    "Bill To",
    "Rule Outs",
  ];
  pagerolepermisions: any;
  isLoadingResult: boolean = false;
  isLoadingResultAccount: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  caseTypeCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ["Lemon"];
  allFruits: string[] = ["Apple", "Lemon", "Lime", "Orange", "Strawberry"];

  @ViewChild("caseInput", { static: false })
  caseInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;
  compendiumaoe: string;
  filteredCasetypes: Observable<any[]>;
  public reactiveFormentity: FormGroup;
  EntityNameList: any[] = [];
  keywordsearcha = "LocationName";
  isLoadingentity: boolean;
  notfounddata:boolean;
  selectedEntitydeploymentkey:string;
  selectedEntitytype:string;
  selectedEntityName:string;
  selectedEntityguid:string;
  constructor(private ngxUiLoaderService: NgxUiLoaderService, public service: CommonService, public router: Router, private toastr: ToastrService,
    private _fb: FormBuilder,) {
    this.reactiveAccount = this._fb.group({ accname: '' });
    this.reactiveFormentity = _fb.group({ entityname: '' });
  }

  async ngOnInit() {
    this.loadPrerequisites();
    this.filtercasetypeMethod();
  }

  filtercasetypeMethod() {
    this.filteredCasetypes = this.caseTypeCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.CaseTypes.slice()
      )
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CaseTypes.filter((fruit) =>
      fruit.CaseType.toLowerCase().includes(filterValue)
    );
  }

  loadPrerequisites() {
    this.dropdownSettingsforcasetype = {
      singleSelection: false,
      idField: "CaseType",
      textField: "CaseTypeDisplayName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };

    this.dropdownSettingsforaoe = {
      singleSelection: false,
      idField: "AoeDetails",
      textField: "AoeName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
  }

  onCaseTypeSelect(item: any) {
    this.selectedCaseType.push(item.CaseType);
  }
  onCaseTypeSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.selectedCaseType.push(items[i].CaseType);
    }
  }
  onaoeSelect(item: any) {
    this.selectedAoeType.push(item.AoeDetails);
  }
  onaoeSelectAll(items: any) {
    for (var i = 0; i < items.length; i++) {
      this.selectedAoeType.push(items[i].AoeDetails);
    }
  }

  onCaseTypeDeSelect(item: any) {
    this.selectedCaseType = this.selectedCaseType.filter((x) => x != item);
  }
  onaoeDeSelect(item: any) {
    this.selectedAoeType = this.selectedAoeType.filter((x) => x != item);
  }
  onCaseTypeDeSelectAll(items: any) {
    this.selectedCaseType = [];
  }
  onaoeDeSelectAll(items: any) {
    this.selectedAoeType = [];
  }


  async getCaseTypes() {
    var urlterm = "api/Compendium/GetCaseTypes?LabOrgID=" + this.OrganizationID + "&LabAccountID=" + this.AccountID + "&deploymentkey=" + this.selectedEntitydeploymentkey.toLowerCase();
    var data = await this.service.getapi(urlterm);
    if (data['status'] && data['statusText']) {
      return '';
    } else {
      if (data) {
        let obj = {
          CaseType: "All",
          OrderCode: "All",
          CaseTypeDisplayName: "All"
        }
        data.length > 0 ? data.unshift(obj) : data;
        this.CaseTypes = data as any[];
        this.filtercasetypeMethod()
      }
    }
  }



  searchAccountsCleared(input: any) {
    this.CaseTypes = [];
  }


  GetOptionalAOE(input: any) {
    this.OptionalAOE = input.value;
  }

  async GetCompendium() {
    let caseTypesValue = "";
    if (this.selectedCaseType.length > 0 && this.selectedEntityguid!='') {
      let exists = this.selectedCaseType.filter((va) => va === "All");
      if (exists.length > 0 && this.CaseTypes.length > 0) {
        for (let i = 0; i < this.CaseTypes.length; i++) {
          if (this.CaseTypes[i].CaseType !== 'All' && i > 0 && i < this.CaseTypes.length - 1) {
            caseTypesValue += this.CaseTypes[i].CaseType + ",";
          } else if (this.CaseTypes[i].CaseType !== 'All') {
            caseTypesValue += this.CaseTypes[i].CaseType;
          }
        }
      } else {
        caseTypesValue = this.selectedCaseType.join(",");
      }
      var urlterm =
      "api/Compendium/GetCompendium?AccountGUID=" +
      this.selectedEntityguid +
      "&CaseType=" +
      caseTypesValue +
      "&OptionalAOE=" +
      this.OptionalAOE +
      "&deploymentkey=" +
      this.selectedEntitydeploymentkey.toLowerCase()
      +
      "&EntityType=" +
      this.selectedEntitytype;
    this.ngxUiLoaderService.start();

    var response = await this.service.getapi(urlterm);
    if (response['status'] && response['statusText']) {
      return '';
    } else {
      const source = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+response["FileContents"];
      const link = document.createElement("a");
      link.href = source;
      link.download = "Compendium_" + Date.now().toString() + ".xls";
      link.click();
      this.ngxUiLoaderService.stop();
      this.toastr.success("Compedium downloaded successfully", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
    }
    

  }

  OnAOEChange(input: any) {
    this.AOE = input.target.value;
  }
  openCompendium() {
    this.clear();
    $("#exampleModal").appendTo("body").modal("show", {
      backdrop: "static",
      keyboard: false,
    });
  }
  clear() {
    this.selectedEntitydeploymentkey = "";
    this.CaseTypes = [];
    this.selectedCaseType = [];
    this.compendiumaoe = "";
    this.caseTypeCtrl.setValue("");
    this.Accounts = [];
    this.reactiveAccount.reset();
  }

  remove(casetype: string): void {
    const index = this.selectedCaseType.indexOf(casetype);
    if (index >= 0) {
      this.selectedCaseType.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.viewValue == "All") {
      this.selectedCaseType = [];
    }
    if (this.selectedCaseType.length > 0) {
      let exists = this.selectedCaseType.filter((va) => va === "All");
      if (exists.length > 0) {
        this.toastr.warning(
          exists[0] +
          " " +
          "option includes every casetype for the lab account",
          "",
          {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          }
        );
      } else {
        let exist = this.selectedCaseType.filter(
          (va) => va === event.option.viewValue
        );
        if (exist.length > 0) {
          this.toastr.warning(exist[0] + " " + "Already Exist", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        } else {
          this.selectedCaseType.push(event.option.viewValue);
        }
      }
    } else {
      this.selectedCaseType.push(event.option.viewValue);
    }
    this.caseInput.nativeElement.value = "";
    this.caseTypeCtrl.setValue("");
    let ele = document.getElementById("CaseType");
    ele.innerText = "";
    ele.focus();
    ele.blur();
  }
  selectedEntity(item) {
    this.selectedEntityName = item.LocationName;
    this.selectedEntitytype = item.TypeName;
    this.selectedEntityguid = item.LocationGUID;
    this.selectedEntitydeploymentkey =item.deploymentkey;
    this.AccountID=item.entityid;
    this.OrganizationID=item.organizationid
    this.getCaseTypes();
  }
  
  searchEntityCleared() {
    this.isLoadingentity = false;
    this.EntityNameList = [];
    this.selectedEntityguid = '';
    this.notfounddata = false;
    this.selectedCaseType=[];
    this.CaseTypes=[];
    this.caseTypeCtrl.setValue("");
  }
  async getEntityNames(event: any) {
    this.selectedEntityguid = '';
    this.reactiveFormentity.patchValue({
      entityname: event
    })
    if (event != '' && !event.endsWith(":") && !event.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = event.replaceAll(": ", ":");
      this.isLoadingentity = true;
      let obj = {
        lSearch: valueToSendToApiWithOutWhiteSpace
      }
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.EntityNameList = [];
          this.isLoadingentity = false;
          this.notfounddata = true;
          this.toastr.error("Unable to fetch Entity Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.EntityNameList = response.filter((V)=>
              V.TypeName.toLowerCase() === 'account' || V.TypeName.toLowerCase() === 'organization'
            );
            this.EntityNameList = this.service.transformAlphabetically(this.EntityNameList, 'LocationName');
            this.EntityNameList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }

          if (response.length == 0) {
            this.EntityNameList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedEntityguid = '';
          this.isLoadingentity = false;
        }
      })
    }
    else {
      this.EntityNameList = [];
      this.notfounddata = true;
    }
  }
  buildtitle(Obj: any) {
    if (typeof (Obj) == "object" && Obj != null) {
      let name = `(${Obj.Type}):${Obj.LocationName}
      ${Obj.Address1}
      `
      return name;
    }
  }
  entityCustomFilter = function (EntityNameList: any[], query: string): any[] {
    return EntityNameList;
  };
  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = names[2] !== 'null)' && names[2] !== undefined ? `${names[0]} <br> (${names[1]} <br> (${names[2]}` : `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }
}

