import { Component, OnInit, Input, TemplateRef, ElementRef, ViewChild, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interfaceLabAdminServices } from '../../services/InterfaceLabAdmin.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FileViewExplorerComponent } from '../../file-view-explorer/file-view-explorer.component';
import { VarulestooltipComponent } from '../varulestooltip/varulestooltip.component';
import { DatePipe } from '@angular/common';
import { startWith, map } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
declare const copyToClipboard: any;
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-messagelist',
  templateUrl: './messagelist.component.html',
  styleUrls: ['./messagelist.component.scss']
})
export class MessagelistComponent implements OnInit {

  @Input() inputDataInModalComponent: any;

  routelist = [];
  pageSize: number = 5;
  failuremessagedata = [];
  statustypelist: any = ['All', 'Interface', 'Ordering Facility', 'Laboratory', 'Requisition Number', 'Accession Number', 'EMR Number'];
  selected: string = 'All';
  searchtextData: string;
  pagenumber: number = 1;
  LocationCode: any;
  labguid: String = "";

  isAllSelected: any;
  selecteditemscount: any;
  backupfileArray: any[] = [];
  AllMatBoxChecked: boolean = false;
  Reprocessdata: any[] = [];
  ReprocessMessageids = [];
  filename: String = "";
  DateFilterForm: FormGroup;
  today: any = new Date();
  fromday: any = new Date();
  fromDate: any;
  toDate: any;
  selectedData: boolean = true;
  serachFiledKey: any;
  selectedFailureReason: string | null = null;
  filteredfailuremessagedatabackup: any[];
  searchOptionsBackuplist: any;


  InterfaceDetailsShow: boolean = false;
  nodataflag: boolean = false;
  isAllSelectedmatcheckbox: boolean = false;
  alertflag: boolean = false;
  servicesso: boolean = false;
  messagestag: string = ' Messages';
  laboratory: string;
  constructor(private activeModal: NgbActiveModal, private interfacelabadminservice: interfaceLabAdminServices,
    private cmmservice: CommonService,
    private fb: FormBuilder, private ngxUiLoaderService: NgxUiLoaderService, public dialog: MatDialog,
    private toastr: ToastrService, private modalService: NgbModal, private _fb: FormBuilder,
  ) { }

  toppings = new FormControl();
  showForm: boolean;
  submitted: boolean;
  searchForm: FormGroup;
  fromDateControl: FormControl;
  toDateControl: FormControl;
  selectedpagesizedisplay: string = "10";
  selectedpagesizedisplayControl = new FormControl(this.selectedpagesizedisplay);
  LabadminLoggedMetaData: any = {};
  searchOptions: Filter[] = [];
  emrnoserachdata = [];
  accessionnumberserachData = [];
  requisitionnumberSerachData = [];
  OrderingFacilitySearchData = [];
  LaboratorySearchData = [];
  interfaceSerachData = [];
  failedmessageflag: boolean = false;
  messagetag: string = "";
  todaysinprogresscount: number = 0;
  todayssuccesscount: number = 0;
  todaysfailurecount: number = 0;
  yesterdaysinprogresscount: number = 0;
  yesterdayssuccesscount: number = 0;
  yesterdaysfailurecount: number = 0;
  routeid: string = '';
  loaderfalg: boolean = true;


  totalcount:number=0;

  ngOnInit() {
    this.cmmservice.servicessostatus.subscribe((data) => {
      if (data) {
        this.servicesso = true;
      } else {
        this.servicesso = false;
      }
    });
    this.DateFilterForm = this._fb.group({
      fromDate: this.fromday,
      toDate: this.today,
      fromtime: '00:00:00',
      totime: this.formatTime(new Date())
    });

    this.searchOptions = [
      { name: 'Interface:' },
      { name: 'Requisition Number:' },
      { name: 'Accession Number:' },
      { name: 'EMR Number:' },
    ];
    this.searchOptionsBackuplist = JSON.parse(JSON.stringify(this.searchOptions));

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );

    this.LabadminLoggedMetaData = JSON.parse(sessionStorage.getItem('LabadminLoggedMetaData'));

    this.routelist = this.inputDataInModalComponent;
    this.toppings.patchValue('');
    this.searchForm = this.fb.group({
      fromDate: this.fromDateControl,
      toDate: this.toDateControl
    });
    if (this.inputDataInModalComponent.InterfaceDetailsShow) {
      this.todaysinprogresscount = this.inputDataInModalComponent.todaysinprogresscount;
      this.todayssuccesscount = this.inputDataInModalComponent.todayssuccesscount;
      this.todaysfailurecount = this.inputDataInModalComponent.todaysfailurecount;
      this.yesterdaysinprogresscount = this.inputDataInModalComponent.yesterdaysinprogresscount;
      this.yesterdayssuccesscount = this.inputDataInModalComponent.yesterdayssuccesscount;
      this.yesterdaysfailurecount = this.inputDataInModalComponent.yesterdaysfailurecount;
      this.routeid = this.inputDataInModalComponent.id;
      this.submitSearch(false);
      this.messagetag = " Failed ";
    }
    //Auto Refresh
    setInterval(() => {
      let obj = {
        orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
        deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
        messagetype: this.inputDataInModalComponent.messagetype,
        fromDate: this.fromDate,  // Concate the time
        toDate: this.toDate,
        labadminLoginInput: this.LabadminLoggedMetaData,
        dashboardcountflag: true,
        loaderfalg: false,
      }
      this.loaderfalg = false;
      this.dataEmitter.emit(obj);
      if (this.searchControl.value == '' || this.searchControl.value == undefined || this.searchControl.value == null) {
        this.GetFailedMessageList();
      }
      if (this.inputDataInModalComponent.InterfaceDetailsShow) {
        this.getInterfaceData();
      }
    }, this.LabadminLoggedMetaData.AutoRefreshTime != '' || this.LabadminLoggedMetaData.AutoRefreshTime != undefined || this.LabadminLoggedMetaData.AutoRefreshTime != null ? this.LabadminLoggedMetaData.AutoRefreshTime : 120000); //2 mins
    console.log('AutoRefreshTime for MessageList : ' + this.LabadminLoggedMetaData.AutoRefreshTime);
  }
  ngOnChanges() {
    this.cmmservice.servicessostatus.subscribe((data) => {
      if (data) {
        this.servicesso = true;
      } else {
        this.servicesso = false;
      }
    });
    this.LabadminLoggedMetaData = JSON.parse(sessionStorage.getItem('LabadminLoggedMetaData'));
    this.searchOptions = [
      { name: 'Interface:' },
      { name: 'Requisition Number:' },
      { name: 'Accession Number:' },
      { name: 'EMR Number:' },
    ];
    this.searchOptionsBackuplist = JSON.parse(JSON.stringify(this.searchOptions));
    if ((sessionStorage.getItem('messagelistfromdate') ?.toString() !== undefined &&
      sessionStorage.getItem('messagelistfromdate') ?.toString() !== '') &&
      (
        sessionStorage.getItem('messagelisttodate') ?.toString() !== undefined &&
          sessionStorage.getItem('messagelisttodate') ?.toString() !== ''
      )
    ) {
      this.fromDate = sessionStorage.getItem('messagelistfromdate');
      this.toDate = sessionStorage.getItem('messagelisttodate');
      sessionStorage.removeItem("messagelistfromdate");
      sessionStorage.removeItem("messagelisttodate");
      if (sessionStorage.getItem('datafrominterfacelist') == 'true') {
        this.DateFilterForm = this._fb.group({
          fromDate: this.fromDate,
          toDate: this.toDate,
          fromtime: ['00:00'],
          totime: this.formatTime(new Date())
        });
        sessionStorage.removeItem("datafrominterfacelist");


      }



      this.fromDate = new Date(this.fromDate);
      this.fromday = new Date(this.fromDate);
      this.today = new Date(this.toDate);
    }
    else {
      this.clearSearch();
      this.toDate = this.today;
      this.DateFilterForm = this._fb.group({
        fromDate: this.toDate,
        toDate: this.toDate,
        fromtime: '00:00:00',
        totime: this.formatTime(new Date())
      });
    }
    this.nodataflag = false;
    this.submitSearch(false);
    if (this.inputDataInModalComponent.messagestatus !== undefined && this.inputDataInModalComponent.messagestatus == 11 && this.inputDataInModalComponent !== undefined) {
      this.failedmessageflag = true;
      this.messagetag = " Success ";
    }
    if (this.inputDataInModalComponent.messagestatus !== undefined && this.inputDataInModalComponent.messagestatus == 12 && this.inputDataInModalComponent !== undefined) {
      this.failedmessageflag = false;
      this.messagetag = " Failed ";
    }
    if (this.inputDataInModalComponent.messagestatus !== undefined && this.inputDataInModalComponent.messagestatus == 13 && this.inputDataInModalComponent !== undefined) {
      this.failedmessageflag = true;
      this.messagetag = " Resolved ";
    }
    if (this.inputDataInModalComponent.messagestatus !== undefined && this.inputDataInModalComponent.messagestatus == 10 && this.inputDataInModalComponent !== undefined) {
      this.failedmessageflag = true;
      this.messagetag = " In-progress ";
    }
    else if (this.inputDataInModalComponent.messagestatus !== undefined && this.inputDataInModalComponent.messagestatus == '') {
      this.failedmessageflag = false;
      this.messagetag = " Failed ";
    }


    this.accesssionnumber='';
    this.requsitionnumber='';
    this.EmrNumber='';
    this.interfacename='';
    this.searchallvalue='';
    this.orderingfacility='';
    this.searchTerm=``;
    this.selectedCategory="All";

  }


  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  submitSearch(emittype): void {
    this.nodataflag = false;
    this.searchControl.setValue(null);
    this.searchControl.setValue('');
    this.searchControl.reset();
    if (this.tomailInput !== undefined) {
      this.tomailInput.nativeElement.value = '';
    }
    this.Filtervalue = [];
    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
    this.searchOptions = JSON.parse(JSON.stringify(this.searchOptionsBackuplist));
    let fromdate = this.interfacelabadminservice.formatDate(this.DateFilterForm.value.fromDate);
    fromdate = fromdate + ' ' + this.DateFilterForm.value.fromtime + ':00';
    this.fromDate = fromdate;
    let todate = this.interfacelabadminservice.formatDate(this.DateFilterForm.value.toDate);
    todate = todate + ' ' + this.DateFilterForm.value.totime + ':59';
    this.toDate = todate;
    // const formValues = this.searchForm.value;
    let obj = {
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      messagetype: this.inputDataInModalComponent.messagetype,
      fromDate: this.fromDate,  // Concate the time
      toDate: this.toDate,
      labadminLoginInput: this.LabadminLoggedMetaData,
      dashboardcountflag: true
    }
    var d1 = moment(this.fromDate);
    var d2 = moment(this.toDate);
    var diffDays = Math.abs(d1.diff(d2, 'days'));
    if (diffDays >= 7) {
      this.toastr.warning("Date range should not exceed 7 days", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    else {
      this.failuremessagedata = [];
      this.filteredfailuremessagedata = [];
      if (emittype) {
        this.dataEmitter.emit(obj);
      }
      this.GetFailedMessageList();
    }
    if (this.inputDataInModalComponent.InterfaceDetailsShow) {
      this.getInterfaceData();
    }
  }

  GetFailedMessageList() {


    let dataitem = this.LabadminLoggedMetaData.OrganizationData;


    let jsonbody = {
      routeid: this.inputDataInModalComponent.id == undefined ? '' : this.inputDataInModalComponent.id,
      pagenumber: this.pagenumber,
      pagesize: this.pageSize,
      fromdate: this.fromDate,
      todate: this.toDate,
      orgguid: dataitem.OrganizationGUID,
      deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      messagetype: this.inputDataInModalComponent.messagetype,
      LocationGuidsIncluded: true,
      LocationGuids: this.LabadminLoggedMetaData.EntityGuid,
      direction: this.inputDataInModalComponent.direction,
      messagestatus: this.inputDataInModalComponent.messagestatus == undefined ? 0 : this.inputDataInModalComponent.messagestatus,
      accesssionnumber:this.accesssionnumber,
      requsitionnumber:this.requsitionnumber,
      EmrNumber:this.EmrNumber,
     orderingfacility:this.orderingfacility,
      interfacename:this.interfacename,
      searchallvalue:this.searchallvalue,
      laboratory:this.laboratory,
    }
    if (this.loaderfalg) {
      this.ngxUiLoaderService.start();
    }
    this.interfacelabadminservice.GetFailedMessages(jsonbody).subscribe((data: any) => {
      if (data.length > 0) {
        this.nodataflag = true;
        this.failuremessagedata = data;
        this.totalcount=this.failuremessagedata[0]["totalcount"];
        this.selectedMsgReasonCard("System Failure");
      }
      else {
        this.nodataflag = true;
        this.failuremessagedata = [];
      }
      if (this.loaderfalg) {
        this.ngxUiLoaderService.stop();

      }
    },
      (error) => {
        this.ngxUiLoaderService.stop();
      });
    this.loaderfalg = true;
  }

  toggleForm() {
    this.showForm = !this.showForm;
    this.submitted = false;
  }

  closetpopup() {
    this.activeModal.close();
  }
  getPageDetails(event) {
    if (event == 0) {
      this.pagenumber = 1;
    } else {
      this.pagenumber = event + 1;
    }
    //this.isSelectChecked = false;
    this.SelectAllMatChekbox(false);
  this.GetFailedMessageList();
  }
  openReprocessDialog(alertmodal, dataitem) {

    //if (this.Reprocessdata.filter(x => x.messageid == dataitem.messageid).length > 0) {

    this.Reprocessdata = this.filteredfailuremessagedata
      .filter(x => x.messageid == dataitem.messageid);

    if (this.Reprocessdata.length == 1) {
      const modalRef = this.modalService.open(alertmodal,
        {
          windowClass: "alertpopup", backdrop: 'static',
          keyboard: false
        })
      modalRef.result.then((result) => {
        if (result === "close modal") {
          modalRef.close();
        }
      });
    }

  }

  onCancel(reprocess) {
    reprocess('close modal');
    this.LocationCode = '';
  }
  openLocationCodeDialog(templateRef) {

    this.modalService.open(templateRef,
      {
        windowClass: "alertpopup", backdrop: 'static',
        keyboard: false
      }).result.then((result) => {

      });


  }
  SaveLocationCode(c: any): void {
    let obj = {
      "locationcode": this.LocationCode,
      "deploymentkey": this.LabadminLoggedMetaData.deploymentkey,
      "interfacedetails": this.routelist
    }
    this.ngxUiLoaderService.start();
    this.interfacelabadminservice.UpdateLocationCode(obj).subscribe((data: any) => {
      if (data['status'] == "success") {
        this.LocationCode = "";
        this.ngxUiLoaderService.stop();
        this.toastr.success("Location code updated Successfully", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
      else {
        this.LocationCode = "";
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failed to update the location code", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    },
      error => {
        this.LocationCode = "";
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failed to update the location code", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      },
    );

  }



  selectedItems: string[] = [];
  onCheckboxChange(item: any, event: any) {

    this.filteredfailuremessagedata.forEach(ele => {
      if (ele.messageid == item.messageid) {
        ele.checked = event.checked;
        ele.selectedAll = event.checked;
      }
    });

    this.Reprocessdata = this.filteredfailuremessagedata
      .filter(x => x.checked == true);

    if (this.Reprocessdata.length > 0) {
      this.isSelectChecked = true;
    }
    else {
      this.isSelectChecked = false;
    }
    if (this.Reprocessdata.length == 1) {
      this.Reprocessdata.forEach(eler => {
        this.filteredfailuremessagedata.forEach(ele => {
          if (ele.messageid == eler.messageid) {
            ele.selectedAll = ele.checked;
          }
        });
      });
      this.alertflag = true;
    }
    else if (this.Reprocessdata.length > 1) {
      this.filteredfailuremessagedata.forEach(ele => {
        ele.selectedAll = !event.checked;
      });
      this.alertflag = false;
    }

  }


  sendSelectedItems() {
    this.ngxUiLoaderService.start();
    this.interfacelabadminservice.FailedMessageReprocess(this.selectedItems).subscribe(data => {
      if (data['status'] == "success") {
        this.ngxUiLoaderService.stop();
        this.dialog.closeAll();
        this.GetFailedMessageList();
        this.toastr.success("Reprocess initiated successfully ", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });

      }
      else {
        this.ngxUiLoaderService.stop();
        this.dialog.closeAll();
        this.GetFailedMessageList();
      }
    },
      error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failed to reprocess", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  ViewHl7Content(Mesagesdata: any) {
    this.filename = Mesagesdata.filename;
    let jsondata = {
      messagetype: Mesagesdata.messagetype,
      fileid: Mesagesdata.fileid,
      contextId: Mesagesdata.id,
      createdBy: this.LabadminLoggedMetaData.UserEmail
    };
    this.ngxUiLoaderService.start();
    this.interfacelabadminservice.GetFileContentByFileID(jsondata).subscribe((response: any) => {
      if (response.length > 0) {
        this.backupfileArray = response;
        this.backupfileArray[0]["filecontent"] = this.backupfileArray[0]["filecontent"].replaceAll("\r", "[tempData]")
        this.backupfileArray[0]["filecontent"] = this.backupfileArray[0]["filecontent"].replaceAll("\n", "[tempData1]")
        if (this.backupfileArray[0]["filecontent"].includes("[tempData1]")) {
          this.backupfileArray[0]["filecontent"] = this.backupfileArray[0]["filecontent"].replaceAll("[tempData1]", "");
        }
        this.backupfileArray[0]["filecontent"] = this.backupfileArray[0]["filecontent"].replaceAll("[tempData]", "\n");
        if ((!this.backupfileArray[0]["filecontent"].includes("[tempData]"))) {
          this.backupfileArray[0]["filecontent"] = this.backupfileArray[0]["filecontent"].replaceAll("[tempData1]", "\n");
          this.backupfileArray[0]["filecontent"] = this.backupfileArray[0]["filecontent"]
        }
        // this.backupfileArray[0]["filecontent"]=this.backupfileArray[0]["filecontent"].replaceAll("\r","\n");
        this.viewFile(this.backupfileArray)
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    }), error => {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Failed to get filecontent", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    };
  }

  viewFile(fileinfo) {
    let namemodal = {
      name: this.backupfileArray[0].filename !== "" ? this.backupfileArray[0].filename : this.filename,
      fullfile: this.backupfileArray[0].filecontent,
      type: this.backupfileArray[0].fileformat,
    };
    const modalRef = this.modalService.open(FileViewExplorerComponent, {
      windowClass: "myCustomModalViewfileLabAdminMessage",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.classname = 'monacoeditorviewcsslabadmin';
    modalRef.componentInstance.fileData = namemodal;
    modalRef.componentInstance.fileType = this.backupfileArray[0].fileformat;
    modalRef.result.then(() => { });
  }

  isSelectChecked = false;
  SelectAllMatChekbox(eventchecked) {

    this.Reprocessdata = [];
    this.ReprocessMessageids = [];
    if (eventchecked) {
      this.isAllSelectedmatcheckbox = true;
      this.Reprocessdata = this.filteredfailuremessagedata
        .slice(((this.pagenumber - 1) * Number(this.selectedpagesizedisplay)),
          Number(this.selectedpagesizedisplay) * this.pagenumber);

      this.Reprocessdata.forEach(item => {
        this.filteredfailuremessagedata.forEach(ele => {
          if (ele.messageid == item.messageid) {
            ele.checked = eventchecked;
          }
          ele.selectedAll = !eventchecked;
        });

      });
      this.alertflag = false;
    }
    else {
      this.isAllSelectedmatcheckbox = false;
      this.filteredfailuremessagedata.forEach(ele => {
        ele.checked = eventchecked;
        ele.selectedAll = eventchecked;
      });
      this.alertflag = true;
    }
  }
  selectEvent(event) {
    this.AllMatBoxChecked = false;
    this.pagenumber = 1;
    this.pageSize = event.value;
  }

  OpenDialogForReprocessAll(alertmodal) {

    if (this.Reprocessdata.length > 0) {
      const modalRef = this.modalService.open(alertmodal,
        {
          windowClass: "alertpopup", backdrop: 'static',
          keyboard: false
        })
      modalRef.result.then((result) => {
        if (result === "close modal") {
          modalRef.close();
        }
      });
    }
    else {
      this.toastr.warning("Please select at least one message for reprocessing", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }

  }

  SaveReprocessMessages(reprocess) {
    let obj = {
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      messagetype: this.inputDataInModalComponent.messagetype,
      fromDate: this.fromDate,  // Concate the time
      toDate: this.toDate,
      labadminLoginInput: this.LabadminLoggedMetaData
    }
    this.ngxUiLoaderService.start();
    this.Reprocessdata.forEach(ele => {
      this.ReprocessMessageids.push({
        messageid: ele.messageid,
        username: this.LabadminLoggedMetaData.UserEmail
      });
    });
    this.interfacelabadminservice.FailedMessageReprocess(this.ReprocessMessageids).subscribe(data => {
      if (data['status'] == "success") {
        reprocess('close modal');
        this.toastr.success("Reprocess initiated successfully ", "", {
          timeOut: 5000,
          positionClass: "toast-bottom-right",
        });
        if (!this.inputDataInModalComponent.InterfaceDetailsShow) {
          this.dataEmitter.emit(obj);
        }
        if (this.inputDataInModalComponent.InterfaceDetailsShow) {
          this.getInterfaceData();
        }
        this.GetFailedMessageList();
        this.Reprocessdata = [];
      }
      else {
        this.onCancel(reprocess);
        if (!this.inputDataInModalComponent.InterfaceDetailsShow) {
          this.dataEmitter.emit(obj);
        }
        if (this.inputDataInModalComponent.InterfaceDetailsShow) {
          this.getInterfaceData();
        }
        this.GetFailedMessageList();
        this.Reprocessdata = [];
      }
      this.searchControl.setValue(null);
      this.searchControl.setValue('');
      this.searchControl.reset();
      if (this.tomailInput !== undefined) {
        this.tomailInput.nativeElement.value = '';
      }
      this.Filtervalue = [];
      this.filteredOptions = this.searchControl.valueChanges
        .pipe(
          startWith(''),
          map(search => (search && typeof search === 'object') ? search.name : search),
          map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
        );
      this.searchOptions = JSON.parse(JSON.stringify(this.searchOptionsBackuplist));
    },
      error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Failed to reprocess", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        setTimeout(() => {
          this.onCancel(reprocess);
        }, 1000);
        this.Reprocessdata = [];
      }
    );
  }

  getvarules(rulename) {
    this.ngxUiLoaderService.start();

    var apiname = "api/VitalHubDXAPI/GetVaRuleForLabAdmin?RuleName=" + rulename;
    this.interfacelabadminservice.getapiurl(
      apiname,
      this.LabadminLoggedMetaData.Deploymentkey
    ).subscribe({
      next: data => {
        if (data.length > 0) {
          const modalRef = this.modalService.open(VarulestooltipComponent, {
            windowClass: "labadminvarulepopupcls",
            backdrop: "static",
            keyboard: false,
          });
          modalRef.componentInstance.inputDataInModalComponent = data
          modalRef.result.then((result) => { });
          this.ngxUiLoaderService.stop();
        }
        else {
          this.toastr.warning("No Varule for this Interface", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.ngxUiLoaderService.stop();
        }
      },
      error: error => {
        this.toastr.warning("Error occured while calling api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxUiLoaderService.stop();
      }
    });
  }

  filenames: string = '';
  ExportMessagelist() {
    if (this.inputDataInModalComponent.InterfaceDetailsShow) {
      this.filenames = "FWC_Failed_Summary.xlsx";
    }
    else {
      this.filenames = "FWC_Interface_" + this.messagetag + "_Orders_Results.xlsx";
    }
    const data = [];
    if (this.Reprocessdata.length>0) {
      this.Reprocessdata.forEach(element => {
        if (element.checked) {
          data.push({
            AccessionNumber: element.accessionnumber,
            RequistionNumber: element.requisitionnumber,
            CollectionDate: element.collectiondatetime,
            RecivedDate: element.receiveddatetime,
            SignedOutBy: element.signedoutby,
            MessageID: element.messageid,
            AccessionType: element.accessionedtype,
            FailureReason: element.outputdata,
            CaseType: element.casetype,
            ConnectivityType: element.connectivitytype,
            Reprocessed: element.reprocessed,
            SendOutLab: element.dynamiclabelvalue,
            MessageType: element.messagetype,
            CurrentStatus: element.status,
            FailedTime: element.modifieddate,
            SignedOutTime: element.signedouttime,
            LatestCaseVersionGenerated: element.lastcaseversiongenertaed,
            ServiceType: element.servicetype,

          })
        };
      });
    } else {
      this.failuremessagedata[0].messagelist.forEach(element => {
        data.push({
          AccessionNumber: element.accessionnumber,
          RequistionNumber: element.requisitionnumber,
          CollectionDate: element.collectiondatetime,
          RecivedDate: element.receiveddatetime,
          SignedOutBy: element.signedoutby,
          MessageID: element.messageid,
          AccessionType: element.accessionedtype,
          FailureReason: element.outputdata,
          CaseType: element.casetype,
          ConnectivityType: element.connectivitytype,
          Reprocessed: element.reprocessed,
          SendOutLab: element.dynamiclabelvalue,
          MessageType: element.messagetype,
          CurrentStatus: element.status,
          FailedTime: element.modifieddate,
          SignedOutTime: element.signedouttime,
          LatestCaseVersionGenerated: element.lastcaseversiongenertaed,
          ServiceType: element.servicetype,
        });
      });
    }
    if (data.length > 0) {
      this.exportToExcel(data, this.filenames);
    } else {
      this.toastr.warning("There is no data !", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  exportToExcel(items: any[], fileName: string): void {
    const selectedItems = items.map(item => {
      return {
        "AccessionNumber": item.AccessionNumber != '' ? item.AccessionNumber : "N/A",
        "RequisitionNumber": item.RequistionNumber != '' ? item.RequistionNumber : "N/A",
        "CollectionDate": item.CollectionDate != ' ' ? item.CollectionDate : "N/A",
        "ReceivedDate": item.RecivedDate != ' ' ? item.RecivedDate : "N/A",
        "SignedOutBy": item.SignedOutBy != '' &&item.SignedOutBy!=null ? item.SignedOutBy : "N/A",
        "MessageID": item.MessageID != '' ? item.MessageID : "N/A",
        "AccessionType": item.AccessionType != '' ? item.AccessionType : "N/A",
        "FailureReason": item.FailureReason != '' ? item.FailureReason : "N/A",
        "CaseType": item.CaseType != '' ? item.CaseType : "N/A",
        "ConnectivityType": item.ConnectivityType != '' ? item.ConnectivityType : "N/A",
        "Reprocessed": item.Reprocessed != '' ? item.Reprocessed : "N/A",
        "SendOutLab ": item.SendOutLab != '' ? item.SendOutLab : "N/A",
        "MessageType": item.MessageType != '' ? item.MessageType : "N/A",
        "CurrentStatus": item.CurrentStatus != '' && item.CurrentStatus!=null ? item.CurrentStatus : "N/A",
        "FailedTime": item.FailedTime != '' ? item.FailedTime : "N/A",
        "SignedOutTime": item.SignedOutTime != ''  && item.SignedOutTime!=null ? item.SignedOutTime : "N/A",
        "LatestCaseVersionGenerated": item.LatestCaseVersionGenerated != ''  && item.LatestCaseVersionGenerated!=null  ? item.LatestCaseVersionGenerated : "N/A",
        "ServiceType": item.ServiceType != '' && item.ServiceType!=null ? item.ServiceType : "N/A",
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

  }


  previousday() {
    let prdate = this.DateFilterForm.value.fromDate;
    const previousDate = new Date(prdate);
    // Subtract one day
    previousDate.setDate(prdate.getDate() - 1);
    this.DateFilterForm.patchValue({ fromDate: previousDate });
    this.DateFilterForm.patchValue({ fromtime: "00:00:00" });
    this.DateFilterForm.patchValue({ totime: "23:59" })
    this.DateFilterForm.patchValue({ toDate: previousDate });

  }
  todayfunction() {
    this.fromday = new Date();
    this.today = new Date();
    this.DateFilterForm = this._fb.group({
      fromDate: this.fromday,
      toDate: this.today,
      fromtime: ['00:00'],
      totime: this.formatTime(new Date())
    });
  }
  clearSearch() {
    this.todayfunction();
  }

  selectedreason: string = '';
  filteredfailuremessagedata: any[] = [];
  selectedMsgReasonCard(reason: any) {

    this.isSelectChecked = false;
    this.selectedreason = reason;
    this.selectedFailureReason = reason;
    this.filteredfailuremessagedata = this.failuremessagedata.filter(x => x.failurereason === reason).length > 0 ?
      this.failuremessagedata.filter(x => x.failurereason === reason)[0].messagelist : [];

    this.filteredfailuremessagedata.forEach(ele => {
      ele.checked = false;
      ele.selectedAll = false;
    });

    this.filteredfailuremessagedatabackup = JSON.parse(JSON.stringify(this.filteredfailuremessagedata));

    this.pageSize = Number(this.selectedpagesizedisplay);
    //this.getPageDetails(0);
  }


  Filtervalue: string[] = [];
  splitvalue: string[] = [];
  FormName: any;
  responseData: any;
  addOption: Filter;
  searchTermData: string[] = [];
  searchControl = new FormControl();


  removeSearchValue(value: any): void {
    if (value.includes(':')) {
      const TrimValue = value.trim();
      const index = this.Filtervalue.findIndex(term => term.trim().toLowerCase() === TrimValue.toLowerCase());
      if (index == 0 && this.Filtervalue.length == 0) {
        this.searchControl.setValue('');
      }
      if (index !== -1) {
        this.Filtervalue.splice(index, 1);
        this.searchControl.setValue(this.Filtervalue[index - 1]);
        if (this.searchControl.value == undefined) {
          this.searchControl.setValue(this.Filtervalue[0])
        }
        this.splitvalue = value.split(":")
        this.FormName = this.splitvalue[0].trim();
        if (TrimValue.includes("Requisition Number")) {
          this.requisitionnumberSerachData = [];
        }
        if (TrimValue.includes("Accession Number")) {
          this.accessionnumberserachData = [];
        }
        if (TrimValue.includes("Interface")) {
          this.interfaceSerachData = [];
        }
        if (TrimValue.includes("EMR Number")) {
          this.emrnoserachdata = [];
        }
        this.responseData = this.FormName + ':';
        this.addOption = { name: this.responseData };
        // Check if the option is already present in searchOptions
        const optionExists = this.searchOptions.some(option => option.name.toLowerCase() === this.addOption.name.toLowerCase());
        if (!optionExists) {
          this.searchOptions.push(this.addOption);
        }
      }
    }
    else {
      const index = this.searchTermData.indexOf(value);
      if (index !== -1) {
        this.searchTermData.splice(index, 1);
      }
    }
    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
  }
  @ViewChild('tomailInput', { static: false }) tomailInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  selectedoption(event: MatAutocompleteSelectedEvent): void {
    this.tomailInput.nativeElement.value = event.option.value.name;
  }
  filteredOptions: Observable<Filter[]> | undefined;

  filter(name: string): Filter[] {
    return this.searchOptions.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  removeOptionWhenButtonCreated(value: string) {
    this.searchOptions.forEach((option, index) => {
      const optionKey = option.name.split(':')[0].trim();
      const optionKeyTrimmed = optionKey.trim();
      if (optionKeyTrimmed === value) {
        this.searchOptions.splice(index, 1); // Remove matching option
      }
    });

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
  }

  addtoFilterForm(event: MatChipInputEvent): void {
    if (event.value.trim() !== '') {
      const value = event.value.trim();
      const parts = value.split(':');
      if (parts.length > 1 && parts[1].trim() !== '') {
        const [inputKey, inputValue] = value.split(':').map(part => part.trim());
        if (!this.Filtervalue.includes(value.trim())) {
          this.Filtervalue.push(value.trim());
        }
        if (this.tomailInput) {
          this.tomailInput.nativeElement.value = null; // Display selected value in input field
        }
        this.removeOptionWhenButtonCreated(inputKey);
      }
    }
  }

  SelectData(event) {
    if (event.value.includes('All')) {
      this.selectedData = true;
      //this.serachFiledKey=event.value
    }
    else {
      this.searchControl.setValue(null);
      this.searchControl.setValue('');
      this.searchControl.reset();
      if (this.tomailInput !== undefined) {
        this.tomailInput.nativeElement.value = '';
      }
      this.Filtervalue = [];
      this.filteredOptions = this.searchControl.valueChanges
        .pipe(
          startWith(''),
          map(search => (search && typeof search === 'object') ? search.name : search),
          map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
        );
      this.searchOptions = JSON.parse(JSON.stringify(this.searchOptionsBackuplist));
      this.selectedData = false;
      this.serachFiledKey = event.value
    }
  }

  isReasonSelected(reason: string): boolean {
    return this.selectedFailureReason === reason;
  }

  copyToClip(event: any, message) {
    if (event != '' && event !== 'N/A') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  async autoRefresh() {
    let fromdate = this.interfacelabadminservice.formatDate(this.DateFilterForm.value.fromDate);
    fromdate = fromdate + ' ' + this.DateFilterForm.value.fromtime + ':00';
    this.fromDate = fromdate;
    let todate = this.interfacelabadminservice.formatDate(this.DateFilterForm.value.toDate);
    todate = todate + ' ' + this.DateFilterForm.value.totime + ':59';
    this.toDate = todate;
    let obj = {
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      messagetype: this.inputDataInModalComponent.messagetype,
      fromDate: this.fromDate,  // Concate the time
      toDate: this.toDate,
      labadminLoginInput: this.LabadminLoggedMetaData,
      dashboardcountflag: true,
      loaderfalg: true,


    }
    this.dataEmitter.emit(obj);
    if (this.inputDataInModalComponent.InterfaceDetailsShow) {
      await this.GetFailedMessageList();
    }
    else if (this.searchControl.value == '' || this.searchControl.value == undefined || this.searchControl.value == null) {
      this.GetFailedMessageList();
    }
    if (this.inputDataInModalComponent.InterfaceDetailsShow) {
      this.getInterfaceData();
    }
  }

  getInterfaceData() {



    let obj = {
      pagenumber: this.pagenumber,
      messagetype: this.inputDataInModalComponent.messagetype,
      status: this.inputDataInModalComponent.status,
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      pagesize: 100, //this.selectedCount,
      directions: this.inputDataInModalComponent.direction,
      LocationGuidsIncluded: true,
      LocationGuids: this.LabadminLoggedMetaData.EntityGuid,
      id: this.routeid
    }
    this.interfacelabadminservice.GetSearchInterface(obj).subscribe((data: any) => {
      if (data.length > 0) {
        this.todaysinprogresscount = data[0]["todaysinprogresscount"];
        this.todayssuccesscount = data[0]["todayssuccesscount"];
        this.todaysfailurecount = data[0]["todaysfailurecount"];
        this.yesterdaysinprogresscount = data[0]["yesterdaysinprogresscount"];
        this.yesterdayssuccesscount = data[0]["yesterdayssuccesscount"];
        this.yesterdaysfailurecount = data[0]["yesterdaysfailurecount"];
      }
      else {

      }

    },
      (error) => {
        this.toastr.warning("Error occured while calling api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxUiLoaderService.stop();
      }
    );

  }

  @Output() dataEmitter = new EventEmitter<any>();




  categories: string[] = ['All', 'Interface', 'Ordering Facility', 'Laboratory', 'Requisition Number', 'Accession Number', 'EMR Number'];
  selectedCategory: string = 'All';
  searchTerm: string = '';
  accesssionnumber:string='';
  requsitionnumber:string='';
  EmrNumber:string='';
  interfacename:string='';
  searchallvalue:string='';
  orderingfacility:string='';

  clearSearchs() {
    console.log(`clearing the value of `+ this.searchTerm);
    this.searchTerm = '';
    this.searchallvalue=``;
    this.interfacename=``;
    this.accesssionnumber=``;
    this.requsitionnumber=``;
    this.EmrNumber=``;
    this.orderingfacility=``;
    this.laboratory=``;
    

    this.GetFailedMessageList();
  }

  performSearch() {
    this.pagenumber=1;
    this.filteredfailuremessagedata=[];
    console.log(`Searching for ${this.searchTerm} in ${this.selectedCategory}`);
    // Implement your search logic here
    if(this.searchTerm!= '')
    { 
      if(this.selectedCategory==`All`)
      {
        this.searchallvalue=this.searchTerm;
        this.interfacename=``;
        this.accesssionnumber=``;
        this.requsitionnumber=``;
        this.EmrNumber=``;
        this.orderingfacility=``;
        this.laboratory=``
      }
      if(this.selectedCategory==`Requisition Number`)
      {
        this.requsitionnumber=this.searchTerm;
        this.interfacename=``;
        this.accesssionnumber=``;
        this.searchallvalue=``;
        this.EmrNumber=``;
        this.orderingfacility=``;
        this.laboratory=``;
      }
      if(this.selectedCategory==`Accession Number`)
      {
        this.accesssionnumber=this.searchTerm;
        this.interfacename=``;
        this.searchallvalue=``;
        this.EmrNumber=``;
        this.requsitionnumber=``;
        this.orderingfacility=``;
      }
      if(this.selectedCategory==`EMR Number`)
      {
        this.EmrNumber=this.searchTerm;
        this.interfacename=``;
        this.searchallvalue=``;
        this.requsitionnumber=``;
        this.accesssionnumber=``;
        this.orderingfacility=``;
        this.laboratory=``
      }
      if(this.selectedCategory==`Interface`)
      {
        this.EmrNumber=``;
        this.interfacename=this.searchTerm;
        this.searchallvalue=``;
        this.requsitionnumber=``;
        this.accesssionnumber=``;
        this.orderingfacility=``;
      }
      if(this.selectedCategory==`Ordering Facility`)
      {
        this.EmrNumber=``;
        this.interfacename=``;
        this.searchallvalue=``;
        this.requsitionnumber=``;
        this.accesssionnumber=``;
        this.laboratory=``;
        this.orderingfacility=this.searchTerm;
      }

      if( this.selectedCategory==`Laboratory`)
      {
        this.EmrNumber=``;
        this.interfacename=``;
        this.searchallvalue=``;
        this.requsitionnumber=``;
        this.accesssionnumber=``;  
        this.orderingfacility=``;
        this.laboratory=this.searchTerm
      }
      this.nodataflag = false
  
      this.GetFailedMessageList();
    }
  }
  getCharconut() {
    if (this.searchTerm.length == 0) {
      this.searchTerm = '';
      this.searchallvalue = ``;
      this.interfacename = ``;
      this.accesssionnumber = ``;
      this.requsitionnumber = ``;
      this.EmrNumber = ``;
      this.orderingfacility=``;
      this.nodataflag = false;
      this.laboratory=``;
      this.GetFailedMessageList();
    }
  }

}

export class Filter {
  constructor(public name: string) { }
}
