import { ChangeDetectorRef, AfterContentChecked, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, take } from "rxjs/operators";
import { CommonService } from '../services/common.service';
import beautify from "xml-beautifier";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewauditComponent } from '../newaudit/newaudit.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  providers: [],
})
export class TemplateComponent implements OnInit {
  addtemplatemodalheight = window.innerHeight - 429;
  formatType: string = "text/plain";
  defaultFormatType:string='text/plain';
  screeHeightforoutput = window.innerHeight - 250;
  screeHeightforinput = window.innerHeight - 281;
  reactiveFormtemp: FormGroup;
  isLoadingtemp: boolean = false;
  keywordsearch = "name";
  oldtemplatexml:any;
  oldmappedxml:any;
  reactiveFormformatfilterreprocess: FormGroup = this._fb.group({
    formatfilterreprocess:''
  })
  ContType=[]
  keywordcont='lookupvalue'
  editorOptions = {
    theme: 'vs', language: 'text/plain', wordWrap: 'on',scrollBeyondLastLine: false
};

editorOptions1 = {theme: 'vs', language: 'xml',readOnly:true, wordWrap: 'on',scrollBeyondLastLine: false
};
  inputdata: string = "";
  OutputData: string = "";
  templatelist: any[];
  templatename: string;
  templatelistbackup: any[];
  notfounddata: boolean;
  updateflag: boolean;
  selectedtemplateid: any;
  title: string='';
  editorInit(editor: any) {
      if (this.inputdata) {
        if (this.inputdata.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.inputdata.startsWith("{")) {
          this.formatType = "json";
        }
        if(this.defaultFormatType!=this.formatType){
          this.defaultFormatType=this.formatType;
        this.editorOptions = {
          ...this.editorOptions,
          language: this.formatType
        };
      }
    }
      if (this.OutputData) {
        if (this.OutputData.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.OutputData.startsWith("{")) {
          this.formatType = "json";
        }
        if(this.defaultFormatType!=this.formatType){
          this.defaultFormatType=this.formatType;
        this.editorOptions1 = {
          ...this.editorOptions1,
          language: this.formatType
        };
      }
      }
  }

  vendorname: string = "";
  vendornameId: string = "";
  reactiveFormmsgtype: FormGroup;
  reactiveFormmsgtypeadd: FormGroup;
  reactiveFormvendor: FormGroup;
  constructor(private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService, private toastr: ToastrService, private _fb: FormBuilder,
    private modalService: NgbModal, private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef) {
    
    this.reactiveFormtemp = _fb.group({ tempname: '' });
    this.reactiveFormmsgtype = this._fb.group({ msgname: '' });
    this.reactiveFormmsgtypeadd = this._fb.group({ msgnameadd: '' });
    this.reactiveFormvendor = _fb.group({ vendorname: '' });
    if (this.vendorname) {
      this.reactiveFormvendor.patchValue({
        vendorname: this.vendorname
      })
    }
    this.reactiveForminterfacetype = this._fb.group({ fcinterfacetype: '' });
    this.reactiveFormtemplist = _fb.group({ templistname: '' });

    this.reactiveFormvendoradd = _fb.group({ vendornameadd: '' });
    if (this.vendornameadd) {
      this.reactiveFormvendoradd.patchValue({
        vendornameadd: this.vendornameadd
      })
    }
    this.reactiveFormformatfilterreprocess = this._fb.group({ formatfilterreprocess: '' });
  }

  registerMonacoCustomTheme() {
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs", // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: "comment",
          foreground: "ffa500",
          fontStyle: "italic underline"
        }, {
          token: "comment.js",
          foreground: "008800",
          fontStyle: "bold"
        }, {
          token: "comment.css",
          foreground: "0000ff"
        }, // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }

  async ngOnInit() {
    this.GetTemplateXML();
    this.updateflag = false;
    await this.getMessagetypelist('msgtype');
    this.reactiveFormmsgtype.patchValue(this.messageTypeAllList);
    this.getVendorname();
    this.service.getIntContType('interfacecontenttype').subscribe((data) => {
      if (data) {
        this.ContType = data
        // this.contLookupid = data.lookupid;
      }
    })
  }
  formatname:string=''
  async selectformat(item) {
    this.formatname = item.lookupvalue;
    this.formatfilterreprocess = item.name;
  }
  clearedformat() {
    this.formatname = '';
    this.formatfilterreprocess=''
  }
  formatXml(xml: any) {
    var formatted = "";
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, "$1\r\n$2$3");
    var pad = 0;
    xml.split("\r\n").map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = "";
      for (var i = 0; i < pad; i++) {
        padding += "  ";
      }

      formatted += padding + node + "\r\n";
      pad += indent;
    });
    this.OutputData = formatted;
    return this.OutputData;
  }
  async addTemplate(c) {
    var templateData;

    if (((this.updateflag && this.templatename != "") || this.reactiveFormtemp.get('tempname').value != "") && this.inputdata != "" && this.OutputData != ""
    && this.formatfilterreprocess!= "" && this.selectedmessagetypeadd != "" && this.selectedinterfacetypevalueadd != "" && this.vendornameaddId != "") {
      var inputobj = this.checkformat(this.inputdata, 'Template');
      var mappedobj;
      if (this.OutputData) {
        mappedobj = this.checkformat(this.OutputData, 'Mapped');
      }

      if (inputobj.condistionflag == true && mappedobj.condistionflag == true) {
        templateData = {
          id: this.updateflag ? this.selectedtemplateid : 0,
          name: this.reactiveFormtemp.get('tempname').value,
          templatexml: this.inputdata,
          IsActive:this.myFlagForSlideToggle,
          inputFormat: this.formatfilterreprocess,
          mappedFormat: mappedobj != "" ? mappedobj["format"] : "",
          mappedxml: this.OutputData,
          Type: "Input",
          IsDefault: 0,
          messagetype: this.selectedmessagetypeadd,
          direction: this.selectedinterfacetypevalueadd,
          vendorId: this.vendornameaddId,
          vendor : this.vendornameadd,
          userId:JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
        };
        this.ngxUiLoaderService.start();
        await this.service.postapi("api/GetMappings/SaveTemplate", templateData).then(responsesource => {
          if ((responsesource['status'] && responsesource['statusText']) || responsesource['statusText'] === "Unknown Error") {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
          }
          else if(responsesource.status=='Fail')
          {

            this.ngxUiLoaderService.stop();
            this.toastr.error(responsesource["statusmessage"], "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
          }
          else if (responsesource["statusmessage"] && responsesource["status"]) {
            this.GetTemplateXML();
            this.ngxUiLoaderService.stop();
            this.toastr.success(responsesource["statusmessage"], "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
            this.autoclosetemplate(c);
            this.searchtemplateslist();
          } else {
            this.ngxUiLoaderService.stop();
            this.toastr.error("Failed to create new template", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
          }
        });
      }

    } else {
      this.toastr.warning("Please enter the required fields", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
    }
  }
  checkformat(data, message) {
    let jsonflag = false;
    let condistionflag = false;
    let inputformat = "";
    let obj;
    try {
      var body = JSON.parse(data);
      jsonflag = true;
    } catch (error) {
      jsonflag = false;
    }

    if (data.startsWith("MSH")) {
      condistionflag = true;
    } else if (jsonflag) {
      condistionflag = true;
    } else {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(data, "application/xml");

      if (xmlDoc.getElementsByTagName("parsererror")[0] == undefined) {
        condistionflag = true;
      } else {
        condistionflag = false;

        this.toastr.warning("Invalid " + message + " Xml", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      }
    }
    if (condistionflag) {

      if (data.startsWith("MSH")) {
        inputformat = "hl7";
      } else if (jsonflag) {
        inputformat = "json";
      } else {
        inputformat = "xml";
      }
    }
    obj = {
      condistionflag: condistionflag,
      format: inputformat
    }
    return obj;
  }
  cleartemplate() {
    this.OutputData = "";
    this.inputdata = "";
    this.oldmappedxml="";
    this.oldtemplatexml="";
    this.updateflag = false;
    this.templatelist = this.templatelistbackup;
    this.reactiveFormtemp.reset();
    this.selectedtemplateid = "";
    this.templatename = "";
    this.reactiveFormtemp.setValue({ "tempname": '' });
    this.reactiveForminterfacetypeadd.reset();
    this.reactiveFormmsgtypeadd.reset();
    this.reactiveFormvendoradd.reset();
    this.reactiveForminterfacetypeadd.setValue({ "fcinterfacetypeadd": '' });
    this.reactiveFormmsgtypeadd.setValue({ "msgnameadd": '' });
    this.reactiveFormvendoradd.setValue({ "vendornameadd": '' });
    this.selectedmessagetypeadd = '';
    this.vendornameadd = '';
    this.vendornameaddId="";
    this.selectedinterfacetypevalueadd = '';
    this.myFlagForSlideToggle=false;
    this.reactiveFormformatfilterreprocess.reset();
    this.reactiveFormformatfilterreprocess.setValue({ "formatfilterreprocess": '' });
  }
  GetTemplateXML() {
    this.ngxUiLoaderService.start();
    var url = "api/GetMappings/GetTemplateXML";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        data = this.service.transformAlphabetically(data, 'name');
        this.templatelistbackup = data.filter((x) => x.Type == "Input");
        this.templatedatalist = data.filter((x) => x.Type == "Input");
        this.ngxUiLoaderService.stop();
      }
      else {
        this.toastr.error("Something Went wrong!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
        this.ngxUiLoaderService.stop();
      }
    }), error => {
      this.ngxUiLoaderService.stop();
    };
  }
  getTemplateName(event) {
    this.updateflag = false;
    this.isLoadingtemp = false;
    if (this.templatelist.length > 0) {
      this.filtertemplatelist(event);
    }
    else {
      this.templatelist = this.templatelistbackup;
      this.filtertemplatelist(event);
    }
  }
  filtertemplatelist(event) {
    if (!event == undefined) {
      this.templatelist = this.templatelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
      if (this.templatelist.length == 0) {
        this.notfounddata = true;
        this.updateflag = false;
      } else {
        this.notfounddata = false;
        this.updateflag = true;
      }
    }
    this.isLoadingtemp = false;
  }
  selectedTemplate(data) {
    this.ngxUiLoaderService.start();
    this.inputdata = beautify(data.templatexml);
    this.OutputData = beautify(data.mappedxml);
    this.templatename = data.name;
    this.updateflag = true;
    this.selectedtemplateid = data.id;
    this.ngxUiLoaderService.stop();
  }
  searchTemplateCleared(data) {
    this.inputdata = "";
    this.OutputData = "";
    this.oldmappedxml="";
    this.oldtemplatexml="";
    this.updateflag = false;
    this.templatelist = this.templatelistbackup;
    this.selectedtemplateid = "";
    this.templatename = "";
    this.reactiveFormtemp.setValue({ "tempname": '' });
  }

  isLoadingmessagetype: boolean = false;
  messageTypeAllList: any = [];
  messageTypeList: any = [];
  keywordsearchmsg = "displayname";
  getMessagetypelist(event) {
    this.isLoadingmessagetype = true;
    if (event !== 'msgtype') {
      if (event == undefined) {
        this.messageTypeList = this.messageTypeAllList;
      }
      else {
        if (this.messageTypeList.length == 0) {
          this.messageTypeList = this.messageTypeAllList;
        }
        this.messageTypeList = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.messageTypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingmessagetype = false;
    }
    else {

      this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: data => {
          data.forEach(element => {
            this.messageTypeAllList.push({ displayname: element });
          });
          this.messageTypeList = this.messageTypeAllList;

          this.isLoadingmessagetype = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingmessagetype = false;
          this.notfounddata = true;
        }
      });
    }

  }
  selectedmessagetype: string = '';
  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
  }

  Vendornamelist: any[];
  VendornameBackuplist: any[];
  isLoadingvendor: boolean;
  keywordsearchvendar = "name";
  getVendorname() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelist = data as any[];
        this.Vendornamelist = this.service.transformAlphabetically(this.Vendornamelist, 'name');
        this.VendornameBackuplist = this.Vendornamelist;
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  selectedVendor(item) {
    this.vendorname = item.name;
    this.vendornameId=item.Id;
  }
  searchVendorCleared() {
    this.isLoadingvendor = false;
    this.vendorname = "";
    this.vendornameId = "";
    this.Vendornamelist = this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
  }
  getVendorName(event) {
    this.isLoadingvendor = false;
    if (this.Vendornamelist.length > 0) {
      this.filtervendorlist(event);
    }
    else {
      this.Vendornamelist = this.VendornameBackuplist;
      this.filtervendorlist(event);
    }
  }
  filtervendorlist(event) {
    this.Vendornamelist = this.Vendornamelist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelist.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendor = false;
  }


  reactiveForminterfacetype: FormGroup;
  keywordvalue: string = 'item_text';
  Interfacetypeslist = [
    { item_id: 1, item_text: "Inbound", checked: true, item_value: "Inbound" },
    { item_id: 2, item_text: "Outbound", checked: false, item_value: "Outbound" },
    { item_id: 3, item_text: "Ellkay Order Inbound", checked: false, item_value: "EllkayOrderInbound" },
    { item_id: 4, item_text: "Ellkay Result Outbound", checked: false, item_value: "EllkayResultOutbound" }
  ];

  selectedinterfacetypetext: string = 'select'
  selectedinterfacetypevalue: string = 'select'

  selectinterfacetypeEvent(item) {
    this.selectedinterfacetypevalue = item.item_value;
  }
  oninterfacetypeFocused(item) {
    this.Interfacetypeslist = [
      { item_id: 1, item_text: "Inbound", checked: true, item_value: "Inbound" },
      { item_id: 2, item_text: "Outbound", checked: false, item_value: "Outbound" },
      { item_id: 3, item_text: "Ellkay Order Inbound", checked: false, item_value: "EllkayOrderInbound" },
      { item_id: 4, item_text: "Ellkay Result Outbound", checked: false, item_value: "EllkayResultOutbound" }
    ];
  }
  clearedinterfacetype(item) {
    this.selectedinterfacetypevalue = '';
  }
  reactiveForminterfacetypeadd: FormGroup;
  messageTypeListadd = [];
  inputFormat:string='';
  mappedFormat:string='';

  async openaddoredittemplate(data, modal) {
    this.ngxUiLoaderService.start();
    this.reactiveFormformatfilterreprocess.reset();
    this.title='';
    this.formatfilterreprocess = '';
    this.reactiveFormformatfilterreprocess.setValue({ "formatfilterreprocess": '' });
    this.OutputData = "";
    this.inputdata = "";
    this.oldmappedxml="";
    this.oldtemplatexml="";
    this.updateflag = false;
    this.reactiveFormmsgtypeadd = this._fb.group({ msgnameadd: '' });
    this.reactiveFormtemp.reset();
    this.reactiveFormtemp = this._fb.group({ tempname: '' });
    this.templatelist = this.templatelistbackup;
    this.messageTypeListadd = this.messageTypeAllList;
    this.reactiveFormmsgtypeadd.patchValue(this.messageTypeAllList);
    this.reactiveForminterfacetypeadd = this._fb.group({ fcinterfacetypeadd: '' });
    this.oninterfacetypeFocusedadd('item');
    this.Vendornamelistadd = this.VendornameBackuplist;
    this.reactiveFormvendoradd.setValue({ "vendornameadd": '' });
    this.cdr.detectChanges();
    this.myFlagForSlideToggle=false;
    if (data != '') {
      this.title='Update Template';
      this.selectedtemplateid=data.id;
      this.OutputData = data.mappedxml;
      this.oldmappedxml=data.mappedxml;
      this.inputdata = data.templatexml;
      this.oldtemplatexml=data.templatexml;
      this.updateflag = true;
      this.vendorname =data.vendor;
      this.vendornameaddId = data.VendorId;
      this.selectedinterfacetypevalueadd = data.direction;
      this.selectedmessagetypeadd = data.messagetype;
      this.templatename = data.name;
      this.inputFormat=data.inputFormat;
      this.mappedFormat=data.inputFormat;
      this.myFlagForSlideToggle=data.isactive;
      this.formatfilterreprocess=data.inputFormat;
      this.reactiveFormtemp.patchValue({
        tempname:data.name
      });
      this.reactiveFormformatfilterreprocess.patchValue({
        formatfilterreprocess: data.inputFormat
      });
      this.reactiveFormmsgtypeadd.patchValue({
        msgnameadd:data.messagetype
      });
      this.reactiveForminterfacetypeadd.patchValue({
        fcinterfacetypeadd:data.direction
      });
      this.reactiveFormvendoradd.patchValue({
        vendornameadd:data.vendor
      });

      if (this.inputdata) {
        if (this.inputdata.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.inputdata.startsWith("{")) {
          this.formatType = "json";
        }
        this.editorOptions = {
          ...this.editorOptions,
          language: this.formatType
        };
      }
      if (this.OutputData) {
        if (this.OutputData.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.OutputData.startsWith("{")) {
          this.formatType = "json";
        }
        this.editorOptions1 = {
          ...this.editorOptions1,
          language: this.formatType
        };
      }

      this.modalService.open(modal, { windowClass: "templatemodaladd", backdrop: 'static', keyboard: false }).result.then((result) => { });
    }
    else {
      this.title='Add Template';
      if (this.inputdata) {
        if (this.inputdata.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.inputdata.startsWith("{")) {
          this.formatType = "json";
        }
        this.editorOptions = {
          ...this.editorOptions,
          language: this.formatType
        };
      }
      if (this.OutputData) {
        if (this.OutputData.startsWith("<")) {
          this.formatType = "xml";
        } else if (this.OutputData.startsWith("{")) {
          this.formatType = "json";
        }
        this.editorOptions1 = {
          ...this.editorOptions1,
          language: this.formatType
        };
      }
      this.modalService.open(modal, { windowClass: "templatemodaladd", backdrop: 'static', keyboard: false }).result.then((result) => { });
    }
    this.ngxUiLoaderService.stop();
  }
  autoclosetemplate(cnt) {
    setTimeout(() => {
      cnt('close modal');
    }, 3000);
  }
  closetemplatepopup(c) {
    c('close modal');
  }

  selectedlisttemplateid: string = '';
  selectedtemplatelist(data) {
    this.selectedlisttemplateid = data.id;
  }

  isLoadingtemplist: boolean = false;
  templatedatalist: any[];
  getTemplatelist(event) {
    this.isLoadingtemplist = false;
    if (this.templatedatalist.length > 0) {
      this.filtertemplatelistbyname(event);
    }
    else {
      this.templatedatalist = this.templatelistbackup;
      this.filtertemplatelistbyname(event);
    }
  }
  notfounddatalist: boolean;
  filtertemplatelistbyname(event) {
    this.templatedatalist = this.templatedatalist.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.templatedatalist.length == 0) {
      this.notfounddatalist = true;
    } else {
      this.notfounddatalist = false;
    }
    this.isLoadingtemplist = false;
  }
  reactiveFormtemplist: FormGroup;
  searchTemplatelistCleared(data) {
    this.templatedatalist = this.templatelistbackup;
    this.selectedlisttemplateid = "";
    this.reactiveFormtemplist.setValue({ "templistname": '' });
  }

  resetlist() {
    this.selectedlisttemplateid = "";
    this.reactiveFormtemplist.setValue({ "templistname": '' });
    this.templatedatalist = this.templatelistbackup;
    this.selectedmessagetype = '';
    this.messageTypeList = this.messageTypeAllList;
    this.reactiveFormmsgtype.setValue({ "msgname": '' });
    this.vendorname = "";
    this.vendornameId = "";
    this.Vendornamelist = this.VendornameBackuplist;
    this.reactiveFormvendor.setValue({ "vendorname": '' });
    this.SearchDetailsList = [];
    this.selectedinterfacetypevalue = '';
    this.oninterfacetypeFocused('item');
    this.reactiveForminterfacetype.reset();
    this.reactiveForminterfacetype.setValue({ "fcinterfacetype": '' });

  }

  SearchDetailsList: any = [];
  async searchtemplateslist() {
    var searchData = {
      "templateid": this.selectedlisttemplateid,
      "messagetype": this.selectedmessagetype,
      "direction": this.selectedinterfacetypevalue == 'select' ? '' : this.selectedinterfacetypevalue,
      "vendor": this.vendornameId
    };

    var apiname = 'api/GetMappings/SearchTemplates';
    this.ngxUiLoaderService.start();
    this.SearchDetailsList=[];
    let response = await this.service.searchpostapi(apiname, searchData);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });

    } else {
      this.ngxUiLoaderService.stop();
      this.SearchDetailsList = response as any[];
      if (this.SearchDetailsList.length === 0) {
        this.toastr.warning("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
  }

  selectedmessagetypeadd: string = '';
  selectedmessagetypeeventadd(item) {
    this.selectedmessagetypeadd = item.displayname;
  }

  Clearemessagetypeadd(event) {
    this.selectedmessagetypeadd = '';
    this.messageTypeListadd = this.messageTypeAllList;
  }
  notfounddataadd: boolean = false;
  isLoadingmessagetypeadd: boolean = false;
  getMessagetypelistadd(event) {

    this.isLoadingmessagetypeadd = true;

    if (event == undefined) {
      this.messageTypeListadd = this.messageTypeAllList;
    }
    else {
      if (this.messageTypeListadd.length == 0) {
        this.messageTypeListadd = this.messageTypeAllList;
      }
      this.messageTypeListadd = this.messageTypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
    }
    if (this.messageTypeListadd.length == 0) {
      this.notfounddataadd = true;
    } else {
      this.notfounddataadd = false;
    }
    this.isLoadingmessagetypeadd = false;

  }

  selectedinterfacetypevalueadd: string = 'select'

  selectinterfacetypeEventadd(item) {
    this.selectedinterfacetypevalueadd = item.item_value;
  }

  clearedinterfacetypeadd(item) {
    this.selectedinterfacetypevalueadd = '';
  }
  Interfacetypeslistadd = [];
  oninterfacetypeFocusedadd(item) {
    this.Interfacetypeslistadd = [
      { item_id: 1, item_text: "Inbound", checked: true, item_value: "Inbound" },
      { item_id: 2, item_text: "Outbound", checked: false, item_value: "Outbound" }
    ];
  }


  reactiveFormvendoradd: FormGroup;
  vendornameadd: string = "";
  vendornameaddId: string = "";

  Vendornamelistadd: any[];
  isLoadingvendoradd: boolean;
  getVendornameadd() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.Vendornamelistadd = data as any[];
        this.Vendornamelistadd = this.service.transformAlphabetically(this.Vendornamelistadd, 'name');
        this.ngxUiLoaderService.stop();
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  selectedVendoradd(item) {
    this.vendornameadd = item.name;
    this.vendornameaddId = item.Id;
  }
  searchVendorClearedadd() {
    this.isLoadingvendoradd = false;
    this.vendornameadd = "";
    this.vendornameaddId="";
    this.Vendornamelistadd = this.VendornameBackuplist;
    this.reactiveFormvendoradd.setValue({ "vendornameadd": '' });
  }
  getVendorNameadd(event) {
    this.isLoadingvendoradd = false;
    if (this.Vendornamelistadd.length > 0) {
      this.filtervendorlistadd(event);
    }
    else {
      this.Vendornamelistadd = this.VendornameBackuplist;
      this.filtervendorlistadd(event);
    }
  }
  filtervendorlistadd(event) {
    this.Vendornamelistadd = this.Vendornamelistadd.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.Vendornamelistadd.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendoradd = false;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  myFlagForSlideToggle: boolean = false;

  keyword: string = 'name';



  formatfilterreprocess: string = '';
  openAuditPopUp(uniqueid: any, templateguid: any,templatename:any) {

    if (uniqueid != '') {
      const auditDataToModal = {
        id: uniqueid,
        title:templatename ,
        context: "Template",
        type: "ID",
        guid: templateguid,
        userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };

      const modalRef = this.modalService.open(NewauditComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = auditDataToModal;
      modalRef.result.then(() => { });
    }

  }
}
