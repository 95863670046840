import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  Injectable,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { InterfacemappingComponent } from "../interfacemapping/interfacemapping.component";
import { CommonService } from "../services/common.service";
import { FileNode, TryitoutComponent } from "../tryitout/tryitout.component";
import { filter, map, startWith, take } from "rxjs/operators";
import { saveAs } from "file-saver";
import { Router } from "@angular/router";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from "@angular/material/tree";
import { BehaviorSubject, Observable, of, Subject, Subscription } from "rxjs";
// import { NgxXmlToJsonService } from "ngx-xml-to-json";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog,MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { SelectionModel } from "@angular/cdk/collections";
import { CdkDrag,CdkDropList, CdkDragDrop, CdkDragEnd, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import MappConfig from "assets/Mappingmethod.json";
import { HttpClient } from "@angular/common/http";
import { CounterService, FileDatabase, FileFlatNode } from "../services/FileDatabaseService";
import * as JsonToXML from "js2xmlparser";
// import { string32 } from "pdfjs-dist/types/shared/util";
import { v4 as uuidv4 } from "uuid";
import { ConditionComponent } from "../condition/condition.component";
import { ToastrService } from "ngx-toastr";
import * as xml2js from 'xml2js';
import { CompareComponent } from "../compare/compare.component";
import { ConfirmDialogComponent, ConfirmDialogModel } from "../confirm-dialog/confirm-dialog.component";
import beautify from "xml-beautifier";
import { MessageattributesComponent } from "../messageattributes/messageattributes.component";
import { V } from "@angular/cdk/keycodes";
import { FormArray, FormBuilder, FormGroup, Validators,ReactiveFormsModule } from "@angular/forms";

declare const loadxml: any;
declare const loadmappedxml: any;
declare const copyToClipboard: any;
declare const createMappedxml: any;
declare var $;

@Component({
  selector: "app-xmlmappings",
  templateUrl: "./xmlmappings.component.html",
  styleUrls: ["./xmlmappings.component.scss"],
  providers: [ FileDatabase],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class XmlmappingsComponent implements OnInit {
  zIndexSerial: number = 1000;
  conditionGroup: FormGroup;
  pretty:boolean|undefined
  @Input() inputData;
  options = { // set up the default options
    textKey: "text", // tag name for text nodes
    attrKey: "attr", // tag for attr groups
    cdataKey: "cdata", // tag for cdata nodes (ignored if mergeCDATA is true)
  };
  treeControl: FlatTreeControl<FileFlatNode>;
  treeMapControl: FlatTreeControl<FileFlatNode>;
  treeFlattener: MatTreeFlattener<FileNode,
    FileFlatNode>;
  treeMapFlattener: MatTreeFlattener<FileNode,
    FileFlatNode>;
  dataSource: MatTreeFlatDataSource<FileNode,
    FileFlatNode>;
  dataMapSource: MatTreeFlatDataSource<FileNode,
    FileFlatNode>;
  // expansion model tracks expansion state
  expansionModel = new SelectionModel<string>(true);
  expansionMapModel = new SelectionModel<string>(true);
  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  validateDrop = false;
  @Input() inputDataInModalComponent: any;
  inputtemplatexml: string = "";
  formatType: string = "text/plain";
  defaultFormatType:string='text/plain';
  @ViewChild("fileInput", { static: false }) myFileInput;
  @ViewChild("fileInputlhs", { static: false }) myFileInputlhs;
  @ViewChild("autocomplete",{static:false}) autocomplete;
  editorOptions = {
    theme: 'vs', language: this.formatType, wordWrap: 'on',scrollBeyondLastLine: false
};
  fileToUpload: any;
  uniqueid: any;
  screenHeight: number;
  xmlDataTitle: any;
  isapproverejectprocesscompleted: boolean = false;
  versionStatus: string = '';
  xmlkey: string = "xml";
  showMapcard: any;
  expressionselected: string;
  conditionparameterarray: any[];
  mappingExpressionname: any;
  backMapData: any;
  SelectedAttribute = [];
  backXMLData: any;
  backup: any;
  selectedExpression: any;
  mapConfigJson: any;
  dynamicJSON: inputField[] = [];
  questionFormGroup: FormGroup;
  items: FormArray;
  selectedExpressionValue: any;
  variableDropList = [];
  versionList: string[] = [];
  dateFormatlist = [
    "MMddyyyy",
    "yyMMdd",
    "MM/dd/yyyy",
    "MM/dd/yy",
    "MM/dd/yyyy hh:mm:ss",
    "MM/dd/yyyy hh:mm",
    "MM/dd/yyyy HH:mm:ss",
    "yyyy-MM-dd",
    "yyyyMMdd",
    "HH:mm",
    "yyyyMMddHHmmss",
    "HHmmss",
    "HH:mm:ss",
    "hhmmss",
    "hh:mm tt",
    "hhmm",
    "HHmm"
  ];
  routedetailsList = [
    "id",
    "displayname",
    "messagetype",
    "sourceagentid",
    "destinationagentid",
    "routepolicy",
    "status",
    "SEGUID",
    "SENAME",
    "SETYPE",
    "DEGUID",
    "DENAME",
    "DETYPE",
    "PAID",
    "PANAME",
    "PAAPIKEY",
    "INTERFACETYPE",
    "UniqueID",
    "SEDEPLOYMENTKEY",
    "DEDEPLOYMENTKEY",
  ];
  headerDetailsList = [
    "transactionid",
    "hash",
    "messageid",
    "processingParams",
    "jobContext",
    "mode",
  ];
  // mappingNotes : Subject < any > = new Subject();
  //   subscription : Subscription;
  // //  mappingNotes = new BehaviorSubject(false);
  //   currentMessage = this.mappingNotes.asObservable();
  messageBodyList = ["fileid", "orginalfilename", "filename"];
  keywordsearch_Template = "name";
  keywordsearch_mapping = "displayname";
  variableDropShow = [];
  enableNodeControl: boolean = false;
  repetableNode: boolean = false;
  addedRepeatableNode: boolean = false;
  nodeVariable: boolean = false;
  NodeMappingMethodSelected: boolean = false;
  snippetxml: string = "";
  nodeSnippet: boolean = false;
  resultJson: any;
  backupParentNode: any;
  nodejson: any;
  repeatablepath: string = "";
  nodevariablename: any;
  nodevariablevalue: any;
  isPosition: any = "";
  versionDetails: any[];
  liveVersion: any;
  userrole: string;
  backXMLWithSequence: any;
  routeid: string;
  processorid: string;
  processorapikey: string;
  classObjalertmap: string;
  StatusMessagedisplaymapping;
  statusmessagemappingflg: boolean = false;
  templatelist: any[];
  Template: string;
  MappedXml: string;
  headerRoute: string = "";
  tempname;
  ProcessorName: string;
  pagerolepermisions: any = [];
  key = "";
  ExpressionDivContent: boolean = false;
  nodeCondition: boolean = false;
  nodeConditionItem: ConditionBuilder[] = [];
  addedNodeCondition: boolean = false;
  SaveMapped: boolean;
  SaveValidateMapped:boolean;
  parentInnerChildName: any;
  panelOpenState: boolean;
  notfounddata: boolean;
  vaconfigdataBackup: any;
  isExistFunction: boolean;
  ShowNotes: boolean;
  templateId: any;
  mappingnotesData = <any>[];
  mappingNotescheck: boolean = false;
  templatejson: any;
  suggestionList: any = [];
  searchValue: string = "";
  isRejected: boolean;
  isSelectedValueDrop: boolean = false;
  isOldValue = "";
  iskeyValue: string;
  suggestionListFilter = [];
  existingattrcomment: string = '';
  closemenu: boolean = true;
  isEditProcessMap: boolean = false;
  isVersionModified: boolean;
  selectedTemplate: any;
  mapping_method = "displayname";
  mappingFormGroup: FormGroup;
  LockXML: any;
  suggestionListwithRemove: any;
  isprocessormapping: any;
  suggestionListFilterIndentifier = [];
  notesmodal: any;
  modalRefpushtoQC: any;
  disableGeneratePDFButton: boolean = true;
  isValidation:boolean;
  ContType=[]

  reactiveFormformatfilterreprocess: FormGroup = this._fb.group({
    formatfilterreprocess:''
  })
  editorInit(editor: any) {
      if (this.inputtemplatexml.startsWith("<")) {
        this.formatType = "xml";
      } else if (this.inputtemplatexml.startsWith("{")) {
        this.formatType = "json";
      }else{
        this.formatType='hl7'
      }
      if(this.defaultFormatType!=this.formatType){
        this.defaultFormatType=this.formatType;
      this.editorOptions = {
        ...this.editorOptions,
        language: this.formatType
      };
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const inputElement = this.autocomplete.inputElement.nativeElement;
      if (inputElement) {
        inputElement.blur();
      }
    },);
  }
  dropList = [];
  conditionList = [
    "select",
    "count",
    "starts with",
    "Not starts with",
    "contains",
    "Not contains",
    "Not Exists",
    "Exists",
  ];
  attrcomment: string = '';
  @ViewChild('languageMenuTrigger', { static: false }) languageMenuTrigger: MatMenuTrigger;

  constructor(private _fb: FormBuilder, private counterService: CounterService, private dialog: MatDialog, private cdr: ChangeDetectorRef, private router: Router, private ngxUiLoaderService: NgxUiLoaderService, public service: CommonService, private activeModal: NgbActiveModal, private modalService: NgbModal,
    // private ngxXmlToJsonService: NgxXmlToJsonService,

    private database: FileDatabase, private httpclient: HttpClient, private formBuilder: FormBuilder, private toastr: ToastrService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
    this.treeMapFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<FileFlatNode>(this._getLevel, this._isExpandable);
    this.treeMapControl = new FlatTreeControl<FileFlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataMapSource = new MatTreeFlatDataSource(this.treeMapControl, this.treeMapFlattener);
    this.reactiveFormroutegbl = _fb.group({ routenamegbl: "" });
    this.mappingFormGroup = _fb.group({ mappingmethodselected: "" });
  }

  registerMonacoCustomTheme() {
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs", // can also be vs or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: "comment",
          foreground: "ffa500",
          fontStyle: "italic underline"
        }, {
          token: "comment.js",
          foreground: "008800",
          fontStyle: "bold"
        }, {
          token: "comment.css",
          foreground: "0000ff"
        }, // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }

  keyword='lookupvalue'
  async ngOnInit() {

    this.questionFormGroup = this.formBuilder.group({ questions: this.formBuilder.array([]) });
    this.conditionGroup = this.formBuilder.group({
      operator: "",
      items: this.formBuilder.array(
        [this.createItem()]
      )
    });
    this.database.dataChange.subscribe((data) => this.rebuildTreeForData(data, this.key));
    this.screenHeight = window.innerHeight - 205;

    if (this.service.checksession()) {
      const currentModule = this.router.url.split('/')[2];
      if (currentModule == 'interface' && this.inputDataInModalComponent.routeid !== "" && this.inputDataInModalComponent.processorid !== "") {
        const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'interface').MenuId;
        const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
        actionitems.forEach(element => {
          this.pagerolepermisions[element.MenuName] = true;
        });

      } else if (currentModule == 'createpipeline' && this.inputDataInModalComponent.routeid !== "" && this.inputDataInModalComponent.processorid !== "") {
        const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
        const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
        actionitems.forEach(element => {
          this.pagerolepermisions[element.MenuName] = true;
        });
      } else {
        const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'agentlist').MenuId;
        const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
        actionitems.forEach(element => {
          this.pagerolepermisions[element.MenuName] = true;
        });
      }
      if (this.pagerolepermisions != undefined) {
        if (this.inputDataInModalComponent.disablePDFButton != undefined && this.inputDataInModalComponent.disablePDFButton != null) {
          this.disableGeneratePDFButton = this.inputDataInModalComponent.disablePDFButton;
        }
        this.routeid = this.inputDataInModalComponent.routeid;
        this.processorid = this.inputDataInModalComponent.processorid;
        this.processorapikey = this.inputDataInModalComponent.processorapikey;
        this.isprocessormapping = this.inputDataInModalComponent.isprocessormapping !== undefined ? this.inputDataInModalComponent.isprocessormapping : false;
        this.ProcessorName = this.inputDataInModalComponent.ProcessorName;
        this.headerRoute = this.inputDataInModalComponent.routeName === undefined ? '' : this.inputDataInModalComponent.routeName;
        this.templatelist = this.inputDataInModalComponent.templatelist.filter((x) => x.Type == "input" || x.type == "input" || x.Type == "Input" || x.type == "Input");
        this.LockXML = this.inputDataInModalComponent.lockXMLmap;
        this.templatelist = this.templatelist.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
        this.isValidation  = this.inputDataInModalComponent.isValidation;
        this.tempname = parseInt(this.inputDataInModalComponent.templateid);
        if (this.tempname !== undefined) {
          let templateData = this.inputDataInModalComponent.templatelist.find((x) => x.id == this.tempname);
          if (templateData) {
            this.Template = templateData.templatexml
            setTimeout(() => {
              this.selectedTemplate = templateData.name;
            });
          } else {
            this.Template = ''
          }
          this.isEditProcessMap = this.inputDataInModalComponent.isprocessorEditmapping !== undefined ? this.inputDataInModalComponent.isprocessorEditmapping : false;

        }

        this.templateId = this.inputDataInModalComponent.templateid;
        this.userrole = sessionStorage.getItem("rolepermissions");

        let templateobj = {
          xml: this.Template,
          operation: "add",
        };
        this.service.XmlModification(templateobj).subscribe((data) => {
          if (data) {
            let parsers = new xml2js.Parser({
              strict: true,
              trim: true,
              
              attrkey: "attr",
            });
            parsers.parseString(data.responsebody, (err, results) => {
              if (results) {
                // const obj = this.ngxXmlToJsonService.xmlToJson(this.Template, this.options);
                this.createtemplatepath(results);
                this.addguid(results);
                let xmldata_gui = JSON.parse(JSON.stringify(results))
                this.removeUnderScore(xmldata_gui)
                //  console.log(xmldata_gui);
                // console.log(results);

                this.backXMLData = results;
                let pars = JSON.stringify(results);
                this.xmlkey = "xml";
                this.database.initialize(pars, this.xmlkey);
                this.treeControl.expandAll();
              }
            });
          }
        });

        if (this.inputDataInModalComponent.resultJson) {
          this.resultJson = JSON.parse(
            this.inputDataInModalComponent.resultJson
          );
        } else {
          this.resultJson = "";
        }
        this.mapConfigJson = await this.getdeploymentconfiguration();
        this.addmethodstodropdown(this.mapConfigJson);

        this.uniqueid = this.inputDataInModalComponent.uniqueid;


        this.ngxUiLoaderService.start();
        setTimeout(() => {
          this.MappedXml = this.inputDataInModalComponent.MappedXml;
          let obj = {
            xml: this.MappedXml,
            operation: "add"
          }
          if (this.inputDataInModalComponent.routedetails) {
            let obj = {
              id: this.inputDataInModalComponent.routedetails.id,
              PAID: this.inputDataInModalComponent.processorid
            }
            if (this.inputDataInModalComponent.CoreConfigVersionList) {
              
              this.versionDetails = this.inputDataInModalComponent.CoreConfigVersionList;
              let versionData = this.versionDetails.find((ele) => ele.islive == true)
              
              if (versionData) {
                this.liveVersion = versionData.version;
              }
              if (this.liveVersion) {
                this.version = this.liveVersion;
                this.versionStatus = versionData.versionstatus;
              }
              else {
                this.version = this.versionDetails[this.versionDetails.length - 1].version;
                this.versionStatus = this.versionDetails[this.versionDetails.length - 1].versionstatus;
              }
            } else {
              this.getVersionDetails(obj, true, false);
            }
          }
          this.service.getIntContType('interfacecontenttype').subscribe((data) => {
            if (data) {
              this.ContType = data
              // this.contLookupid = data.lookupid;
            }
          })

          // this.ngxUiLoaderService.stopAll();
          this.service.XmlModification(obj).subscribe(data => {
            if (data.statusmessage == null) {
              let parsers = new xml2js.Parser({ strict: true, trim: true,  attrkey: "attr" });
              parsers.parseString(data.responsebody, (err, results) => {
                if (results) {
                  this.addguid(results);
                  // console.log(results);
                  this.backMapData = results;
                  let parseData = JSON.stringify(results);
                  this.xmlkey = "map";
                  this.database.initialize(parseData, this.xmlkey);
                  this.getVariable(this.MappedXml);
                  this.showMapcard = true;
                  this.treeMapControl.expandAll();
                }
              });
              // const objj = this.ngxXmlToJsonService.xmlToJson(
              // this.MappedXml,
              // this.options
              // );
              this.ngxUiLoaderService.stopAll();
            } else {
              this.toastr.error(data.statusmessage, "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
              this.ngxUiLoaderService.stop();
              this.myFileInput.nativeElement.value = "";
            }
          }, error => {
            console.error()
            this.ngxUiLoaderService.stop()
          })
        });
      }
    } else {
      this.router.navigate(["/login"]);
    }

  }





  rKeys(o, path = "") {
    if (!o || typeof o !== "object") return path;
    return Object.keys(o).map(key => this.rKeys(o[key], path ? [path, key].join("/") : key))
  }
  objectPaths = (o) => { return this.rKeys(o).toString().split(",") }
  openpanel(value) {
    // this.panelOpenState = !this.panelOpenState;
    if (value) {
      this.screenHeight = window.innerHeight - 270;
    } else {
      this.screenHeight = window.innerHeight - 204;
    }
  }
  getVersionDetails(routeDetails: any, getLive: any, addaudit: any) {

    let versionToUpdate = "";
    if (addaudit) {
      if (this.liveVersion) {
        if (parseInt(this.versionDetails[this.versionDetails.length - 1].version) > parseInt(this.liveVersion)) {
          versionToUpdate = this.versionDetails[this.versionDetails.length - 1].version;
        }
      } else {
        versionToUpdate = this.versionDetails[this.versionDetails.length - 1].version;
      }
    }

    this.versionDetails = [];
    this.versionList = [];
    this.version = "";
    var url = "api/CoreConfig/GetCoreconfigVersion?routeid=" + routeDetails["id"] + "&agentid=" + this.inputDataInModalComponent.processorid + "&version=";
    this.service.getapiurl(url).subscribe(async data => {
      if (data) {
        let response = data as any[];
        this.versionDetails = response;

        response.forEach((ele) => {
          if (ele.version) {
            if (ele.islive) {
              this.liveVersion = ele.version;

              this.versionList.push('Live ' + ele.version);
              if (getLive) {
                this.versionStatus = ele.versionstatus;
                this.version = ele.version;
              }
            } else {
              this.versionList.push(ele.version);
            }
          }
        })

        if (this.versionList.length > 0 && this.version == "") {
          this.version = this.versionDetails[this.versionDetails.length - 1].version;
          //   let obj={
          //     value: this.version
          //   }
          //   this.onVersionChange(obj)
          this.versionStatus = this.versionDetails[this.versionDetails.length - 1].versionstatus;
          let versionString=''
          if (addaudit) {
            if (this.versionStatus === '101') {
              versionString = this.version + ' QC'
            } else if (this.versionStatus === '100') {
              versionString = this.version + ' Dev'
            } else if (this.versionStatus === '103') {
              versionString = this.version + ' Live'
            }
            else{
              versionString = this.version
            }

            if (versionToUpdate && this.versionDetails.length > 0) {
              var responseaudit = await this.service.auditsave("Update", "", `New Mapping Version is Updated-${versionString} `, "Pipeline", this.inputDataInModalComponent.routedetails.UniqueID,'Mapping Version Updated Successfully')
              if (responseaudit['status'] && responseaudit['statusText']) {

              }
            }
            else {
              var responseaudit = await this.service.auditsave("Create", "", `New Mapping Version is Created-${this.version.replace('Live', '').trim()} `, "Pipeline", this.inputDataInModalComponent.routedetails.UniqueID,'Mapping Version Created Successfully');
              if (responseaudit['status'] && responseaudit['statusText']) {

              }
            }
          }
        }

      }
    }),
      error => { };
  }


  createItem(): FormGroup {
    return this.formBuilder.group({ left: "", panel: "", rigth: "" });
  }
  addItem(arr): void {
    let obj = {
      left: "",
      oper: "",
      right: "",
      leftsubcondition: [],
      leftsubexp: "",
      rightsubexp: "",
      rightsubcondition: [],
      conditiontype: "",
      notCondition: false,
      startsWithorContainsCondition: false
    };
    arr.push(obj);
  }

  addGroup(array, i) {
    let obj = {
      key: "",
      value: "",
      isdroppable: false,
      required: true,
      label: "Add Group",
      type: "button",
      placeholder: "Enter the Value",
      order: i + 1,
      conditions: [],
      enablegroup: true,
      isAddedgroup: true,
      operator: ""
    };
    if (array.length > 0) {
      array[i].enablegroup = false;
    }
    array.push(obj);
  }

  addparams(array) {
    let isVariable = false;
    if (array.length > 0) {
      if (array[0].isvariable !== undefined) {
        isVariable = true;
      } else {
        isVariable = false;
      }
    }
    let val = {
      key: "",
      value: "",
      isdroppable: true,
      isvariable: isVariable,
      required: true,
      label: "Parameter ",
      type: "text",
      placeholder: "Enter the Value",
      order: array.length + 1,
      condition: []
    };
    array.push(val);
  }
  deleteConcat(array, i) {
    array.splice(i, 1);
  }

  removeGroup(array, i) {
    array.splice(i, 1);
  }
  openComment() {
    this.closemenu = true;
    this.languageMenuTrigger.openMenu();
  }



  generateresultjson() {
    let customarray = [];
    customarray.push(...this.dynamicJSON);
    var type = this.createJson("Type");
    type["Type"] = this.selectedExpressionValue;
    customarray.push(type);
    if (typeof customarray == "object") {
      var jsonObj = JSON.stringify(customarray);
    }
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    if (this.resultJson == "") {
      this.resultJson = {};
      var nodeFormat = this.backupParentNode.filename + "|" + guid;
      var node = this.createJson(nodeFormat);
      var attribute = this.createJson(this.selectednode.filename);
      attribute[this.selectednode.filename] = jsonObj;
      node[nodeFormat] = attribute;
      Object.assign(this.resultJson, node);
    } else {
      var nodeFormat = this.backupParentNode.filename + "|" + guid;
      var isfoundnodekey = false;
      var isfoundattributekey = false;
      this.resultJson = this.resultJsonChange(this.resultJson)
      if (typeof this.resultJson === "object") {
        for (var [key, value] of Object.entries(this.resultJson)) {
          if (key == nodeFormat) {
            var customvalue = value;
            for (let [key, value] of Object.entries(customvalue)) {
              if (key == this.selectednode.filename) {
                isfoundattributekey = true;
              }
            }
            isfoundnodekey = true;
            break;
          }
        }
      }
      if (!isfoundnodekey && !isfoundattributekey) {
        var node = this.createJson(nodeFormat);
        var attribute = this.createJson(this.selectednode.filename);
        attribute[this.selectednode.filename] = jsonObj;
        node[nodeFormat] = attribute;
        Object.assign(this.resultJson, node);
      } else if (isfoundnodekey && isfoundattributekey) {
        delete this.resultJson[nodeFormat][this.selectednode.filename];
        var attribute = this.createJson(this.selectednode.filename);
        attribute[this.selectednode.filename] = jsonObj;
        Object.assign(this.resultJson[nodeFormat], attribute);
      } else if (isfoundnodekey && !isfoundattributekey) {
        var attribute = this.createJson(this.selectednode.filename);
        attribute[this.selectednode.filename] = jsonObj;
        Object.assign(this.resultJson[nodeFormat], attribute);
      }
    }
  }
  conditionresult: string = "";
  resultJsonChange(value) {
    if (typeof value === "string") {
      value = JSON.parse(value);
      if (typeof value === "string") {
        this.resultJsonChange(value)
      } else if (typeof value === "object") {
        return value
      }
    } else if (typeof value === "object") {
      return value
    }
  }
  generateresult() {
    this.conditionresult = "";
    let resultcount = 1;
    let result = [];
    let mappingResult = "";
    let conditionIndex;
    this.dynamicJSON.forEach((ele: any) => {
      if (ele.label.toLowerCase().includes("condition")) {
        conditionIndex = Object.keys(this.selectedExpression["parameter"]).indexOf(ele.label);
      }
      if (ele.condition.length > 0) {
        this.conditionBuilder(ele.condition);
        if (this.conditionresult) {
          result.push(this.conditionresult);
        }
      } else {
        if (ele.isdatefield || ele.isroutefield || ele.isheaderfield || ele.ismessagebodyfield) {
          let value = "'" + ele.value + "'";
          result.push(value);
        } else {
          result.push(ele.value);
        }
      }
    });
    if (result.length > 0) {
      let methodType = this.selectedExpression["MethodType"];
      let parameterSeperator = this.selectedExpression["ParameterSeperator"];
      if (conditionIndex != undefined) {
        result = this.array_move(result, result.length - 1, conditionIndex);
      }
      if (methodType.toLowerCase() == "xslt") {
        mappingResult = "_xsltfunction:" + this.selectedExpressionValue + "(" + result.join(parameterSeperator) + ")";
      } else if (methodType.toLowerCase() == "c#") {
        mappingResult = "_function:" + this.selectedExpressionValue + "(" + result.join(parameterSeperator) + ")";
      } else if (methodType.toLowerCase() == "xsltbuiltin") {
        mappingResult = this.selectedExpressionValue + "(" + result.join(parameterSeperator) + ")";
      } else if (methodType.toLowerCase() == "variable") {
        mappingResult = result[0];
      }
    }
    return mappingResult;
  }


  generateCondition(array, andOr, isGroupadded) {
    var count = 1;
    array.forEach((ele) => {
      if (ele.notCondition) {
        this.conditionresult += "(";
      } else if (count == 1 && isGroupadded && array.length > 1) {
        this.conditionresult += "(";
      }
      if (ele.startsWithorContainsCondition) {
        if (ele.conditiontype.toLowerCase() == "starts with") {
          this.conditionresult += "starts-with(" + ele.left + "," + ele.right + ")";
        } else if (ele.conditiontype.toLowerCase() == "not starts with") {
          this.conditionresult += "not(starts-with(" + ele.left + "," + ele.right + "))";
        } else if (ele.conditiontype.toLowerCase() == "contains") {
          this.conditionresult += "contains(" + ele.left + "," + ele.right + ")";
        } else if (ele.conditiontype.toLowerCase() == "not contains") {
          this.conditionresult += "not(contains(" + ele.left + "," + ele.right + "))";
        }
      } else if (ele.notCondition) {
        if (ele.conditiontype.toLowerCase() == "not exists") {
          this.conditionresult += "not(" + ele.left + ")";
        } else {
          this.conditionresult += "(" + ele.left + ")";
        }
      } else if (ele.conditiontype.toLowerCase() == "count") {
        this.conditionresult += "count(" + ele.left + ")" + " " + ele.oper + " " + ele.right;
      } else {
        this.conditionresult += ele.left + " " + ele.oper + " " + ele.right;
      }

      if (array.length >= 2 && count == array.length - 1) {
        this.conditionresult += " " + andOr + " ";
      }
      if (count == array.length && isGroupadded && array.length > 1) {
        this.conditionresult += ")";
      } else if (ele.notCondition) {
        this.conditionresult += ")";
      }
      count++;
    });
  }
  removeAddressAt(arr, i) {
    arr.splice(i, 1);
  }

  drag(ev, value) {
    ev.preventDefault();
    ev.dataTransfer.setData("text", ev.target.id);
  }

  drop(event: CdkDragDrop<Node[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.container.id === "playlist_add") { 
      }
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  onItemDrop(e: any, value: any) {
    let path = this.getDraggedPath(e);
    let pathss;
    // if (value.type !== '') {
    // pathss = this.getPath(this.backMapData, value.type, '')
    // } else {
    // pathss = this.findPropPath(this.backMapData, value.filename).split('/').join(".")
    // }
    pathss = this.getPath(this.backMapData, value.GUID, "");
    if (pathss.length > 0) {
      for (let i = 0; i < pathss.length; i++) {
        if ((pathss[i] === "attr" || pathss[i] === "@") && e.item.data.isNode == "Child" || e.item.data.isNode == "innerChild") { // path.splice(i,1);
          pathss[pathss.length - 1] = value.filename;
        } else if ((pathss[i] === "attr" || pathss[i] === "@") && e.item.data.isNode == "Parent") { // pathss.splice(i, 1);
          pathss.pop();
          pathss.push(value.filename)
          pathss = pathss.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
        }
      }
      if (path.length > 0) {
        path = path.split("/")
        this.removeuniq(path)
        path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
        // if(e.item.data.filename == "Node_Text"){
        //   path.push("Node_Text")
        // path = path.join("/");
        // }else{
        // path = path.join("/");
        // }
        path = path.join("/");
        if(path.startsWith('Root/')){
          path=path.replace(/^Root\//, "");
        }
        if (e.item.data.isNode == "innerChild") {
          let uniqinnerchild = this.getAncestorsInner(this.dataSource.data, e.item.data.id);
          if (uniqinnerchild.length > 0) {
            let name = uniqinnerchild[uniqinnerchild.length - 2].filename;
            path = path + "/" + name
          }
        }
      }
      e.item.data['color']=true;
      value.type = path;
      value['highlight'] = true;
      if (value.isNode == "innerChild") {
        let uniqinnerchild = this.getAncestorsInner(this.dataMapSource.data, value.id);
        if (uniqinnerchild.length > 0) {
          this.parentInnerChildName = uniqinnerchild[uniqinnerchild.length - 2].filename;
        }
      }
      if (e.item.data.filename) {
        let bui = this.recompose(this.backMapData, pathss, path);
      }
      setTimeout(() => {
        if (this.backup) {
          this.backup.higlight = false;
        } else {
          this.backup = JSON.parse(JSON.stringify(value));
        }
      }, 1000);
    }
    this.checkmappednode();
  }

  checkmappednode() {
    // this.treeControl.dataNodes[10].color = true;
    this.treeControl.dataNodes.forEach(va => va.color = undefined)
    if (this.suggestionListFilterIndentifier.length > 0) {
      for (let i = 0; i < this.suggestionListFilterIndentifier.length; i++) {

        if (this.treeMapControl.dataNodes !== undefined && this.treeMapControl.dataNodes.length > 0) {
          for (let j = 0; j < this.treeMapControl.dataNodes.length; j++) {
            if (this.treeMapControl.dataNodes[j].type !== undefined) {

              if (this.suggestionListFilterIndentifier[i].new.includes(this.treeMapControl.dataNodes[j].type) && this.treeMapControl.dataNodes[j].type !== '') {
                let pathss = this.suggestionListFilterIndentifier[i].old.split("/").join(".");

                // console.log(this.backXMLData[pathss])
                let pathsleng = pathss.split(".")
                let older
                for (let k = 0; k < pathsleng.length - 2; k++) {
                  if (k == 0) {
                    if(this.backXMLData !== undefined){
                      older = this.backXMLData[pathsleng[k]];
                    }
                  } else {
                    if (older !== undefined) {
                      older = older[pathsleng[k]];
                    }
                  }
                }
                if (older !== undefined) {
                  let uni = this.treeControl.dataNodes.filter(va => va.GUID == older['attr']['_NodeIdentifier'])
                  let uniq = uni.filter(va => va.filename == pathsleng[pathsleng.length - 1])
                  // console.log(uniq)
                  if (uniq.length) {
                    uniq[0].color = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.treeMapControl.dataNodes;
  }

  onItemDropOnInput(e: any, input) {

    let path = this.getDraggedPath(e);
    let type = e.nativeEvent.target.title;
    path = path.split('/')
    if (path.length > 0) {
      this.removeuniq(path)
      path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
      path = path.join("/");
    }
    switch (type) {
      case "left":
        input.left = path;
        break;
      case "right":
        input.right = path;
        break;
      default:
        input.value = path;
        break;
    }
  }
  onItemDropOnRepeatable(e: any) {
    this.repeatablepath = "";
    let path = this.getDraggedPath(e);
    let type = e.nativeEvent.target.title;
    path = path.split('/')
    if (path.length > 0) {
      this.removeuniq(path)
      path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
      path = path.join("/");
    }
    this.repeatablepath = path;
  }
  onItemDropVariable(e: any) {
    this.nodevariablevalue = "";
    let path = this.getDraggedPath(e);
    let type = e.nativeEvent.target.title;
    path = path.split('/')
    if (path.length > 0) {
      this.removeuniq(path)
      path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
      path = path.join("/");
    }
    this.nodevariablevalue = path;
  }


  selectednode: any;
  showExpression(event) { // if (event.isNode !== "innerChild") {
    this.isExistFunction = false;
    this.existingattrcomment = '';
    this.onLeafNodeClick(event.GUID);
    if (event.isNode == "innerChild") {
      let uniqinnerchild = this.getAncestorsInner(this.dataMapSource.data, event.id);
      if (uniqinnerchild.length > 0) {
        this.parentInnerChildName = uniqinnerchild[uniqinnerchild.length - 2].filename;
      }
    }
    let path;
    path = this.getPath(this.backMapData, event.GUID, "");
    for (let i = 0; i < path.length; i++) {
      if (path[i] === "attr" && event.isNode == "Child") {
        path.splice(i, 1);
        path[path.length - 1] = "@" + event.filename;
      } else if (path[i] === "attr" && event.isNode == "Parent") {
        path.splice(i, 1);
        path.pop();

      } else if (path[i] === "attr" && event.isNode == "innerChild") {
        path.splice(i, 1);
        path.pop();
      }
    }
    if (path.length > 0) {
      this.removeuniq(path)
      path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
      // path = path.join("/");
    }
    if (event.isNode == "innerChild" && path !== undefined) {
      path.push("/@" + event.filename)
    }

    this.selectednode = event;
    this.SelectedAttribute = path;
    this.ExpressionDivContent = true;
    this.panelOpenState = true;
    this.screenHeight = window.innerHeight - 270;
    // reset Control
    this.selectedExpressionValue = "";
    this.dynamicJSON = []
    //
    this.clearflags();
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    this.getBackupData(this.backupParentNode.filename, guid, this.selectednode.filename, this.selectednode.type);
    // this.getExistingCommentForAttr(this.backMapData);
    // }else{
    // let innertext = this.getPath(this.backMapData, event.GUID, "");
    // }
  }
  // addseuqn(xml) {
  // let xmlss = this.parseXML(xml);
  // var tags = xmlss.getElementsByTagName('*');
  // for (var i = 0; i < tags.length; i++) {
  //     tags[i].setAttribute("_sequence", i);
  // }
  // return xmlss.documentElement.outerHTML
  // }
  // sortxmlbysequence(xml){
  // let xmlss = this.parseXML(xml);
  // var tags = xmlss.getElementsByTagName('*');
  // for (var i = 1; i < tags.length; i++)
  // for (var j = 0; j < i; j++)
  //       if (tags[i]._sequence < tags[j]._sequence) {
  //         var x = tags[i]._sequence;
  //         tags[i]._sequence = tags[j]._sequence;
  //         tags[j]._sequence = x;
  //       }

  //     tags = Array.prototype.slice.call(tags);
  //    let adata =  this.getSortedData({ data: tags, prop: "_sequence", isAsc: 1 })
  // //  xmlss =JsonToXML.parse("person", adata)
  //       return xmlss.documentElement.outerHTML
  // }
  sortxmlbysequence(xml) {
    let xmlss = this.parseXML(xml);
    let tags = xmlss.getElementsByTagName('*');
    let xmlssBack = this.parseXML(this.backXMLWithSequence);
    let tagsback = xmlssBack.getElementsByTagName('*');
    let arra = ""
    let aarr = []
    tags = Array.prototype.slice.call(tags);
    // let adata =  this.getSortedData({ data: tags, prop: "attributes._sequence.value", isAsc: 1 })
    for (let j = 0; j < tagsback.length; j++) {
      for (var i = 0; i < tags.length; i++) {
        if (parseInt(tagsback[j].attributes["_sequence"].value) == parseInt(tags[i].attributes["_sequence"].value)) {
          aarr.push(tags[i])
        }
      }
    }

    tags = aarr
    // for (var i = 0; i < aarr.length; i++) {
    // // if(parseInt(tags[i].attributes[0].nodeValue)  === i ){
    // arra += aarr[i].outerHTML
    // // }
    // }
    // return arra
    return xmlss.documentElement.outerHTML
  }

  // getSortedData({ data = [], prop, isAsc }: { data?: any[]; prop; isAsc; }) {

  // let arr = [];
  // [].slice.call(data).sort(function (a, b) {
  //     if (a.textContent > b.textContent) return 1;
  //     if (a.textContent < b.textContent) return -1;
  //     return 0;
  // }).forEach(function (val) {

  //     arr.push(val);
  // });
  // return arr;
  // }
  // return data.sort((a, b) => {
  //     return (parseInt(a[prop])  < parseInt(b[prop])? -1 : 1) * (isAsc ? 1 : -1)
  // });
  // }

  getVariable(xmldata: any) {
    this.variableDropShow = [];
    if (this.backMapData) {
      for (const [key, value] of Object.entries(this.backMapData)) {
        for (const [keyss, valuess] of Object.entries(this.backMapData[key])) {
          if (keyss.split("_")[0] === "Variables") {
            if (this.backMapData[key][keyss] !== undefined) {
              let xml = this.parseXML(xmldata);
              let longitudes = xml.getElementsByTagName("Variables");
              let cv = longitudes[0].children;
              let result = [];
              for (var i = 0; i < cv.length; i++) {
                let vaee = cv[i].attributes;
                if (vaee.length > 0) {
                  for (let j = 0; j < vaee.length; j++) {
                    if (vaee[j].name === "name") {
                      result.push("$" + vaee[j].nodeValue);
                    }
                  }
                  if (cv.length > result.length) {
                    if (cv[i]["localName"] !== "text" && cv[i]["localName"] !== "Variable")
                      result.push("$" + cv[i]["localName"]);
                  }
                }
              }
              this.variableDropShow = result;
            }
          }
        }
      }
    }
  }

  getBackupData(nodename: any, GUID: any, attrname, attrvalue) {
    let searchKey = nodename + "|" + GUID;
    if (this.resultJson) {
      this.isExistFunction = false;
      for (let [key, value] of Object.entries(this.resultJson)) {
        if (key == searchKey) {
          this.dynamicJSON = [];
          if (value[attrname] != undefined) {
            this.isExistFunction = false;
            this.dynamicJSON = JSON.parse(value[attrname]) as inputField[];
            let type = this.dynamicJSON[this.dynamicJSON.length - 1];
            let usecase = JSON.parse(JSON.stringify(type))
            if (usecase == "ConcatNodeAttributes" || usecase == "MatchingConditionValue") {
              this.screenHeight = window.innerHeight - 350;
            } else {
              this.screenHeight = window.innerHeight - 270;
            }
            if (type["Type"]) {
              this.selectedExpressionValue = type["Type"];
              this.selectedExpression = this.mapConfigJson[type["Type"]];
              this.dynamicJSON.pop();
            }
          } else {
            this.enableDivControl("Other")
            //  if (attrvalue.includes("concat") || attrvalue.includes("_function")||attrvalue.includes("_xsltfunction") ) {
            // this.isExistFunctionAddDynamic(attrvalue)
          }
        } else {
          this.enableDivControl("Other")
          // if (attrvalue.includes("concat") || attrvalue.includes("_function")||attrvalue.includes("_xsltfunction") ) {
          this.isExistFunctionAddDynamic(attrvalue)
        }
      }
    } else {
      this.enableDivControl("Other")
      // this.isExistFunctionAddDynamic(attrvalue)
    }

  }
  isExistFunctionAddDynamic(attrvalue) {
    this.dynamicJSON = []
    this.isExistFunction = true;
    let inputValue: inputField = {
      key: "",
      value: attrvalue,
      isdroppable: false,
      isvariable: false,
      isdatefield: false,
      isroutefield: false,
      isheaderfield: false,
      ismessagebodyfield: false,
      required: true,
      label: "",
      type: "text",
      placeholder: "Enter the Value",
      order: 0,
      condition: [],
      subcondition: [],
      subxpath: ""
    };
    this.dynamicJSON.push(inputValue);
  }
  getAncestors(array, name) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].GUID === name) {
          return [array[i]];
        }
        const a = this.getAncestors(array[i].children, name);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }
  getAncestorsInner(array, name) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === name) {
          return [array[i]];
        }
        const a = this.getAncestorsInner(array[i].children, name);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  onLeafNodeClick(values) {
    const ancestors = this.getAncestors(this.dataMapSource.data, values);
    this.backupParentNode = "";
    // this.treeControl.collapse(ancestors[0]);
    this.backupParentNode = ancestors[ancestors.length - 1];
    let breadcrumbs = "";
    ancestors.forEach((ancestor) => {
      breadcrumbs += `${ancestor.GUID
        }/`;
    });
  }

  closetpopup() {
    this.activeModal.close();
  }

  FadeOutmappingpageMsg() {
    this.statusmessagemappingflg = true;
    setTimeout(() => {
      this.statusmessagemappingflg = false;
    }, 20000);
  }
  addSubCondition(item, i) {
    if (item[i].condition == undefined) {
      item[i]["condition"] = [{
        key: "",
        value: "",
        isdroppable: true,
        required: true,
        label: "Add Group",
        type: "button",
        placeholder: "Enter the Value",
        enablegroup: true,
        order: i + 1,
        conditions: [],
        operator: ""
      },];
    } else {
      item[i].isOpen = true;
    }
  }


  async savemappeddataDB() {
    if (this.isEditProcessMap === false) {
      this.SaveMapped = true;
      this.ngxUiLoaderService.start();
      var str = JSON.stringify(this.backMapData);
      // str = str.replace(/attr/g, "@");
      str = str.replace(new RegExp("\\b" + 'attr' + "\\b"), "@");
      var Obj = JSON.parse(JSON.stringify(this.backMapData).replace(/"attr":/g, '"@":'));
      // var Obj = JSON.parse(str);
      var jsondata;
      var jsonObjresult = "";
      if (this.resultJson == "") {
        jsonObjresult = this.resultJson;
      } else {
        jsonObjresult = JSON.stringify(this.resultJson);
      }
      let versionToUpdate = "";
      if (this.routeid) {
        if (this.liveVersion && !this.isValidation) {
          if (parseInt(this.versionDetails[this.versionDetails.length - 1].version) > parseInt(this.liveVersion)) {
            versionToUpdate = this.versionDetails[this.versionDetails.length - 1].version;
          }
        }
        else if(this.isValidation)
        {
          versionToUpdate = this.liveVersion;
        } else {
          versionToUpdate = this.versionDetails[this.versionDetails.length - 1].version;
        }
      }

      for (const [key, value] of Object.entries(Obj)) {
        var xml = JsonToXML.parse(key, Obj[key], {
          format: {
            doubleQuotes: true
          }
        });
        xml = xml.replace(/&apos;/g, " ' ");
        xml = xml.replace(/\n/g, "  ");
        xml = xml.replace(/<_>/g, "  ");
        xml = xml.replace(/<\/_>/g, "  ");

        let obj = {
          xml: xml.replace(/<\?xml.+\?>|<!DOCTYPE.+] | <?xml version=\"1.0\"?>/g, ""),
          operation: "delete"
        }
        this.service.XmlModification(obj).subscribe(async data => {
          if (data.statusmessage == null) {
            jsondata = {
              mappedxml: data.responsebody,
              routeid: this.routeid,
              routeuniqueid:this.uniqueid,
              processorid: this.inputDataInModalComponent.processorid,
              createdby: JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata["userid"],
              processorapikey: this.processorapikey,
              isupdateconfig: true,
              template:this.uploadedtemplate?'0':this.inputDataInModalComponent.templateid,
              resultjson: jsonObjresult,
              version: this.isprocessormapping ? 1 : versionToUpdate,
              templatexml:this.uploadedtemplate?this.lhstree:''
            };

            var jsonvalues = JSON.stringify(jsondata);

            const apiUrl = this.isValidation  ? "api/CoreConfig/SaveValidationConfig"   : "api/CoreConfig/SaveTransformConfig";
            this.service.savemappings(jsonvalues, apiUrl).subscribe(response => {
              response = JSON.parse(response);
              if (!response["status"] && !response["statusmessage"]) {
                this.ngxUiLoaderService.stop();
                this.SaveMapped = false;
                this.toastr.error("Failure - due to" + response["statusmessage"], "", {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right"
                });
              } else {
                if (response["status"].toLocaleLowerCase() == "failure") {
                  this.SaveMapped = false;
                  this.ngxUiLoaderService.stop();
                  this.toastr.error(response["statusmessage"], "", {
                    timeOut: 4000,
                    positionClass: "toast-bottom-right"
                  });
                }
                else {
                  this.SaveMapped = false;
                  if (this.inputDataInModalComponent.routedetails) {
                    let obj = {
                      id: this.inputDataInModalComponent.routedetails.id,
                      PAID: this.inputDataInModalComponent.processorid
                    };
                    if (!this.isValidation)
                    {
                      this.getVersionDetails(obj, false, true);
                    } 
                  }

                  this.ngxUiLoaderService.stop();
                  this.toastr.success(response["statusmessage"], "", {
                    timeOut: 4000,
                    positionClass: "toast-bottom-right"
                  });
                }

              }
            }, error => {
              this.ngxUiLoaderService.stop();
              this.toastr.error(error.statusText, "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
            });

          }
          else {
            this.toastr.error(data.statusmessage, "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
            this.ngxUiLoaderService.stop();
          }
          this.SaveMapped = false;
        }, error => {
          console.error();
          this.ngxUiLoaderService.stop();
          this.SaveMapped = false;
        })
      }
    } else {
      var str = JSON.stringify(this.backMapData);
      // str = str.replace(/attr/g, "@");
      str = str.replace(new RegExp("\\b" + 'attr' + "\\b"), "@");
      var Obj = JSON.parse(JSON.stringify(this.backMapData).replace(/"attr":/g, '"@":'));
      // var Obj = JSON.parse(str);
      for (const [key, value] of Object.entries(Obj)) {
        var xml = JsonToXML.parse(key, Obj[key], {
          format: {
            doubleQuotes: true
          }
        });
        xml = xml.replace(/&apos;/g, " ' ");
        xml = xml.replace(/\n/g, "  ");
        xml = xml.replace(/<_>/g, "  ");
        xml = xml.replace(/<\/_>/g, "  ");
        let obj = {
          xml: xml.replace(/<\?xml.+\?>|<!DOCTYPE.+] | <?xml version=\"1.0\"?>/g, ""),
          operation: "delete"
        }
        this.service.XmlModification(obj).subscribe(async data => {
          if (data) {
            // jsondata = {
            this.service.isprocessEditedMappingData = data.responsebody;
            this.service.isprocessEditedtemplatedId = this.templateId
            this.closetpopup()
            // }
          }
        })
      }

    }
  }

  public dataToModal: any;
  openinterface(content) {
    this.dataToModal = {
      routeid: this.routeid,
      routename: this.headerRoute,
      uniqueid: this.uniqueid,
      templateid: this.templateId,
      templatename: this.templatename,
      LockInterfaceUpload: this.LockXML
    };

    const modalRef = this.modalService.open(InterfacemappingComponent, {
      windowClass: "myCustomModalClass",
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then(() => { });
  }

  async ontemplateChange(eventvalue) {

    eventvalue = eventvalue;
    this.dynamicJSON = []
    this.ExpressionDivContent = false;
    this.ngxUiLoaderService.start();
    this.inputDataInModalComponent.templateid = eventvalue.id;
    this.ngxUiLoaderService.start();
    this.Template = this.templatelist.find((x) => x.id == eventvalue.id).templatexml;
    this.templateId = eventvalue.id;
    this.MappedXml = this.templatelist.find((x) => x.id == eventvalue.id).mappedxml;
    let parsersd = new xml2js.Parser({ strict: true, trim: true,  attrkey: "attr" });
    parsersd.parseString(this.Template, (err, templateresult) => {
      if (templateresult) {
        this.templatejson = templateresult;
        let paths = this.objectPaths(this.templatejson);
        if (paths.length > 0) {
          for (let i = 0; i < paths.length; i++) {
            paths[i] = paths[i].replace('attr/', '@');
            let path = paths[i].split("/")
            path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
            paths[i] = path.join("/")
          }
        }
        this.suggestionList = paths
        this.suggestionListFilter = JSON.parse(JSON.stringify(this.suggestionList));
        this.suggestionListFilterIndentifier = JSON.parse(JSON.stringify(this.suggestionList));
        for (const [keyss, values] of Object.entries(this.templatejson)) {
          this.iskeyValue = keyss;
        }
      }
    })
    let templateobj = {
      xml: this.Template,
      operation: "add"
    }
    this.service.XmlModification(templateobj).subscribe(data => {
      if (data.statusmessage == null) {
        let parsers = new xml2js.Parser({ strict: true, trim: true,  attrkey: "attr" });
        parsers.parseString(data.responsebody, (err, results) => {
          if (results) { // const obj = this.ngxXmlToJsonService.xmlToJson(this.Template, this.options);
            this.createtemplatepath(results)
            this.addguid(results);
            this.backXMLData = results;
            let pars = JSON.stringify(results);
            this.xmlkey = "xml";
            this.database.initialize(results, this.xmlkey);
            this.treeControl.expandAll();
            this.ngxUiLoaderService.stop();
          }
        })
      }
      else {
        this.toastr.error("Invalid Template XML", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      }

    }), error => {
      this.ngxUiLoaderService.stop();
    }
    if (this.MappedXml) {
      setTimeout(() => { // let xml =
        let obj = {
          xml: this.MappedXml,
          operation: "add"
        }
        this.service.XmlModification(obj).subscribe(data => {
          if (data) {
            if (data.statusmessage == null) {
              this.backXMLWithSequence = JSON.parse(JSON.stringify(data.responsebody));
              const parser = new xml2js.Parser({
                strict: true,
                trim: true,
                attrkey: "attr",
                
                preserveChildrenOrder: true
              });
              parser.parseString(data.responsebody, (err, result) => {
                const objj = result;
                this.backMapData = objj;
              });
              this.addguid(this.backMapData);
              let parseData = JSON.stringify(this.backMapData);
              this.getVariable(this.MappedXml);
              this.xmlkey = "map";
              if (this.versionDetails !== undefined) {
                if (parseInt(this.versionDetails[this.versionDetails.length - 1].version) >= parseInt(this.liveVersion)) {
                  this.versionDetails[this.versionDetails.length - 1].json.processorconfig.xslconfig.mappedxml = this.MappedXml.toString();
                  this.version = this.versionDetails[this.versionDetails.length - 1].version;
                  this.versionStatus = this.versionDetails[this.versionDetails.length - 1].versionstatus;
                }
              }
              this.database.initialize(parseData, this.xmlkey);
              this.treeMapControl.expandAll();
              this.showMapcard = true;
              this.resultJson = "";
              this.ngxUiLoaderService.stop();
            } else {
              this.toastr.error("Invalid Mapped XML", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
              this.ngxUiLoaderService.stop();
              this.myFileInput.nativeElement.value = "";
            }
          }
        })
      }, 4000);
    } else {
      this.ngxUiLoaderService.stop();
    }
    // let obj = {
    //   templateid: this.templateId,
    //   pipelineid: this.uniqueid
    // }
    // var data = this.service.postapi('api/XMLMappings/SaveTemplateMappingToPipeline', obj);
    // if (data["status"] == "Success") {
    //   this.toastr.success(data["statusmessage"], "", {
    //     timeOut: 4000,
    //     positionClass: 'toast-bottom-right'
    //   });
    //   this.ngxUiLoaderService.stop();
    // } else {
    //     this.toastr.warning("The selected template does'nt have interfacemappig record", "", {
    //       timeOut: 4000,
    //       positionClass: 'toast-bottom-right'
    //     });
    //   }
  }

  searchTemplateCleared() { }
  removeUnderScore(results) {

    if (results) {
      if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
          ;
          Object.keys(results[i]).map((key) => {
            let oldKey = key;
            let newKey = key.split("_")[0]
            if (typeof results[i][key] === 'object') {
              if (key === oldKey) {
                results[i][newKey] = results[i][oldKey]
                if (oldKey !== "attr") {
                  delete results[i][oldKey];
                  this.removeUnderScore(results[i][newKey]);
                }

              }

            }
          })
        }
      } else {
        Object.keys(results).map((key) => {
          let oldKey = key;
          let newKey = key.split("_")[0]
          if (typeof results[key] === 'object') {
            if (key === oldKey) {
              results[newKey] = results[oldKey]
              delete results[oldKey];
            }
            this.removeUnderScore(results[newKey]);
          }
        })
      }
    }
    // return  results
  }
  createtemplatepath(results) {
    if (results) {
      this.templatejson = results;
      ;
      let paths = this.objectPaths(this.templatejson);
      // console.log(JSON.parse(JSON.stringify(paths)))
      // this.suggestionListwithRemove = path
      if (paths.length > 0) {
        for (let i = 0; i < paths.length; i++) {
          let obj = {
            old: JSON.parse(JSON.stringify(paths[i])),
            new: ''
          }
          paths[i] = paths[i].replace("attr/", "@");
          let path = paths[i].split("/");
          if (path.length > 0) {
            for (let i = 0; i < path.length; i++) {
              path[i] = path[i].includes("_") ? path[i].split("_")[0] : path[i];
            }
          }

          path = path.filter(
            (va) =>
              va !== "0" &&
              va !== "1" &&
              va !== "2" &&
              va !== "attr" &&
              va !== "_NodeIdentifier"
          );
          obj.new = path.join("/")
          paths[i] = obj;
        }
      }
      ;
      this.suggestionList = paths;

      this.suggestionListFilter = JSON.parse(
        JSON.stringify(this.suggestionList)
      );
      this.suggestionListFilterIndentifier = JSON.parse(
        JSON.stringify(this.suggestionList)
      );
      this.checkmappednode()
      for (const [keyss, values] of Object.entries(this.templatejson)) {
        this.iskeyValue = keyss;
      }
    }
  }



  templatename: string;
  classObjalertmain: string;
  StatusMessagedisplaytemplate: string;
  statusmessageMainflg: boolean = false;

  FadeOutmainpageMsg() {
    this.statusmessageMainflg = true;
    setTimeout(() => {
      this.statusmessageMainflg = false;
    }, 20000);
  }

  opentemplate(content) {
    this.templatename = "";
    this.inputtemplatexml = "";
    this.modalService.open(content, {
      backdrop: "static",
      windowClass: "myModalTemplate"
    }).result.then((result) => { }, (reason) => { });
  }
  validate() {
    if (this.inputtemplatexml != "") {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(this.inputtemplatexml, "application/xml");

      if (xmlDoc.getElementsByTagName("parsererror")[0] == undefined) {
        // this.classObjalertmain = "btn alert-success";
        // this.StatusMessagedisplaytemplate = "Valid template xml ";
        // this.FadeOutmainpageMsg();
        this.toastr.success("Valid template xml", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      } else {
        // this.classObjalertmain = "btn alert-danger";
        // this.StatusMessagedisplaytemplate = "Invalid template xml";
        // this.FadeOutmainpageMsg();
        this.toastr.error("Invalid template xml", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      }
    }
  }
  async addTemplate(c) {
    let condistionflag = false;
    let jsonflag = false;

    if (this.templatename != "" && this.inputtemplatexml != "") {
      let checktemplatename = this.templatelist.find((x) => x.name.toLowerCase() == this.templatename.toLocaleLowerCase());
      if (checktemplatename) {

        this.toastr.warning("Template name already exists", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      } else {
        try {
          var body = JSON.parse(this.inputtemplatexml);
          jsonflag = true;
        } catch (error) {
          jsonflag = false;
        }

        if (this.inputtemplatexml.startsWith("MSH")) {
          condistionflag = true;
        } else if (jsonflag) {
          condistionflag = true;
        } else {
          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(this.inputtemplatexml, "application/xml");

          if (xmlDoc.getElementsByTagName("parsererror")[0] == undefined) {
            condistionflag = true;
          } else {
            condistionflag = false;

            this.toastr.warning("Invalid Xml", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
          }
        }

        if (condistionflag == true) {
          let format = "";
          if (this.inputtemplatexml.startsWith("MSH")) {
            format = ".hl7";
          } else if (jsonflag) {
            format = ".json";
          } else {
            format = ".xml";
          }
          var templateData = {
            name: this.templatename,
            templatexml: this.inputtemplatexml,
            IsActive: 1,
            Format: format, // this.inputtemplatexml.startsWith('MSH') == true ? '.hl7' : '.xml',
            Type: "Input",
            IsDefault: 0
          };
          this.ngxUiLoaderService.start();
          var responsesource = await this.service.postapi("api/GetMappings/CreateTemplate", templateData);
          if (responsesource["status"] && responsesource["statusText"]) {
            this.ngxUiLoaderService.stop();

            this.toastr.error("Failed to create new template", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
          } else {
            if (responsesource.id == -1) {

              this.ngxUiLoaderService.stop();
              this.toastr.error("Failed to perform HL7 to XML conversion", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
            } else {
              var TempList = responsesource as any[];
              this.inputDataInModalComponent.templatelist.push({ id: responsesource.id, name: responsesource.name, templatexml: responsesource.templatexml, isactive: responsesource.isactive });

              this.ngxUiLoaderService.stop();
              this.toastr.success("Template created successfully", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
            }
          }
          this.autoclosetemplate(c);
        }
      }
    } else {

      this.toastr.warning("Please enter the required fields", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
    }
  }

  closetemplate(c) {
    c("close modal");
  }

  autoclosetemplate(c) {
    setTimeout(() => {
      c("close modal");
    }, 3000);
  }

  copyToClip(event: any, message: any) {
    if (event != "") {
      copyToClipboard(event);

      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
    }
  }

  async opentryiytout() {
    this.ngxUiLoaderService.start();

    let fileUploadFTP = false;
    let enableAccessionSearch = false;
    var str = JSON.stringify(this.backMapData);
    // str = str.replace(/attr/g, "@");
    // str=str.replace(new RegExp("\\b"+'attr'+"\\b"), "@");
    var Obj = JSON.parse(JSON.stringify(this.backMapData).replace(/"attr":/g, '"@":'));
    // var xml = await createMappedxml();
    let response = await this.getFileConfig(this.routeid, this.processorid);
    if (response != "") {
      var jsonData = JSON.parse(response[0]["json"]);
      var vaconfigdata = jsonData.processorconfig["vaconfig"];
      // let messagetype=jsonData.processoragentconfig["messagetype"];
      var interfacetype = jsonData.processorconfig["interfacetype"];
      if (this.inputDataInModalComponent.routedetails.messagetype === "order") {
        fileUploadFTP = true;
        enableAccessionSearch = false;
      } else if (this.inputDataInModalComponent.routedetails.messagetype === "result") {
        fileUploadFTP = false;
        enableAccessionSearch = true;
      }
      else {
        fileUploadFTP = false;
        enableAccessionSearch = false;
      }
      var xml;
      this.vaconfigdataBackup = vaconfigdata
      // Notes:This JsonToXML parse library using here only . Please change this to new xml2js.Parser library in future by Aleem
      for (const [key, value] of Object.entries(Obj)) {
        xml = JsonToXML.parse(key, Obj[key], {
          format: {
            doubleQuotes: true
          }
        });
        xml = xml.replace(/&apos;/g, " ' ");
        xml = xml.replace(/\n/g, "  ");
        xml = xml.replace(/<_>/g, "  ");
        xml = xml.replace(/<\/_>/g, "  ");
        let obj = {
          xml: xml.replace(/<\?xml.+\?>|<!DOCTYPE.+] | <?xml version=\"1.0\"?>/g, ""),
          operation: "delete"
        }


        // if (messagetype.toLocaleLowerCase() == 'result') {
        //     messagetype = "Results";
        // } else if (messagetype.toLocaleLowerCase() == 'order') {
        //     messagetype = "Orders";
        // }
        this.service.XmlModification(obj).subscribe(async data => {
          if (data) {
            this.dataToModal = {
              RouteIdtry: this.routeid,
              ProcessorIdtry: this.processorid,
              ProcessorDisplayNametry: this.ProcessorName,
              RouteDisplayNametry: this.headerRoute,
              Processorapikey: this.processorapikey,
              Popupflag: true,
              enableAccessionSearch: enableAccessionSearch,
              fileUploadFTP: fileUploadFTP,
              MappedXmlTemp: data.responsebody,
              isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
              isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
              showVersionDetails: true,
              version: this.version.replace('Live', '').trim(),
              showPush: true,
              sourcedeploymentkey: this.inputDataInModalComponent.routedetails.SEDEPLOYMENTKEY,
              targetdeploymentkey: this.inputDataInModalComponent.routedetails.DEDEPLOYMENTKEY,
              sourceaccounttype: this.inputDataInModalComponent.routedetails.sourcetype,
              targetaccounttype: this.inputDataInModalComponent.routedetails.targettype,
              seguid: this.inputDataInModalComponent.routedetails.SEGUID,
              deguid: this.inputDataInModalComponent.routedetails.DEGUID,
              sourceagent: this.inputDataInModalComponent.routedetails.sourceagentid,
              interfacetype: interfacetype,
              Uniqueid: this.uniqueid,
              isValidationFlag: this.isValidation
            };
            const modalRef = this.modalService.open(TryitoutComponent, {
              windowClass: "myCustomModalClass",
              backdrop: "static",
              keyboard: false
            });

            modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
            modalRef.result.then(() => { });
            this.ngxUiLoaderService.stop();
          }
        }, error => {
          this.ngxUiLoaderService.stop();
        })

      }
    }


  }

  formatXml(xml: any) {
    var formatted = "";
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, "$1\r\n$2$3");
    var pad = 0;
    xml.split("\r\n").map((node: any, index: number) => {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = "";
      for (var i = 0; i < pad; i++) {
        padding += "  ";
      }

      formatted += padding + node + "\r\n";
      pad += indent;
    });
    this.inputtemplatexml = formatted;
    return this.inputtemplatexml;
  }

  // copy route
  reactiveFormroutegbl: FormGroup;
  isLoadingroutesgbl: boolean = false;
  RouteNameListgbl: any = [];
  selectedrouteIdgbl: string = "";
  selectedrouteDisplayNamegbl: string = "";
  keywordsearchgbl: string = "displayname";

  getRouteNamegbl(event: any) {
    var val = event;
    if (val != "") {
      this.isLoadingroutesgbl = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=2";
      this.service.getapiurl(apiname).subscribe({
        next: (data) => {
          this.RouteNameListgbl = data as any[];
          this.isLoadingroutesgbl = false;
          this.RouteNameListgbl.map((obj) => (
            obj.displayname = `${obj.displayname
            } ${"(" + obj.id + ")"
            }`));
          this.RouteNameListgbl = this.RouteNameListgbl.filter((x) => x.id != this.routeid);
          this.selectedrouteIdgbl = "";
          this.selectedrouteDisplayNamegbl = val;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: (error) => {
          this.isLoadingroutesgbl = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedRoutegbl(item) {
    this.selectedrouteIdgbl = item.id;
    this.selectedrouteDisplayNamegbl = item.displayname;
    this.getRouteDetails(this.selectedrouteIdgbl);
  }

  searchRouteNameClearedgbl(event) {
    this.isLoadingroutesgbl = false;
    this.RouteNameListgbl = [];
    this.selectedrouteIdgbl = "";
    this.selectedrouteDisplayNamegbl = "";
    this.getRouteDetails(this.routeid);
    this.notfounddata = false;
  }
  Templatearray: any = [];
  getRouteDetails(routeid) {
    if (routeid != "") {
      var url = "api/GetMappings/GetRouteProcConfig?routeid=" + routeid;
      this.ngxUiLoaderService.start();
      this.service.getapiurl(url).subscribe({
        next: (responce) => {
          this.ngxUiLoaderService.stop();
          this.Templatearray = responce as any[];
          this.tempname = this.Templatearray["template"];
          this.Template = this.Templatearray["templatelist"].find((x) => x.id == this.Templatearray["template"]).templatexml;
          this.inputDataInModalComponent.templateid = this.Templatearray["template"];

          let obj = {
            xml: this.Templatearray["mappedxml"],
            operation: "add"
          }
          this.service.XmlModification(obj).subscribe(data => {
            if (data.statusmessage == null) {
              let parsers = new xml2js.Parser({ strict: true, trim: true,  attrkey: "attr" });
              parsers.parseString(data.responsebody, (err, results) => {
                if (results) {
                  this.backMapData = results;
                  this.addguid(this.backMapData);
                  let parseData = JSON.stringify(results);
                  this.getVariable(this.Templatearray["mappedxml"]);
                  this.xmlkey = "map";
                  this.database.initialize(parseData, this.xmlkey);
                  if (parseInt(this.versionDetails[this.versionDetails.length - 1].version) >= parseInt(this.liveVersion)) {
                    this.versionDetails[this.versionDetails.length - 1].json.processorconfig.xslconfig.mappedxml = this.Templatearray["mappedxml"].toString();
                    this.version = this.versionDetails[this.versionDetails.length - 1].version;
                  }
                  this.showMapcard = true;
                  this.resultJson = "";
                  this.treeMapControl.expandAll();
                }
              })
            } else {
              this.toastr.error(data.statusmessage, "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
              this.ngxUiLoaderService.stop();
              this.myFileInput.nativeElement.value = "";
            }
          },error=>{
            this.ngxUiLoaderService.stop();
          })

        },
        error: (error) => {

          this.toastr.error("Something Went Wrong!", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right"
          });
        }
      });
    }
  }

  async downloadmappedxml() {
    let str = JSON.stringify(this.backMapData);
    this.backMapData = JSON.parse(JSON.stringify(this.backMapData).replace(/"attr":/g, '"@":'));

    // console.log(this.backMapData)
    // console.log( this.convertattrtoat(this.backMapData))
    // return
    // str = str.replace(/attr/g, "@");
    // str=str.replace(new RegExp("\\b"+'attr'+"\\b"), "@");
    // str = str.replace(/attr/g, '@');


    // this.renameKeys(this.backMapData,this)
    // console.log(this.backMapData)
    // return
    // var json = JSON.stringify({ field: { field: 'foo' } }),
    // newJSON = json.replace(/"field":/g, '"category":');

    // this.backMapData = JSON.parse(str);

    let xml;

    for (const [key, value] of Object.entries(this.backMapData)) {
      xml = JsonToXML.parse(key, this.backMapData[key], {
        format: {
          doubleQuotes: true
        }
      });

      xml = xml.replace(/&apos;/g, " ' ");
      xml = xml.replace(/<\?xml.+\?>|<!DOCTYPE.+]>/g, "");
      xml = xml.replace(/\n/g, " ")
      xml = xml.replace(/<_>/g, "  ");
      xml = xml.replace(/<\/_>/g, "  ");
      let obj = {
        xml: xml,
        operation: "delete"
      }

      this.ngxUiLoaderService.start()
      this.service.XmlModification(obj).subscribe(data => {
        var FileSaver = require("file-saver");
        const xml = beautify(data.responsebody);
        var blob = new Blob([xml], { type: "text/xml;charset=utf-8" });
        FileSaver.saveAs(blob, "MappedXML.xml");
        this.ngxUiLoaderService.stop()
        this.service.auditsave("Add", "", ` Mapped XML Downloaded Successfully`, "Pipeline", this.uniqueid, "Mapped XML Downloaded Successfully")
      }, error => {
        console.error();
        this.ngxUiLoaderService.stop()
      })
    }
  }
  uploadedfile:any=''
  uploadedtemplate:boolean
  lhstree:string=''
 async addfilelhs(e){
    this.uploadedfile=e.target.files[0];
    let fileContent;
    if (this.uploadedfile) {
      this.ngxUiLoaderService.start();
      const filename = e.target.files[0].name;
        fileContent = await this.readFileContent(this.uploadedfile);
        // fileContent = fileContent.replace(/\n/g, "  ");
        // fileContent = fileContent.replace(/\r/g, "  ");
        let obj = {
          "templatexml": fileContent,
      }
      this.lhstree='';
      this.uploadedtemplate=false;
      await  this.service.createTemplatelhs(obj).subscribe(data =>{
      if(data['status']!=null&&data['status'].toLowerCase()=='success')
      {
        this.lhstree=data['responsebody'];
        if(this.lhstree!=''&& this.lhstree!=null){
          this.uploadedtemplate=true;
          this.makelhstree(this.lhstree);
          this.toastr.success("Template Uploaded Successfully", data['status'], {
            timeOut: 4000,
            positionClass: "toast-bottom-right"
          });
        }
      }else{
        this.toastr.error(data['responsebody'], data['status'], {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      }
    },error=>{
      this.ngxUiLoaderService.stop();
    });
        this.ngxUiLoaderService.stop();
    }
    else{
      this.ngxUiLoaderService.stop();
    }
  }

  async addFile(e): Promise<void> {
    this.fileToUpload = e.target.files[0];
    let fileContent;
    if (this.fileToUpload) {
      this.ngxUiLoaderService.start();
      const filename = e.target.files[0].name;
      if (filename.endsWith(".xml")) {
        fileContent = await this.readFileContent(this.fileToUpload);
        fileContent = fileContent.replace(/\n/g, "  ");
        fileContent = fileContent.replace(/\r/g, "  ");
        setTimeout(() => { // let xml =
          let obj = {
            xml: fileContent,
            operation: "add"
          }
          this.service.XmlModification(obj).subscribe(data => {
            if (data.statusmessage == null) {
              this.backXMLWithSequence = JSON.parse(JSON.stringify(data.responsebody));
              const parser = new xml2js.Parser({
                strict: true,
                trim: true,
                attrkey: "attr",
                
                preserveChildrenOrder: true
              });
              parser.parseString(data.responsebody, (err, result) => {
                const objj = result;
                this.backMapData = objj;
              });
              this.addguid(this.backMapData);
              let parseData = JSON.stringify(this.backMapData);
              this.getVariable(fileContent);
              this.xmlkey = "map";
              if (this.routeid && !this.isValidation) {
                if (this.versionDetails.length > 0) {
                  if (parseInt(this.versionDetails[this.versionDetails.length - 1].version) >= parseInt(this.liveVersion)) {
                    this.versionDetails[this.versionDetails.length - 1].json.processorconfig.xslconfig.mappedxml = fileContent.toString();
                    this.version = this.versionDetails[this.versionDetails.length - 1].version;
                    this.versionStatus = this.versionDetails[this.versionDetails.length - 1].versionstatus;
                  }
                }
              }
              this.database.initialize(parseData, this.xmlkey);
              this.treeMapControl.expandAll();
              this.showMapcard = true;
              this.resultJson = "";
              this.ngxUiLoaderService.stop();
              this.myFileInput.nativeElement.value = "";
              this.toastr.success("Mapped XML Uploaded Successfully", "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
              this.service.auditsave("Add", "", "file name:- "+filename+`, Uploaded Successfully`, "Pipeline", this.uniqueid, "Mapped XML Uploaded Successfully")
            } else {
              this.toastr.error(data.statusmessage, "", {
                timeOut: 4000,
                positionClass: "toast-bottom-right"
              });
              this.ngxUiLoaderService.stop();
              this.myFileInput.nativeElement.value = "";
            }
          }, error => {
            this.ngxUiLoaderService.stop();
            this.myFileInput.nativeElement.value = "";
          })
        });
      } else {
        this.ngxUiLoaderService.stop();
        this.myFileInput.nativeElement.value = "";
        // this.StatusMessagedisplaymapping = "Please select xml type";
        // this.classObjalertmap = "btn alert-danger";
        // this.FadeOutmappingpageMsg();
        this.toastr.warning("Please select xml type", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
      }
    }
  }

  async readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        resolve("");
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const text = reader.result.toString();
        resolve(text);
      };

      reader.readAsText(file);
    });
  }
  async getFileConfig(routeid: string, ProcessorId: string) {
    var dataPost = {
      agentid: ProcessorId,
      guids: routeid
    };

    return await this.service.postapi("api/CoreConfig/GetCoreConfig", dataPost);
  }

  // treeeFunctions

  transformer = (node: FileNode, level: number) => {
    return new FileFlatNode(
      !!node.children,
      node.filename,
      level,
      node.type,
      node["id"],
      node["GUID"],
      node["isNode"],
      node["color"]
    );
  };
  private _getLevel = (node: FileFlatNode) => node.level;
  private _isExpandable = (node: FileFlatNode) => node.expandable;
  private _getChildren = (node: FileNode): Observable<FileNode[]> => of(node.children);
  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;

  // DRAG AND DROP METHODS

  shouldValidate(event: MatCheckboxChange): void {
    this.validateDrop = event.checked;
  }

  /**
 * This constructs an array of nodes that matches the DOM
 */
  visibleNodes(): FileNode[] {
    const result = [];
    function addExpandedChildren(node: FileNode, expanded: string[]) {
      result.push(node);
      if (expanded.includes(node["id"])) {
        node.children.map((child) => addExpandedChildren(child, expanded));
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected);
    });
    this.dataMapSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionMapModel.selected);
    });
    return result;
  }

  dragStart() {
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }
  dragHover(node: FileFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }
  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  rebuildTreeForData(data: any, key: any) {
    if (this.xmlkey === "xml") {
      this.dataSource.data = data;
      this.expansionModel.selected.forEach((id) => {
        const node = this.treeControl.dataNodes.find((n) => n.id === id);
        this.treeControl.expand(node);
      });
    } else {
      this.dataMapSource.data = data;
      this.expansionMapModel.selected.forEach((id) => {
        const node = this.treeMapControl.dataNodes.find((n) => n.id === id);
        this.treeMapControl.expand(node);

      });
    }
    setTimeout(() => {
      this.checkmappednode()
    })
  }

  enableDivControl(valueInput: any) {
    let valueData;
    if (valueInput.value !== undefined) {
      valueData = valueInput.value;
    } if (valueInput.values !== undefined) {
      valueData = valueInput.values;
    }

    if (valueInput.values === undefined && valueInput.value === undefined) {
      valueData = valueInput;
    }
    this.mappingFormGroup.patchValue({ mappingmethodselected: valueData });
    this.selectedExpressionValue = JSON.parse(JSON.stringify(valueData));
    this.isExistFunction = false;
    this.dynamicJSON = [];

    // this.mapConfigJson["Count"] = this.mapConfigJson["count"];
    // this.mapConfigJson["Alphanumeric"] = this.mapConfigJson["GetAlphaNumericValues"]
    // this.mapConfigJson["Replace"] = this.mapConfigJson["GetReplace"]
    // this.mapConfigJson["Split"] = this.mapConfigJson["GetSplit"]
    // this.mapConfigJson["DownloadFileFromCore"] = this.mapConfigJson["GetDownloadFileFromCore"]
    // this.mapConfigJson["ReadEmbeddedContent"] = this.mapConfigJson["GetEmbeddedContent"]
    // this.mapConfigJson["Mapping"] = this.mapConfigJson["GetMappingValue"]
    // this.mapConfigJson["ConvertDateTime"] = this.mapConfigJson["GetConvertDateTime"]
    // this.mapConfigJson["ConvertDateTimeRoundOff"] = this.mapConfigJson["GetConvertDateTimeRoundOff"]
    // this.mapConfigJson["PdfFromLIS"] = this.mapConfigJson["GetPdf"]
    // this.mapConfigJson["CurrentDateTime"] = this.mapConfigJson["GetCurrentDateTime"]
    // this.mapConfigJson["CurrentDateTimeRoundOff"] = this.mapConfigJson["GetCurrentDateTimeRoundOff"]
    // this.mapConfigJson["RemoveExtraSpace"] = this.mapConfigJson["GetRemoveExtraSpaces"]
    this.selectedExpression = this.mapConfigJson[valueData];
    if (this.selectedExpression !== undefined) {
      if (this.selectedExpression.Displayname === "Concat Node Attributes" || this.selectedExpression.Displayname === "Matching Condition Value") {
        this.screenHeight = window.innerHeight - 350;
      } else {
        this.screenHeight = window.innerHeight - 270;
      }
      this.loadDynamicControl(this.mapConfigJson[valueData]);
      this.expressionselected = "";
      if (valueInput != "") {
        this.conditionparameterarray = [];
      }

      this.generateForm();
      this.sortDynamicJson();

      if (valueData.toLowerCase() === "Other") {
        this.selectedExpressionValue = 'Other';
      } else {
        this.selectedExpressionValue = valueData
      }
    }


  }
  loadDynamicControl(obj) {
    let counter = 0;
    let defualtnumberofparameter = "";
    if (obj["defualtnumberofparameter"]) {
      defualtnumberofparameter = obj["defualtnumberofparameter"];
    }
    if (defualtnumberofparameter != "" && defualtnumberofparameter != undefined) {
      for (var i = 0; i < obj.defualtnumberofparameter; i++) {
        for (const [key, value] of Object.entries(obj["parameter"])) {
          if (value instanceof Object) {
            let isdroppable = false;
            let isVariable = false;
            let isdatefield = false;
            let isroutefield = false;
            let isheaderfield = false;
            let ismessagebodyfield = false;
            if (value["type"].includes("xpath")) {
              isdroppable = true;
            }
            if (value["type"].includes("dateformat")) {
              isdatefield = true;
            }
            if (value["type"].includes("variable")) {
              isVariable = true;
            }
            if (value["type"].includes("routeformat")) {
              isroutefield = true;
            }
            if (value["type"].includes("headerformat")) {
              isheaderfield = true;
            }
            if (value["type"].includes("messagebodyformat")) {
              ismessagebodyfield = true;
            }
            let inputValue: inputField = {
              key: "",
              value: "",
              isdroppable: isdroppable,
              isvariable: isVariable,
              isdatefield: isdatefield,
              isroutefield: isroutefield,
              isheaderfield: isheaderfield,
              ismessagebodyfield: ismessagebodyfield,
              required: true,
              label: "Parameter " + (
                i + 1
              ),
              type: "text",
              placeholder: "Enter the Value",
              order: i + 1,
              condition: [],
              subcondition: [],
              subxpath: ""
            };
            this.dynamicJSON.push(inputValue);
          }
        }
      }
    } else {
      for (const [key, value] of Object.entries(obj["parameter"])) {
        for (var i = counter; i <= counter; i++) {
          if (value instanceof Object) {
            let isdroppable = false;
            let isVariable = false;
            let isdatefield = false;
            let isroutefield = false;
            let isheaderfield = false;
            let ismessagebodyfield = false;
            if (value["type"].includes("xpath")) {
              isdroppable = true;
            }
            if (value["type"].includes("dateformat")) {
              isdatefield = true;
            }
            if (value["type"].includes("variable")) {
              isVariable = true;
            }
            if (value["type"].includes("routeformat")) {
              isroutefield = true;
            }
            if (value["type"].includes("headerformat")) {
              isheaderfield = true;
            }
            if (value["type"].includes("messagebodyformat")) {
              ismessagebodyfield = true;
            }
            let inputValue: inputField = {
              key: "",
              value: "",
              isdroppable: isdroppable,
              isvariable: isVariable,
              isdatefield: isdatefield,
              isroutefield: isroutefield,
              isheaderfield: isheaderfield,
              ismessagebodyfield: ismessagebodyfield,
              required: true,
              label: key,
              type: "text",
              placeholder: "Enter the Value",
              order: i + 1,
              condition: [],
              subcondition: [],
              subxpath: ""
            };
            this.dynamicJSON.push(inputValue);
          } else {
            let inputValue: inputField = {
              key: "",
              value: "",
              isdroppable: false,
              isvariable: false,
              isdatefield: false,
              required: true,
              isroutefield: false,
              isheaderfield: false,
              ismessagebodyfield: false,
              label: key,
              type: "conditionbutton",
              placeholder: "Enter the Value",
              order: i + 1,
              subcondition: [],
              subxpath: "",
              condition: [
                {
                  key: "",
                  value: "",
                  isdroppable: false,
                  required: true,
                  label: "Add Group",
                  type: "button",
                  placeholder: "Enter the Value",
                  order: i + 1,
                  conditions: [],
                  operator: ""
                },
              ]
            };

            this.dynamicJSON.push(inputValue);
          }
        }
      }
    }
    if (this.selectedExpressionValue.toLowerCase() === "other") {
      this.dynamicJSON[0].value = this.selectednode.type
    }
  }
  generateForm() {
    this.dynamicJSON.push(...this.dynamicJSON.splice(this.dynamicJSON.findIndex((v) => v.label.includes("Condition")), 1));

    this.dynamicJSON.forEach((t) => {
      let questions = <FormArray>this.questionFormGroup.controls["questions"];
      questions.push(this.formBuilder.group({
        value: [
          t.value,
          [t.required ? Validators.required : null]
        ]
      }));
    });
  }
  moveToTheEnd(arr, word) {
    arr.map((elem, index) => {
      if (elem.label.includes(word)) {
        arr.splice(index, 1);
        arr.push(elem);
      }
    });
    return arr;
  }
  sortDynamicJson() {
    let newArray = this.dynamicJSON;
    let questions: any = [];
    newArray.forEach((element) => {
      questions.push(element);
    });
    questions.sort((a: any, b: any) => a.order - b.order);
    this.dynamicJSON = questions;
  }
  async getdeploymentconfiguration() {
    var apiname = "api/CoreConfig/GetProcessorUIConfig?agentid=&apikey=";
    var responseconfig = await this.service.getapi(apiname);
    var configs = JSON.parse(responseconfig.responsebody);
    return configs["Expressionmethods"];
  }
  closetryitoutpopup(c: any) {
    c("close modal");
  }
  xpathArray: any[] = [];
  opensubcondition(xpath: any, input) {
    this.xpathArray = [];
    let pas = [];
    let lastatt = "";
    let paths;
    let isPosition = xpath.target.title;
    let subexp = "";
    var inputValue = "";
    if (isPosition === "left") {
      subexp = input.leftsubexp;
      inputValue = input.left;
    }
    if (isPosition === "right") {
      subexp = input.rightsubexp;
      inputValue = input.right;
    }
    if (isPosition == "") {
      subexp = input.subxpath;
      inputValue = input.value;
    }
    if (inputValue != "") {
      if (subexp != "") {
        paths = subexp.split("/");
      } else {
        paths = xpath.target.value.split("/");
      }

      let pat = paths.filter((c: any) => !c.startsWith("@"));
      if (pat.length > 0) {
        if (paths[paths.length - 1].startsWith("@")) {
          lastatt = paths[paths.length - 1];
        }
      }
      pas = pat.length > 0 ? pat : paths;
      for (let i = 0; i < pas.length; i++) {
        let isContainsAttr = false;
        if (pas[i].startsWith("@")) {
          isContainsAttr = true;
        }
        let pats;
        let ps;
        if (pas[i].includes("[")) {
          pats = pas[i].split("[");
          ps = {
            path: pats[0],
            value: pats[1].split("]")[0],
            isOpen: true,
            isContainsAttr: isContainsAttr
          };
        } else {
          ps = {
            path: pas[i],
            value: "",
            isOpen: true,
            isContainsAttr: isContainsAttr
          };
        }

        this.xpathArray.push(ps);
      }
      if (this.xpathArray.length > 0) {
        let poss: any = "";
        if (isPosition === "left") {
          poss = input.leftsubcondition;
        }
        if (isPosition === "right") {
          poss = input.rightsubcondition;
        }
        if (isPosition == "") {
          poss = input.subcondition;
        }
        let path = {
          xpath: this.xpathArray,
          xmldata: this.backXMLData,
          input: poss,
          backupxpath: JSON.parse(JSON.stringify(this.xpathArray))
        };
        this.counterService.backupdatainput = {};
        this.counterService.count = 0;
        this.counterService.instanceList = [];
        const dialogRef = this.dialog.open(ConditionComponent, {
          disableClose: true,
          hasBackdrop: false,
          position: {
            right: "0"
          },
          width: "100%",
          data: path
        });
        dialogRef.afterClosed().toPromise().then((result) => {
          if (result) {
            let val = dialogRef._containerInstance._config.data;
            if (val.xpath) {
              let value = Array.prototype.map.call(val.xpath, function (item) {
                if (item.value !== "") {
                  return item.path + "[" + item.value + "]";
                } else {
                  return item.path + item.value;
                }
              }).join("/");
              if (lastatt !== "") {
                value = value + "/" + lastatt;
              }
              if (isPosition === "left") {
                input.left = value;
              }
              if (isPosition === "right") {
                input.right = value;
              }
              if (isPosition == "") {
                input.value = value;
              }
            }
            if (val.backupxpath) {
              let subxpath = Array.prototype.map.call(val.backupxpath, function (item) {
                if (item.value !== "") {
                  return item.path + "[" + item.value + "]";
                } else {
                  return item.path + item.value;
                }
              }).join("/");
              if (lastatt !== "") {
                subxpath = subxpath + "/" + lastatt;
              }

              if (isPosition === "left") {
                input.leftsubexp = subxpath;
              }
              if (isPosition === "right") {
                input.rightsubexp = subxpath;
              }
              if (isPosition == "") {
                input.subxpath = subxpath;
              }
            }
          }
        });
      }
    } else {
      if (isPosition === "left") {
        input.leftsubcondition = [];
        input.leftsubexp = "";
      }
      if (isPosition === "right") {
        input.rightsubcondition = [];
        input.rightsubexp = "";
      }
      if (isPosition == "") {
        input.subcondition = [];
        input.subxpath = "";
      }
      this.toastr.warning("Please enter the value", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });

    }
  }


  onDragStarted(event: CdkDragEnd): void {
    event.source.element.nativeElement.style.zIndex = this.zIndexSerial + "";
    this.zIndexSerial = this.zIndexSerial + 1;
  }



  addcondition(data: any, array: any, index: any): void {
    let selectedCondition = data.value;

    if (selectedCondition != "select") {
      array[index].conditiontype = selectedCondition;
    }

    if (selectedCondition) {
      if (selectedCondition != "count" && selectedCondition != "select") {
        if (selectedCondition.toLowerCase() == "starts with" || selectedCondition.toLowerCase() == "not starts with" || selectedCondition.toLowerCase() == "contains" || selectedCondition.toLowerCase() == "not contains") {
          array[index].startsWithorContainsCondition = true;
          array[index].notCondition = false;
        } else {
          array[index].notCondition = true;
          array[index].startsWithorContainsCondition = false;
        }
      } else {
        array[index].notCondition = false;
        array[index].startsWithorContainsCondition = false;
      }
    }
  }
  addDateFormat(e: any, input) {
    input.value = e.target.value;
  }

  showNodeExpression(node: any) {
    this.clearflags();
    this.enableNodeControl = true;
    this.ExpressionDivContent = true;
    this._globalVariableadded = false;
    this.selectednode = node;
    const ancestors = this.getAncestors(this.dataMapSource.data, node.GUID);
    this.backupParentNode = "";
    // this.treeControl.collapse(ancestors[0]);
    this.backupParentNode = ancestors[ancestors.length - 1];
    let path;
    path = this.getPath(this.backMapData, node.GUID, "");
    for (let i = 0; i < path.length; i++) {
      if (path[i] === "attr" && node.isNode == "Child") {
        path.splice(i, 1);
        path[path.length - 1] = "@" + node.filename;
      } else if (path[i] === "attr" && node.isNode == "Parent") {
        path.splice(i, 1);
        path.pop();
        path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
      }
    }
    if (path.length > 0) {
      this.removeuniq(path)
      // path = path.join("/");
    }

    this.SelectedAttribute = path;
    let guid;
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    var nodeformat = this.selectednode.filename + "|" + guid;
    if (this.resultJson) {
      this.getNodeBackUpdata(this.resultJson, nodeformat);
    }
  }


  changeValue(item, row) {
    row.value = item;
    if (row.isdatefield) {
      row.isdatefield = false;
    }
  }
  isAddedRepeatableNode(input) {
    if (input.checked) {
      this.repetableNode = true;
    } else {
      this.repetableNode = false;
    }
  }
  addRepeatable() {
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    this.recurrepeatble(this.backMapData, this.repeatablepath, this.selectednode.filename, guid);

    this.addedRepeatableNode = true;
    this.toastr.success("Added Repeatablenode for " + this.selectednode.filename, "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right"
    });
    let obj = {
      Added: true,
      Value: this.repeatablepath
    };
    this.saveNodeconfiguration(guid, "RepeatableNode", obj);
    this.xmlkey = "map";
    this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
    this.dataMapSource;
  }
  saveNodeconfiguration(guid, attrname, obj) {
    var nodeformat = this.selectednode.filename + "|" + guid;
    var isfoundnode = false;
    var isfoundnodeconfiguration = false;
    var isEmpty = false;
    var addkeys = () => {
      var node = this.createJson(nodeformat);
      var nodeconfiguration = this.createJson("NodeConfiguration");

      var key = this.createJson(attrname);
      key[attrname] = obj;
      Object.assign(nodeconfiguration["NodeConfiguration"], key);
      Object.assign(node[nodeformat], nodeconfiguration);
      Object.assign(this.resultJson, node);
    };
    if (!this.resultJson) {
      this.resultJson = {};
      addkeys();
      isEmpty = true;
    } else {
      for (let [key, value] of Object.entries(this.resultJson)) {
        if (key == nodeformat) {
          isfoundnode = true;
          if (value["NodeConfiguration"] != undefined) {
            isfoundnodeconfiguration = true;
          }
          break;
        }
      }
      if (isfoundnode && isfoundnodeconfiguration && !isEmpty) {
        if (this.resultJson[nodeformat]["NodeConfiguration"][attrname] == undefined) {
          var key = this.createJson(attrname);
          key[attrname] = obj;
          Object.assign(this.resultJson[nodeformat]["NodeConfiguration"], key);
        } else {
          delete this.resultJson[nodeformat]["NodeConfiguration"][attrname];
          var key = this.createJson(attrname);
          key[attrname] = obj;
          Object.assign(this.resultJson[nodeformat]["NodeConfiguration"], key);
        }
      } else if (!isfoundnode && !isfoundnodeconfiguration && !isEmpty) {
        addkeys();
      }
    }
  }


  removeRepeatable() {
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    this.recurremoverepeatble(this.backMapData, this.repeatablepath, this.selectednode.filename, guid);

    this.toastr.success("Removed Repeatablenode for " + this.selectednode.filename, "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right"
    });
    var nodeformat = this.selectednode.filename + "|" + guid;
    delete this.resultJson[nodeformat]["NodeConfiguration"]["RepeatableNode"];
    this.repeatablepath = "";
    this.repetableNode = false;
    this.addedRepeatableNode = false;
    this.xmlkey = "map";
    this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
  }
  recurremoverepeatble(obj, input, nodename, guid) {
    for (const [key, value] of Object.entries(obj)) {
      if (key.split('_')[0] === nodename && value[0]["attr"]["_NodeIdentifier"] == guid) {
        if (typeof value == "object") {
          if (value[0].hasOwnProperty("attr")) {
            delete value[0]["attr"]["_RepeatableNode"];
          }
        }
        this.addRepeatablenodepath(value[0], input);
        break;
      } else if (typeof value == "object") {
        this.recurremoverepeatble(value, input, nodename, guid);
      }
    }
  }

  addNodeCondition() {
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    this.conditionBuilder(this.nodeConditionItem);
    if (this.conditionresult != "") {
      this.recurcondition(this.backMapData, this.conditionresult, this.selectednode.filename, "_AddCondition", guid);
      this.xmlkey = "map";
      this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
      this.addedNodeCondition = true;
      this.toastr.success("Added Condition for " + this.selectednode.filename, "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
      var obj = {
        Added: true,
        JSON: JSON.stringify(this.nodeConditionItem)
      };
      this.saveNodeconfiguration(guid, "AddCondition", obj);
    }
  }
  removeNodeCondition() {
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    this.removerecurcondition(this.backMapData, this.selectednode.filename, guid, "_AddCondition");
    this.xmlkey = "map";
    this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
    this.toastr.success("Removed Condition for " + this.selectednode.filename, "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right"
    });
    var nodeformat = this.selectednode.filename + "|" + guid;
    delete this.resultJson[nodeformat]["NodeConfiguration"]["AddCondition"];
    this.nodeCondition = false;
    this.nodeConditionItem = [];
    this.addedNodeCondition = false;
  }


  isAddedNodeCondition(input) {
    if (input.checked) {
      this.nodeCondition = true;
      this.nodeConditionItem = [];
      let val: ConditionBuilder = {
        key: "",
        value: "",
        isdroppable: true,
        required: true,
        label: "Add Group",
        type: "button",
        placeholder: "Enter the Value",
        enablegroup: true,
        order: 1,
        conditions: [],
        operator: ""
      };
      this.nodeConditionItem.push(val);
    } else {
      this.nodeCondition = false;
    }
  }

  isAddedNodeVariableExpression(input, value) {
    if (input.checked) {
      this.NodeMappingMethodSelected = true;
      value.disabled = true;
    } else {
      this.NodeMappingMethodSelected = false;
      this.dynamicJSON = [];
      value.disabled = false;
    }
  }
  isAddedNodeVariable(input) {
    if (input.checked) {
      this.dynamicJSON = [];
      this.nodeVariable = true;
    } else {
      this.nodeVariable = false;
      this.NodeMappingMethodSelected = false;
      this.dynamicJSON = [];
    }
  }
  addNodeVariableExpression(input) {
    let result = this.generateresult();
    if (result) {
      input.value = result;
      input.disabled = false;
      this.nodejson = [];
      this.nodejson.push(...this.dynamicJSON);
      this.dynamicJSON = [];
      this.NodeMappingMethodSelected = false;
    }
  }
  isAddedNodeSnippet(input) {
    if (input.checked) {
      this.nodeSnippet = true;
    } else {
      this.nodeSnippet = false;
    }
  }
  closeDivContent() {
    this.ExpressionDivContent = false;
    this.clearflags();
    this.dynamicJSON = [];
    this.selectedExpression = "";
    this.selectedExpressionValue = "";
    this.screenHeight = window.innerHeight - 161;
  }
  addNodeVariable() {
    if (this.nodevariablename != "" && this.nodevariablevalue != "") {
      let guid = "";
      if (this._globalVariableadded) {
        if (this.variableDropShow.length > 0 && this.variableDropShow.findIndex((v) => v === "$" + this.nodevariablename) >= 0) {
          this.toastr.warning("Variable name already exists", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right"
          });
        } else {
          this.variableDropShow.push("$" + this.nodevariablename);
          this.addglobalvariabletotree(this.nodevariablename, this.nodevariablevalue);
          this.toastr.success("Added Variable", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right"
          });
        }
        this.nodevariablename = "";
        this.nodevariablevalue = "";
      } else {
        this.backupParentNode.children.forEach((ele) => {
          if (ele.filename == "_NodeIdentifier") {
            guid = ele.type;
          }
        });
        var variable = this.nodevariablename + ":" + this.nodevariablevalue;
        this.recurcondition(this.backMapData, variable, this.selectednode.filename, "_Variable", guid);
        this.database.initialize(JSON.stringify(this.backMapData), "map");
        this.toastr.success("Added Variable for " + this.selectednode.filename, "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
        let obj = {
          Name: this.nodevariablename,
          Value: this.nodevariablevalue,
          JSON: JSON.stringify(this.nodejson)
        };
        this.saveNodeconfiguration(guid, "NodeVariable", obj);
        this.nodevariablename = "";
        this.nodevariablevalue = "";
      }
    }
  }


  SaveSubCondition() {
    let xpath = "";
    let count = 1;
    this.xpathArray.forEach((ele) => {
      if (ele.value == "") {
        xpath += ele.path;
      } else {
        xpath += ele.path + "[" + ele.value + "]";
      }
      if (count > 1 && count < this.xpathArray.length) {
        xpath += "/";
      }
    });
  }
  addSubConditionValue(array, input) {
    this.conditionBuilder(array);
    if (this.conditionresult != "") {
      input.value = this.conditionresult;
    }
  }
  closeSubCondition(input: any) {
    input.isOpen = false;
  }

  _globalVariableadded = false;
  addvariable(node) {
    this._globalVariableadded = true;
    this.ExpressionDivContent = true;
    this.enableNodeControl = false;
    this.selectednode = node;
    this.SelectedAttribute = []
    const ancestors = this.getAncestors(this.dataMapSource.data, node.GUID);
    this.backupParentNode = "";
    // this.treeControl.collapse(ancestors[0]);
    this.backupParentNode = ancestors[ancestors.length - 1];
  }

  addsnippetaschild() {
    this.ngxUiLoaderService.start();
    let xmlobj = {
      xml: this.snippetxml,
      operation: "add"
    }
    this.service.XmlModification(xmlobj).subscribe(data => {
      if (data) {
        let parsers = new xml2js.Parser({ strict: true, trim: true,  attrkey: "attr" });
        parsers.parseString(data.responsebody, (err, results) => {
          if (results) {
            this.addguid(results);
            let guid;
            this.backupParentNode.children.forEach((ele) => {
              if (ele.filename == "_NodeIdentifier") {
                guid = ele.type;
              }
            });
            this.addsnippetxml(this.backMapData, results, this.selectednode.filename, guid);
            this.xmlkey = "map";
            this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
            this.toastr.success("Added Snippet to " + this.selectednode.filename, "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
            let obj = {
              Added: true,
              Value: this.snippetxml
            };
            this.saveNodeconfiguration(guid, "Snippet", obj);
          }
        })
      }
    },error=>{
      this.ngxUiLoaderService.stop();
    });
    this.ngxUiLoaderService.stop();
  }

  checkdynamic() {
    let label = false;
    if (this.dynamicJSON.length > 0) {
      for (let i = 0; i < this.dynamicJSON.length; i++) {
        if (this.dynamicJSON[i]['value'] === "") {
          label = true;
        }
        if (this.dynamicJSON[i].condition !== undefined) {
          if (this.dynamicJSON[i].condition.length > 0) {
            for (let j = 0; j < this.dynamicJSON[i].condition.length; j++) {
              for (let k = 0; k < this.dynamicJSON[i].condition[j].conditions.length; k++) {
                if (this.dynamicJSON[i].condition[j].conditions[k].left == '' || this.dynamicJSON[i].condition[j].conditions[k].oper == '' || this.dynamicJSON[i].condition[j].conditions[k].right == '')
                  label = true;
                this.dynamicJSON[i]['value'] = 'true';
              }
            }
          }
        }
      }
      return label;
    }
  }
  // Reusabel methods
  addguid(objj: any) {
    if (objj !== undefined) {
      var attrname = "_NodeIdentifier";
      for (const [key, value] of Object.entries(objj)) {
        if (key == "text" && typeof value == "object") {
          delete objj[key];
        }
        if (typeof value == "object") { // Adding index for repeatable node
          if (value.hasOwnProperty("attr")) {
            if (value["attr"]._NodeIdentifier == undefined) {
              value["attr"][attrname] = uuidv4();
            }
          } else {
            if (!value.hasOwnProperty("_NodeIdentifier") && !value.hasOwnProperty("attr")) {
              var guid = this.createJson(attrname);
              guid[attrname] = uuidv4();
              value["attr"] = guid;
            }
          }
        }
        if (typeof value == "object") {
          this.addguid(value);
        }
      }
    }
  }

  addmethodstodropdown(mapConfigJson: any) {
    this.dropList = [];
    for (const [key, value] of Object.entries(mapConfigJson)) {
      if (key.toLowerCase() !== '#condition')
        var obj = {
          displayname: value["Displayname"],
          values: key
        };

      this.dropList.push(obj);
    }
    this.dropList = this.dropList.sort((a, b) => a.displayname !== b.displayname ? a.displayname < b.displayname ? -1 : 1 : 0);
  }

  renameNode(node, newNodeName) {
    const newNode = node.ownerDocument.createElement(newNodeName);
    Array.from(node.attributes).forEach(attr => newNode.setAttribute(attr['localName'], attr['']));
    Array.from(node.childNodes).forEach(childNode => newNode.appendChild(childNode));
    node.parentElement.insertBefore(newNode, node);
    node.parentElement.removeChild(node);
  }

  addsnippetxml(obj: any, spObj: any, nodename: any, guid) {
    for (const [key, value] of Object.entries(obj)) {
      if (key.split('_')[0] === nodename && value[0]["attr"]["_NodeIdentifier"] === guid) {
        if (typeof value[0] === 'object') {
          Object.assign(value[0], spObj);
          break;
        }
      } else if (typeof value == "object") {
        this.addsnippetxml(value, spObj, nodename, guid);
      }
    }
  }
  version: string = '';
  onVersionChange(eventvalue) {
    this.versionDetails.forEach((ele) => {
      if (ele.version == eventvalue.value.replace('Live', '').trim()) {
        this.ngxUiLoaderService.start();
        let obj = {
          xml: ele.json.processorconfig.xslconfig.mappedxml,
          operation: 'add'
        }
        let tid=ele['json']['processorconfig']['xslconfig']['template'];
        let selectedtemplatedata=this.templatelist.find(element=> element['id'].toString()===tid)
        if(selectedtemplatedata!=''&& selectedtemplatedata!=undefined ){
          this.makelhstree(selectedtemplatedata['templatexml']);
          this.selectedTemplate=selectedtemplatedata['name'];
        }else{
          this.toastr.error("template not found", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right"
          });
        }
        this.service.XmlModification(obj).subscribe(async data => {
          if (data) {
            let parsers = new xml2js.Parser({ strict: true, trim: true, attrkey: "attr" });
            parsers.parseString(data.responsebody, (err, results) => {
              if (results) {
                this.backMapData = results;
                this.versionStatus = ele.versionstatus;
                this.addguid(this.backMapData);
                let parseData = JSON.stringify(results);
                this.getVariable(ele.json.processorconfig.xslconfig.mappedxml);
                this.xmlkey = 'map';
                this.database.initialize(parseData, this.xmlkey);
                this.treeMapControl.expandAll();
                this.showMapcard = true;
                this.versionStatus = ele.versionstatus;
                if (ele.json.processorconfig.xslconfig.resultjson) {
                  this.resultJson = JSON.parse(ele.json.processorconfig.xslconfig.resultjson);
                } else {
                  this.resultJson = "";
                }
                this.ngxUiLoaderService.stop();
              }
            })

          }
        },error=>{
          this.ngxUiLoaderService.stop();
        })
      }
    });
  }
  openCompare() {
    this.ngxUiLoaderService.start()
    this.getFileConfig(this.routeid, this.processorid).then(data => {
      if (data) {
        let jsonData = JSON.parse(data[0]["json"]);
        let vaconfigdata = jsonData.processorconfig["vaconfig"];
        let compare = {
          RouteIdtry: this.routeid,
          ProcessorIdtry: this.processorid,
          ProcessorDisplayNametry: this.ProcessorName,
          RouteDisplayNametry: this.headerRoute,
          Processorapikey: this.processorapikey,
          Popupflag: true,
          isFileUpload: vaconfigdata.isfileupload == 0 ? false : true,
          isfiledownload: vaconfigdata.isfiledownload == 0 ? false : true,
          showVersionDetails: true,
          version: this.version.replace('Live', '').trim(),
          showPush: true,
          versionDetails: this.versionDetails,
          sendAgentid: this.inputDataInModalComponent.routedetails.SEGUID
        };
        const modalRef = this.modalService.open(CompareComponent, {
          windowClass: "myCustomModalClass",
          backdrop: "static",
          keyboard: false
        });
        modalRef.componentInstance.inputDataInModalComponent = compare;
        modalRef.result.then(() => { });
      }
    });

  }
  openVersionNotes(isRejected: any, updateVersionstatus: any, modal) {
    if (isRejected) {
      this.isRejected = isRejected;
    }
    else if (updateVersionstatus == '' && !isRejected) {
      this.mappingNotescheck = true;
    }
    this.getMappingnotes();
    this.ngxUiLoaderService.start();
    this.modalRefpushtoQC = this.modalService.open(modal,
      { windowClass: "mappingnote", backdrop: 'static', keyboard: false });
    this.modalRefpushtoQC.result.then(() => { });

    this.ngxUiLoaderService.stop();
  }
  UpdateVersionStatus(modal) {
    let apiname = 'api/CoreConfig/UpdateVersionStatusCoreConfig';
    this.ngxUiLoaderService.start();
    this.isapproverejectprocesscompleted = false;
    let configid = '';
    let status = '';
    let auditMessage = "";
    let configguid = ''
    this.versionDetails.forEach((element) => {
      if (element.version == this.version.replace('Live', '').trim()) {
        configid = element.coreconfigid;
        configguid = element.configid;
      }
    });
    if (!this.isRejected) {
      if (this.versionStatus === '100') {
        status = '101';
        auditMessage = 'New Mapping Version is Sent for QC-' + this.version.replace('Live', '').trim();
      } else if (this.versionStatus == '101' && this.pagerolepermisions.qcapprove) {
        status = "102";
        auditMessage = "New Mapping Version is Sent for Manager's Approval-" + this.version.replace('Live', '').trim();
      } else if (this.versionStatus == '101' && this.pagerolepermisions.liveapprove) {
        status = "103";
        auditMessage = "New Mapping Version is Approved-" + this.version.replace('Live', '').trim();
      } else if (this.versionStatus == '102') {
        status = "103";
        auditMessage = "New Mapping Version is Approved-" + this.version.replace('Live', '').trim();
      }
    } else {
      status = "100";
      auditMessage = this.pagerolepermisions.liveapprove ? "New Mapping Version is Rejected-" + this.version.replace('Live', '').trim() : "New Mapping Version is Rejected by QC-" + this.version.replace('Live', '').trim();
    }

    let updateStatus = {
      routeid: this.inputDataInModalComponent.routedetails.id,
      coreconfigid: configid,
      versionstatus: status,
      processorid: this.processorid,
      processorapikey: this.processorapikey,
      configid: configguid,
      modifiedby: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
    }
    this.service.searchpostapi(apiname, updateStatus).then(responsehub => {

      this.modalRefpushtoQC.close()
      this.ngxUiLoaderService.stop();
      if (responsehub["status"] == "success") {
        let message = '';
        if (!this.isRejected) {
          if (this.versionStatus == '100') {
            message = "Sent to QC Verification";
          } else if (this.versionStatus == '101' && this.pagerolepermisions.qcapprove) {
            message = "Sent for Manager Approval";
            this.isapproverejectprocesscompleted = true;
          } else if (this.versionStatus == '101' && this.pagerolepermisions.liveapprove) {
            message = "Version updated as Live";
            this.isapproverejectprocesscompleted = true;
          } else if (this.versionStatus == '102') {
            message = "Version updated as Live";
            this.isapproverejectprocesscompleted = true;
          }
        } else {
          message = "Version is Rejected";
          this.isapproverejectprocesscompleted = true;
          this.isRejected = false;
        }
        if (this.versionStatus) {
          this.service.auditsave("Update", "", auditMessage, "Pipeline", this.inputDataInModalComponent.routedetails.UniqueID,'Version Status Updated Successfully').then(response => {
            if (response) {

            }
          })
          this.service.auditsave("Update", "", responsehub["statusmessage"], "Pipeline", this.inputDataInModalComponent.routedetails.UniqueID,'Version Status Updated Successfully').then(response => {
            if (response) {

            }
          })

        }
        this.toastr.success(message, "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
        if (this.inputDataInModalComponent.routedetails) {
          let obj = {
            id: this.inputDataInModalComponent.routedetails.id,
            PAID: this.inputDataInModalComponent.processorid
          }
          if ((this.versionStatus == '102' || (this.versionStatus == '101' && this.pagerolepermisions.liveapprove)) && !this.isRejected) {
            this.getVersionDetails(obj, true, false);
          } else {
            this.getVersionDetails(obj, false, false);
          }
        }
        this.isVersionModified = false;
        this.ngxUiLoaderService.stop();
      } else {
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
        this.ngxUiLoaderService.stop();
      }
    },error=>{
      this.ngxUiLoaderService.stop();
    });
  }
  convertToInt(versionno: any) {
    return parseInt(versionno);
  }
  clearflags() {
    this.enableNodeControl = false;
    this._globalVariableadded = false;
    this.nodeSnippet = false;
    this.nodeVariable = false;
    this.NodeMappingMethodSelected = false;
    this.nodeCondition = false;
    this.nodeConditionItem = [];
    this.addedNodeCondition = false;
    this.addedRepeatableNode = false;
    this.repetableNode = false;
    this.snippetxml = "";
    this.nodejson = [];
    this.nodevariablename = "";
    this.nodevariablevalue = "";
    this.repeatablepath = "";
  }

  variablerecurcondition(obj, input, nodename, guid, attrname) {
    for (const [key, value] of Object.entries(obj)) {
      if (key.split('_')[0] === nodename && value[0]["attr"]["_NodeIdentifier"] == guid) {
        if (typeof value == "object") {
          var node = this.createJson(attrname);
          var valueattr = this.createJson("value");
          valueattr["value"] = input;
          var attr = this.createJson("attr");
          attr["attr"] = valueattr;
          node[attrname] = attr;
          Object.assign(value[0], node);
        }
        break;
      } else if (typeof value == "object") {
        this.variablerecurcondition(value, input, nodename, guid, attrname);
      }
    }
  }

  addglobalvariabletotree(name: any, value: any) {
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    this.variablerecurcondition(this.backMapData, value, this.selectednode.filename, guid, name);
    this.xmlkey = "map";
    this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
  }

  conditionBuilder(array) {
    this.conditionresult = "";
    var parentandOr = "";
    parentandOr = array[0].operator;
    let addParentCondition = false;
    if (array[0].conditions.length > 0) {
      addParentCondition = true;
    } else if (array.length > 2) {
      addParentCondition = true;
    }
    var count = 1;
    array.forEach((element) => {
      let andOr = "";
      andOr = element.operator;
      let isAddedgroup = false;
      if (element.hasOwnProperty("isAddedgroup")) {
        isAddedgroup = element.isAddedgroup;
      }
      this.generateCondition(element.conditions, andOr, isAddedgroup);
      if (addParentCondition && count < array.length) {
        this.conditionresult += " " + parentandOr + " ";
      }
      count++;
    });
  }

  recurcondition(obj, input, nodename, attrname, guid) {
    for (const [key, value] of Object.entries(obj)) {
      if (key.split('_')[0] == nodename && value[0]["attr"]["_NodeIdentifier"] == guid) {
        if (typeof value == "object") {
          if (value[0].hasOwnProperty("attr")) {
            value[0]["attr"][attrname] = input;
          } else {
            let attrobj = {};
            let repeatablenode = this.createJson(attrname);
            repeatablenode[attrname] = input;
            let attrnode = this.createJson("attr");
            attrnode["attr"] = repeatablenode;
            Object.assign(attrobj, attrnode);
          }
        }
        break;
      } else if (typeof value == "object") {
        this.recurcondition(value, input, nodename, attrname, guid);
      }
    }
  }

  removerecurcondition(obj, nodename, guid, attrname) {
    for (const [key, value] of Object.entries(obj)) {
      if (key.split('_')[0] === nodename && value[0]["attr"]["_NodeIdentifier"] == guid) {
        if (typeof value == "object") {
          if (value.hasOwnProperty("attr")) {
            delete value[0]["attr"][attrname];
          }
        }
        break;
      } else if (typeof value == "object") {
        this.removerecurcondition(value, nodename, guid, attrname);
      }
    }
  }

  addRepeatablenodepath(Obj: Object, input: any) {
    for (const [key, value] of Object.entries(Obj)) {
      if (key == "attr") {
        let valueObj = value;
        for (let [key, attrvalue] of Object.entries(valueObj)) {
          if (key != "_NodeIdentifier" && !valueObj[key].toLowerCase().startsWith("case") && !valueObj[key].startsWith("hl7message") && !valueObj[key].startsWith("_func") && !valueObj[key].startsWith("_xsl") && !valueObj[key].startsWith("$")) {
            valueObj[key] = input + "/" + attrvalue;
          }
        }
      } else {
        if (typeof value == "object") {
          this.addRepeatablenodepath(value, input);
        }
      }
    }
  }
  replaceRepeatablenodepath(Obj: Object, input: any) {
    for (const [key, value] of Object.entries(Obj)) {
      if (key == "attr") {
        let valueObj = value;
        for (let [key, attrvalue] of Object.entries(valueObj)) {
          if (attrvalue.toString().includes(input) && key != "_NodeIdentifier") {
            valueObj[key] = attrvalue.toString().split(input + "/").join("");
          }
        }
      } else {
        if (typeof value == "object") {
          this.replaceRepeatablenodepath(value, input);
        }
      }
    }
  }
  recurrepeatble(obj, input, nodename, guid) {
    for (const [key, value] of Object.entries(obj)) {
      if (key.split('_')[0] === nodename && value[0]["attr"]["_NodeIdentifier"] == guid) {
        if (typeof value == "object") {
          if (value[0].hasOwnProperty("attr")) {
            value[0]["attr"]["_RepeatableNode"] = input;
          } else {
            let attrobj = {};
            let repeatablenode = this.createJson("_RepeatableNode");
            repeatablenode["_RepeatableNode"] = input;
            let attrnode = this.createJson("attr");
            attrnode["attr"] = repeatablenode;
            Object.assign(attrobj, attrnode);
            Object.assign(value, attrobj);
          }
        }
        this.replaceRepeatablenodepath(value[0], input);
        break;
      } else if (typeof value == "object") {
        this.recurrepeatble(value, input, nodename, guid);
      }
    }
  }

  loadcontrolsforNode(obj: any) {
    if (obj.RepeatableNode != undefined) {
      this.repetableNode = true;
      this.addedRepeatableNode = true;
      this.repeatablepath = obj.RepeatableNode.Value;
    }
    if (obj.AddCondition != undefined) {
      this.nodeCondition = true;
      this.nodeConditionItem = JSON.parse(obj.AddCondition.JSON) as ConditionBuilder[];
      this.addedNodeCondition = true;
    }
    if (obj.Snippet != undefined) {
      this.nodeSnippet = true;
      this.snippetxml = obj.Value;
    }
    if (obj.NodeVariable != undefined) {
      this.dynamicJSON = JSON.parse(obj.NodeVariable.JSON) as inputField[];
      this.nodevariablename = obj.NodeVariable.Name;
      this.nodevariablevalue = obj.NodeVariable.Value;
      if (this.dynamicJSON.length > 0) {
        this.nodeVariable = true;
        this.NodeMappingMethodSelected = true;
      }
    }
  }

  getNodeBackUpdata(obj: any, nodeformat) {
    for (var [key, value] of Object.entries(obj)) {
      if (key == nodeformat) {
        if (value["NodeConfiguration"] != undefined) {
          this.loadcontrolsforNode(value["NodeConfiguration"]);
        }
      }
    }
  }

  removeuniq(array) {
    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].includes("_")) {
          let nameuniq = array[i].split("_")[1]
          if (nameuniq !== "AddCondition" && nameuniq !== "RepeatableNode" && nameuniq !== "Variable" && nameuniq !== "NodeIdentifier") {
            array[i] = array[i].split("_")[0]
          }
        }
      }
    }
  }

  getPath(obj, value, path) {
    if (typeof obj !== "object") {
      return false;
    }
    for (var key in obj) {
      if (obj[key]) {
        var t = path;
        var v = obj[key];
        var newPath = path ? path.slice() : [];
        newPath.push(key);
        if (v === value) {
          return newPath;
        } else if (typeof v !== "object") {
          newPath = t;
        }
        var res = this.getPath(v, value, newPath);
        if (res) {
          return res;
        }
      }
    }
    return false;
  }

  parseXML(text) {
    let doc;
    let parser = new DOMParser();
    doc = parser.parseFromString(text, "text/xml");
    return doc;
  }

  createJson(objectname) {
    let json = `{"${objectname}":{}}`;
    return JSON.parse(json);
  }

  recompose(obj, string, val) {
    if (string) {
      let parts;
      if (typeof string === "string") {
        parts = string.split("/");
      } else {
        parts = string;
      }
      var newObj = obj[parts[0]];
      if (newObj !== undefined && parts[1] !== "Node_Text") {
        if (parts[1]) {
          parts.splice(0, 1);
          var newString = parts.join("/");
          return this.recompose(newObj, newString, val);
        }
       
        if (typeof val === "object") {
          obj[parts[0]] = val.join("/");
        } else {
          obj[parts[0]] = val;
        }
      } else {
        if (obj != undefined) {
          for (var [key, value] of Object.entries(obj)) {
            if (value[0] !== undefined) {
              if (typeof value[0] == "string" && key.split("_")[0] === this.parentInnerChildName) {
                value[0] = val
              }
            }
          }
        }
      }
      return obj;
    }
  }


  findPropPath(obj, name) {
    for (var prop in obj) {
      if (prop == name) {
        return name;
      } else if (typeof obj[prop] == "object") {
        var result = this.findPropPath(obj[prop], name);
        if (result) {
          return prop + "/" + result;
        }
      }
    }
    return null; // Not strictly needed, but good style
  }

  getFocusList(value: string) {
    this.isOldValue = value
    this.searchValue = value
  }

  doFilter(value: string) {
    this.suggestionListFilter = this.suggestionList.filter(joke => joke['new'].toLowerCase().includes(value.toLowerCase()))
  }
  optionSelectPath(event: any, input: object) {

    if (event) {
      input['value'] = event
    }
  }
  keyupSuggestion(event, text, ) {
    // if(this.iskeyValue == text.value){
    //   this.isOldValue ="";
    // }
    if (this.isOldValue.includes("/")) {
      let splitdata = this.isOldValue.split("/")
      if (splitdata.length > 0) {
        splitdata.pop()
      }
      if (splitdata.length > 1) {
        this.isOldValue = splitdata.join("/")
        this.isOldValue = this.isOldValue + '/'
      } else {
        this.isOldValue = splitdata[0] + "/"
      }

    } else {
      this.isOldValue = ""
    }
    if (this.isOldValue == "") {
      this.isOldValue = text.value + "/"
      // this.isOldValue   = text.value
    } else {
      if (text.value.includes("@")) {
        this.isOldValue = this.isOldValue + text.value
      } else {
        this.isOldValue = this.isOldValue + text.value + "/"
        // this.isOldValue = this.isOldValue+ text.value
      }
    }
    text.value = JSON.parse(JSON.stringify(this.isOldValue))
  }

  // keybackspace(event, text){
  //     this.isOldValue =text.value;
  //     this.searchValue =text.value;
  // }
  modifiedValue(value) {
    this.isOldValue = value;
    this.searchValue = value;
  }

  selectsuggestion(objj, text, item) {
    // if(this.iskeyValue == text.value){
    //   this.isOldValue ="";
    //   this.searchValue ="";
    // }
    if (this.searchValue.includes("/")) {
      let splitdata = this.searchValue.split("/")
      if (splitdata.length > 0) {
        splitdata.pop()
      }
      if (splitdata.length > 1) {
        this.searchValue = splitdata.join("/")
        this.searchValue = this.searchValue + '/'
      } else {
        this.searchValue = splitdata[0] + "/"
      }

    } else {
      this.searchValue = ""
    }
    if (this.searchValue == "") {
      this.searchValue = text.value + "/"
      // this.searchValue   = text.value
    } else {
      if (text.value.includes("@")) {
        this.searchValue = this.searchValue + text.value
      } else {
        this.searchValue = this.searchValue + text.value + "/"
        // this.searchValue = this.searchValue+ text.value
      }
    }
    // if(text.value.includes("/")){
    //   let splitdata = text.value.split("/")
    //   if(splitdata.length > 0){
    //     for(let i = 0; i < splitdata.length; i++){
    //       if(splitdata[i] ==this.iskeyValue ){
    //         this.isOldValue ="";
    //       }
    //       if(splitdata[i] == item.isname){
    //         splitdata.splice(i,1)
    //       }
    //     }
    //   }
    //   if(item != undefined){
    //     if(item.ischild){
    //     splitdata[splitdata.length - 1] =   item.isname
    //     }
    //   }else{
    //   splitdata[splitdata.length - 1] = item.isname + "/"
    //   }
    //   text.value = splitdata.join("/")
    // }else{
    // if(item != undefined){
    //   if(item.ischild){
    //   text.value =  item.isname
    //   }else{
    //     text.value =  item.isname + "/"
    //   }
    // }else{
    //   text.value =  item.isname + "/"
    // }
    // }
    setTimeout(() => {
      this.isOldValue = this.searchValue
      // this.isOldValue = this.isOldValue+ text.value
      text.value = JSON.parse(JSON.stringify(this.isOldValue))
      this.searchValue = text.value
    })
  }



  getSearchDataList(jsonData) {
    for (const [keysso, valueso] of Object.entries(jsonData)) {
      if (keysso === "attr") {
        for (const [keyssoe, valuesoe] of Object.entries(valueso)) {
          let childobj = {
            ischild: true,
            isname: "@" + keyssoe
          }
          this.suggestionList.push(childobj);
        }
      } else {
        let parentsobj = {
          ischild: false,
          isname: keysso
        }
        if (keysso !== "0" && keysso !== "1") {
          this.suggestionList.push(parentsobj);
        }
        if (typeof (valueso) === "object") {
          this.getSearchDataList(valueso)
        }
      }
    }
  }
  getDraggedPath(e: any) {
    let path;
    if (e) {
      path = this.getPath(this.backXMLData, e.item.data.GUID, "");
      for (let i = 0; i < path.length; i++) {
        if ((e.item.data.isNode == "Child" || e.item.data.isNode == "Parent") && (path[i] == "1" || path[i] == "0")) {
          path.splice(i, 1);
        }
        if (path[i] === "attr" && e.item.data.isNode == "Child" && e.item.data.filename.toLowerCase() != "value") {
          path.splice(i, 1);
          path[path.length - 1] = "@" + e.item.data.filename;
        } else if (path[i] === "attr" && e.item.data.isNode == "Parent") {
          path.splice(i, 1);
          path.pop();
          path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
        }
      }
      if (path) {
        path = path.join("/");
      }
    }
    return path;
  }
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  closepopup(c) {
    c('close modal');
    this.mappingNotescheck = false;
    this.mappingnotesData = [];
    this.content = '';
    this.isRejected = false;
  }
  content: string = '';
  savemappingnote(c) {
    this.notesmodal = c
    if (this.content != '') {
      this.ngxUiLoaderService.start();
      let configid;
      this.versionDetails.forEach(data => {
        if (data.version == this.version) {
          configid = data.coreconfigid;
        }
      });
      let role = "";
      if (this.pagerolepermisions.liveapprove) {
        role = "Manager";
      }
      else if (this.pagerolepermisions.qcapprove) {
        role = "Qc"
      }
      else {
        role = "Developer"
      };
      var obj = {
        RouteId: this.uniqueid,
        ConfigId: configid,
        Notes: this.content,
        UserId: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        Role: role
      }
      this.service.postapi("api/XMLMappings/SaveMappingVersionNotes", obj).then(response => {
        if (response) {
          if (response["status"] == "Success") {
            this.toastr.success(response["statusmessage"], "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
            this.content = '';
            this.getMappingnotes();
            this.ngxUiLoaderService.stop();
            if (!this.mappingNotescheck) {
              this.isVersionModified = true;
              this.UpdateVersionStatus(c);
              setTimeout(() => {
                c('close modal');
                this.mappingNotescheck = false;
                this.mappingnotesData = [];
              }, 6000);
            }

          }
          else {
            this.toastr.error("Something Went Wrong!", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right"
            });
            this.ngxUiLoaderService.stop();
          }
        }
      },error=>{
        this.ngxUiLoaderService.stop();
      });

    }
    else {
      this.toastr.warning("Please add the Notes!", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
    }
  }
  getMappingnotes() {
    let configid;
    this.versionDetails.forEach(data => {
      if (data.version == this.version) {
        configid = data.coreconfigid;
      }
    });
    var apiurl = "api/XMLMappings/GetMappingVersionNotes?configid=" + configid + "&routeid=" + this.uniqueid;
    this.service.getapiurl(apiurl).subscribe(data => {
      if (data.length != 0) {
        this.mappingnotesData = data;
      }
    },
      error => {
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right"
        });
        this.ngxUiLoaderService.stop();
      })

  }
  //   addComment(){
  //     if(this.attrcomment){
  //       this.ngxUiLoaderService.start();
  //       this.createcommentattr(this.backMapData);
  //       // this.closemenu = false;
  //       this.languageMenuTrigger.closeMenu();
  //       // this.attrcomment='';
  //       this.saveJson(this.attrcomment);
  //       this.xmlkey = "map";
  //       this.database.initialize(JSON.stringify(this.backMapData), this.xmlkey);
  //       this.ngxUiLoaderService.stop();
  //       this.ExpressionDivContent=false;
  //     }
  //     else{
  //       this.toastr.warning("Please add the comment!!!!!!!!", "", {
  //         timeOut: 4000,
  //         positionClass: "toast-bottom-right"
  //       });
  //     }
  // }
  // createcommentattr(Obj:any) {
  //   for (let [key, value] of Object.entries(Obj)) {
  //     if (key.split('_')[0] == "Comment") {
  //       if(typeof(value)=="object")
  //       {
  //         var creatobj=()=>{
  //           let keyname='Key_'+uuidv4();
  //           var keynode=this.createJson(keyname)
  //             var attr=this.createJson('attr');
  //             Object.assign(keynode[keyname],attr);
  //             var name=this.createJson('Name');
  //             name['Name']=this.selectednode.filename;
  //               var notes=this.createJson('Notes');
  //               notes['Notes']=this.attrcomment;
  //             var nodeidentifier=this.createJson("_NodeIdentifier");
  //             nodeidentifier["_NodeIdentifier"]=this.selectednode.GUID;
  //             Object.assign(keynode[keyname]["attr"], name);
  //             Object.assign(keynode[keyname]["attr"],notes);
  //             Object.assign(keynode[keyname]["attr"],nodeidentifier);
  //             return keynode;
  //         }
  //          if(value[0]=='' ){
  //          let keynode= creatobj();
  //           value[0]=keynode;
  //             break;
  //          }
  //          else{
  //            let isFoundAttr=false;
  //            if(value[0]["attr"]==undefined){
  //             for (const [key, attrvalue] of Object.entries(value[0])){
  //               if(attrvalue["attr"]["Name"]==this.selectednode.filename && attrvalue["attr"]["_NodeIdentifier"]==this.selectednode.GUID){
  //                 attrvalue["attr"]["Notes"]=this.attrcomment;
  //                 isFoundAttr=true;
  //                }
  //             }
  //             if(!isFoundAttr){
  //               let keynode= creatobj();
  //               Object.assign(value[0],keynode);
  //             }
  //            }
  //          }
  //       }
  //     }
  //     else if(typeof(value)=="object"){
  //       this.createcommentattr(value)
  //     }
  //   }
  // }
  saveAsBlank() {
    let result = ""
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });

    let path;
    path = this.getPath(this.backMapData, this.selectednode.GUID, "");
    path[path.length - 1] = this.selectednode.filename;
    let bui = this.recompose(this.backMapData, path, result);
    //
    this.selectednode.type = result;
    this.toastr.success("Saved Mapped Expression for " + this.selectednode.filename, "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right"
    });

    this.dynamicJSON = [];
    this.screenHeight = window.innerHeight - 161;
    this.checkmappednode()
  }
  saveJson(value) {

    // if (value !== "") {
    let result
    if (this.selectedExpressionValue !== "Other") {
      this.generateresultjson();
      result = this.generateresult();
    } else {
      if (this.dynamicJSON.length > 0) {
        result = this.dynamicJSON[0].value
      }
    }
    let guid = "";
    this.backupParentNode.children.forEach((ele) => {
      if (ele.filename == "_NodeIdentifier") {
        guid = ele.type;
      }
    });
    if (result) {
      let path;
      path = this.getPath(this.backMapData, this.selectednode.GUID, "");
      path[path.length - 1] = this.selectednode.filename;
      let bui = this.recompose(this.backMapData, path, result);
      //
      this.selectednode.type = result;
      this.toastr.success("Saved Mapped Expression for " + this.selectednode.filename, "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right"
      });
      // }
      this.dynamicJSON = [];
      this.ExpressionDivContent = false;
      this.screenHeight = window.innerHeight - 161;
    }
    // }else{
    //   if(this.existingattrcomment!=""){
    //     this.attrcomment=this.existingattrcomment;
    //   }
    //   else{
    //     this.attrcomment =""
    //   }
    //   this.languageMenuTrigger.openMenu();
    // }
    // getExistingCommentForAttr(Obj:any){
    //   for (let  [key, value] of Object.entries(Obj)) {
    //     if (key.split('_')[0] == "Comment") {
    //       if(typeof(value)=="object")
    //       {
    //         if(value[0]!='' ){
    //           let obj=value[0];
    //         for (let  [key, attrvalue] of Object.entries(obj)){
    //           if(attrvalue["attr"]["Name"]==this.selectednode.filename && attrvalue["attr"]["_NodeIdentifier"]==this.selectednode.GUID){
    //            this.existingattrcomment= attrvalue["attr"]["Notes"];
    //            }
    //         }
    //         }
    //       }
    //     }else if(typeof(value)=="object"){
    //       this.getExistingCommentForAttr(value);
    //     }

    //   }
    // }
    this.checkmappednode()
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  ngDoCheck() {
    this.cdr.detectChanges();
  }
  openmessageattributeconfig() {
    this.dataToModal = {
      routeid: this.routeid,
      routename: this.headerRoute,
      uniqueid: this.uniqueid,
      lockMessAttribute: this.LockXML
    };
    // this.dataToModal = {
    //   routeid: "FFE768EA-CAD1-4152-BF8A-03A5A5A6373A",
    //   routename: "this.headerRoute",
    //   uniqueid: 810,
    // };

    const modalRef = this.modalService.open(MessageattributesComponent, {
      windowClass: "myCustomModalClass",
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.inputDataInModalComponent = this.dataToModal;
    modalRef.result.then(() => { });
  }



  async generatepdf() {
    this.ngxUiLoaderService.start();
    var str = JSON.stringify(this.backMapData);
    str = str.replace(new RegExp("\\b" + 'attr' + "\\b"), "@");
    var Obj = JSON.parse(JSON.stringify(this.backMapData).replace(/"attr":/g, '"@":'));
    var jsondata;
    var jsonObjresult = "";
    if (this.resultJson == "") {
      jsonObjresult = this.resultJson;
    } else {
      jsonObjresult = JSON.stringify(this.resultJson);
    }

    let versionString;


    let founddata = [this.versionDetails.find((ele: any) => ele.version === this.version)]
    if (founddata.length > 0) {
      if (founddata[0].islive) {
        versionString = this.version + " Live"
      } else {
        if (founddata[0].versionstatus === '101') {
          versionString = this.version + ' QC'
        } else if (founddata[0].versionstatus === '100') {
          versionString = this.version + ' Dev'
        }
        else {
          versionString = this.version;
        }
      }
    }

    for (const [key, value] of Object.entries(Obj)) {
      var xml = JsonToXML.parse(key, Obj[key], {
        format: {
          doubleQuotes: true
        }
      });
      xml = xml.replace(/&apos;/g, " ' ");
      xml = xml.replace(/\n/g, "  ");
      let obj = {
        xml: xml.replace(/<\?xml.+\?>|<!DOCTYPE.+] | <?xml version=\"1.0\"?>/g, ""),
        operation: "delete"
      }

      if (founddata[0].versionstatus === '103') {
        if (!versionString.includes('Live')) {
          versionString = versionString + "_Live";
        }
        jsondata = {
          contextid: this.uniqueid,
          filename: (this.inputDataInModalComponent.routeName === undefined ? '' : this.inputDataInModalComponent.routeName)
            + "_" + versionString.trim().replace(/\s+/g, '_') + '.pdf'
        }
        this.service.GenerateMappingPDFLive(jsondata).subscribe((response: any) => {
          if (response.fileinfo != null) {
            const decodedContent = 'data:application/pdf;base64,' + response.fileinfo;
            this.downloadFile(decodedContent, response.filename);
            this.ngxUiLoaderService.stop();
          this.toastr.success("Mapped PDF Generated Successfully", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          }
          else{
            this.ngxUiLoaderService.stop();
          }

        },error=>{
          this.ngxUiLoaderService.stop();
        });
      }
      else {
        this.service.XmlModification(obj).subscribe(async data => {
          if (data.statusmessage == null) {
            jsondata = {

              createdby: JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata["userid"],
              CreatedDate: new Date().toISOString().split('T')[0],
              input: data.responsebody,
              inputtype: this.formatType,
              interfacename: this.inputDataInModalComponent.routeName === undefined ? '' : this.inputDataInModalComponent.routeName,
              version: versionString.trim(),
              disablePDFButton: this.disableGeneratePDFButton,
              routeid: this.routeid
            };

            this.service.GenerateMappingPDF(jsondata).subscribe((response: any) => {
              if (response.status === 'Success' && response.responsebody && response.responsebody.trim() !== '' && response.responsebody !== null) {
                const filename = (this.inputDataInModalComponent.routeName === undefined ? '' : this.inputDataInModalComponent.routeName)
                  + "_" + versionString.trim().replace(/\s+/g, '_');
                const decodedContent = 'data:application/pdf;base64,' + response.responsebody;
                this.ngxUiLoaderService.stop();
                this.downloadFile(decodedContent, filename + '.pdf');
                this.toastr.success("Mapped PDF Generated Successfully", "", {
                  timeOut: 4000,
                  positionClass: 'toast-bottom-right'
                });
              }
              else {
                let filename = this.inputDataInModalComponent.routeName === undefined ? '' : this.inputDataInModalComponent.routeName;
                this.toastr.success("", response.Status, {
                  timeOut: 4000,
                  positionClass: "toast-bottom-right",
                });
                this.ngxUiLoaderService.stop();
              }
            },error=>{
              this.ngxUiLoaderService.stop();
            })
          }
        },error=>{
          this.ngxUiLoaderService.stop();
        });

      }
    }

  }
  downloadFile(data: any, filename: any) {
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
  }


  makelhstree(value:string)
  {
    let templateobj = {
      xml: value,
      operation: "add",
    };
    this.ngxUiLoaderService.start();
    this.service.XmlModification(templateobj).subscribe((data) => {
      if (data) {
        let parsers = new xml2js.Parser({
          strict: true,
          trim: true,
          attrkey: "attr",
        });
        parsers.parseString(data.responsebody, (err, results) => {
          if (results) {
            // const obj = this.ngxXmlToJsonService.xmlToJson(this.Template, this.options);
            this.createtemplatepath(results);
            this.addguid(results);
            let xmldata_gui = JSON.parse(JSON.stringify(results))
            this.removeUnderScore(xmldata_gui)

            this.backXMLData = results;
            let pars = JSON.stringify(results);
            this.xmlkey = "xml";
            this.database.initialize(pars, this.xmlkey);
            this.treeControl.expandAll();
          }
        });
      }
      this.ngxUiLoaderService.stop();
    },error=>{
      this.ngxUiLoaderService.stop();
    });
  }

}


export interface inputField {
  key: string;
  label: string;
  type: string;
  value: string;
  required: boolean;
  order: Number;
  condition: any;
  placeholder: string;
  isdroppable: boolean;
  isvariable: boolean;
  isroutefield: boolean;
  isheaderfield: boolean;
  ismessagebodyfield: boolean;
  isdatefield: boolean;
  subcondition: any;
  subxpath: any;
}
export interface ConditionBuilder {
  key: string;
  label: string;
  type: string;
  value: string;
  isdroppable: boolean;
  required: boolean;
  order: Number;
  enablegroup: boolean;
  placeholder: string;
  conditions: [];
  operator: string;
}
