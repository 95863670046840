import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { CommonService } from '../services/common.service';
import * as XLSX from 'xlsx';
import { data } from 'highcharts';
declare const copyToClipboard: any;

@Component({
  selector: 'app-interfacemapping',
  templateUrl: './interfacemapping.component.html',
  styleUrls: ['./interfacemapping.component.scss']
})
export class InterfacemappingComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  reactiveFormmapping: FormGroup;
  isflag:string;
  updatemapping : any;
  xmlmappinglist;
  mappingmodel: any = {};
  interfacerouteid: string;
  classObjrequiredmessage: string;
  requiredmessage: string;
  hideSuccessMessage = false;
  headerRoute: string = '';
  @ViewChild("fileInput", { static: false }) myFileInput;
  uniqueid: any;
  copyinterfacemapping:boolean=false;
  templatename: any;
  previousmapping: {};
  enabledownloadmappings: boolean=false;
  interfaceName: any;
  lookuphandler: any;
  addmapping: any;
  lockInterfaceMap: any;
  searchtextData: string = '';
  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService,
    private activeModal: NgbActiveModal,
    private _fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) {
    this.reactiveFormmapping = _fb.group({
      LookupType: [''],
      LookupValue: [''],
      LookupId: ['']
    }),
      this.xmlmappinglist = <any>[];

  }

  tripfilter = {
    LookupType: '',
    LookupId: '',
    LookupValue: ''
  }

  pagerolepermisions: any = [];
  async ngOnInit() {
    if (await this.service.checksession() == true) {
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'createpipeline').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
      if (this.pagerolepermisions != undefined) {
        if(this.inputDataInModalComponent.isflag ==="ciis"){
          this.xmlmappinglist= this.inputDataInModalComponent.data;
          this.interfaceName= this.inputDataInModalComponent.name;
          this.isflag = this.inputDataInModalComponent.isflag;
        }else{

          this.lockInterfaceMap = this.inputDataInModalComponent.LockInterfaceUpload;
          this.headerRoute = this.inputDataInModalComponent.routename;
          this.uniqueid = this.inputDataInModalComponent.uniqueid;
          this.templatename=this.inputDataInModalComponent.templatename;
          this.interfacerouteid= this.inputDataInModalComponent.routeid;
          this.getxmlmappings();
        }

      }
    }

  }

  getFilter() {
    this.tripfilter.LookupType = this.searchtextData;
    this.tripfilter.LookupId = this.searchtextData;
    this.tripfilter.LookupValue =this.searchtextData;
  }

  getxmlmappings() {
    this.clearmappings();
    const interfacemapping = {
      "ContextId":  this.uniqueid,
      "Handler": ""
    }  
    let response = this.service.postapi('api/XMLMappings/GetByHandler', interfacemapping).then(
      data=>{
        if(data){
          this.xmlmappinglist = data as mappingdata[];
          if(this.xmlmappinglist.length>0){
            let exists=this.xmlmappinglist.filter(x=>x.ContextId==this.uniqueid);
            if(exists.length>0){
              this.enabledownloadmappings=true;
            }
            else{
              this.enabledownloadmappings=false;
            }
          }
        }
      }
    )
    

  }

  clearmappings() {
    this.reactiveFormmapping.reset();
    this.mappingid = 0;
    this.selectedRow = -1;
    this.mappingmodel.LookupType = '';
    this.mappingmodel.LookupId = '';
    this.mappingmodel.LookupValue = '';
    this.previousmapping='';
  }

  selectedRow: any;
  mappingid: any;
  editmapping(item: any, index: any) {
    this.selectedRow = index;
    this.mappingid = item.Id || item.id;
    this.lookuphandler = item.handler
    this.reactiveFormmapping.patchValue({
      LookupType: item.LookupType || item.lookupType,
      LookupValue: item.LookupValue || item.lookupValue,
      LookupId: item.LookupId || item.lookupId

    });
    this.previousmapping={
      LookupType: item.LookupType,
      LookupValue: item.LookupValue,
      LookupId: item.LookupId,
      id: item.Id
    }
  }

  async savemappings() {
this.mappingid = this.inputDataInModalComponent.isflag ==="ciis"?this.mappingid?this.mappingid.toString():"0":this.mappingid;
    const objmapping = {
      "id": this.mappingid,
      "LookupType": this.mappingmodel.LookupType,
      "LookupId": this.mappingmodel.LookupId,
      "LookupValue": this.mappingmodel.LookupValue,
      "Handler": this.interfacerouteid || this.interfaceName
    }
if(this.inputDataInModalComponent.isflag !=="ciis"){
    if (this.mappingmodel.LookupType != '' && this.mappingmodel.LookupId != '' && this.mappingmodel.LookupValue != ''
      && this.mappingmodel.LookupType != undefined && this.mappingmodel.LookupId != undefined && this.mappingmodel.LookupValue != undefined) {
      this.ngxUiLoaderService.start();
      let response = await this.service.postapi('api/XMLMappings/CreateUpdate', objmapping);
      if (response['status'] && response['statusText']) {
        // this.classObjrequiredmessage = 'btn alert-danger';
        // this.requiredmessage = response['statusText'];
        // this.FadeOutSuccessMsg();
        this.ngxUiLoaderService.stop();
        this.toastr.error("Something Went Wrong!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.ngxUiLoaderService.stop();
        var res = response as any[];
        if (res == null || res['id'] == '-1') {
          this.toastr.info("Record Already Exists", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          let auditMessage,type,previousmessage="";

          if(this.mappingid==0)
          {
            auditMessage= "Added Interface Mapping : Lookup Type :- "+this.mappingmodel.LookupType+" , Lookup Id :- "+this.mappingmodel.LookupId+" and Lookup Value :- "+this.mappingmodel.LookupValue;
            type="Save";
          }
          else{
            previousmessage="Previous Interface Mapping: Lookup Type :- "+this.previousmapping["LookupType"]+" , Lookup Id :- "+this.previousmapping["LookupId"]+" and Lookup Value :- "+this.previousmapping["LookupValue"];
            auditMessage= "Updated Interface Mapping:  Lookup Type :-  "+this.mappingmodel.LookupType+ " , Lookup Id :- "+this.mappingmodel.LookupId+" and Lookup Value :- "+this.mappingmodel.LookupValue;
            type="Update";
          }
          this.service.auditsave(type,previousmessage,auditMessage, "Pipeline", this.uniqueid,'Mapping'+type+'Successfully').then(response => {
            if (response) {

            }
          });

          this.mappingid = res["Id"];
          this.toastr.success("Interface Mapping Saved Successfully", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
          this.getxmlmappings();
          this.clearmappings();
        }
        // this.FadeOutSuccessMsg();
      }
    }
    else {
      this.toastr.error("Please fill in the mandatory fields", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }else{
    let lookupobj= {
      "id": this.mappingid.toString(),
      "handler": this.lookuphandler,
      "lookupType": this.mappingmodel.LookupType,
      "lookupId": this.mappingmodel.LookupId,
      "lookupValue": this.mappingmodel.LookupValue
    }

    if (this.mappingid !== "0" ) {
      this.updatemappings(lookupobj);
    }else {
      // this.ngxUiLoaderService.start()
      if(this.mappingid=="0")
      {
    this.addmappings(objmapping)
    }
   }
  }
  }

  async deletemapping(item: any) {
    this.ngxUiLoaderService.start();
    let response = await this.service.deletemapping('api/XMLMappings/delete?Id=' + item.Id);
    let auditMessage= "Deleted Interface Mapping:  Lookup Type :- "+item.LookupType+" , Lookup Id :- "+item.LookupId+" and Lookup Value:- "+item.LookupValue;
    this.service.auditsave("Delete", "",auditMessage, "Pipeline", this.uniqueid,'Mapping Deleted Successfully').then(response => {
      if (response) {

      }
    });
    this.toastr.success("Interface Mapping Deleted successfully", "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
    this.getxmlmappings();
    this.ngxUiLoaderService.stop();
  }

  FadeOutSuccessMsg() {
    this.hideSuccessMessage = true;
    setTimeout(() => {
      this.hideSuccessMessage = false;
    }, 20000);
  }

  closetpopup() {
    this.activeModal.close();
  }

  fileToUpload: any;
  bulkUploadBtn: any;

  // async addFile(e): Promise<void> {
  //   this.fileToUpload = e.target.files[0];
  //   if (this.fileToUpload) {
  //     this.ngxUiLoaderService.start();

  //     this.bulkUploadBtn = e.target.files[0].name;
  //     if (this.bulkUploadBtn.endsWith(".xls") || this.bulkUploadBtn.endsWith(".xlsx")) {
  // this.service.uploadExcel("api/XMLMappings/UploadBulkMappings?routeId=" + this.interfacerouteid, this.fileToUpload).then(
  //   response=>{
  //     if (response["status"] == "Success") {
  //       this.fileToUpload = "";

  //     }
  //     else {
  //       this.ngxUiLoaderService.stop();
  //       this.toastr.error(response["statusmessage"],"",{timeOut: 4000,
  //         positionClass: 'toast-bottom-right'});
  //       this.fileToUpload = "";
  //     }
  //   }
  // );

  //     }
  //     else {
  //       this.ngxUiLoaderService.stop();
  //       this.toastr.error("Select Valid File","",{timeOut: 4000,
  //         positionClass: 'toast-bottom-right'});
  //     }
  //   }

  // }


  theFile: any = null;
  messages: string[] = [];
  Getfileinfo(event) {
    this.ngxUiLoaderService.start();
    this.theFile = null;
    if (event.target.files && event.target.files.length > 0) {
      this.readAndUploadFile(event.target.files[0]);
      this.myFileInput.nativeElement.value = "";
    }
    else{
      this.myFileInput.nativeElement.value = "";
    }
    this.ngxUiLoaderService.stop();
  }

  private readAndUploadFile(theFile: any) {
    let file = new FileToUpload();
    file.routeid = this.interfacerouteid;
    let reader = new FileReader();
    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result.toString();
      // POST to server
      this.service.uploadFile('api/XMLMappings/UploadBulkMappings', file).subscribe(resp => {
        if(resp.status=='Success')
        {
          this.service.auditsave("Add", "","Interface Mappings Uploaded","Pipeline", this.uniqueid,'Interface Mappings Uploaded Successfully').then(response => {
            if (response) {
              this.ngxUiLoaderService.stop();
              this.toastr.success(resp.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              this.getxmlmappings();
            }
          });

        }
        else {
          this.ngxUiLoaderService.stop();
          this.toastr.error(resp.statusmessage, "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
        }
      });
    }
    // Read the file
    reader.readAsDataURL(theFile);
  }
  downloadinterfacemapping(){
    
    if(this.xmlmappinglist.length>0){
     // this.downloadaudits();
      let fileName = 'data.xlsx'
      let data=JSON.parse(JSON.stringify(this.xmlmappinglist));
      console.log(data)
      data=data.filter(x=>x.ContextId==this.uniqueid);
    data.forEach(ele=>{
       delete ele.ContextId;
       delete ele.Id;
       ele.Handler=this.interfacerouteid
    })
     this. exportToExcel(data,fileName)

    }
  }
  downloadaudits() {
    this.ngxUiLoaderService.start();
    let csvData = '';
    let data=JSON.parse(JSON.stringify(this.xmlmappinglist));
       data=data.filter(x=>x.ContextId==this.uniqueid);
    data.forEach(ele=>{
       delete ele.ContextId;
       delete ele.Id;
       ele.Handler=this.interfacerouteid
    })

    csvData = this.ConvertToCSV(data, ['Handler','LookupType', 'LookupId', 'LookupValue']);


    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", this.headerRoute+'_'+new Date() + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.ngxUiLoaderService.stop();
  }
  ConvertToCSV(objArray, headerList) {

    let items = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let separator = ',';
    const columns = Object.keys(items[0]).join(separator);
    const body = items.map(item =>
      Object.values(item).join(separator)
    ).join('\n');

    return headerList + '\n' + body;
  }
  exportToExcel(items: any[], fileName: string): void {
    
    
      const selectedItems = items.map(item => {
        return {
          Handler: item.Handler,
          LookupType: item.LookupType,
          LookupId: item.LookupId,
          LookupValue: item.LookupValue,
        };
      });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    //saveAs(blob, fileName);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = this.headerRoute+'_'+new Date() + ".xlsx";
    link.click();

  }


  // ciis apps add and update methods
  addmappings(objmapping){
    this.service.addIntegrationMapping(objmapping).subscribe(data=>{
      if(data){
        this.addmapping =data
        this.toastr.success("Interface Mapping Saved Successfully", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'

    })
    this.ngxUiLoaderService.stop()
  }else{
    this.toastr.error("Please fill in the mandatory fields", "", {
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    });
  }
    })
  }

  updatemappings(lookupobj){
      this.ngxUiLoaderService.start()
      this.service.updateIntegrationMapping(lookupobj).subscribe(data =>{
        if(data){
          this.updatemapping=data;
          this.toastr.success("Interface Mapping Updated Successfully", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
        })
          this.ngxUiLoaderService.stop()
        }else{
          this.toastr.error("Failed to Update Interface Mapping", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

      }

      })
  }
  copyToClip(data, message: any) {
    if (data != '') {

      copyToClipboard(data);

      this.toastr.success(message + ' Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
}

export interface mappingdata {
  Handler: string;
  Lookuptype: string;
  Lookupvalue: string;
  Lookupid: string;
  Id: bigint;
}
export class FileToUpload {
  fileAsBase64: string = "";
  routeid: string = "";
}
