import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-vitalhublabadmin',
  templateUrl: './vitalhublabadmin.component.html',
  styleUrls: ['./vitalhublabadmin.component.scss']
})
export class VitalhublabadminComponent {

  public reactiveFormsource: FormGroup;
  SourceAccountList = <any>[];
  constructor(public service: CommonService, private toastr: ToastrService, private _fb: FormBuilder,) {

  }
  selectedsourceaccountid: string = "";
  selectedsourceaccountName: string = "";
  isLoadingSource: boolean = false;
  notfounddata: boolean;
  keywordaccount = "LocationName";
  sourceCustomFilter = function (SourceAccountList: any[], query: string): any[] {
    return SourceAccountList;
  };
  async getSourceAccountName(event: any) {
    var valuesa = event;
    if (event.includes(":")) {

      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    this.selectedsourceaccountid = '';
    this.reactiveFormsource.patchValue({ name: event })


    if (valuesa != '' && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      ;
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      this.isLoadingSource = true;
      let obj = {
        lsearch: valueToSendToApiWithOutWhiteSpace
      }
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {

        if (response['status'] && response['statusText']) {
          this.SourceAccountList = [];
          this.notfounddata = true;
          this.isLoadingSource = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.SourceAccountList = response as any[];
            this.SourceAccountList = this.service.transformAlphabetically(this.SourceAccountList, 'LocationName');
            this.SourceAccountList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.SourceAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedsourceaccountid = '';
          this.isLoadingSource = false;
        }
      })


    }
    else {
      this.SourceAccountList = [];
      this.notfounddata = true;
    }
  }
}
