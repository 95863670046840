// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputvalue_card {
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

::ng-deep.modalbody {
  position: relative;
  flex: 1 1 auto;
  padding: 10px;
}

.info {
  background-color: #007887;
}

.searchtext {
  border-radius: 5px;
  border: 1px solid;
}

.Header_Border {
  border: 2px solid #007887;
  /* margin-left: -29px; */
}

.Header_row {
  background: #007887;
  margin-inline: -0.5px;
  color: white;
}

.Header_BorderBulk {
  padding-inline: 0px;
  /* margin-left: -29px; */
}

.Header_rowBulk {
  background: #007887;
  margin-inline: -27.5px;
  color: white;
}

.input_data {
  box-shadow: 0 0 2px rgb(224, 224, 224);
}

.Fotter_boder {
  background: #17A0BA;
}

.NoData {
  font-weight: bold;
  text-align: center;
  height: 60vh;
}`, "",{"version":3,"sources":["webpack://./src/app/Model/data-validation/data-validation.component.scss"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,cAAA;EACA,aAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,kBAAA;EACC,iBAAA;AAAL;;AAGA;EACQ,yBAAA;EAEA,wBAAA;AADR;;AAKA;EACI,mBAAA;EACA,qBAAA;EAEA,YAAA;AAHJ;;AAOA;EAEI,mBAAA;EACA,wBAAA;AALJ;;AAQA;EACA,mBAAA;EACC,sBAAA;EACD,YAAA;AALA;;AAQA;EACI,sCAAA;AALJ;;AAQA;EACI,mBAAA;AALJ;;AAQA;EACI,iBAAA;EACA,kBAAA;EACA,YAAA;AALJ","sourcesContent":[".inputvalue_card{\r\n    // height: 65vh;\r\n    height: 60vh;\r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n}\r\n\r\n::ng-deep.modalbody {\r\n    position: relative;\r\n    flex: 1 1 auto;\r\n    padding: 10px;\r\n}\r\n\r\n.info {\r\n    background-color: #007887;\r\n}\r\n\r\n.searchtext{\r\n    border-radius: 5px;\r\n     border: 1px solid;\r\n}\r\n\r\n.Header_Border{\r\n        border: 2px solid #007887; \r\n        //padding-inline: 0px;\r\n        /* margin-left: -29px; */\r\n    \r\n}\r\n\r\n.Header_row{\r\n    background:#007887; \r\n    margin-inline: -0.5px;\r\n    // margin-inline: -27.5px;\r\n    color: white\r\n}\r\n\r\n//for interface bulk validation start\r\n.Header_BorderBulk{\r\n    //border: 2px solid #007887; \r\n    padding-inline: 0px;\r\n    /* margin-left: -29px; */\r\n}\r\n\r\n.Header_rowBulk{\r\nbackground:#007887; \r\n margin-inline: -27.5px;\r\ncolor: white\r\n}\r\n//for interface bulk validation end\r\n.input_data{\r\n    box-shadow: 0 0 2px rgb(224, 224, 224)\r\n}\r\n\r\n.Fotter_boder{\r\n    background : #17A0BA\r\n}\r\n\r\n.NoData{\r\n    font-weight: bold;\r\n    text-align: center;\r\n    height: 60vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
