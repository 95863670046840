import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "../services/common.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatCheckbox } from "@angular/material/checkbox";
import { V } from "@angular/cdk/keycodes";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute,Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { ImportService } from "../services/importResolver.service";


declare var $;
declare const copyToClipboard: any;

@Component({
  selector: "app-patienthistory",
  templateUrl: "./patienthistory.component.html",
  styleUrls: ["./patienthistory.component.scss"],
})
export class PatienthistoryComponent implements OnInit {
  IsActive: any;
  selectedLaborgid: any = "";
  orgguid: any = "";
  labname: any = "";
  deploykey: any = "";
  intervalex: any;
  intervalim: any;
  filesListImp = [];
  HeadersImp=[]
  headerListImp = [];
  importData = [];
  csvdataImp =[]
  constructor(
    public service: CommonService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private actr : ActivatedRoute,
    private improute : ImportService,
    private router : Router
  ) {}

  ngOnInit() {
    // this.PHsummary();
    // this.startIntervalim();
    // this.PHsummaryexport()

    // this.startintervalex();

    this.actr.data.pipe(map((res)=>res)).subscribe(data=>{

      if(data){
        this.importData = JSON.parse(data['cres'].responsebody)
        this.filesListImp = JSON.parse(this.importData['FileInfo'])
        this.HeadersImp = JSON.parse(this.importData['Headers'])
        this.csvdataImp = JSON.parse(this.importData['CSVData'])
        for (let i = 0; i < this.HeadersImp.length; i++) {
          this.headerListImp.push({
            index: i,
            header: this.HeadersImp[i],
          });
        }
        this.MapheadersImp();
      }
    },error=>{}
    )

  }
  PatientFormGroup: FormGroup = this._formBuilder.group({
    Host: ["", Validators.required],
    Username: ["", Validators.required],
    Password: ["", Validators.required],
    Port: ["", Validators.required],
    Folderpath: ["", Validators.required],
    laborgid: ["", Validators.required],
  });
  connection: {};
  fileslist: {};
  defaultfiledata: any[] = [];
  Headers: any[] = [];
  Headerslist: any[] = [];
  islist: boolean = false;
isimport:boolean
selectedIndexTab:number =0;

  islistpage(value: boolean,exporimp:boolean,tocall:unknown) {
this.isimport=exporimp;
// clearInterval(this.interval3);
// clearInterval(this.interval2);
this.selectedIndexTab = tocall['index']
if(tocall['index']== 0)
{
  this.startIntervalim();
}
else if(tocall['index']== 1){
  this.startintervalex();
}
else{
  clearInterval(this.interval2);
  clearInterval(this.interval3);
}
    // this.islist = value;
  }

  exportView(){
    this.PatientFormGroup.reset()
    this.islist = false;
    this.isimport = false;
  }





  ftpconnection() {
    if (this.PatientFormGroup.status == "INVALID") {
      this.toastr.warning("Connection failed check required fields", "FTP", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    } else {
      this.ngxUiLoaderService.start();
      this.Headerslist=[];
      this.fileslist=[];
      let obj = {
        Host: this.PatientFormGroup.value.Host,
        Username: this.PatientFormGroup.value.Username,
        Password: this.PatientFormGroup.value.Password,
        Port: this.PatientFormGroup.value.Port,
        FTPFolder: this.PatientFormGroup.value.Folderpath,
      };
      this.service.getPatienthistory(obj).subscribe((data) => {
        if (data["ConnectionStatus"] == true) {
          this.ngxUiLoaderService.stop();
          this.connection = data;
          this.Headerslist =[]
          this.fileslist = [JSON.parse(this.connection["FileInfo"])][0];
          this.Headers = JSON.parse(this.connection["Headers"]);
          for (let i = 0; i < this.Headers.length; i++) {
            this.Headerslist.push({
              index: i,
              header: this.Headers[i],
            });
          }
          this.defaultfiledata = JSON.parse(this.connection["CSVData"]);
          this.Mapheaders();
        }
        else{
          this.toastr.warning("check connection details", "FTP", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,});
        }
        this.ngxUiLoaderService.stop();
      });
    }
  }
  predefinedcol = [
    {
      Sno: "1",
      Opposition: "[A]",
      Header: "accessionnumber",
    },
    {
      Sno: "2",
      Opposition: "[B]",
      Header: "casetype",
    },
    {
      Sno: "3",
      Opposition: "[C]",
      Header: "collectiondate",
    },
    {
      Sno: "4",
      Opposition: "[D]",
      Header: "receiveddate",
    },
    {
      Sno: "5",
      Opposition: "[E]",
      Header: "requisitionnumber",
    },
    {
      Sno: "6",
      Opposition: "[F]",
      Header: "OrderingFacility",
    },
    {
      Sno: "7",
      Opposition: "[G]",
      Header: "Physician",
    },
    {
      Sno: "8",
      Opposition: "[H]",
      Header: "PathologistAccount",
    },
    {
      Sno: "9",
      Opposition: "[I]",
      Header: "Pathologist",
    },
    {
      Sno: "10",
      Opposition: "[J]",
      Header: "SpecimenTypeName",
    },
    {
      Sno: "11",
      Opposition: "[K]",
      Header: "firstname",
    },
    {
      Sno: "12",
      Opposition: "[L]",
      Header: "lastname",
    },
    {
      Sno: "13",
      Opposition: "[M]",
      Header: "middleinitials",
    },
    {
      Sno: "14",
      Opposition: "[N]",
      Header: "gender",
    },
    {
      Sno: "15",
      Opposition: "[O]",
      Header: "dob",
    },
    {
      Sno: "16",
      Opposition: "[P]",
      Header: "ssn",
    },
    {
      Sno: "17",
      Opposition: "[Q]",
      Header: "emrnumbermedicalrecno",
    },
    {
      Sno: "18",
      Opposition: "[R]",
      Header: "address1",
    },
    {
      Sno: "19",
      Opposition: "[S]",
      Header: "address2",
    },
    {
      Sno: "20",
      Opposition: "[T]",
      Header: "state",
    },
    {
      Sno: "21",
      Opposition: "[U]",
      Header: "city",
    },
    {
      Sno: "22",
      Opposition: "[V]",
      Header: "zip",
    },
    {
      Sno: "23",
      Opposition: "[W]",
      Header: "workphone",
    },
    {
      Sno: "24",
      Opposition: "[X]",
      Header: "homephone",
    },
    {
      Sno: "25",
      Opposition: "[Y]",
      Header: "OtherDetails",
    },
    {
      Sno: "26",
      Opposition: "[Z]",
      Header: "Reports",
    },
    {
      Sno: "27",
      Opposition: "[AA]",
      Header: "Attachments",
    },
  ];

  saveexport()
  {
    if (this.PatientFormGroup.controls.laborgid.status == "INVALID") {
      this.toastr.warning("Connection failed check required fields", "FTP", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    } else {
      let buildprojectid = "Export";
      let random = Math.floor(Math.random() * 100000 + 1);
      if (this.selectedLaborgid != undefined && this.selectedLaborgid != "") {
        buildprojectid += "_" + this.selectedLaborgid;
      }
      buildprojectid += "_" + random;

      this.ngxUiLoaderService.start();
      let obj = {
        ProjectDetails: {
          ProjectID: buildprojectid,
          User: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
            .userdata["userid"],
          Type: "Export",
        },
        LabDetails: {
          LabOrgGuid: this.orgguid,
          OrgID: this.selectedLaborgid,
          DeploymentKey: this.deploykey,
          LabAccountName: this.labname,
          LabOrgName: this.labname,
        }
    }

      this.service.exportpatienthistory(obj).subscribe((data) => {
        if (data) {
          this.ngxUiLoaderService.stop();
          this.selectedIndexTab = 1;
          this.islist = true;
          this.startintervalex()
          this.toastr.success("Export Project Created Successfully", " Created", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,});
        }
        else{
          this.toastr.warning("check connection details", "FTP", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,});
        }
        this.ngxUiLoaderService.stop();
      });
    }
  }

isBack(){
  // this.islist = true;this.isimport =false
  this.service.lasturl ='pateintHistory';
  this.router.navigate(['/home/interfacerequestform'])
}

  submitImportMapping(){
    let headerobj = {};
    let invalidheaderobj = {};

      for (let i = 0; i < this.predefinedcol.length; i++) {
        headerobj[this.predefinedcol[i]["Header"]] =
          this.headerListImp[i]["index"];
      }

      for (let i = 0; i < this.headerListImp.length; i++) {
        if (this.predefinedcol[i] == undefined) {
          invalidheaderobj[this.headerListImp[i]["header"]] =
            this.headerListImp[i]["index"];
        }
      }
      // let buildprojectid = "import";
      // let random = Math.floor(Math.random() * 100000 + 1);
      // if (this.selectedLaborgid != undefined && this.selectedLaborgid != "") {
      //   buildprojectid += "_" + this.selectedLaborgid;
      // }
      // buildprojectid += "_" + random;

      this.ngxUiLoaderService.start();

      let finalobj = {
        ProjectDetails: {
          TaskID : this.improute.taskid,
          ProjectID: this.improute.projid,
          User: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
            .userdata["userid"],
          Type: "Import",
        },
        HeaderDetails: {
          Valid: {
            ...headerobj,
          },
          Invalid: {
            ...invalidheaderobj,
          },
        },
        Status : 'Submit'
      };
      let count = 0;
      for (let i = 0; i < this.headerListImp.length; i++) {
        if (this.headerListImp[i]["disabled"]) {
          count++;
        }
      }

      if (count == this.headerListImp.length) {
        this.service.submitImportPatient(finalobj).subscribe((data) => {
          if (data) {
            this.toastr.success("Submitted Successfully", "Success", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            this.router.navigate(['home/interfacerequestform'])

          }
          else
          {
            this.toastr.error(data.statusmessage, "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        });
      } else {
        this.toastr.warning("Must check all columns", "Mapping", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      setTimeout(()=>{
        this.ngxUiLoaderService.stop();
      },4000)
  }



  submitmapping() {
    if (this.PatientFormGroup.status == "INVALID") {
      this.toastr.warning("Check required fields", "Data not found", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    } else {
      let headerobj = {};
      let invalidheaderobj = {};

      for (let i = 0; i < this.predefinedcol.length; i++) {
        headerobj[this.predefinedcol[i]["Header"]] =
          this.Headerslist[i]["index"];
      }

      for (let i = 0; i < this.Headerslist.length; i++) {
        if (this.predefinedcol[i] == undefined) {
          invalidheaderobj[this.Headerslist[i]["header"]] =
            this.Headerslist[i]["index"];
        }
      }
      let buildprojectid = "import";
      let random = Math.floor(Math.random() * 100000 + 1);
      if (this.selectedLaborgid != undefined && this.selectedLaborgid != "") {
        buildprojectid += "_" + this.selectedLaborgid;
      }
      buildprojectid += "_" + random;

      this.ngxUiLoaderService.start();

      let finalobj = {
        ProjectDetails: {
          ProjectID: buildprojectid,
          User: JSON.parse(sessionStorage.getItem("sessionObjectlogin"))
            .userdata["userid"],
          Type: "Import",
        },
        FTPDetails: {
          Host: this.PatientFormGroup.value.Host,
          Username: this.PatientFormGroup.value.Username,
          Password: this.PatientFormGroup.value.Password,
          Port: this.PatientFormGroup.value.Port,
          FTPFolder: this.PatientFormGroup.value.Folderpath,
        },
        LabDetails: {
          LabOrgGuid: this.orgguid,
          OrgID: this.selectedLaborgid,
          DeploymentKey: this.deploykey,
          LabAccountName: this.labname,
          LabOrgName: this.labname,
        },
        HeaderDetails: {
          Valid: {
            ...headerobj,
          },
          Invalid: {
            ...invalidheaderobj,
          },
        },
      };
      let count = 0;
      for (let i = 0; i < this.Headerslist.length; i++) {
        if (this.Headerslist[i]["disabled"]) {
          count++;
        }
      }

      if (count == this.Headerslist.length) {
        this.service.submitPatienthistory(finalobj).subscribe((data) => {
          if (data) {
            this.toastr.success("Saved Successfully", "Success", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
            // this.islistpage(true,true,'import');
            this.selectedIndexTab = 0;
            this.islist = true;
            this.startIntervalim()
            this.resetform();
          }
          else
          {
            this.toastr.error("check ftp and lab details", "Failed", {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            });
          }
        });
      } else {
        this.toastr.warning("Must check all columns", "Mapping", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
      setTimeout(()=>{
        this.ngxUiLoaderService.stop();
      },4000)
    }
  }

  MapheadersImp() {
    if (this.headerListImp != undefined) {
      for (let i = 0; i < this.predefinedcol.length; i++) {
        if (
          this.predefinedcol[i]["Header"].toLowerCase() ===
          this.headerListImp[i]["header"].toLowerCase()
        ) {
          this.headerListImp[i]["disabled"] = true;
        }
      }
    }

    // item['disabled'] =
  }

  Mapheaders() {
    if (this.Headerslist != undefined) {
      for (let i = 0; i < this.predefinedcol.length; i++) {
        if (
          this.predefinedcol[i]["Header"].toLowerCase() ===
          this.Headerslist[i]["header"].toLowerCase()
        ) {
          this.Headerslist[i]["disabled"] = true;
        }
      }
    }

    // item['disabled'] =
  }
  keywordaccount = "LocationName";

  dropMap(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.headerListImp, event.previousIndex, event.currentIndex);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Headerslist, event.previousIndex, event.currentIndex);
  }

  Action(item: any, event: MatCheckbox) {
    item["disabled"] = event.checked;
  }

  notfounddata: boolean;

  LogLabOrgList: any[];
  searchTargetNameCleared(event) {
    this.selectedLaborgid = "";
    this.LogLabOrgList = [];
    this.notfounddata = false;
  }
  selectedDeployement: string;
  EventList: any[] = [];
  maincard: boolean;

  isLoadingEventLabOrg: boolean;
  LabOrgList = <any>[];
  async getlaborgs(event: any) {
    var valueta = event;
    if (valueta != "") {
      var createSearch =
        "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueta;
      this.isLoadingEventLabOrg = true;
      let response = await this.service.getapi(createSearch);
      if (response["status"] && response["statusText"]) {
        this.isLoadingEventLabOrg = false;
        this.toastr.error("Unable to fetch Lab Details", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      } else {
        if (response.length > 0) {
          this.LabOrgList = response as any[];
          this.LabOrgList = response.filter((va) => va.Type == "O");

          this.LogLabOrgList = this.service.transformAlphabetically(
            this.LabOrgList,
            "LocationName"
          );
          this.LogLabOrgList.map(
            (obj) =>
              (obj.LocationName = `  ${"(" + obj.deploymentkey + ")"} ${
                "(" + obj.organizationid + ")"
              } ${obj.LocationName} ${"(" + obj.LocationGUID + ")"}`)
          );
        }
        if (response.length == 0) {
          this.notfounddata = true;
        } else {
          this.notfounddata = false;
        }
        this.isLoadingEventLabOrg = false;
      }
    }
  }
  selectedLaborg(item) {
    this.selectedLaborgid = item.organizationid;
    this.orgguid = item.LocationGUID;
    this.labname = item.Displayname;
    this.deploykey = item.deploymentkey;
  }
  getStatus(status:string,statusmessage:string)
  {

    let value
    if(statusmessage!=undefined)
    {
       value = `Status (Cr / Tr)        : ${status}
      Reason        : ${statusmessage}
`
    }
    else
    {
       value = `Status (Cr / Tr)        : ${status}`
    }

    return value
  }
  phsummaryexport: any[] = [];
  PHsummaryexport() {
    this.service
      .getexportpatienthistory(
        JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata[
          "userid"
        ]
      )
      .subscribe((data) => {

        if (data != undefined && data.length > 0) {

          // this.phsummaryexport=[]
          this.phsummaryexport = data;
          // this.islist=true
        }
      })
    }
  phsummary: any[] = [];
  PHsummary() {
    // this.ngxUiLoaderService.start();
    this.service
      .Patienthistorysummary(
        JSON.parse(sessionStorage.getItem("sessionObjectlogin")).userdata[
          "userid"
        ]
      )
      .subscribe((data) => {
        if (data != undefined && data.length > 0) {
          this.phsummary=[]
          this.phsummary = data;
          let countfiles = 0;
          let countfilesfailed=0;
          let countfilesprocess=0;
          for (let i = 0; i < this.phsummary.length; i++) {
            countfiles=0;
            countfilesfailed=0;
            countfilesprocess=0;
            if (this.phsummary[i]["FileContent"].length > 0) {
              for (
                let j = 0;
                j < this.phsummary[i]["FileContent"].length;
                j++
              ) {
                if(this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                "initial" || this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                "initials")
                {
                  this.phsummary[i]["FileContent"][j]["Status"]= "Yet to start"
                }
                if(this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                "requestreceived" || this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                "processing")
                {
                  this.phsummary[i]["FileContent"][j]["Status"]= "Inprogress"
                }


                if (this.phsummary[i]["FileContent"][j]["CurrentRecords"] > 0) {
                    this.phsummary[i]["FileContent"][j]["progress"] =
                    Math.round(
                      (this.phsummary[i]["FileContent"][j]["CurrentRecords"] *
                        100) /
                        this.phsummary[i]["FileContent"][j]["TotalRecords"]
                    );
                } else {
                  this.phsummary[i]["FileContent"][j]["progress"] = 0;
                }


                if (
                  this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                  "inprogress"|| this.phsummary[i]["FileContent"][j]["Status"].toLowerCase()== "yet to start"
                ) {
                  countfilesprocess++;
                }
                if (
                  this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                  "completed"
                ) {
                  countfiles++;
                }
                 if (
                  this.phsummary[i]["FileContent"][j]["Status"].toLowerCase() ==
                  "failed"
                ) {
                  countfilesfailed++;
                }
                if(this.phsummary[i]["FileContent"][j]["Status"] ==
                "completed" || this.phsummary[i]["FileContent"][j]["Status"]=="Completed")
                {
                  this.phsummary[i]["FileContent"][j]["progress"] = 100;
                }
              }





              if (countfiles <= this.phsummary[i]["FileContent"].length && countfilesfailed < this.phsummary[i]["FileContent"].length) {
                this.phsummary[i]["Status"] =
                  "Completed" +
                  " (" +
                  countfiles +
                  "/" +
                  this.phsummary[i]["FileContent"].length +
                  ")";
              }
              else if(countfilesfailed== this.phsummary[i]["FileContent"].length){
                this.phsummary[i]["Status"] = "Failed";
              }
              if(countfilesprocess>0)
              {
                this.phsummary[i]["Status"] = "Inprogress"  +
                " (" +
                countfiles +
                "/" +
                this.phsummary[i]["FileContent"].length +
                ")";
              }


            }
          }
          // this.historymodal();
        }
        // this.ngxUiLoaderService.stop();
      });
  }
  interval: any;
  interval2: any;
  interval3:any

  startIntervalim() {
    this.PHsummary();
    clearInterval(this.intervalex)
    this.intervalim = setInterval(() => {
      this.PHsummary();
      // this.PHsummaryexport();
    }, 40000);

  }
  startintervalex()
  {
    this.PHsummaryexport();
    clearInterval(this.intervalim)
    this.intervalex = setInterval(() => {
      this.PHsummaryexport();
    }, 30000);
  }

  viewfiledata: any[] = [];
  viewfile(filename: string,modal:any) {
    let obj = {
      ftpdetails: {
        Host: this.improute.ftpDetail['Host'],
        Username: this.improute.ftpDetail['Username'],
        Password: this.improute.ftpDetail['Password'],
        Port: this.improute.ftpDetail['Port'],
        FTPFolder: this.improute.ftpDetail['FTPFolder'],
      },
      filename: filename,
    };
    this.ngxUiLoaderService.start();
    this.service.previewfile(obj).subscribe((data) => {
      if (data != "") {
        this.ngxUiLoaderService.stop();
        this.viewMapData = JSON.parse(data["CSVData"]);
        if (this.viewMapData != undefined && this.viewMapData.length > 0) {
        this.modalService.open(modal, { windowClass: "preview_template", backdrop: 'static', keyboard: false }).result.then((result) => { });
          // $("#previewModalRight").modal("show").appendTo("body");
        }
      }
    });
  }

  previewMapData:any[]=[]
  viewMapData =[];
  previewImport(modal) {
      let exist;
      for (let i = 0; i < this.headerListImp.length; i++) {
        for (let j = 0; j < this.csvdataImp.length; j++) {
          if (
            this.headerListImp[i]["header"] === this.csvdataImp[j]["Header"]
          ) {
            exist = this.csvdataImp[j];
            this.previewMapData.push(exist);
            this.viewMapData = this.previewMapData;
          }
        }
      }
      if (
        this.previewMapData != undefined &&
        this.previewMapData.length > 0
      ) {
        this.modalService.open(modal, { windowClass: "preview_template", backdrop: 'static', keyboard: false }).result.then((result) => { });
        // $("#previewModalRight").modal("show").appendTo("body");
      }

  }


  previewsavedata: any[] = [];
  preview(modal) {
    if (this.PatientFormGroup.status == "INVALID") {
      this.toastr.warning("Check required fields", "Data not found", {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });
    } else {
      let exist;
      for (let i = 0; i < this.Headerslist.length; i++) {
        for (let j = 0; j < this.defaultfiledata.length; j++) {
          if (
            this.Headerslist[i]["header"] === this.defaultfiledata[j]["Header"]
          ) {
            exist = this.defaultfiledata[j];
            this.previewsavedata.push(exist);
            this.viewfiledata = this.previewsavedata;
          }
        }
      }
      if (
        this.previewsavedata != undefined &&
        this.previewsavedata.length > 0
      ) {
        this.modalService.open(modal, { windowClass: "preview_template", backdrop: 'static', keyboard: false }).result.then((result) => { });
        // $("#previewModalRight").modal("show").appendTo("body");
      }
    }
  }
  resetpreview(modal) {
    // this.previewsavedata = [];
    // this.viewfiledata = [];

    this.previewMapData =[];
    this.viewMapData=[];
    modal('close modal');
  }
  resetform() {
    this.PatientFormGroup.reset();
    // this.deploymentlist=[];
    this.LogLabOrgList = [];
    this.EventList = [];
    this.selectedDeployement = "";
    this.maincard = false;
    this.fileslist =[]
    this.predefinedcol =[];
    this.Headerslist =[];
    this.connection=false
  }

  resetImpMapping() {
    for (let i = 0; i < this.headerListImp.length; i++) {

        this.headerListImp[i]["disabled"] = false;
    }
  }

  resetmapping() {
    for (let i = 0; i < this.Headerslist.length; i++) {

        this.Headerslist[i]["disabled"] = false;
    }
  }
  copyToClip(data, message: any) {
    if (data != '' && data != undefined) {

      copyToClipboard(data);

      this.toastr.success(message + ' Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
    else {
      this.toastr.warning(message + 'Invalid', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  ngOnDestory() {
    clearInterval(this.interval);
    this.phsummary = [];
  }

  units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  niceBytes(x: any) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this.units[l];
  }
}
