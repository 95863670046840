import { HttpEventType, HttpProgressEvent, HttpResponse, HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
declare const copyToClipboard: any;
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-route-notes',
  templateUrl: './route-notes.component.html',
  styleUrls: ['./route-notes.component.scss']
})
export class RouteNotesComponent implements OnInit {

  @Input() inputDataInModalComponent: any;
  percentDone: number;
  uploadSuccess: boolean;
  filedownloadurl: string = '';
  myFlagForShowFiles: boolean = false;
  myFlagForShowNotes: boolean = false;

  constructor(private httpService: HttpClient,
    private ngxUiLoaderService: NgxUiLoaderService,
    public service: CommonService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal) { }

  headerRoute: any = '';
  userid: string = '';
  headerRoutenamedisplay: any = '';

  async ngOnInit() {
    this.headerRoute = this.inputDataInModalComponent.routeid;
    this.headerRoutenamedisplay = this.inputDataInModalComponent.routename;
    this.userid = this.inputDataInModalComponent.userid;
    await this.getroutenotedata(this.headerRoute);

  }

  routenotelist: any = [];
  routenotelistdata: any = [];
  async getroutenotedata(routeid) {
    var url = "api/Routes/GetRouteNotesList?routeId=" + routeid;
    this.ngxUiLoaderService.start();
    await this.service.getapiurl(url).subscribe({
      next: async data => {
        this.routenotelist = data as any[];
        const resultset = data as any[];
        const resultarray1 = [];

        resultset.forEach(element => {
          if (element.FileInfojson == null || element.FileInfojson.length == 0) {
            element.filename = '';
            resultarray1.push({ element });
          }
          else {
            resultarray1.push({ element });
          }
        });
        this.filecontentastext = [];
        this.content = '';
        this.fileName = '';
        this.filenamelist = [];
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Failed to get Pipeline notes:' + routeid , "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

      }
    });

  }

  copyToClip(event: any,message:any) {
    if (event != '') {

      copyToClipboard(event);
      this.toastr.success(message+" "+'Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });

    }
  }

  closetpopup() {
    this.activeModal.close();
  }


  content: string = '';

  filecontentastext: any = [];
  fileName: string = '';
  filenamelist: any = [];
  async readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        resolve('');
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const text = reader.result.toString();
        resolve(text);
      };

      reader.readAsText(file);
    });
  }
  onFileSelected(event) {
    let list = []
    this.ngxUiLoaderService.start();
    list = event.target.files;
    let f;
    for (let i = 0; i < list.length; i++) {
     
      f = list[i];
      this.filenamelist.push(f.name);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        return function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData.toString());
          fobj.fileContent = base64String;
        };
      })(f);
      reader.readAsBinaryString(f);
      let fobj = {
        fileContent: '',
        filename : list[i].name
      }
      this.filecontentastext.push(fobj);
    }
    this.ngxUiLoaderService.stop();
  }
  fileid: string = '';
  async saveroutenote() {
    if (this.content != '' || this.filecontentastext.length > 0) {
      var datapost = {
        "RouteID": this.headerRoute,
        "Note": this.content,
        "UserID": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
        "FileInfojson": this.filecontentastext, 
        "UserDisplayName": JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid']
      };
      this.ngxUiLoaderService.start();
      let response = await this.service.postapi('api/Routes/SaveRouteNote', datapost);
      if (response['status'] && response['statusText']) {
        this.ngxUiLoaderService.stop();
        this.toastr.error('Unable to save the note', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      } else {
        this.ngxUiLoaderService.stop();
        this.toastr.success('Note saved successfully', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });

        await this.getroutenotedata(this.headerRoute);
      }
    }

  }

 
   
  downloadattachedfile(fileDetails: any) {
    this.ngxUiLoaderService.start()
    let fileDetailslist=[];
    let  fileId=fileDetails.fileid;
    let fileObj={
      fileid:fileId
    }
    fileDetailslist.push(fileObj);
    var apiname = "api/Routes/GetFileContent";
    this.service.postapi(apiname,fileDetailslist).then(response => {
      let data=response as any[];
      for(var i=0;i<data.length;i++){
      const source = `data:${JSON.parse(data[i].statusmessage).contenttype};base64,${data[i]["responsebody"]
        }`;
      const link = document.createElement("a");
      link.href = source;
      link.download = JSON.parse(data[i].statusmessage).filename;
      link.click(); 
      }
    });
    this.ngxUiLoaderService.stop()

    }


  showFiles() {
    this.myFlagForShowFiles = true;
    this.myFlagForShowNotes = false;
  }

  showNotes() {
    this.myFlagForShowNotes = true;
    this.myFlagForShowFiles = false;
  }

  removeFile(file) {
    this.filenamelist = this.filenamelist.filter(obj => obj !== file);
    this.filecontentastext = this.filecontentastext.filter(obj => obj.filename !== file)
  }


}
