// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-table {
  width: 100%;
}

.mat-column-demo-position {
  width: 32px;
  border-right: 1px solid currentColor;
  padding-right: 24px;
  text-align: center;
}

.mat-column-demo-name {
  padding-left: 16px;
  font-size: 20px;
}

.mat-column-demo-weight {
  font-style: italic;
}

.mat-column-demo-symbol {
  width: 32px;
  text-align: center;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/mappingversionapprove/mappingversionapprove.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEE;EACE,WAAA;EACA,oCAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,eAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAEE;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".demo-table {\r\n    width: 100%;\r\n  }\r\n  \r\n  .mat-column-demo-position {\r\n    width: 32px;\r\n    border-right: 1px solid currentColor;\r\n    padding-right: 24px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .mat-column-demo-name {\r\n    padding-left: 16px;\r\n    font-size: 20px;\r\n  }\r\n  \r\n  .mat-column-demo-weight {\r\n    font-style: italic;\r\n  }\r\n  \r\n  .mat-column-demo-symbol {\r\n    width: 32px;\r\n    text-align: center;\r\n    font-weight: bold;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
