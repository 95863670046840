import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ValidatorService } from '../services/hl7validationfunctions.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forEach } from 'jszip';
import { CommonService } from '../services/common.service';
declare const copyToClipboard: any;

@Component({
  selector: 'app-hl7viewer',
  templateUrl: './hl7viewer.component.html',
  styleUrls: ['./hl7viewer.component.scss']
})

export class Hl7viewerComponent implements OnInit {
  VASpec: any;
  noofrows: number = 8;
  constructor(private myServices: ValidatorService,
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    private commonService: CommonService) {
    this.route.queryParams.subscribe(params => {
      //  this.receivedData = params['data'];
      if (params['fileid'] !== undefined && params !== undefined) {
        this.getfileContentById(params['fileid']);
        this.noofrows = 10;
      }
    });

    // this.Parseinputselecteddata(this.inputdata);
  }
  receivedMessage: string = ''
  splittedContent: any[] = []
  fieldvalues: any[] = []
  subcomponentHighlighttext: any
  @Input() inputDataInModalComponent: any;

  async ngOnInit(): Promise<void> {

    this.route.params.subscribe(params => {

      this.receivedMessage = params['message'];
      this.inputdata = this.receivedMessage;
    });
    this.segmentSubcomponentheader = 'Name';
    this.receivedMessage = this.inputDataInModalComponent;
    this.inputdata = this.receivedMessage;

    this.splittedContent = this.inputdata.split("\n");
    if (this.splittedContent[0].split("|")[8].includes("BAR")) {
      this.VASpec = this.commonService.BAR;
    }
    else if (this.splittedContent[0].split("|")[8].includes("DFT")) {
      this.VASpec = this.commonService.DFT;
    }
    else if (this.splittedContent[0].split("|")[8].includes("ORU")) {
      this.VASpec = this.commonService.ORU;
    }
    else {
      this.VASpec = this.commonService.hl7VASpec;
    }



    await this.Parseinputselecteddata(this.inputdata);
    await this.getfieldsbyheaders(this.segmentheader);


  }
  onKeydown(event: any) {
    console.log("inside onKeydown ");
    event.preventDefault();
  }
  onSpace(event: any) {
    console.log("inside onSpace")
    event.preventDefault();
  }

  inputdata: any = '';
  segments: any = [];
  displaynamesegheader: string = 'Name';
  segmentheader: string = 'Name'

  async Parseinputselecteddata(segdata: any) {
    this.segments = segdata.split('\n');
    if (this.segments.length > 0) {
      //this.segmentheader = this.segments[0].substr(0, 3);
      if (this.segments[0].includes('|')) {
        this.segmentheader = this.segments[0].split('|')[0];
      }
      else {
        this.segmentheader = 'Name';
      }
    }
  }
  returnvalue: any
  getfieldsbyheaders(segment: any) {

    this.returnvalue = undefined
    this.fieldvalues = []
    let currectSegment = [];
    if (this.VASpec[segment] == undefined) {
      this.SelectedSubsegComponentfileds = []
      this.SelectedsegComponentfileds = []
    }

    for (let index = 0; index < this.VASpec[segment].length; index++) {
      let vaspecvalue = {
        "segindex": 0,
        "segfield": "References Range",
        "segtype": "",
        "segvalue": "",
        "segmentheader": "",
        "segcomponent": []
      }
      currectSegment = this.segments.filter((va: string) => va.toUpperCase().includes(segment.toUpperCase()));


      // if (currectSegment.length > 0) {
      //   this.returnvalue = currectSegment[0].split('|')[this.VASpec[segment][index].segindex];
      // }
      if (segment == "MSH") {
        currectSegment[0] = currectSegment[0].replace("|", "|star|")
        this.returnvalue = currectSegment[0].split('|')[this.VASpec[segment][index].segindex];
        if (this.returnvalue.includes("star")) {
          this.returnvalue = "|"
        }

      }
      else {
        this.returnvalue = currectSegment[0].split('|')[this.VASpec[segment][index].segindex];
      }




      if (this.returnvalue != '' && this.returnvalue != undefined) {
        (vaspecvalue.segvalue = this.returnvalue)
        vaspecvalue.segindex = this.VASpec[segment][index].segindex
        vaspecvalue.segfield = this.VASpec[segment][index].segfield
        vaspecvalue.segtype = this.VASpec[segment][index].segtype
        vaspecvalue.segmentheader = segment
        vaspecvalue.segcomponent = this.VASpec[segment][index].segcomponent
        let res = this.myServices.validate(this.returnvalue, segment, vaspecvalue);

        if (res !== '') {
          vaspecvalue.segtype = '[' + res + ']';
        }


        this.fieldvalues.push(vaspecvalue);

        //  {segmentheader +' '+objsegment.segindex
      }


    }
  }

  showdiv: boolean

  getsegmentfieldvaluebyindex(segment: any, dataitem: any) {
    let currectSegment = [];
    let returnvalue = '';
    currectSegment = this.segments.filter((va: string) => va.toUpperCase().includes(segment.toUpperCase()));
    if (currectSegment.length > 0) {
      returnvalue = currectSegment[0].split('|')[dataitem.segindex];
    }
    let res = this.myServices.validate(returnvalue, segment, dataitem);
    if (res !== '') {
      dataitem.segvalue = '[' + res + ']';
      this.showdiv = true
    }
    else {
      this.showdiv = false
    }
    return returnvalue;
  }

  shouldDisplaySegment(header, objsegment): boolean {

    // Call the function to get the return value
    let returnValue = this.getsegmentfieldvaluebyindex(header, objsegment);
    // Return true if the return value is not empty, false otherwise
    return returnValue !== '';

  }
  rowClicked: any;
  arrayindex: any
  segmentcomponentheader: string = 'Name';
  SelectedsegComponentfileds: any = [];
  async selectSegment(segmentname: any, segmentfield: any, HighlightIndex: any, arrayIndex) {

    this.llarrayvalues = []
    this.arrayindex = arrayIndex;
    this.arrayvalues = []
    this.toStoreAccruence = []
    this.rowClickedSub = undefined;
    this.SelectedsegComponentfileds = [];
    this.SelectedSubsegComponentfileds = [];
    this.segmentSubcomponentheader = 'Name';
    this.rowClicked = arrayIndex;
    this.segmentcomponentheader = segmentname + ' ' + segmentfield.segindex;

    let currectSegment = [];
    let segmentfieldvalue = '';
    currectSegment = this.segments.filter((va: string) => va.toUpperCase().includes(segmentname.toUpperCase()));

    if (currectSegment.length > 0) {
      if (currectSegment[0].startsWith("MSH")) {
        segmentfieldvalue = currectSegment[0].split('|')[segmentfield.segindex - 1];
      }

      else {
        segmentfieldvalue = currectSegment[0].split('|')[segmentfield.segindex];
      }
    }

    // this.highlight(segmentfieldvalue, arrayIndex, this.segments)

    this.highLightFunction(segmentfieldvalue, arrayIndex, HighlightIndex)
    if (segmentname == "MSH") {

      if (segmentfieldvalue.toString().includes("^")) {
        segmentfieldvalue = segmentfieldvalue.replace('^', "star")
      }
    }
    if (segmentfieldvalue == "MSH") {
      segmentfieldvalue = "|"
    }
    segmentfieldvalue = '^' + segmentfieldvalue;


    if (segmentfield.segcomponent.length < segmentfieldvalue.split('^').length) {
      for (let i = segmentfield.segcomponent.length; i < segmentfieldvalue.split('^').length - 1; i++) {
        segmentfield.segcomponent.push({
          segindex: i + 1,
          segfield: '',
          segtype: '',
          segvalue: '',
          segcomponent: [],
        });
      }
    }
    segmentfield.segcomponent.forEach((ele: any) => {

      if (segmentfieldvalue.split('^')[ele.segindex])

        ele.segvalue = segmentfieldvalue.split('^')[ele.segindex];
      if (ele.segvalue.includes("star")) {
        ele.segvalue = ele.segvalue.replaceAll('star', '^')
      }
      if (ele.segvalue != '' && ele.segvalue != undefined) {
        this.SelectedsegComponentfileds.push(ele);
      }

    });
    await this.focus();
    // for double click
    this.matMenuTimer = setTimeout(() => { this.clickedMe(); }, 300);

  }

  segmentSubcomponentheader: string = 'Name';
  SelectedSubsegComponentfileds: any = [];
  rowClickedSub: any;
  async selectSegmentCom(segmentfield: any, index: any) {
    this.SelectedSubsegComponentfileds = [];
    this.rowClickedSub = index;
    this.segmentSubcomponentheader = this.segmentcomponentheader + '.' + segmentfield.segindex;
    this.SelectedSubsegComponentfileds = segmentfield.segcomponent;


    let segmentfieldvalue = segmentfield.segvalue;
    if (segmentfieldvalue == "^~\\&") {

      this.SelectedSubsegComponentfileds = []
      this.SelectedSubsegComponentfileds.push({
        segindex: this.segmentcomponentheader + '.' + segmentfield.segindex + '.' + 1,
        segfield: '',
        segtype: '',
        segvalue: "^~\\&",
        segcomponent: [],
      });
      return
    }

    if (segmentfieldvalue && this.SelectedSubsegComponentfileds.length > 0) {
      segmentfieldvalue = '&' + segmentfieldvalue;
      // this.SelectedSubsegComponentvalues = segmentfieldvalue.split('&');
    }
    else {
      segmentfieldvalue = '&' + segmentfieldvalue;
      for (let i = 1; i < segmentfieldvalue.split('&').length; i++) {


        this.SelectedSubsegComponentfileds.push({
          segindex: this.segmentcomponentheader + '.' + segmentfield.segindex + '.' + i,
          segfield: '',
          segtype: '',
          segvalue: segmentfieldvalue.split('&')[i],
          segcomponent: [],
        });
      }

    }

    await this.focus();
    // for double click
    this.matMenuTimer = setTimeout(() => { this.clickedMe(); }, 300);
  }

  dosegmentvalidate(dataitem: any) {
    if (dataitem.segtype == "NOTEMPTY" && dataitem.segvalue.length == 0) {
      return "[Mandatory]"
    }
    return "";
  }


  clearvalues() {
    this.SelectedsegComponentfileds = [];
    this.SelectedSubsegComponentfileds = [];
    this.segmentcomponentheader = 'Name';
    this.segmentSubcomponentheader = 'Name';
  }

  Yindex: number = -1;
  //textarea element

  selectedSegment: string
  filedIndexs = 0
  async doEditorKeyUp(SelectedsegmentContent: string, filedIndex) {
    if (this.filedIndexs != filedIndex) {
      this.valuetoHighlight = ''
    }
    this.filedIndexs = filedIndex

    let data = this.inputdata.split("\n")
    SelectedsegmentContent = data[filedIndex]
    this.selectedSegment = ""
    // if (SelectedsegmentContent.includes("mark")) {
    //   SelectedsegmentContent = SelectedsegmentContent.replace("</mark>", "")
    //   SelectedsegmentContent = SelectedsegmentContent.replace("<mark>", "")
    // }

    this.selectedSegment = SelectedsegmentContent;
    await this.Parseinputselecteddata(SelectedsegmentContent);

    this.splittedContent = this.inputdata.split("\n");

    if (this.valuetoHighlight != "" && this.highlightSegment == SelectedsegmentContent) {
      this.highLightFunction(this.valuetoHighlight, this.HeighlightIndex, this.segIndexs)

    }
    else {
      this.rowClicked = -1
      this.rowClickedSub = -1
      this.SelectedSubsegComponentfileds = []
      this.SelectedsegComponentfileds = []
      this.segmentcomponentheader ='Name';
      this.segmentSubcomponentheader='Name';
    }

    this.getfieldsbyheaders(this.segmentheader);

  }


  setselectionrange(position: any) {
    // const textarea = document.querySelector('textarea');

    const textarea: HTMLTextAreaElement = this.myinput.nativeElement;

    if (textarea != null) {
      var b = this.inputdata.split("\n");
      for (var i = 0; i < b.length; i++) {
        b[i] = b[i].replace('\n', '');
      }

      var c = 0, d = 0;
      for (var i = 0; i < b.length; i++) {

        if (i != position) {
          d += b[i].length + 1
        }
        else {
          break;
        }
      }
      textarea.focus();
      textarea.setSelectionRange(d, d + this.inputdata.split("\n")[position].length);
    }
  }

  getselectedpositions(selectionStart: any) {
    var a = selectionStart;
    var b = this.inputdata.split("\n");
    var c = 0, d = 0;
    for (var i = 0; i < b.length; i++) {
      if (d + b[i].length < a) {
        d += b[i].length + 1
        c = i;
      }
      else {
        c = i;
        break
      }
    }
    // for (var a = selectionStart, b = this.inputdata.split("\n"), c = 0, d = 0; d + b[c].length < a;) d += b[c].length + 1, c++;
    return c
  };
  doEditorKeychange(event: any) {

    console.log("inside doEditorKeychange")
    var SelectedsegmentContent = this.inputdata.split("\n")[1];
    this.Parseinputselecteddata(SelectedsegmentContent);
  }

  async onPaste(item: any) {
    console.log("inside onPaste ")

    await this.clearAll();
    this.Parseinputselecteddata(item);
    await this.focus();
  }
  doDropMessage(item: any) {

    console.log("Inside doDropMessage")
    console.log("doDropMessage::", item);
  }

  getCursorX(selectionStart: any) {
    for (var a = this.inputdata.split("\n"), b = 0, c = this.getCursorY(selectionStart), d = 0; d < c; d++) b += a[d].length + 1;
    return selectionStart - b
  };
  getCursorY(selectionStart: any) {
    for (var a = selectionStart, b = this.inputdata.split("\n"), c = 0, d = 0; d + b[c].length < a;) d += b[c].length + 1, c++;
    return c
  };

  @ViewChild('myInput', { static: false }) myinput!: ElementRef<any>;

  async focus() {
    this.myinput.nativeElement.focus();
  }

  //page menu functions
  async clearAll() {
    this.inputdata = "";
    this.segments = [];
    this.segmentheader = 'Name';
    this.displaynamesegheader = 'Name';
    this.rowClicked = -1;
    this.rowClickedSub = -1;
    this.segmentcomponentheader = 'Name';
    this.SelectedsegComponentfileds = [];
    this.segmentSubcomponentheader = 'Name';
    this.SelectedSubsegComponentfileds = [];
    this.Yindex = -1;
  }

  async paste() {
    await this.clearAll();
    this.inputdata = await (await navigator.clipboard.readText()).trim();
  }
  copyMessage(val: string, type: string) {

    if (val) {
      copyToClipboard(val);




      this.toastr.success(' Copied to clipboard !', '', {
        positionClass: "toast-bottom-right",
        timeOut: 4000,
      });

    }
  }

  trigger() {
    let element = document.getElementById('upload_file') as HTMLInputElement;
    element.click();
  }

  onChange(file: any) {
    var file = file.files[0];
    var reader = new FileReader();
    reader.onload = (e: any) => {
      this.inputdata = e.target.result;
    };
    reader.readAsText(file);
  }
  async DoubleClickselectsubSegmentCom(segment: any) {

    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
    await this.copyMessage(segment.segvalue, '');
    await this.focus();
  }
  matMenuTimer: any;
  async DoubleClickselectSegment(segment: any) {

    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
    await this.copyMessage(segment, '');
    await this.focus();
  }

  clickedMe() {
    if (!this.matMenuTimer) return;
  }
  async DoubleClickselectSegmentCom(dataitem: any) {

    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
    await this.copyMessage(dataitem.segvalue, '');
    await this.focus();
  }
  getspechelemnt() {
    this.VASpec[this.segmentheader]
  }
  getTitle(objdata: any) {
    return (objdata == 'Mandatory' || objdata == '') ? 'Value could not be empty' : 'The expected format is ' + objdata;
  }

  async getfileContentById(fileid: any) {
    this.ngxUiLoaderService.start();
    (await this.myServices.getfilecontent(fileid)).subscribe({
      next: data => {
        // const file = new window.Blob([data], { type: 'application/octet-stream' });
        // const downloadAncher = document.createElement("a");
        // const fileURL = URL.createObjectURL(file);
        // downloadAncher.href = fileURL;
        // downloadAncher.download = fileid;
        // downloadAncher.click();
        this.inputdata = data;
        // var SelectedsegmentContent = this.inputdata.split("\n")[0];
        // this.Parseinputselecteddata(SelectedsegmentContent);
        // const textarea = document.querySelector('textarea');
        // if (textarea != null) {
        //   textarea.setSelectionRange(0, this.inputdata.split("\n")[0].length);
        // }
        if (this.checkJsonValidity(data)) {
          this.noofrows = 10;
          this.getfileContentById(JSON.parse(data).fileid)
          this.ngxUiLoaderService.stop();
        }
        this.noofrows = 10;
        this.myServices.updatessoStatus(true);
        this.ngxUiLoaderService.stop();
      },
      error: error => {
        this.ngxUiLoaderService.stop();
        console.log('Failed to retrieve the file contents' + error);
      }
    });
  }

  checkJsonValidity(datastring: any) {
    try {
      JSON.parse(datastring);
      return true;
    } catch (error) {
      return false;
    }
  }
  closeEmail() {
    this.activeModal.close();
  }
  highlightedText: string
  valuetoHighlight: any
  highlightSegment: any
  HeighlightIndex: any
  textHighlight(text, Occ, segmentIndexToGetFields, highlightIndex) {
    this.valuetoHighlight = text;
    this.splittedContent = this.inputdata.split("\n");
    if (this.splittedContent[segmentIndexToGetFields].includes("mark")) {
      this.splittedContent[segmentIndexToGetFields] = this.splittedContent[segmentIndexToGetFields].replace("</mark>", "")
      this.splittedContent[segmentIndexToGetFields] = this.splittedContent[segmentIndexToGetFields].replace("<mark>", "")
    }
    this.selectedSegment = this.splittedContent[segmentIndexToGetFields];
    this.highlightSegment = this.selectedSegment;
    let count = 0;
    let index = -1;
    let splittedcontent = this.selectedSegment.split("|");
    while (count < Occ) {

      index = this.selectedSegment.indexOf(text, index + 1);
      if (index === -1) break;
      count++;
    }
    if (index >= 0) {
      let HTML = ""
      const highlightedText = `<mark>${text}</mark>`;
      if (this.splittedContent[segmentIndexToGetFields].startsWith("MSH")) {
        highlightIndex = highlightIndex - 1
      }

      if (text == "MSH" && highlightIndex == 0) {
        const highlightedText = `<mark>${"|"}</mark>`;
        this.selectedSegment = this.selectedSegment.substring(0, 3) + highlightedText + this.selectedSegment.substring(5, this.selectedSegment.length)
      } else {


        for (let index = 0; index < splittedcontent.length; index++) {

          if (splittedcontent[index] == text && index == highlightIndex) {
            HTML = HTML + highlightedText + "|"
          }
          else {
            HTML = HTML + splittedcontent[index] + "|";
          }


          //const updatedHTML = this.selectedSegment.substring(0, index) +highlightedText +this.selectedSegment.substring(index + text.length);


        }
        this.selectedSegment = HTML.slice(0, -1);
      }
      // alongwith mark tag
      // for (let index = 0; index < this.splittedContent.length; index++) {
      //   if (this.splittedContent[index] == segment[0]) {
      //     this.segmentIndexToGetFields[index] = this.selectedSegment;
      //     break;
      //   }
      // }
      this.splittedContent[segmentIndexToGetFields] = this.selectedSegment

    }
  }

  isPartOfWord(index: number, length: number, segment: string): boolean {
    const prevChar = segment[index - 1];
    const nextChar = segment[index + length];
    return (prevChar && prevChar.match(/[a-zA-Z]/) !== null) || (nextChar && nextChar.match(/[a-zA-Z]/) !== null);
  }
  toStoreAccruence: any = []
  arrayvalues: any = []
  llarrayvalues: any[]
  segIndexs: any
  highLightFunction(item: string, idex: number, segIndex: any) {
    this.HeighlightIndex = idex;
    this.valuetoHighlight = item;
    this.segIndexs = segIndex


    let Occ = this.addObjectIfMissing(this.toStoreAccruence, idex, item, this.fieldvalues);

    this.textHighlight(item, Occ, this.filedIndexs, segIndex)
  }

  addObjectIfMissing(occurancebook, index, itemName, arrayofFields) {
    const foundIndex = occurancebook.findIndex(item => item['name'] == itemName);

    if (foundIndex == -1) {
      const container = { name: itemName, indexes: [index] };
      occurancebook.push(container);

      this.arrayvalues = occurancebook[occurancebook.findIndex(item => item['name'] == itemName)];
      this.llarrayvalues = this.arrayvalues['indexes'];

      for (let index2 = 0; index2 < arrayofFields.length; index2++) {
        if (arrayofFields[index2].segvalue == itemName) {
          if (!this.llarrayvalues.includes(index2)) {
            this.llarrayvalues.push(index2);
            this.llarrayvalues.sort((a, b) => a - b);
          }
        }

      }
    }
    return this.llarrayvalues.indexOf(index) + 1;
  }


}



