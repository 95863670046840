// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font_size_vatracelogs {
  font-size: 12px !important;
}

.filecompare_input {
  height: 30px !important;
  font-size: 11px;
  padding-left: 1%;
}

.form-control.filecompare_input {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}

.card {
  background-color: rgba(211, 211, 211, 0.2509803922);
}

.date_control_style {
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-inline: 2%;
}

.cardstyle {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 67vh !important;
}`, "",{"version":3,"sources":["webpack://./src/app/tracelog/tracelog.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AACA;EACI,0BCDO;ADCX;;AAEE;EACE,uBCDS;EDET,eAAA;EACA,gBAAA;AACJ;;AACE;EAEE,oDAAA;EACA,6BAAA;AACJ;;AACE;EACE,mDAAA;AAEJ;;AAAE;EACE,WAAA;EACA,+GAAA;EACA,kBAAA;EACA,kBAAA;AAGJ;;AACA;EACE,kBAAA;EACE,gBAAA;EACA,kBAAA;EACA,2BAAA;AAEJ","sourcesContent":["@import \"../../variables.scss\";\r\n.font_size_vatracelogs {\r\n    font-size: $fontsize\r\n  }\r\n  .filecompare_input{\r\n    height:$inputHeight;\r\n    font-size: 11px;\r\n    padding-left: 1%;\r\n  }\r\n  .form-control.filecompare_input\r\n  {\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;\r\n    border-radius: 5px !important;\r\n  }\r\n  .card{\r\n    background-color: #d3d3d340;\r\n  }\r\n  .date_control_style {\r\n    width: 100%;\r\n    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);\r\n    border-radius: 5px;\r\n    padding-inline: 2%;\r\n    // padding-block: 3%;\r\n}\r\n\r\n.cardstyle{\r\n  position: relative;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n    max-height: calc(100vh - 33vh) !important;\r\n}","$btn-color: #17a0b4 !important;\r\n$fontsize: 12px !important;\r\n$btntextcolor: white !important;\r\n$btnHeight: 28px !important;\r\n$inputHeight:30px !important;\r\n$inputfontsize:11px !important;\r\n$primary:#004bff;\r\n// $modalHeaderTitleFont: ;\r\n@mixin modalheaderTtext {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
