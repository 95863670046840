import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-varulestooltip',
  templateUrl: './varulestooltip.component.html',
  styleUrls: ['./varulestooltip.component.scss']
})
export class VarulestooltipComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  constructor(private ngactive: NgbActiveModal) { }

  buildtitle(data) {
    return data.Status == '1' ? 'Active varule' : 'Passive varule';
  }
  ngOnInit() {
    this.Rulelist = this.inputDataInModalComponent;
    this.InterfaceName = this.inputDataInModalComponent.InterfaceName;
  }

  InterfaceName: string = '';

  closetpopup() {
    this.ngactive.close()
  }
  Rulelist: any = [];
  currentlyOpenedItemIndex = -1;

  setOpened(itemIndex) {
    this.currentlyOpenedItemIndex = itemIndex;
  }

  setClosed(itemIndex) {
    if (this.currentlyOpenedItemIndex === itemIndex) {
      this.currentlyOpenedItemIndex = -1;
    }
  }
  step = 0;
  setStep(index: number) {
    this.step = index;
  }
}
