import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { interfaceLabAdminServices } from '../services/InterfaceLabAdmin.services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-labadmininterfaces',
  templateUrl: './labadmininterfaces.component.html',
  styleUrls: ['./labadmininterfaces.component.scss']
})
export class LabadmininterfacesComponent implements OnInit {

  LabadminLoginInput: any = {}
  listpagedisplay: boolean = false;
  messagetype: any;
  inputDataInModal: any;
  inputDataInModalComponent: any;
  interfacelistdirection: string = '';
  messagestatus: any;

  constructor(
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private service: interfaceLabAdminServices,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public cmmservice: CommonService
  ) {
    this.service.interfacelistpagedisplay.subscribe(
      (data: boolean) => {
        this.listpagedisplay = data;
      });

    this.route.queryParams.subscribe((params: any) => {
      if (params.Input != undefined && params.Input != "") {
        sessionStorage.setItem('LabadminLoginEncryptData', params.Input);
        this.cmmservice.updatessoStatus(true);
      }
      else {
        let encrypttemp = "qc5W/VGZVreBONlplEMfRgWoZqhHefxRnKr8jCqumfKF6WzCd5F96m683rzthpMgI+KLFCCTQEsYe/m55V1s5vw73COgtXA4B/xH+TumndsLb7JNeev5zlHm4kaSXsDjFGtSy80AVkI3WHMlGQ66lRHEPSdBzj1B4czOSOziAK4070biMrnaE3HqGjCHKgZwataglLFa1h385Uze12B+QRCQvDuCtkPM6029QmVBmBE9ShgWgyK9puNva7TND7UD7TiVjVTumfMPsbBbRkvib+h7AgM+7N1NtE8K/gEz44zJ621P7LbyuuZsET3CHEPgSSpG+4PXXDx7Gr3P+diHzpqKPxT+DfemIMLu63Pbjw==";
        sessionStorage.setItem('LabadminLoginEncryptData', encrypttemp);
      }
    });

  }
  //url: string = 'https://interfaceadmin-qc.vitalaxis.net/SSOLaunch?Input=qc5W/VGZVreBONlplEMfRgWoZqhHefxRnKr8jCqumfKF6WzCd5F96m683rzthpMgIv2bZHPPDmAEZvC46Hts8apKmxPM2385HOpH5D6pkqJ5d+MEKffuiRmN9RHaBZO/DTghjYFVBgN0emojCVW6jjKHfGAzhnBp9c37YuDmC64i71/wNYr7E3ntVD6fRBFwSN+Nl6ptygD9tRKZ4EFYaA7W4nvO7RCBtROnE0sdl3xZOEtYrWbg65w7L6LEFY8WjnfUjVPnis8C96hSUjuZG9FnSRZLovth+RhTgyEi7JK9n2RJvK/MrZ5KNxT0RnSZGSpQ6tmHB2BnW6PPgpmizYCWIjn7D5r6e5K5+E3ngI8Ex/sMzMNA84Wwy4l/';

  fromDate: any = new Date();
  ngOnInit() {

    // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.selectedTabValue('Order');
   // this.GetLoginedLabadminData();

  }

  ngOnChanges() {
    this.service.interfacelistpagedisplay.subscribe(
      (data: boolean) => {
        this.listpagedisplay = data;
      });

  }

  selectedTabValue(event) {
    this.messagestatus=12;
    this.listpagedisplay = false;
    if (event.index !== undefined) {
      this.messagetype = event.tab.textLabel;
      if (this.messagetype == 'Orders') {
        this.messagetype = 'Order'
      }
      if (this.messagetype == 'Results') {
        this.messagetype = 'Result'
      }
    }
    else {
      this.messagetype = event;
    }
    this.GetLoginedLabadminData();
    //message headercount
    this.inputDataInModal =
    {
      messagetype: this.messagetype,
      fromDate: this.service.formatDate(this.fromDate) + ' ' + '00:00',
      toDate: this.service.formatDate(this.fromDate) + ' ' + this.formatTime(new Date()),
      fromtime: '00:00',
      totime: this.formatTime(new Date()),
      labadminLoginInput: this.LabadminLoginInput
    }
    //message list page
    this.inputDataInModalComponent = {
      dynamiclabelvalue: '',
      direction:'', //this.interfacelistdirection,
      dynamiclabelname: '',
      InterfaceDetailsShow: false,
      messagetype: this.messagetype,
      messagestatus:this.messagestatus,
    }
    

    if ((sessionStorage.getItem('messagelistfromdate')?.toString() !== undefined &&
      sessionStorage.getItem('messagelistfromdate')?.toString() !== '') &&
      (
        sessionStorage.getItem('messagelisttodate')?.toString() !== undefined &&
        sessionStorage.getItem('messagelisttodate')?.toString() !== ''
      )
    ) {
      sessionStorage.removeItem("messagelistfromdate");
      sessionStorage.removeItem("messagelisttodate");
    }

  }


  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  GetLoginedLabadminData() {
    let obj = {
      Param: sessionStorage.getItem('LabadminLoginEncryptData').toString()
    }
    //this.ngxUiLoaderService.start();
    this.service.GetLoginedLabadminData(obj).subscribe((data: any) => {
      //console.log(data);
      sessionStorage.setItem('LabadminLoggedMetaData', JSON.stringify(data));
      this.LabadminLoginInput = data;
      //message header count
      this.inputDataInModal =
      {
        messagetype: this.messagetype,
        fromDate: this.service.formatDate(this.fromDate) + ' ' + '00:00',
        toDate: this.service.formatDate(this.fromDate) + ' ' + this.formatTime(new Date()),
        fromtime: '00:00',
        totime: this.formatTime(new Date()),
        labadminLoginInput: this.LabadminLoginInput
      }
      // message list
       this.inputDataInModalComponent = {
         dynamiclabelvalue: '',
         direction: '',
         dynamiclabelname: '',
         InterfaceDetailsShow: false,
         messagetype: this.messagetype,
         messagestatus:this.messagestatus
       }

      // this.ngxUiLoaderService.stop();
    },
      (error) => {
        this.toastr.warning("Error occured while calling api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        //this.ngxUiLoaderService.stop();
      }
    );
  }

  //urlSafe: SafeResourceUrl;

  receiveDataFromMessageList(data: any) {
    this.inputDataInModal = data;
  }
  receiveDataFromMessageheader(data: any) {
    this.interfacelistdirection = data.directions;
   
    if(data.messagestatus!=undefined &&data.messagestatus!='')
    {
      if(data.messagestatus=="Success")
      {
        this.messagestatus=11;
      }
      if(data.messagestatus=="Failed")
      {
        this.messagestatus=12;  
      }
      if(data.messagestatus=="Resolved")
      {
        this.messagestatus=13;  
      }
      if(data.messagestatus=="In-progress")
      {
        this.messagestatus=10;  
      }
    }
    else
    {
      this.messagestatus=12;
    }
      if ((sessionStorage.getItem('messagelistfromdate')?.toString() !== undefined &&
    sessionStorage.getItem('messagelistfromdate')?.toString() !== '') &&
    (
      sessionStorage.getItem('messagelisttodate')?.toString() !== undefined &&
      sessionStorage.getItem('messagelisttodate')?.toString() !== ''
    )
  ) {
    sessionStorage.removeItem("messagelistfromdate");
    sessionStorage.removeItem("messagelisttodate");  
    sessionStorage.setItem('messagelistfromdate',  this.inputDataInModal.fromDate);
    sessionStorage.setItem('messagelisttodate',  this.inputDataInModal.toDate);
  }
  else{
    sessionStorage.setItem('messagelistfromdate',  this.inputDataInModal.fromDate);
    sessionStorage.setItem('messagelisttodate',  this.inputDataInModal.toDate);
   
  }
    //message list page
    this.inputDataInModalComponent = {
      dynamiclabelvalue: '',
      direction: this.interfacelistdirection,
      dynamiclabelname: '',
      InterfaceDetailsShow: false,
      messagetype: this.messagetype,
      messagestatus:this.messagestatus
    }

   
  }
  receiveDataFromIntrfacelist(data: any) {
    this.inputDataInModal = data;

    sessionStorage.setItem('messagelistfromdate', data.fromDate);
    sessionStorage.setItem('messagelisttodate', data.toDate);
    sessionStorage.setItem('datafrominterfacelist',  'true');
  }





}
