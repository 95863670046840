import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Hl7viewerComponent } from '../hl7viewer/hl7viewer.component';


@Component({
  selector: 'app-file-view-explorer',
  templateUrl: './file-view-explorer.component.html',
  styleUrls: ['./file-view-explorer.component.scss']
})
export class FileViewExplorerComponent implements OnInit {
  pdfSrc: any;
  @Input() fileData;
  @Input() messageData;
  @Input() fileType='pdf';
  mediatypedisplay: boolean = true;
  displaytype:boolean=true;
  isRead: boolean = false;
  Name: any;
  inputdata: string = "";
  formatType: string = 'hl7';
  inputdataXml = ""
  fileOutput: any;
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {

  }
  ngOnInit() {
    this.Name = this.fileData.name;
    if (this.fileType == "pdf") {
      this.isRead=true;
      this.mediatypedisplay = false;
      this.pdfSrc = this._base64ToArrayBuffer(this.fileData.fullfile)
     // this.pdfSrc = this._base64ToArrayBuffer(this.fileData.fullfile)
    }else if(this.fileType=="hl7"){
      this.isRead = true;
      this.mediatypedisplay = true;
      this.pdfSrc= this._base64ToArrayBuffer(this.fileData.fullfile)
     //this.editorInit(this.editor);
    }
    else if(this.fileType=="json"){
      this.isRead = true;
      this.mediatypedisplay = true;
      this.pdfSrc= this._base64ToArrayBuffer(this.fileData.fullfile)
     //this.editorInit(this.editor);
    } else {
      this.isRead = true;
      this.mediatypedisplay = true;
      this.pdfSrc= this._base64ToArrayBuffer(this.fileData.fullfile)
    }
  }

  closetpopup() {
    this.activeModal.close();
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  editorOptions= {theme: 'vs', language: this.formatType,readOnly:true, wordWrap: 'on',scrollBeyondLastLine: false
};
  editorInit(editor: any) {
    this.inputdata = this.fileData.fullfile;
  }

  HL7Viewer(message: string) {

    if(message==null){

       this.toastr.error("The given input is empty", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
      return
    }
    if (message.startsWith('MSH|^')) {
      const modalRef = this.modalService.open(Hl7viewerComponent,
        { windowClass: "myCustomModalClass", backdrop: 'static', keyboard: false });
      modalRef.componentInstance.inputDataInModalComponent = message;
    }

    else {
      this.toastr.error("The given input not in HL7 format", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }

}
