// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input {
  display: none;
}

a:link, a:visited {
  text-decoration: none;
  color: rgb(73, 73, 179);
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

a.active .navlink.active {
  text-decoration: underline;
  color: #01790b;
}

.screenheight {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ellipsisfull {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100% !important; */
  max-width: 60%;
}

.icons {
  font-size: 16px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/route-notes/route-notes.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,qBAAA;EACA,uBAAA;AAEF;;AACA;EACE,0BAAA;AAEF;;AACA;EACE,0BAAA;AAEF;;AAAA;EACE,0BAAA;EACA,cAAA;AAGF;;AADA;EACE,iBAAA;EACE,kBAAA;EACA,gBAAA;AAIJ;;AADA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,4BAAA;EACA,cAAA;AAIF;;AADA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;AAIF","sourcesContent":[".file-input {\r\n  display: none;\r\n}\r\na:link ,a:visited {\r\n  text-decoration: none;\r\n  color: rgb(73, 73, 179);\r\n}\r\n\r\na:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\na:active {\r\n  text-decoration: underline;\r\n}\r\na.active .navlink.active {\r\n  text-decoration: underline;\r\n  color: #01790b\r\n}\r\n.screenheight{\r\n  max-height: 350px;\r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n}\r\n\r\n.ellipsisfull{\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  /* width: 100% !important; */\r\n  max-width: 60%;\r\n}\r\n\r\n.icons{\r\n  font-size: 16px;\r\n  width: 16px;\r\n  height: 16px;\r\n  position: relative;\r\n  top: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
