// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  width: -moz-fit-content;
  width: fit-content;
}

.modal-body {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/app/file-view-explorer/file-view-explorer.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;AACJ;;AAEA;EACI,uBAAA;EAAA,kBAAA;AACJ","sourcesContent":[".modal-content{\r\n    width:fit-content ;\r\n}\r\n\r\n.modal-body{\r\n    width: fit-content;\r\n}\r\n\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
