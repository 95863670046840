// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_control_jobproperties {
  height: 30px !important;
  font-size: 11px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
  padding-left: 1%;
}

.lables_font_jobproperties {
  font-size: 13px !important;
}

.small-title {
  font-size: 14px;
}

.custom-modal {
  font-size: 14px;
}

.custom-modal .modal-header h5,
.custom-modal .modal-body label,
.custom-modal .modal-body input {
  font-size: 14px;
}

.custom-modal .modal-footer .btn {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/jobproperties/jobproperties.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,uBCCW;EDAX,0BCCa;EDAb,oDAAA;EACA,gBAAA;AADF;;AAGA;EACE,0BAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;;;EAGE,eAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":["@import \"../../variables.scss\";\r\n\r\n.search_control_jobproperties{\r\n  height:$inputHeight;\r\n  font-size:$inputfontsize;\r\n  box-shadow:0 2px 5px rgb(0 0 0 / 25%) !important;\r\n  padding-left: 1%;\r\n}\r\n.lables_font_jobproperties{\r\n  font-size: 13px !important;\r\n}\r\n\r\n.small-title {\r\n  font-size: 14px; \r\n}\r\n\r\n.custom-modal {\r\n  font-size: 14px;\r\n}\r\n\r\n.custom-modal .modal-header h5,\r\n.custom-modal .modal-body label,\r\n.custom-modal .modal-body input {\r\n  font-size: 14px;\r\n}\r\n\r\n.custom-modal .modal-footer .btn {\r\n  font-size: 14px;\r\n}\r\n","$btn-color: #17a0b4 !important;\r\n$fontsize: 12px !important;\r\n$btntextcolor: white !important;\r\n$btnHeight: 28px !important;\r\n$inputHeight:30px !important;\r\n$inputfontsize:11px !important;\r\n$primary:#004bff;\r\n// $modalHeaderTitleFont: ;\r\n@mixin modalheaderTtext {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
