import {
  Component,
  Input,
  OnInit
} from "@angular/core";
import {
  MatTableDataSource,
} from "@angular/material/table";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from "src/app/services/common.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-casedetails',
  templateUrl: './casedetails.component.html',
  styleUrls: ['./casedetails.component.scss']
})
export class CaseDetailsComponent implements OnInit {
  @Input() FilterFields = [];
  auditDetails: any[] = [];
  pagerolepermisions: any;
  nodataalert: boolean = true;
  displayedColumns: string[] = [
    'accession',
    'application',
    'entitytype',
    'action',
    'reason',
    'interface',
    'laboratory',
    'filename',
    'messagereferenceid',
    'transactionid',
    'transactiondate',
    'content'
  ];
  dataSource = new MatTableDataSource<any>([]);
  transactions: any[] = [];
  allTransactions: { TransactionId: string; TransactionDate: string }[] = [];

  constructor(
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private service: CommonService,
    private clipboard: Clipboard
  ) {
  }

  ngOnInit() {
    this.GetTransactionDetails();
  }

  GetTransactionDetails() {
    this.ngxUiLoaderService.start();

    const transactionDetails = {
      Accession: this.FilterFields.find(field => field.Accession)?.Accession || "",
      Requisition: this.FilterFields.find(field => field.Requisition)?.Requisition || "",
      PatientName: this.FilterFields.find(field => field.PatientName)?.PatientName || "",
      EMR: this.FilterFields.find(field => field.EMR)?.EMR || "",
      DeploymentKey: this.FilterFields.find(field => field.DeploymentKey)?.DeploymentKey || ""
    };
  
    this.service.getTransactionDetails(transactionDetails).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop(); 
  
        if (data && data.length > 0) {
          this.transactions = data;
  
          this.allTransactions = data.map(transaction => ({
            TransactionId: transaction.TransactionId,
            TransactionDate: transaction.TransactionDate
          }));
        } 
        else {
          this.toastr.warning("No transactions found.", "", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
        }
      },
      (error) => {
        this.ngxUiLoaderService.stop();
        this.toastr.error("Error fetching transaction details.", "", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
      }
    );
  }
  

  onTransactionIdClick(transactionId: string, transactionDate: string) {
    this.GetCaseAuditDetails(transactionId, transactionDate);
  }
  
  GetCaseAuditDetails(transactionId: string, transactionDate: string) {
    this.ngxUiLoaderService.start();
  
   const caseAuditDetails = { TransactionId: transactionId, TransactionDate: transactionDate };  

    this.service.getCaseAuditDetails360(caseAuditDetails).subscribe(
      (data) => {
        this.ngxUiLoaderService.stop();
  
        if (data.responsebody && data.responsebody.length > 0) {
          const parsedResponse = JSON.parse(data.responsebody);
          this.auditDetails = parsedResponse.map(item => ({
            application: item.application,
            entitytype: item.entitytype,
            action: item.action,
            reason: item.reason,
            transactionid: item.transactionid,
            messagereferenceid: item.messagereferenceid,
            content: item.content,
            interface: item.content.routename,
            laboratory: item.content.laboratory,
            accession: item.content.accession,
            filename: item.content.filename,
            transactiondate: item.content.transactioncompletedOn,
            requisitionnumber: item.content.requisitionnumber
          }));
          
          this.dataSource = new MatTableDataSource(this.auditDetails);
        } else {
          this.ngxUiLoaderService.stop();
          if (this.nodataalert) {
            this.toastr.warning(
              "No CaseAudit details Found",
              "",
              {
                positionClass: "toast-bottom-right",
                timeOut: 4000,
              }
            );
            this.nodataalert = false;
          }
        }
     },
    );
  }

  get noData(): boolean {
    return !this.dataSource || !this.dataSource.data || this.dataSource.data.length === 0;
  }

  getTitle(element: any): string {
    const { accession, requisitionnumber } = element;
    if (accession && requisitionnumber) {
      return `Accession Number: ${accession}, Requisition Number: ${requisitionnumber}`;
    } else if (accession) {
      return `Accession Number: ${accession}`;
    } else if (requisitionnumber) {
      return `Requisition Number: ${requisitionnumber}`;
    }
    return '';
  }

  rolepermission() {
    const roleName = sessionStorage.getItem("SelectedUserRole");
    const roleData = JSON.parse(sessionStorage.getItem("rolewithmenus"));

    const menuid = roleData.find((x) => x.RoleName === roleName)
      ?.menuslist.find((y) => y.MenuDisplayName === "CASEAUDIT")?.MenuId;

    if (menuid) {
      const actionItems = roleData.find((x) => x.RoleName === roleName)
        .menuslist.filter((y) => y.ParentMenuId === menuid);

      actionItems.forEach((element) => {
        this.pagerolepermisions[element.MenuName] = true;
      });
    }
  }

  closeModal(c) {
    c("close modal");
  }

  copyContent(content: any, message?: string): void {
    if (content == null || content === '' || (typeof content === 'string' && content.trim() === '')) {
      return;
    }
    let contentString: string;

    if (typeof content === 'object') {
      contentString = JSON.stringify(content, null, 2);
    } else {
      contentString = content;
    }

    this.clipboard.copy(contentString);
    this.toastr.success(message + " " + "Copied Successfully", "", {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
    });
  }

  getCopyMessage(element: any): string {
    const { accession, requisitionnumber } = element;
    if (accession && requisitionnumber) {
      return `${accession} / ${requisitionnumber}`;
    }
    return accession || requisitionnumber || '';
  }

  getCopyTitle(element: any): string {
    const { accession, requisitionnumber } = element;
    if (accession && requisitionnumber) {
      return 'Accession and Requisition Number';
    } else if (accession) {
      return 'Accession Number';
    } else if (requisitionnumber) {
      return 'Requisition Number';
    }
    return '';
  }

  transformReason(input: string): string {
    if (input == null || input.trim() === '') {
      return '';
    }

    const words = input.split(' ');

    return words
      .map((word, index) => {
        if (word.match(/[a-z][A-Z]/)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(' ');
  }

  getReasonTitle(reason: string | null): string {
    return this.transformReason(reason);
  }

}

