// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_content {
  float: left;
  position: relative;
  top: 9%;
}

.controls_feild {
  width: 70%;
  float: left;
  margin-left: 4%;
  height: 35px;
}

.controls_feild_lookvalue {
  width: 69%;
  float: left;
  margin-left: 4%;
  height: 35px;
}

.icons {
  font-size: 16px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 0px;
}

.interfaceMappingUpload {
  width: 3% !important;
  left: unset;
  opacity: 0 !important;
  position: absolute !important;
  top: 1% !important;
  overflow: hidden !important;
  right: 12% !important;
}

.searchtext {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/interfacemapping/interfacemapping.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,OAAA;AACF;;AAEA;EACE,UAAA;EACE,WAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACE,UAAA;EACE,WAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;AACF;;AACA;EAEE,oBAAA;EACA,WAAA;EACA,qBAAA;EACA,6BAAA;EACA,kBAAA;EACA,2BAAA;EACA,qBAAA;AACF;;AAEA;EACE,oDAAA;EACA,6BAAA;AACF","sourcesContent":[".label_content{\r\n  float: left;\r\n  position: relative;\r\n  top: 9%;\r\n}\r\n\r\n.controls_feild{\r\n  width: 70%;\r\n    float: left;\r\n    margin-left: 4%;\r\n    height: 35px;\r\n}\r\n\r\n.controls_feild_lookvalue{\r\n  width: 69%;\r\n    float: left;\r\n    margin-left: 4%;\r\n    height: 35px;\r\n}\r\n\r\n.icons{\r\n  font-size: 16px;\r\n  width: 16px;\r\n  height: 16px;\r\n  position: relative;\r\n  top: 0px;\r\n}\r\n.interfaceMappingUpload {\r\n  // font-size: 100px;\r\n  width: 3% !important;\r\n  left: unset;\r\n  opacity: 0 !important;\r\n  position: absolute !important;\r\n  top: 1% !important;\r\n  overflow: hidden !important;\r\n  right: 12% !important;\r\n}\r\n\r\n.searchtext{\r\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;\r\n  border-radius: 5px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
