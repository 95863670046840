// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep.msgreplist.mat-mdc-checkbox {
  padding-left: 0 !important;
  padding-right: 0px !important;
  width: 100% !important;
}
::ng-deep.msgreplist.mat-mdc-checkbox .mdc-form-field {
  width: 100% !important;
}
::ng-deep.msgreplist.mat-mdc-checkbox .mdc-checkbox {
  width: 16px;
  height: 16px;
}
::ng-deep.msgreplist.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: #17a0b4 !important;
  background-color: #17a0b4 !important;
}
::ng-deep.msgreplist.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white;
}
::ng-deep.msgreplist.mat-mdc-checkbox .mdc-checkbox__background {
  height: 16px !important;
  width: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/cache/cache.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI,0BAAA;EACA,6BAAA;EACA,sBAAA;AADJ;AAEI;EACE,sBAAA;AAAN;AAEI;EACI,WAAA;EACA,YAAA;AAAR;AACQ;EACE,gCCbE;EDcF,oCCdE;ADeZ;AACQ;EACE,YAAA;AACV;AAEI;EACE,uBAAA;EACA,sBAAA;AAAN","sourcesContent":["@import \"../../variables.scss\";\r\n\r\n::ng-deep.msgreplist.mat-mdc-checkbox {\r\n    padding-left: 0 !important;\r\n    padding-right: 0px !important;\r\n    width: 100% !important;\r\n    .mdc-form-field{\r\n      width: 100% !important;\r\n    }\r\n    .mdc-checkbox {\r\n        width: 16px;\r\n        height: 16px;\r\n        .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{\r\n          border-color: $btn-color;\r\n          background-color: $btn-color;\r\n        }\r\n        .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {\r\n          color: white;\r\n      }\r\n      }\r\n    .mdc-checkbox__background{\r\n      height: 16px !important;\r\n      width: 16px !important;\r\n    }\r\n  }","$btn-color: #17a0b4 !important;\r\n$fontsize: 12px !important;\r\n$btntextcolor: white !important;\r\n$btnHeight: 28px !important;\r\n$inputHeight:30px !important;\r\n$inputfontsize:11px !important;\r\n$primary:#004bff;\r\n// $modalHeaderTitleFont: ;\r\n@mixin modalheaderTtext {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
