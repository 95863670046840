import { Component, OnInit, ViewChildren, QueryList, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTab } from '@angular/material/tabs';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from 'ngx-toastr';
import { interfaceLabAdminServices } from 'src/app/services/InterfaceLabAdmin.services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessagelistComponent } from '../messagelist/messagelist.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VarulestooltipComponent } from '../varulestooltip/varulestooltip.component';
import { map, startWith } from 'rxjs/operators';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatMenuTrigger } from "@angular/material/menu";
import moment from 'moment';
import * as XLSX from 'xlsx';

declare const copyToClipboard: any;

@Component({
  selector: 'app-interfacelist',
  templateUrl: './interfacelist.component.html',
  styleUrls: ['./interfacelist.component.scss']
})
export class InterfacelistComponent implements OnInit {
  @ViewChildren(MatTab) tabs: QueryList<MatTab>;
  @Input() messagetype: any;
  @Input() interfacelistpagedirection: any;
  DateFilterForm: FormGroup;
  today: any = new Date();
  dataArray: any;
  dataArraybackup: any;
  pagenumber: number = 1;
  pageSize: number = 10;
  totalmessagecount: number;
  showForm: boolean;
  submitted: boolean;
  selectedpagesizedisplay: string = "10";
  serachMatselected = new FormControl();
  selected: string = 'All';
  selectedCountControl = new FormControl(this.selectedpagesizedisplay);
  interfaceSearchData = [];
  LocationSearchData = [];
  OrderingFacilitySearchData = [];
  OrderingPhysicianSearchData = [];
  LaboratorySearchData = [];

  MessagecountsArray: any;
  searchOptions: Filter[] = [];
  status: string = 'active';
  TestObj = ['interface:', 'Location:', 'Ordering Facility:', 'Ordering Physician:']
  fromDate: any;
  toDate: any;
  AllMatBoxChecked: boolean = true;
  serachFiledKey: any;
  ContentContainer: boolean = false;
  searchOptionsBackuplist: any;
  servicesso: boolean = false;
  constructor(private ngxUiLoaderService: NgxUiLoaderService,
    private service: interfaceLabAdminServices,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    public cmmservice: CommonService) {
    this.reactiveFormsearchControl = this._fb.group({ searchctnvalue: '' });
  }

  LabadminLoggedMetaData: any = {}

  ngOnInit() {
    //Auto Refresh
    setInterval(() => {
      let obj = {
        orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
        Deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
        messagetype: this.messagetype,
        fromDate: this.fromDate,  // Concate the time
        toDate: this.toDate,
        labadminLoginInput: this.LabadminLoggedMetaData,
        loaderfalg: false,
      }
      this.dataEmitter.emit(obj);
      if (this.searchControl.value == '') {
        this.getInterfaceData(false);
      }
    }, this.LabadminLoggedMetaData.AutoRefreshTime != '' || this.LabadminLoggedMetaData.AutoRefreshTime != undefined || this.LabadminLoggedMetaData.AutoRefreshTime != null ? this.LabadminLoggedMetaData.AutoRefreshTime : 120000); //2mins
    console.log('AutoRefreshTime for InterfaceList : ' + this.LabadminLoggedMetaData.AutoRefreshTime);
    this.cmmservice.servicessostatus.subscribe((data) => {
      if (data) {
        this.servicesso = true;
      } else {
        this.servicesso = false;
      }
    });
    this.LabadminLoggedMetaData = JSON.parse(sessionStorage.getItem('LabadminLoggedMetaData'));

    this.searchOptions = [
      { name: 'Interface:' },
      { name: 'Location:' },
      { name: 'Ordering Facility:' },
      { name: 'Ordering Physician:' },
      { name: 'Laboratory:' }
    ];
    this.searchOptionsBackuplist = JSON.parse(JSON.stringify(this.searchOptions));
    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );

    // this.inputDataInModal =
    // {
    //   messagetype: this.messagetype,
    //   fromDate: new Date(),
    //   toDate: new Date(),
    //   fromtime: '00:00',
    //   totime: this.formatTime(new Date())
    // }

  }

  ngOnChanges() {
    this.cmmservice.servicessostatus.subscribe((data) => {
      if (data) {
        this.servicesso = true;
      } else {
        this.servicesso = false;
      }
    });
    this.DateFilterForm = this._fb.group({
      fromDate: this.today,
      toDate: this.today,
      fromtime: '00:00:00',
      totime: this.formatTime(new Date())
    });
    this.LabadminLoggedMetaData = JSON.parse(sessionStorage.getItem('LabadminLoggedMetaData'));

    this.searchControl.setValue(null);
    this.searchControl.setValue('');
    this.searchControl.reset();
    this.reactiveFormsearchControl.reset();
    this.reactiveFormsearchControl.setValue({ "searchctnvalue": '' });
    if (this.tomailInput !== undefined) {
      this.tomailInput.nativeElement.value = '';
    }
    this.Filtervalue = [];
    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
    this.searchOptions = this.searchOptionsBackuplist;
    //await this.removeSearchValue('');
    this.pagenumber = 1;
    this.submitSearch();
    this.getInterfaceData(false);

    // this.isClickedOutBound = false;
    // this.isClickedInBound = false;
    // this.isClickedAll = true;
  }
  statustypelist = ["All", "Interface", "Location", "Ordering Facility", "Ordering Physician", "Laboratory"]

  selectedTabLabel: string = '';


  myFlagForSlideToggle: boolean = true;
  isActive: boolean = true;
  isChecked: boolean = true;
  stringValue: string = "Off";
  isClickedAll: boolean = true;
  isClickedInBound: boolean = false;
  isClickedOutBound: boolean = false;
  isstatus: string = ''
  async toggleChanged(booleValue: any) {
    if (booleValue) {
      this.isActive = false;
      this.myFlagForSlideToggle = false;
    }
    else {
      this.isActive = true;
      this.myFlagForSlideToggle = true;
    }
    if (this.isActive) {
      this.status = 'active';
    }
    else {
      this.status = 'passive'
    }
    await this.getInterfaceData(true)
  }
  onClickFilterInterfaces(test: string) {
    if (test == 'All') {
      this.isClickedAll = true;
      this.isClickedOutBound = false;
      this.isClickedInBound = false;
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
    else {
      this.isClickedInBound = true;
      this.isClickedOutBound = false;
      this.isClickedAll = false;
      this.dataArray = this.dataArraybackup.filter(data => data.direction.toLowerCase() === test.toLowerCase());
    }
    if (test.toLowerCase() == 'outbound') {
      this.isClickedAll = false;
      this.isClickedInBound = false;
      this.isClickedOutBound = true;
    }
  }

  selectEvent(event) {
    if (event.value.includes('All')) {
      this.AllMatBoxChecked = true;
      //this.serachFiledKey=event.value
    }
    else {
      this.searchControl.setValue(null);
      this.searchControl.setValue('');
      this.searchControl.reset();
      if (this.tomailInput !== undefined) {
        this.tomailInput.nativeElement.value = '';
      }
      this.Filtervalue = [];
      this.filteredOptions = this.searchControl.valueChanges
        .pipe(
          startWith(''),
          map(search => (search && typeof search === 'object') ? search.name : search),
          map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
        );
      this.searchOptions = JSON.parse(JSON.stringify(this.searchOptionsBackuplist));
      this.getFilter();
      this.AllMatBoxChecked = false;
      this.serachFiledKey = event.value
    }
  }
  autoRefresh() {
    let fromdate = this.formatDate(this.DateFilterForm.value.fromDate);
    fromdate = fromdate + " " + this.DateFilterForm.value.fromtime + ":00";
    this.fromDate = fromdate;
    let todate = this.formatDate(this.DateFilterForm.value.toDate);
    todate = todate + " " + this.DateFilterForm.value.totime + ":59";
    this.toDate = todate;
    let obj = {
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      Deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      messagetype: this.messagetype,
      fromDate: this.fromDate,  // Concate the time
      toDate: this.toDate,
      labadminLoginInput: this.LabadminLoggedMetaData,
      loaderfalg: true,
    }
    this.dataEmitter.emit(obj);
    if (this.searchControl.value == '') {
      this.getInterfaceData(false);
    }
  }

  getPageDetails(event) {

    if (event == 0) {
      this.pagenumber = 1;
    } else {
      this.pagenumber = event + 1;
    }
  }
  selectpageEvent(event) {

    this.pagenumber = 1;
    this.pageSize = event.value;
  }

  getInterfaceData(loadertype) {


    let obj = {
      pagenumber: this.pagenumber,
      messageType: this.messagetype,
      status: this.status,
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      pagesize: 100, //this.selectedCount,
      directions: this.interfacelistpagedirection,
      LocationGuidsIncluded: true,
      LocationGuids:this.LabadminLoggedMetaData.EntityGuid,
    }
    if (loadertype) {
      this.ngxUiLoaderService.start();
    }
    this.service.GetSearchInterface(obj).subscribe((data: any) => {
      if (data.length > 0) {
        this.dataArray = data;
        this.dataArraybackup = data;
        this.totalmessagecount = this.dataArray.length > 0 ? this.dataArray[0]["totalpipelinecount"] : 0;
        this.pageSize = Number(this.selectedpagesizedisplay);// this.totalmessagecount / 15;
        this.getPageDetails(0);
        this.onClickFilterInterfaces(this.interfacelistpagedirection);
      }
      else {
        this.dataArray = data;
        this.dataArraybackup = data;
        this.totalmessagecount = this.dataArray.length > 0 ? this.dataArray[0]["totalpipelinecount"] : 0;
        //this.pageSize = this.totalmessagecount / 15;
        this.pageSize = Number(this.selectedpagesizedisplay);
        this.getPageDetails(0);
        this.onClickFilterInterfaces(this.interfacelistpagedirection);
      }
      this.ContentContainer = true;
      if (loadertype) {
        this.ngxUiLoaderService.stop();
      }
    },
      (error) => {
        this.toastr.warning("Error occured while calling api", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
        this.ngxUiLoaderService.stop();
      }
    );
  }

  clearSearchs() {
    this.searchControl.setValue(null);
    this.searchControl.setValue('');
    this.searchControl.reset();
    this.reactiveFormsearchControl.reset();
    this.reactiveFormsearchControl.setValue({ "searchctnvalue": '' });
    if (this.tomailInput !== undefined) {
      this.tomailInput.nativeElement.value = '';
    }
    this.getFilter();
  }

  getCharconut() {
    if (this.searchControl.value.length == 0) {
      this.searchControl.setValue(null);
      this.searchControl.setValue('');
      this.searchControl.reset();
      this.reactiveFormsearchControl.reset();
      this.reactiveFormsearchControl.setValue({ "searchctnvalue": '' });
      if (this.tomailInput !== undefined) {
        this.tomailInput.nativeElement.value = '';
      }
      this.getFilter();
    }
  }
  getFilter() {
    if (this.searchControl.value != null && this.searchControl.value != '') {
      if (this.searchControl.value != null || this.searchControl.value != undefined) {
        if (this.searchControl.value.length >= 3) {
          if (this.isClickedAll) {
            this.isClickedAll = false;
          }
          this.onClickFilterInterfaces("All");
        }
      }
      if (this.searchControl.value.includes(':')) {
        this.filterdata(this.searchControl);
      }
      else if (!this.AllMatBoxChecked) {
        if (this.serachFiledKey.includes('Interface')) {
          this.filterdatainterface(this.searchControl.value);
        }
        if (this.serachFiledKey.includes('Ordering Facility')) {
          this.filterDataOrderingFacility(this.searchControl.value);
        }
        if (this.serachFiledKey.includes('Laboratory')) {
          this.filterDataLabName(this.searchControl.value);
        }
        if (this.serachFiledKey.includes('Location')) {
          this.filterDataLocation(this.searchControl.value);
        }
        if (this.serachFiledKey.includes('Ordering Physician')) {
          this.filterDataOrderingPhysician(this.searchControl.value);
        }
      }
      else {
        if (this.searchControl.value !== '') {
          let filteredData = this.dataArraybackup.filter(va => {
            let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
            let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : '';
            let Location = va.labname;//this.LabadminLoginInput.labname.toLowerCase();
            let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
            return dynamiclabelvalue.includes(this.searchControl.value.toLowerCase())
              || orderingphysician.includes(this.searchControl.value.toLowerCase())
              || Location.includes(this.searchControl.value.toLowerCase())
              || interfacename.includes(this.searchControl.value.toLowerCase());

          });
          this.dataArray = filteredData;
        } else {
          this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
        }
      }
    }
    else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }
  //Filter Values function Data
  filterdatainterface(searchtextData) {
    if (searchtextData !== '') {
      let filteredData = this.dataArraybackup.filter(va => {
        let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
        return interfacename.includes(searchtextData.toLowerCase());
      });
      this.dataArray = filteredData;
    } else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }
  filterDataLocation(searchtextData) {
    if (searchtextData !== '') {
      let filteredData = this.dataArraybackup.filter(va => {
        let Location = va.labname.toLowerCase(); //this.LabadminLoginInput.labname.toLowerCase();
        return Location.includes(searchtextData.toLowerCase());
      });
      this.dataArray = filteredData;
    } else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }

  filterDataOrderingFacility(serachValue) {
    if (serachValue !== '') {
      let filteredData = this.dataArraybackup.filter(va => {
        if (va.dynamiclabelname == 'Ordering Facility') {
          let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
          return dynamiclabelvalue.includes(serachValue.toLowerCase());
        }
      });
      this.dataArray = filteredData;
    } else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }

  filterDataLabName(serachValue) {
    if (serachValue !== '') {
      let filteredData = this.dataArraybackup.filter(va => {
        let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
        return dynamiclabelvalue.includes(serachValue.toLowerCase());
      });
      this.dataArray = filteredData;
    } else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }

  filterDataOrderingPhysician(searchtextData) {
    if (searchtextData !== '') {
      let filteredData = this.dataArraybackup.filter(va => {
        let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : 'N/A';
        return orderingphysician.includes(searchtextData.toLowerCase());
      });
      this.dataArray = filteredData;
    } else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }

  filterdata(event) {
    if (this.searchControl.value.includes(':')) {
      let searchtextData = this.searchControl.value.split(":")[1].toLowerCase();
      if (this.searchControl.value.split(":")[0].includes('Interface')) {
        if (searchtextData !== '') {
          let filteredData = this.dataArraybackup.filter(va => {
            let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
            return interfacename.includes(searchtextData);
          });
          this.dataArray = filteredData;
          this.interfaceSearchData = this.dataArray;
        } else {
          this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
        }
      }
      if (this.searchControl.value.split(":")[0].includes('Ordering Facility')) {
        if (searchtextData !== '') {
          let filteredData = this.dataArraybackup.filter(va => {
            if (va.dynamiclabelname == 'Ordering Facility') {
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            }
            let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
            return dynamiclabelvalue.includes(searchtextData);
          });
          this.dataArray = filteredData;
          this.OrderingFacilitySearchData = this.dataArray;
        } else {
          this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
        }
      }
      if (this.searchControl.value.split(":")[0].includes('Laboratory')) {
        if (searchtextData !== '') {
          let filteredData = this.dataArraybackup.filter(va => {
            let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
            return dynamiclabelvalue.includes(searchtextData);
          });
          this.dataArray = filteredData;
          this.LaboratorySearchData = this.dataArray;
        } else {
          this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
        }
      }
      if (this.searchControl.value.split(":")[0].includes('Ordering Physician')) {
        if (searchtextData !== '') {
          let filteredData = this.dataArraybackup.filter(va => {
            let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : 'N/A';
            return orderingphysician.includes(searchtextData);
          });
          this.dataArray = filteredData;
          this.OrderingPhysicianSearchData = this.dataArray;
        } else {
          this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
        }
      }
      if (this.searchControl.value.split(":")[0].includes('Location')) {
        if (searchtextData !== '') {
          let filteredData = this.dataArraybackup.filter(va => {
            let Location = va.labname ? va.labname.toLowerCase() : '';; //this.LabadminLoginInput.labname.toLowerCase();
            return Location.includes(searchtextData);
          });
          this.dataArray = filteredData;
          this.LaboratorySearchData = this.dataArray;
        } else {
          this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
        }
      }
      if (this.interfaceSearchData.length > 0) {

        if (this.searchControl.value.split(":")[0].includes('Ordering Facility')) {
          if (searchtextData !== '') {
            let filteredData = this.interfaceSearchData.filter(va => {
              if (va.dynamiclabelname == 'Ordering Facility') {
                let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
                return dynamiclabelvalue.includes(searchtextData);
              }
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingFacilitySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Laboratory')) {
          if (searchtextData !== '') {
            let filteredData = this.interfaceSearchData.filter(va => {
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          } else {
            this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Physician')) {
          if (searchtextData !== '') {
            let filteredData = this.interfaceSearchData.filter(va => {
              let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : 'N/A';
              return orderingphysician.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingPhysicianSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Location')) {
          if (searchtextData !== '') {
            let filteredData = this.interfaceSearchData.filter(va => {
              let Location = va.labname ? va.labname.toLowerCase() : ''; //this.LabadminLoginInput.labname.toLowerCase();
              return Location.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        this.dataArray = this.dataArray;
      }

      if (this.LaboratorySearchData.length > 0) {

        if (this.searchControl.value.split(":")[0].includes('Interface')) {
          if (searchtextData !== '') {
            let filteredData = this.LaboratorySearchData.filter(va => {
              let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
              return interfacename.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.interfaceSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Facility')) {
          if (searchtextData !== '') {
            let filteredData = this.LaboratorySearchData.filter(va => {
              if (va.dynamiclabelname == 'Ordering Facility') {
                let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
                return dynamiclabelvalue.includes(searchtextData);
              }
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingFacilitySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Physician')) {
          if (searchtextData !== '') {
            let filteredData = this.LaboratorySearchData.filter(va => {
              let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : 'N/A';
              return orderingphysician.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingPhysicianSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Location')) {
          if (searchtextData !== '') {
            let filteredData = this.LaboratorySearchData.filter(va => {
              let Location = va.labname; //this.LabadminLoginInput.labname.toLowerCase();
              return Location.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        this.dataArray = this.dataArray;
      }

      if (this.OrderingFacilitySearchData.length > 0) {


        if (this.searchControl.value.split(":")[0].includes('Interface')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingFacilitySearchData.filter(va => {
              let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
              return interfacename.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.interfaceSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Laboratory')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingFacilitySearchData.filter(va => {
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Physician')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingFacilitySearchData.filter(va => {
              let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : 'N/A';
              return orderingphysician.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingPhysicianSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Location')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingFacilitySearchData.filter(va => {
              let Location = va.labname; //this.LabadminLoginInput.labname.toLowerCase();
              return Location.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        this.dataArray = this.dataArray;
      }

      if (this.OrderingPhysicianSearchData.length > 0) {

        if (this.searchControl.value.split(":")[0].includes('Interface')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingPhysicianSearchData.filter(va => {
              let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
              return interfacename.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.interfaceSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Facility')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingPhysicianSearchData.filter(va => {
              if (va.dynamiclabelname == 'Ordering Facility') {
                let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
                return dynamiclabelvalue.includes(searchtextData);
              }
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingFacilitySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Laboratory')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingPhysicianSearchData.filter(va => {
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Location')) {
          if (searchtextData !== '') {
            let filteredData = this.OrderingPhysicianSearchData.filter(va => {
              let Location = va.labname; //this.LabadminLoginInput.labname.toLowerCase();
              return Location.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        this.dataArray = this.dataArray;
      }

      if (this.LocationSearchData.length > 0) {


        if (this.searchControl.value.split(":")[0].includes('Interface')) {
          if (searchtextData !== '') {
            let filteredData = this.LocationSearchData.filter(va => {
              let interfacename = va.interfacename ? va.interfacename.toLowerCase() : '';
              return interfacename.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.interfaceSearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Facility')) {
          if (searchtextData !== '') {
            let filteredData = this.LocationSearchData.filter(va => {
              if (va.dynamiclabelname == 'Ordering Facility') {
                let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
                return dynamiclabelvalue.includes(searchtextData);
              }
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingFacilitySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Laboratory')) {
          if (searchtextData !== '') {
            let filteredData = this.LocationSearchData.filter(va => {
              let dynamiclabelvalue = va.dynamiclabelvalue ? va.dynamiclabelvalue.toLowerCase() : '';
              return dynamiclabelvalue.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.LaboratorySearchData = this.dataArray;
          }
        }
        if (this.searchControl.value.split(":")[0].includes('Ordering Physician')) {
          if (searchtextData !== '') {
            let filteredData = this.LocationSearchData.filter(va => {
              let orderingphysician = va.orderingphysician ? va.orderingphysician.toLowerCase() : 'N/A';
              return orderingphysician.includes(searchtextData);
            });
            this.dataArray = filteredData;
            this.OrderingPhysicianSearchData = this.dataArray;
          }
        }
        this.dataArray = this.LocationSearchData;
      }
    }
    else {
      this.dataArray = JSON.parse(JSON.stringify(this.dataArraybackup));
    }
  }

  toggleForm() {
    this.showForm = !this.showForm;
    this.submitted = false;
  }

  getfailedemessage(data) {
    const modalRef = this.modalService.open(MessagelistComponent, {
      windowClass: 'myCustomModalClasslabadmin',
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.inputDataInModalComponent = data;
    modalRef.componentInstance.inputDataInModalComponent.InterfaceDetailsShow = true;
    modalRef.result.then(() => { });


  }

  getvarules(rulename) {
    this.ngxUiLoaderService.start();
    var apiname = "api/VitalHubDXAPI/GetVaRuleForLabAdmin?RuleName=" + rulename;
    this.service.getapiurl(
      apiname,
      this.LabadminLoggedMetaData.Deploymentkey).subscribe({
        next: data => {
          if (data.length > 0) {
            const modalRef = this.modalService.open(VarulestooltipComponent, {
              windowClass: "labadminvarulepopupcls",
              backdrop: "static",
              keyboard: false,
            });
            modalRef.componentInstance.inputDataInModalComponent = data
            modalRef.componentInstance.inputDataInModalComponent.InterfaceName = rulename
            modalRef.result.then((result) => { });
            this.ngxUiLoaderService.stop();
          }
          else {
            this.toastr.warning("No Varule for this Interface", "", {
              timeOut: 4000,
              positionClass: "toast-bottom-right",
            });
            this.ngxUiLoaderService.stop();
          }
        },
        error: error => {
          this.toastr.warning("Error occured while calling api", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
          this.ngxUiLoaderService.stop();
        }
      });
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  isAscending: boolean = true;
  MessagecountsArraySortBy(property: string) {
    this.MessagecountsArray.sort((a, b) => {
      const sortOrder = this.isAscending ? 1 : -1;
      if (a[property] < b[property]) return -1 * sortOrder;
      if (a[property] > b[property]) return 1 * sortOrder;
      return 0;
    });
    this.isAscending = !this.isAscending;
  }


  clearSearch() {
    this.DateFilterForm = this._fb.group({
      fromDate: this.today,
      toDate: this.today,
      fromtime: this.formatTime(new Date()),
      totime: this.formatTime(new Date())
    });
  }

  previousday() {
    let prdate = this.DateFilterForm.value.fromDate;
    const previousDate = new Date(prdate);
    // Subtract one day
    previousDate.setDate(prdate.getDate() - 1);
    this.DateFilterForm.patchValue({ fromDate: previousDate });
    this.DateFilterForm.patchValue({ fromtime: "00:00:00" });
    this.DateFilterForm.patchValue({ totime: "23:59" })
    this.DateFilterForm.patchValue({ toDate: previousDate });

  }

  todayfunction() {
    this.DateFilterForm = this._fb.group({
      fromDate: this.today,
      toDate: this.today,
      fromtime: this.formatTime(new Date()),
      totime: this.formatTime(new Date())
    });
  }

  submitSearch() {
    let fromdate = this.formatDate(this.DateFilterForm.value.fromDate);
    fromdate = fromdate + ' ' + this.DateFilterForm.value.fromtime + ':00';
    this.fromDate = fromdate;
    let todate = this.formatDate(this.DateFilterForm.value.toDate);
    todate = todate + ' ' + this.DateFilterForm.value.totime + ':59';
    this.toDate = todate;
    let obj = {
      orgguid: this.LabadminLoggedMetaData.OrganizationData.OrganizationGUID,
      Deploymentkey: this.LabadminLoggedMetaData.Deploymentkey,
      messagetype: this.messagetype,
      fromDate: this.fromDate,  // Concate the time
      toDate: this.toDate,
      labadminLoginInput: this.LabadminLoggedMetaData,
      loaderfalg: true,
    }
    var d1 = moment(this.fromDate);
    var d2 = moment(this.toDate);
    var diffDays = Math.abs(d1.diff(d2, 'days'));
    if (diffDays >= 7) {
      this.toastr.warning("Date range should not exceed 7 days", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    else {
      this.dataEmitter.emit(obj);
    }


  }

  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  formatDate(userDate: string): string {
    const dateObject = new Date(userDate);
    return this.datePipe.transform(dateObject, 'MM-dd-yyyy');
  }

  Filtervalue: string[] = [];
  splitvalue: string[] = [];
  FormName: any;
  responseData: any;
  addOption: Filter;
  searchTermData: string[] = [];
  searchControl = new FormControl();
  public reactiveFormsearchControl: FormGroup;

  removeSearchValue(value: any): void {
    if (value.includes(':')) {
      const TrimValue = value.trim();
      const index = this.Filtervalue.findIndex(term => term.trim().toLowerCase() === TrimValue.toLowerCase());
      if (index == 0 && this.Filtervalue.length == 0) {
        this.searchControl.setValue('');
      }
      if (index !== -1) {
        this.Filtervalue.splice(index, 1);
        this.searchControl.setValue(this.Filtervalue[index - 1]);
        if (this.searchControl.value == undefined) {
          this.searchControl.setValue(this.Filtervalue[0])
        }
        this.splitvalue = value.split(":")
        this.FormName = this.splitvalue[0].trim();
        if (TrimValue.includes("Interface")) {
          this.interfaceSearchData = [];
        }
        if (TrimValue.includes("Location")) {
          this.LocationSearchData = [];
        }
        if (TrimValue.includes("Ordering Facility")) {
          this.OrderingFacilitySearchData = [];
        }
        if (TrimValue.includes("Ordering Physician")) {
          this.OrderingPhysicianSearchData = [];
        }
        if (TrimValue.includes("Laboratory")) {
          this.LaboratorySearchData = [];
        }

        this.responseData = this.FormName + ':';
        this.addOption = { name: this.responseData };
        // Check if the option is already present in searchOptions
        const optionExists = this.searchOptions.some(option => option.name.toLowerCase() === this.addOption.name.toLowerCase());
        if (!optionExists) {
          this.searchOptions.push(this.addOption);
        }
      }
    }
    else {
      this.searchControl.setValue('');
      const index = this.searchTermData.indexOf(value);
      if (index !== -1) {
        this.searchTermData.splice(index, 1);
      }
    }
    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
    this.getFilter();
  }

  @ViewChild('tomailInput', { static: false }) tomailInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  selectedoption(event: MatAutocompleteSelectedEvent): void {
    this.tomailInput.nativeElement.value = event.option.value.name;
  }
  filteredOptions: Observable<Filter[]> | undefined;

  filter(name: string): Filter[] {
    return this.searchOptions.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }
  removeOptionWhenButtonCreated(value: string) {
    this.searchOptions.forEach((option, index) => {
      const optionKey = option.name.split(':')[0].trim();
      const optionKeyTrimmed = optionKey.trim();
      if (optionKeyTrimmed === value) {
        this.searchOptions.splice(index, 1); // Remove matching option
      }
    });

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
  }

  addtoFilterForm(event: MatChipInputEvent): void {
    if (event.value.trim() !== '') {
      const value = event.value.trim();
      const parts = value.split(':');
      if (parts.length > 1 && parts[1].trim() !== '') {
        const [inputKey, inputValue] = value.split(':').map(part => part.trim());
        if (!this.Filtervalue.includes(value.trim())) {
          this.Filtervalue.push(value.trim());
        }
        if (this.tomailInput) {
          this.tomailInput.nativeElement.value = null; // Display selected value in input field
        }
        this.removeOptionWhenButtonCreated(inputKey);
      }
    }
  }

  copyToClip(event: any, message) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  copyToClipObject(event: any, message) {
    if (event != '') {
      const formattedString = `InterfaceDetails\nInterfaceName:${event.interfacename},\nLocation:${event.labname},\n${event.dynamiclabelname}:${event.dynamiclabelvalue}`;
      copyToClipboard(formattedString);
      this.toastr.success(message + " " + 'Copied', "", {
        timeOut: 2000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  //inputDataInModal: any;

  @Output() dataEmitter = new EventEmitter<any>();
  ExportInterfacelist() {
    let filename = "FWC_Interface_Summary.xlsx";
    const data = [];
    if (this.dataArray.length != 0) {
      this.dataArray.forEach(element => {
        data.push({
          interfacename: element.interfacename,
          locations: element.labname,
          golive: element.golive,
          //sendinlab: element.dynamiclabelvalue,
          OrderingPhysician: element.orderingphysician,
          LastMessage: element.lastmessage,
          Stage: element.stage,
          Status: element.status,
          TodayFailureCount: element.todaysfailurecount,
          TodaySuccessCount: element.todayssuccesscount,
          TodayInprogressCount: element.todaysinprogresscount,
          YesterdayFailureCount: element.yesterdaysfailurecount,
          YesterdayInprogressCount: element.yesterdaysinprogresscount,
          YesterdaySuccessCount: element.yesterdayssuccesscount,
        });

      });
    }
    if (data.length > 0) {
      this.exportToExcel(data, filename);
    } else {
      this.toastr.warning("There is no data !", "", {
        timeOut: 4000,
        positionClass: "toast-bottom-right",
      });
    }
  }
  exportToExcel(items: any[], fileName: string): void {
    const selectedItems = items.map(item => {
      return {
        "InterFaceName": item.interfacename,
        "Locations": item.locations,
        "GoLive": item.golive,
        "OrderingPhysician": item.OrderingPhysician,
        "Stage": item.Stage,
        "Status": item.Status,
        "TodayFailureCount": item.TodayFailureCount,
        "TodaySuccessCount": item.TodaySuccessCount,
        "TodayInprogressCount": item.TodayInprogressCount,
        "YesterdayFailureCount": item.YesterdayFailureCount,
        "YesterdayInprogressCount": item.YesterdayInprogressCount,
        "YesterdaySuccessCount ": item.YesterdaySuccessCount,
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}
export class Filter {
  constructor(public name: string) { }
}
