// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casehistory {
  font-family: montserrat !important;
}
.casehistorybutton {
  font-family: montserrat !important;
}

.case_info_container {
  border: 1.5px solid #001C3B;
  padding: 5px;
  position: relative;
  margin-block: 16px;
  width: 22vw;
}

.card_label_title {
  background-color: #fbfbfb;
  position: absolute;
  padding-inline: 5px;
  top: -11px;
  font-weight: 600;
  font-size: 12px;
  color: #5682A6 !important;
}

.overxflow_caseinfo {
  overflow-x: hidden !important;
}

.padding_leftinpx {
  padding-left: 5px;
}

.lables_style {
  font-size: 10px;
  color: #001C3B;
  font-weight: 600;
}

.value_style {
  font-weight: 600;
  font-size: 10px;
  color: #5682A6 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.view_claim {
  width: 48%;
  line-height: 7px;
  border: 1px solid #376b96;
  font-size: 12px;
  color: #5b93bf;
  font-weight: 600;
  cursor: pointer;
  font-family: montserrat !important;
}

.view_case {
  width: 100%;
  background: #376B96;
  color: white;
  line-height: 11px;
  font-size: 12px;
  cursor: pointer;
  font-family: montserrat !important;
}`, "",{"version":3,"sources":["webpack://./src/app/case360/casehistory/casehistory.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;AAAE;EACE,kCAAA;AAEJ;;AAEA;EACE,2BAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AACA;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;AAEF;;AACA;EACE,6BAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAGF;;AAAA;EACE,UAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,kCAAA;AAGF;;AADA;EACE,WAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,kCAAA;AAIF","sourcesContent":[".casehistory{\r\n  font-family: montserrat !important;\r\n  &button{\r\n    font-family: montserrat !important;\r\n\r\n  }\r\n}\r\n.case_info_container{\r\n  border: 1.5px solid #001C3B;\r\n  padding: 5px;\r\n  position: relative;\r\n  margin-block: 16px;\r\n  width: 22vw;\r\n}\r\n.card_label_title{\r\n  background-color: #fbfbfb;\r\n  position: absolute;\r\n  padding-inline: 5px;\r\n  top: -11px;\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  color: #5682A6 !important;\r\n}\r\n\r\n.overxflow_caseinfo{\r\n  overflow-x: hidden !important;\r\n}\r\n\r\n.padding_leftinpx{\r\n  padding-left: 5px;\r\n}\r\n\r\n.lables_style{\r\n  font-size: 10px;\r\n  color: #001C3B;\r\n  font-weight: 600;\r\n}\r\n.value_style{\r\n  font-weight: 600;\r\n  font-size: 10px;\r\n  color:#5682A6 !important;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n  overflow-x: hidden;\r\n}\r\n\r\n.view_claim{\r\n  width: 48%;\r\n  line-height: 7px;\r\n  border: 1px solid #376b96;\r\n  font-size: 12px;\r\n  color: #5b93bf;\r\n  font-weight: 600;\r\n  cursor: pointer;\r\n  font-family: montserrat !important;\r\n}\r\n.view_case{\r\n  width: 100%;\r\n  background: #376B96;\r\n  color: white;\r\n  line-height: 11px;\r\n  font-size: 12px;\r\n  cursor: pointer;\r\n  font-family: montserrat !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
