import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import * as XLSX from 'xlsx';
declare const copyToClipboard: any;
declare var $;

@Component({
  selector: 'app-metricpipelinelist',
  templateUrl: './metricpipelinelist.component.html',
  styleUrls: ['./metricpipelinelist.component.scss']
})
export class MetricpipelinelistComponent implements OnInit {

  cardHeight: Number = window.innerHeight - 222;
  keywordsearch = "displayname";
  MessagetypeList: any = [];
  isLoadingpa: boolean = false;
  currendate: Date = new Date();
  selectedmessagetype: string = "";
  reactiveFormpa: FormGroup;
  reactiveFormsource: FormGroup;
  reactiveFormtarget: FormGroup;
  reactiveFormroute: FormGroup;
  notfounddata: boolean;
  showSourceTarget: boolean = true;
  SourceAccountList: any = [];
  TargetAccountList: any = [];
  RouteNameList: any = [];
  HeaderListDates: any = [];
  selectedsourceaccountid: string = '';
  selectedsourceaccountName: string = '';
  selectedtargetaccountid: string = '';
  selectedtargetaccountName: string = '';
  selectedtargetdeployementkey: string = '';
  selectedtargetassociatedaccountids: string = '';
  isLoadingsource: boolean = false;
  isLoadingTarget: boolean = false;
  isLoadingroutes: boolean = false;
  selectedrouteId: string = "";
  selectedrouteDisplayName: string = "";
  selectedSourceDeploymentkey: string = "";
  selectedSourceAssociatedAccountids: string = "";
  today = new Date();
  constructor(private _fb: FormBuilder,
    private service: CommonService,
    private modalService: NgbModal,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe
  ) {
    this.getMessagetypelist('msgtype');

    this.reactiveFormpa = _fb.group({ paname: '' });
    this.reactiveFormsource = _fb.group({ name: '' });
    this.reactiveFormtarget = _fb.group({ namet: '' });
    this.reactiveFormroute = _fb.group({ routename: '' });
  }
  screenHeight: number;
  userrole: string = '';
  pagerolepermisions: any = [];
  async ngOnInit() {
    this.screenHeight = window.innerHeight - 229;
    this.fromdate = this.datePipe.transform(this.getPreviousDay(new Date()), 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  }

  getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate());
    return previous;
  }


  MessageTypeListAll: any = [];

  async getMessagetypelist(event: any) {

    this.isLoadingpa = true;
    if (event !== 'msgtype') {

      if (event == undefined) {
        this.MessagetypeList = this.MessageTypeListAll;
      }
      else {
        if (this.MessagetypeList.length == 0) {
          this.MessagetypeList = this.MessageTypeListAll;
        }
        this.MessagetypeList = this.MessagetypeList.filter(t => t.displayname.toLowerCase().includes(event.toLowerCase()));
      }
      if (this.MessagetypeList.length == 0) {
        this.notfounddata = true;
      } else {
        this.notfounddata = false;
      }
      this.isLoadingpa = false;
    }
    else {

      await this.service.getapiurl("api/Lookup/GetMessageType?lookuptype=messagetype").subscribe({
        next: async data => {
          data.forEach(element => {
            this.MessagetypeList.push({ displayname: element });
          });
          this.MessageTypeListAll = this.MessagetypeList;
          this.isLoadingpa = false;
          if (data.length == 0) {
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingpa = false;
          this.notfounddata = true;
        }
      });
    }
  }

  selectedmessagetypeevent(item) {
    this.selectedmessagetype = item.displayname;
  }

  Clearemessagetype(event) {
    this.isLoadingpa = false;
    this.MessagetypeList = this.MessageTypeListAll;
    this.notfounddata = false;
    this.selectedmessagetype = '';
  }

  reset() {
    this.reactiveFormpa.reset();
    this.MessagetypeList = this.MessageTypeListAll;
    this.reactiveFormpa.setValue({ "paname": '' });
    this.reactiveFormsource.reset();
    this.reactiveFormroute.reset();
    this.reactiveFormpa.reset();
    this.SourceAccountList = [];
    this.TargetAccountList = [];
    this.RouteNameList = [];
    this.reactiveFormsource.setValue({ "name": '' });
    this.reactiveFormtarget.setValue({ "namet": '' });
    this.reactiveFormroute.setValue({ "routename": '' });
    this.notfounddata = false;
    this.fromdate = this.datePipe.transform(this.getPreviousDay(new Date()), 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.selectedrouteId = '';
    this.selectedmessagetype = null;
    this.selectedtargetaccountid = '';
    this.selectedsourceaccountid = '';
    this.metricspipelineList = [];
  }


  copyToClip(event: any) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success('Copied', "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }

  copyToClips(event: any, message: string) {
    if (event != '') {
      copyToClipboard(event);
      this.toastr.success(message + " " + "Copied", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      })
    }
  }

  fromdate: any;
  todate: any;
  metricspipelineList: any = [];
  async getmetricsreport() {
    this.metricspipelineList = [];
    if (this.fromdate == '' && this.todate == '') {
      this.fromdate = undefined;
      this.todate = undefined;
    }

    if ((this.fromdate && !this.todate) || (!this.fromdate && this.todate) || (this.fromdate == undefined && this.todate == undefined)) {
      this.toastr.warning("Select From date & To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.fromdate > this.todate) {
      this.toastr.warning("From date should be  equal  or  less than To date ", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    var d1 = moment(this.fromdate);
    var d2 = moment(this.todate);
    var diffDays = Math.abs(d1.diff(d2, 'days'));
    if (diffDays >= 21) {
      this.toastr.warning("Date range should not exceed 20 days", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    if (this.reactiveFormroute.get('routename').value != '' && this.selectedrouteId == '') {
      this.toastr.warning("Please check pipeline", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }


    if (this.reactiveFormsource.get('name').value != '' && this.selectedsourceaccountid == '') {
      this.toastr.warning("Please check source entity", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    if (this.reactiveFormtarget.get('namet').value != '' && this.selectedtargetaccountid == '') {
      this.toastr.warning("Please check destination entity", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }

    if (this.reactiveFormpa.get('paname').value != '' && this.selectedmessagetype == '') {
      this.toastr.warning("Please check message type", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      return;
    }
    var searchinput = {
      "Messagetype": this.selectedmessagetype,
      "FromDate": (this.fromdate == undefined || this.fromdate == null) ? '' : this.fromdate + ' 00:00:00',
      "ToDate": (this.todate == undefined || this.todate == null) ? '' : this.todate + ' 23:59:59',
      "Pipeline": this.selectedrouteId,
      "Source": this.selectedsourceaccountid,
      "Target": this.selectedtargetaccountid,
      "Setype": this.selectedSourceEntitytype,
      "Sedeploymentkey": this.selectedSourceDeploymentkey,
      "Detype": this.selectedTargetEntitytype,
      "Dedeploymentkey": this.selectedtargetdeployementkey,
    }

    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi("api/Metrics/GetTransactionsPipelineList", searchinput);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.ngxUiLoaderService.stop();
      // this.metricspipelineList = response as any[];
      this.metricspipelineList = this.sortArraybyObjValue(response, 'Total');
      if (this.metricspipelineList.length == 0) {
        this.toastr.warning("No results found!!", "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
    }
  }

  sortArraybyObjValue(arr, value) {

    return arr.sort((a, b) => {
      return b[value] - a[value];
    })
  }

  getLast30DaysDates() {
    const dates = [];
    const today = new Date(); // Get the current date
    // Loop backward for the last 30 days
    for (let i = 0; i < 30; i++) {
      const date = new Date(today); // Create a new Date object
      date.setDate(today.getDate() - i); // Subtract 'i' days from the current date
      dates.push(date.toISOString().split('T')[0]); // Add the date to the list in ISO format (YYYY-MM-DD)
    }
    return dates;
  }

  getDates(startDate, endDate) {

    const dates = [];
    if (startDate !== undefined && endDate !== undefined) {
      let currentDate = new Date(startDate);
      endDate = new Date(endDate);
      while (currentDate <= endDate) {
        dates.push(this.formatDate(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.HeaderListDates = dates;
      return dates;
    }
    else {
      var date = this.getLast30DaysDates();
      let currentDate = new Date(startDate);
      endDate = new Date(endDate);
      while (currentDate <= endDate) {
        dates.push(this.formatDate(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.HeaderListDates = date;
      return date;
    }
  }

  formatDate(date) {

    if (date !== '') {
      var last = new Date(date);
      var day = ("0" + last.getDate()).slice(-2);
      var month = ("0" + (last.getMonth() + 1)).slice(-2);
      var year = last.getFullYear();
      return year + "-" + month + "-" + day;
    }
  }



  async exportmetricsreport() {
    var searchinput = {
      "Messagetype": this.selectedmessagetype,
      "FromDate": (this.fromdate == undefined || this.fromdate == null) ? '' : this.fromdate + ' 00:00:00',
      "ToDate": (this.todate == undefined || this.todate == null) ? '' : this.todate + ' 23:59:59',
      "Pipeline": this.selectedrouteId,
      "Source": this.selectedsourceaccountid,
      "Target": this.selectedtargetaccountid,
      "Setype": this.selectedSourceEntitytype,
      "Sedeploymentkey": this.selectedSourceDeploymentkey,
      "Detype": this.selectedTargetEntitytype,
      "Dedeploymentkey": this.selectedtargetdeployementkey,
    }

    this.ngxUiLoaderService.start();
    let response = await this.service.searchpostapi("api/Metrics/GetExportTransData", searchinput);
    if (response['status'] && response['statusText']) {
      this.ngxUiLoaderService.stop();
      this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
    }
    else {
      this.ngxUiLoaderService.stop();
      if (response["status"] == 'success') {
        const source = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response['responsecontent']
          }`;
        const link = document.createElement("a");
        link.href = source;
        link.download = "Pipeline_" + Date.now().toString() + ".xlsx";
        link.click();
        this.toastr.success("Data Exported successfully !", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      }
      else {
        this.toastr.error("Data Exported Failed !", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
      }
    }

  }
  exportToExcel(items: any[], fileName: string): void {

    const selectedItems = items.map(item => {
      return {
        "PipelineGuid": item.PipelineGuid,
        "Pipeline": item.Pipeline,
        "ProcessorAgent": item.ProcessorAgent,
        "ProcessorAgentName": item.ProcessorAgentName,
        "SourceAgent": item.SourceAgent,
        "SourceAgentName": item.SourceAgentName,
        "DestinationAgent": item.DestinationAgent,
        "DestinationAgentName": item.DestinationAgentName,
        "SourceEntityGuid": item.SourceEntityGuid,
        "SourceEntityName": item.SourceEntityName,
        "DestinationEntityGuid": item.DestinationEntityGuid,
        "DestinationName": item.DestinationName,
        "RouteStatus ": item.RouteStatus,
        "RouteMode": item.RouteMode,
        "RouteDirection": item.RouteDirection,
        "Transactiondate": item.Transactiondate,
        "MessageType": item.MessageType,
        "Success": item.Success,
        "Failed": item.Failed,
        "Total": item.Total
      };
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedItems);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob: Blob = new Blob([excelBuffer], { type: 'application/xml' });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

  }



  locationString(item, item1, item2) {
    let names = item2.split("(")
    item2 = names[2] !== 'null)' && names[2] !== undefined ? `${names[0]} <br> (${names[1]} <br> (${names[2]}` : `${names[0]} <br> (${names[1]}`
    return '(' + item + ')' + ' ' + item1 + ' : ' + item2
  }

  targetCustomFilter = function (TargetAccountList: any[], query: string): any[] {
    return TargetAccountList;
  };
  selectedTargetEntitytype: string = ''
  selectedtargetagentEvent(item) //target agent selected Event
  {
    this.selectedtargetaccountid = item.LocationGUID;
    this.selectedtargetaccountName = item.locationName;
    this.selectedtargetassociatedaccountids = item.associatedaccountids;
    this.selectedtargetdeployementkey = item.deploymentkey;
    this.selectedTargetEntitytype = item.TypeName;
  }

  searchTargetNameCleared(event) {
    this.isLoadingTarget = false;
    this.TargetAccountList = [];
    this.selectedtargetaccountid = '';
    this.selectedtargetaccountName = '';
    this.notfounddata = false;
    this.selectedtargetassociatedaccountids = '';
    this.selectedtargetdeployementkey = '';
  }

  async getTargetAccountName(event: any) {

    var valueta = event;
    if (event.includes(":")) {
      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    this.selectedtargetaccountid = '';
    this.reactiveFormtarget.patchValue({
      namet: event
    })
    if (valueta != '' && !valueta.endsWith(":") && !valueta.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valueta.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingTarget = true;
      let obj = {
        lSearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedsourceaccountid,
        deploymentkey: this.selectedSourceDeploymentkey,
        associatedaccountids: this.selectedSourceAssociatedAccountids
      }
      //let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.TargetAccountList = [];
          this.notfounddata = true;
          this.isLoadingTarget = false;
          this.toastr.error("Unable to fetch Target Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {

            this.TargetAccountList = response as any[];
            //this.TargetAccountList = this.service.transformAlphabetically(this.TargetAccountList, 'LocationName');
            this.TargetAccountList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.TargetAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedtargetaccountid = '';
          this.isLoadingTarget = false;
        }
      })
    }

    else {
      this.TargetAccountList = [];
      this.notfounddata = true;
    }
  }
  selectedSourceEntitytype: string = "";
  selectedsourceagentEvent(item) //source agent selected Event
  {

    this.selectedsourceaccountid = item.LocationGUID;
    this.selectedsourceaccountName = item.LocationName;
    this.selectedSourceDeploymentkey = item.deploymentkey;
    this.selectedSourceAssociatedAccountids = item.associatedaccountids;
    this.selectedSourceEntitytype = item.TypeName;
  }

  searchSourceNameCleared(event) {
    this.isLoadingsource = false;
    this.SourceAccountList = [];
    this.selectedsourceaccountid = '';
    this.selectedsourceaccountName = '';
    this.notfounddata = false;
    this.selectedSourceDeploymentkey = '';
    this.selectedSourceAssociatedAccountids = '';
  }

  sourceCustomFilter = function (SourceAccountList: any[], query: string): any[] {
    return SourceAccountList;
  };
  keywordaccount = "LocationName";

  async getSourceAccountName(event: any) {

    var valuesa = event;
    if (event.includes(":")) {
      let splitValues = event.split(':')
      if (splitValues.length === 2) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        event = splitValues.join(":")
      }
      if (splitValues.length === 3) {
        splitValues[1] = splitValues[1].trimStart();
        splitValues[1] = " " + splitValues[1];
        splitValues[2] = splitValues[2].trimStart();
        splitValues[2] = " " + splitValues[2];
        event = splitValues.join(":")
      }
    }
    this.selectedsourceaccountid = '';
    this.reactiveFormsource.patchValue({
      name: event
    })
    if (valuesa != '' && !valuesa.endsWith(":") && !valuesa.endsWith(": ")) {
      let valueToSendToApiWithOutWhiteSpace = valuesa.replaceAll(": ", ":");
      //var createSearch = "api/SearchAccounts/GetAccountNamesListAsync?lSearch=" + valueToSendToApiWithOutWhiteSpace;
      this.isLoadingsource = true;
      let obj = {
        lSearch: valueToSendToApiWithOutWhiteSpace,
        LocationGUID: this.selectedtargetaccountid,
        deploymentkey: this.selectedtargetdeployementkey,
        associatedaccountids: this.selectedtargetassociatedaccountids
      }
      // let response = await this.service.getapi(createSearch);
      this.service.GetAccountNamesListAsync(obj).subscribe((response: any) => {
        if (response['status'] && response['statusText']) {
          this.SourceAccountList = [];
          this.notfounddata = true;
          this.isLoadingsource = false;
          this.toastr.error("Unable to fetch Source Details", "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });

        } else {
          if (response.length > 0) {
            this.SourceAccountList = response as any[];
            this.SourceAccountList = this.service.transformAlphabetically(this.SourceAccountList, 'LocationName');
            this.SourceAccountList.map(obj => obj.LocationName = obj.UniqueID !== null && obj.UniqueID !== undefined ? `${obj.LocationName} ${'(' + obj.LocationGUID + ')'} ${'(' + obj.UniqueID + ')'}` : `${obj.LocationName} ${'(' + obj.LocationGUID + ')'}`);
          }
          if (response.length == 0) {
            this.SourceAccountList = [];
            this.notfounddata = true;
          } else {
            this.notfounddata = false;
          }
          this.selectedsourceaccountid = '';
          this.isLoadingsource = false;
        }
      })
    }
    else {
      this.SourceAccountList = [];
      this.notfounddata = true;
    }
  }

  selectedRoute(item) {
    this.selectedrouteId = item.id;
    this.selectedrouteDisplayName = item.displayname;
  }

  getRouteNames(event: any) {
    var val = event;
    if (val) {
      this.isLoadingroutes = true;
      var apiname = "api/Routes/GetByDisplayName?name=" + val + "&version=0";
      this.service.getapiurl(apiname).subscribe({
        next: data => {
          this.RouteNameList = data as any[];
          this.RouteNameList.map(obj => obj.displayname = `${obj.displayname}  ${'(' + obj.id + ')'}`);
          this.isLoadingroutes = false;
          if (data.length == 0) {
            this.notfounddata = true;
          }
          else {
            this.notfounddata = false;
          }
        },
        error: error => {
          this.isLoadingroutes = false;
          this.notfounddata = true;
        }
      });
    }
  }

  searchRouteNameCleared(event) {
    this.isLoadingroutes = false;
    this.RouteNameList = [];
    this.selectedrouteId = '';
    this.selectedrouteDisplayName = '';
    this.notfounddata = false;
  }

  getstringRoute(value) {
    let names = value.split("(")
    value = `${names[0]} <br>(${names[1]}`
    return value
  }
}
