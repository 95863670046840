// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date_control_style {
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-inline: 2%;
}`, "",{"version":3,"sources":["webpack://./src/app/pipelinedataextract/pipelinedataextract.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,+GAAA;EACA,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".date_control_style{\r\n    width: 100%;\r\n    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);\r\n    border-radius: 5px;\r\n    padding-inline: 2%;\r\n    // padding-block: 3%;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
