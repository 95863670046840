import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/services/common.service';
// import { error } from 'util';

@Component({
  selector: 'app-savevendor',
  templateUrl: './savevendor.component.html',
  styleUrls: ['./savevendor.component.scss']
})
export class SavevendorComponent implements OnInit {
  @Input() inputDataInModalComponent: any;
  connectivitylist = [ 'FTP', 'VPN', 'Rest API','Device']
  enablerestapicontrol: boolean;
  vendorupdate: boolean;
  vendorObj: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  connectivitytype: string = "Select";
  ApiValue: string = "";
  ContractDetails: string = "";
  separatorKeysCodes: number[] = [ENTER, COMMA];
  specificationdocumentCntrl = new FormControl();
  samplefileCntrl = new FormControl();
  samplefilenamelist: any = [];
  specificationdocumentfilenamelist: any = [];
  samplefiledetails: any = [];
  specificationdocumentfiledetails: any = [];
  title: string;
  @ViewChild("SpecificDocumentUpload", { static: false }) specificfileInput;
  @ViewChild("fileUpload", { static: false }) samplefileInput;
  vendornamelist: any;
  connectivitycntrl = new FormControl();
  public reactiveFormvendar: FormGroup;
  contentctrl = new FormControl('');
  interfacetypectrl = new FormControl('');


  selectedconnectivity = [];
  constructor(private ngxUiLoaderService: NgxUiLoaderService, public service: CommonService, private activeModal: NgbActiveModal, private toastr: ToastrService, private _fb: FormBuilder,
  ) {
    this.reactiveFormvendar = this._fb.group({ namevendar: '' });
  }
  pagerolepermisions: any = [];
  ngOnInit() {
    this.getVendorname();
    this.selectInttype();
    this.vendornamelist = this.inputDataInModalComponent.vendornamelist;

    if (this.inputDataInModalComponent.Type == "Add") {
      this.setUiObj();
      if (this.inputDataInModalComponent.vendorname) {
        this.vendorObj.name = this.inputDataInModalComponent.vendorname;
      }
    }
    else if (this.inputDataInModalComponent.Type == "Edit") {

      this.vendorupdate = true;
      this.vendorObj = {};
      this.vendorObj = JSON.parse(JSON.stringify(this.inputDataInModalComponent.data));
      this.title = "Vendor Name : ";
      this.reactiveFormvendar.patchValue({ namevendar: this.vendorObj['name'] });
      this.contentctrl.setValue(this.vendorObj['contentType'])
      this.interfacetypectrl.setValue(JSON.parse(this.vendorObj['interfaceType']))
      this.contenttype = this.vendorObj['contentType'];
      this.interfacetypelist = JSON.parse(this.vendorObj['interfaceType']);
      this.loadPrerequisits(this.vendorObj);
      const menuid = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.find(y => y.MenuName == 'vendor').MenuId;
      const actionitems = JSON.parse(sessionStorage.getItem('rolewithmenus')).find(x => x.RoleName == sessionStorage.getItem('SelectedUserRole')).menuslist.filter(y => y.ParentMenuId == menuid);
      actionitems.forEach(element => {
        this.pagerolepermisions[element.MenuName] = true;
      });
    }
  }
  setUiObj() {
    this.vendorObj = {
      name: '',
      contactperson: '',
      contactphone: '',
      contactemail: '',
      mnemonic: '',
      specificationdocument: [],
      samplefiles: []
    }
    this.title = "Create Vendor";
    this.vendorupdate = false;
  }
  loadPrerequisits(vendorObj: any) {
    if (vendorObj["connectivity"]) {
      let connectivityDetails = JSON.parse(vendorObj["connectivity"]);
      if (connectivityDetails["connectivitytype"]) {
        this.selectedconnectivity = connectivityDetails["connectivitytype"].split(",");
        if (connectivityDetails["restapiadditionalinfo"]) {
          this.enablerestapicontrol = true;
          this.ApiValue = connectivityDetails["restapiadditionalinfo"][0]["api"];
          this.ContractDetails = connectivityDetails["restapiadditionalinfo"][0]["contractdetails"];
        }
      }
    }
    this.samplefiledetails = [];
    this.specificationdocumentfiledetails = [];
    this.samplefilenamelist = [];
    this.specificationdocumentfilenamelist = [];
    if (vendorObj["specificationdocument"].length > 0) {
      for (var i = 0; i < vendorObj["specificationdocument"].length; i++) {
        this.specificationdocumentfilenamelist.push(vendorObj["specificationdocument"][i].filename)
      }
      this.specificationdocumentfiledetails = vendorObj["specificationdocument"];
    }
    if (vendorObj["samplefiles"].length > 0) {
      for (var i = 0; i < vendorObj["samplefiles"].length; i++) {
        this.samplefilenamelist.push(vendorObj["samplefiles"][i].filename)
      }
      this.samplefiledetails = vendorObj["samplefiles"];
    }
  }
  verify(data) {
    if (data == "Rest API") {
      this.enablerestapicontrol = true;
    }
    else {
      this.ContractDetails = "";
      this.ApiValue = "";
      this.enablerestapicontrol = false;
    }
  }
  closevendorpopup() {

    this.activeModal.close();
    this.clearvalue();
  }
  clearvalue() {
    this.vendorupdate = false;
    this.enablerestapicontrol = false;
    this.connectivitytype = "Select";
    this.ApiValue = "";
    this.ContractDetails = "";
    this.vendorObj = "";
    this.specificationdocumentfilenamelist = [];
    this.specificationdocumentfiledetails = [];
    this.samplefiledetails = [];
    this.samplefilenamelist = [];
    this.title = "";
    this.selectedconnectivity = [];
  }
  remove(filename, type) {
    if (type == "samplefile") {
      this.samplefilenamelist = this.samplefilenamelist.filter(obj => obj !== filename);
      this.samplefiledetails = this.samplefiledetails.filter(obj => obj.filename !== filename)
    }
    else {
      this.specificationdocumentfilenamelist = this.specificationdocumentfilenamelist.filter(obj => obj !== filename);
      this.specificationdocumentfiledetails = this.specificationdocumentfiledetails.filter(obj => obj.filename !== filename);
    }

  }
  async onFileSelected(event, type) {
    this.ngxUiLoaderService.start();
    let list = [];
    for (let i = 0; i < event.target.files.length; i++) {
      let fileData = {
        fileContent: '',
        filename: '',
        fileid: ''
      };
      var file = event.target.files[i];
      // this.fileName = file.name;
      fileData.filename = file.name;
      if (type == "samplefile") {
        this.samplefilenamelist.push(file.name);
      }
      else {
        this.specificationdocumentfilenamelist.push(file.name);
      }

      var reader = new FileReader();
      reader.onload = (function (theFile) {

        return function (e) {
          var binaryData = e.target.result as any;
          var base64String = window.btoa(binaryData);
          fileData.fileContent = base64String as any;
        };

      })(event.target.files[i]);
      reader.readAsBinaryString(event.target.files[i]);
      if (type == "samplefile") {
        this.samplefiledetails.push(fileData);
      }
      else {
        this.specificationdocumentfiledetails.push(fileData);
      }
    }
    if (type == "samplefile") {
      this.samplefileInput.nativeElement.value = "";
    }
    else {
      this.specificfileInput.nativeElement.value = "";
    }
    this.ngxUiLoaderService.stop();

  }

  savevendor() {

    if (this.validate()) {
      if (this.vendorObj["name"] != "" || this.vendarnamevalue !== '') {
        let Vename
        if (typeof this.reactiveFormvendar.value.namevendar === "string") {
          Vename = this.reactiveFormvendar.value.namevendar
        } else if (typeof this.reactiveFormvendar.value.namevendar === "object") {
          Vename = this.vendarnamevalue
        }
        let exists =[]
if(this.vendornamelist !== undefined && this.vendornamelist.length > 0) {
   exists = this.vendornamelist.filter(va => va.name === Vename);
}

        if (exists.length > 0 && !this.vendorupdate) {
          this.toastr.warning('Vendor already exists!', "", {
            timeOut: 4000,
            positionClass: 'toast-bottom-right'
          });
        }
        else {
          this.ngxUiLoaderService.start();
          let connectivitytypeObj = {};
          let vendorObj = {
            Id: this.vendorupdate ? this.vendorObj["Id"] : 0,
            name: Vename,
            contactperson: this.vendorObj["contactperson"],
            contactphone: this.vendorObj["contactphone"],
            contactemail: this.vendorObj["contactemail"],
            specificationdocument: this.specificationdocumentfiledetails,
            samplefiles: this.samplefiledetails,
            connectivity: "",
            userid: JSON.parse(sessionStorage.getItem('sessionObjectlogin')).userdata['userid'],
            mnemonic: this.vendorObj["mnemonic"],
            contentType: this.contenttype,
            interfaceType: JSON.stringify(this.interfacetypelist)
          }

          if (this.selectedconnectivity.findIndex(x => x == "Rest API") >= 0) {
            connectivitytypeObj = {
              connectivitytype: this.selectedconnectivity.join(","),
              restapiadditionalinfo: [{
                api: this.ApiValue,
                contractdetails: this.ContractDetails
              }]
            };
          }
          else {
            connectivitytypeObj = {
              connectivitytype: this.selectedconnectivity.join(",")
            }
          }
          vendorObj.connectivity = JSON.stringify(connectivitytypeObj);
          let obj = vendorObj
          this.service.postapi("api/Vendor/SaveVendor", vendorObj).then(data => {
            if (data) {
              if ((data['status'] && data['statusText']) || data['statusText'] === "Unknown Error") {
                this.ngxUiLoaderService.stop();
                this.toastr.error("Something Went Wrong!", "", { timeOut: 4000, positionClass: 'toast-bottom-right' });
              }
              else {
                let displaymessage = this.vendorupdate ? "Vendor Updated Successfully" : "Vendor Created Successfully";
                this.service.vendorStatus(true)
                if (vendorObj["Id"] != 0) {
                  this.vendorObj.contactperson = this.reactiveFormvendar.value.contactperson;
                  this.vendorObj.contactemail = this.vendorObj["contactemail"];
                  this.reactiveFormvendar.patchValue({
                    contactperson: this.vendorObj.contactperson,
                    contactemail: this.vendorObj.contactemail,
                  });
                  this.ngxUiLoaderService.stop();
                  this.toastr.success(displaymessage, "", {
                    timeOut: 4000,
                    positionClass: 'toast-bottom-right'
                  });
                  this.autoclosetemplate("save");
                }
                else {

                  this.ngxUiLoaderService.stop();
                  this.toastr.success(displaymessage, "", {
                    timeOut: 4000,
                    positionClass: 'toast-bottom-right'
                  });

                  if (this.inputDataInModalComponent.component == "createpipeline") {
                    let data = {
                      vendorname: vendorObj.name,
                      dataflag: "refreshvendor"
                    }
                    this.autoclosetemplate(data);
                  }
                  else {
                    this.autoclosetemplate("save");
                  }
                }
              }
            }
          }), error => {
            this.ngxUiLoaderService.stop();
            this.toastr.error('Failed to Save the Vendor', "", {
              timeOut: 4000,
              positionClass: 'toast-bottom-right'
            });
          }
        }
      }
    }
    else {
      this.toastr.warning("Please enter the required fields", "", {
        timeOut: 4000,
        positionClass: 'toast-bottom-right'
      });
    }
  }
  validate(): boolean {
    let isvalid = true;
    if (this.vendorObj["name"] == "") {
      isvalid = false;
    }
    else if (this.samplefiledetails.length == 0) {
      isvalid = false;
    }
    else if (this.specificationdocumentfiledetails.length == 0) {
      isvalid = false;
    }
    else if (this.selectedconnectivity.length == 0) {
      isvalid = false;
    }
    else if ((this.selectedconnectivity.findIndex(x => x == "Rest API") >= 0) && (this.ApiValue == "" || this.ContractDetails == "")) {
      isvalid = false;
    }
    else if (this.vendorObj["mnemonic"] == "") {
      isvalid = false;
    }
    else if (this.interfacetypelist.length == 0) {
      isvalid = false;
    }
    else if (this.contenttype == undefined || this.contenttype == null || this.contenttype == '') {
      isvalid = false;
    }

    return isvalid;
  }
  autoclosetemplate(cnt) {
    setTimeout(() => {
      this.activeModal.close(cnt);
    }, 3000);
  }
  selectedconnectivitytype(event) {
    if (event.option.viewValue == "Rest API" || this.selectedconnectivity.findIndex(x => x == "Rest API") >= 0) {
      this.enablerestapicontrol = true;
    }
    else {
      this.enablerestapicontrol = false;
    }
    if (this.selectedconnectivity.length > 0) {
      let exist = this.selectedconnectivity.filter(va => va === event.option.value);
      if (exist.length > 0) {
        this.toastr.warning(exist[0] + " " + 'Already Exist', "", {
          timeOut: 4000,
          positionClass: 'toast-bottom-right'
        });
      }
      else {
        this.selectedconnectivity.push(event.option.value);
      }
    }
    else {
      this.selectedconnectivity.push(event.option.value);
    }
    this.connectivitycntrl.setValue(null);
    let ele = document.getElementById("connectivity");
    ele.innerText = '';
    ele.focus();
    ele.blur();
  }
  removeconnectivitytype(data) {
    if (data == "Rest API") {
      this.ContractDetails = "";
      this.ApiValue = "";
      this.enablerestapicontrol = false;
    }
    const index = this.selectedconnectivity.findIndex(va => va == data);
    if (index >= 0) {
      this.selectedconnectivity.splice(index, 1);
    }
  }
  VendarsList: any
  keywordsearchvendar = "name";
  vendarnamevalue: string
  isLoadingvendar: boolean = false
  notfounddata: boolean = false
  VendornameBackuplist: any[] = []
  selectedvendorId: string
  selectedvendorname: string
  getVendorname() {
    this.ngxUiLoaderService.start();
    var url = "api/Vendor/GetVendorName";
    this.service.getapiurl(url).subscribe(data => {
      if (data) {
        this.VendarsList = data as any[];
        this.VendarsList = this.service.transformAlphabetically(this.VendarsList, 'name');
        this.VendornameBackuplist = this.VendarsList;
        this.ngxUiLoaderService.stop();
        // this.reactiveFormvendar.setValue({ "namevendar": '' });
      }
      else {
        this.ngxUiLoaderService.stop();
      }
    })
  }
  filtervendorlist(event) {
    this.VendarsList = this.VendarsList.filter(t => t.name.toLowerCase().includes(event.toLowerCase()));
    if (this.VendarsList.length == 0) {
      this.notfounddata = true;

    } else {
      this.notfounddata = false;
    }
    this.isLoadingvendar = false;
  }
  selectedvendarEvent(event) {
    this.vendarnamevalue = event.name;
    this.selectedvendorId = event.Id;
    this.selectedvendorname = event.name;
  }
  async getvendarsName(event) {
    var vendarvalue = event;
    this.vendarnamevalue = '';
    if (vendarvalue != '') {
      this.isLoadingvendar = false;
      if (this.VendarsList.length > 0) {
        this.notfounddata = false;
        this.vendorObj['name'] = event;
        this.filtervendorlist(vendarvalue);
      }
      else {
        this.notfounddata = true;
        this.VendarsList = this.VendornameBackuplist;
        this.filtervendorlist(vendarvalue);
      }
    }
  }
  searchvendarNameCleared() {
    this.reactiveFormvendar.setValue({ "namevendar": '' });
    this.isLoadingvendar = false;
    this.vendorObj['name'] = '';
  }
  IType: any[] = []
  ContType: any[] = [];
  contenttype: string
  selectInttype() {

    this.service.getIntContType('interfacetype').subscribe((data) => {
      if (data) {
        this.IType = data
      }
    })
    this.service.getIntContType('interfacecontenttype').subscribe((data) => {
      if (data) {
        this.ContType = data
      }
    })
  }
  interfacetypelist: any[] = [];
  selectedItype(event) {
    this.interfacetypelist = event.value;
  }
  selectedCtype(event) {
    this.contenttype = event.value;
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
}


