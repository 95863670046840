import { Component, Inject, OnInit, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CounterService } from '../services/FileDatabaseService';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})

export class ConditionComponent {
  instanceList = [];
  conditionresult = ""
  num = 0;
  [x: string]: any;
  isDirty: any;
  xpathArray = [];
  xpath=[];
  xmldata: any;
  conditionList = [
    "select",
    "count",
    "starts with",
    "Not starts with",
    "contains",
    "Not contains",
    "Not Exists",
    "Exists",
  ];
  lastatt:any;
  constructor(public dialogRef: MatDialogRef<ConditionComponent>, private dialog: MatDialog, public counterService: CounterService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
    this.xpathArray = this.data.xpath
    this.xmldata = this.data.xmldata
    this.getXpath();
  }
  onNoClick() {

    this.isDirty = false;
    this.dialogRef.close('true');
    // this.canDeactivate()
  }
  getXpath(){
    if(this.data.backupxpath.length>0){
      this.xpath = Object.assign([], this.xpathArray);
      this.xpath.forEach((ele)=>{
        if (this.data.input.length > 0) {
          this.data.input.forEach(element => {
            if(element !== null && element !== undefined){
              for (const [key, values] of Object.entries(element)){
                this.data.backupxpath.forEach(val =>{
                if(key==val.value && val.value== ele.value){
                  ele.value= values["conditionvalue"];
                }
              });
              }
            }
          });
        }
        else{
          ele.value='';
        }
      });
    }
  }

  addSubCondition(item, i) {

    let isfound = false;
    if (this.data.input !== undefined) {
      if (this.data.input.length > 0) {
        this.data.input.forEach(element => {
          if(element !== null && element !== undefined){
            if (element["#"+(i+1)] != undefined) {
              item[i]['condition'] = JSON.parse(element["#"+(i+1)].condition);
              isfound = true;
            }
          }
        });
      }
    }

    if (!isfound) {
      item[i]['condition'] = [
        {
          key: '',
          value: '',
          isdroppable: true,
          required: true,
          label: 'Add Group',
          type: 'button',
          placeholder: 'Enter the Value',
          enablegroup: true,
          order: (i + 1),
          conditions: [],
          operator: ""
        }
      ]
    }
  }

  addItem(arr, ite): void {

    let obj = {
      left: "",
      oper: "",
      right: "",
      leftsubcondition: [],
      leftsubexp: "",
      rightsubexp: "",
      rightsubcondition: [],
      conditiontype: "",
      notCondition: false,
      startsWithorContainsCondition: false,
    };
    arr.push(obj);
    // this.items = this.conditionGroup.get('items') as FormArray;
    // this.items.push(this.createItem());
  }

  addGroup(array, i) {
    let obj = {
      key: "",
      value: "",
      isdroppable: false,
      required: true,
      label: "Add Group",
      type: "button",
      placeholder: "Enter the Value",
      order: i + 1,
      conditions: [],
      enablegroup: true,
      isAddedgroup: true,
      operator: ""
    }
    if (array.length > 0) {
      array[i].enablegroup = false
    }
    array.push(obj)
  }

  removeGroup(array, i) {
    array.splice(i, 1);
  }
  canDeactivate(): boolean {
    return true
  }

  removeAddressAt(arr, i) {
    arr.splice(i, 1);
  }

  opensubcondition(xpath: any, input) {
    let xpathArray = []
    this.counterService.backupdatainput = input;
    this.counterService.position = xpath.target.title;
    let subexp="";
    let inputValue="";
    if (this.counterService.position === "left") {
      subexp = input.leftsubexp;
      inputValue=input.left;
    }
     if (this.counterService.position === "right") {
      subexp = input.rightsubexp;
      inputValue=input.right;
    }
    if(inputValue!=""){
    let pas = []
    let paths
    if(subexp!=""){
      paths= subexp.split('/');
    }
    else{
      paths = xpath.target.value.split('/');

    }

    let pat
    if (paths.length > 0) {
      pat = paths.filter((c: any) => !c.startsWith('@'));
      if (pat.length > 0) {
        if (paths[paths.length - 1].startsWith("@")) {
          this.lastatt = paths[paths.length - 1];
        }
      }
      pas = pat
    } else {
      pas = paths;
    }

    for (let i = 0; i < pas.length; i++) {
      let isContainsAttr = false;
      if (pas[i].startsWith("@")) {
        isContainsAttr = true;
      }
      let pats
      let ps
      if (pas[i].includes("[")) {
        pats = pas[i].split("[")
        ps = {
          path: pats[0],
          value: pats[1].split("]")[0],
          isOpen: true,
          isContainsAttr: isContainsAttr
        }
      } else {
        ps = {
          path: pas[i],
          value: "",
          isOpen: true,
          isContainsAttr: isContainsAttr
        }
      }

      xpathArray.push(ps);
    }
    if (xpathArray.length > 0) {
      this.num = this.incCount()
      this.counterService.count = this.num
      if(this.counterService.backupdatainput[this.counterService.position+'subcondition'] == undefined){
        this.counterService.backupdatainput[this.counterService.position+'subcondition'] =[]
      }
      let ov = {
        xpath: xpathArray,
        xmldata: this.xmldata,
        input: this.counterService.backupdatainput[this.counterService.position+'subcondition'],
        backupxpath:JSON.parse(JSON.stringify(xpathArray))
      }

    let dialogRef: MatDialogRef<ConditionComponent> = this.dialog.open(ConditionComponent, {
      disableClose: true,
      hasBackdrop: false});
    dialogRef.componentInstance.data =ov

    dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        if (this.counterService.instanceList.length > 0) {
          let uniq = this.counterService.instanceList.filter(va => va.name === dialogRef.id)
          if (uniq.length > 0) {
            let unipath = uniq[0].refdata.xpath;
            let backupxpath=uniq[0].refdata.backupxpath;
            this.counterService.backupdatainput[this.counterService.position] = Array.prototype.map.call(unipath, function (item) {
              if (item.value !== "" && item.value !== undefined) {
                return item.path + "[" + item.value + "]"
              } else {
                if (item.value !== undefined) {
                  return item.path + item.value
                } else {
                  return item.path
                }
              }
            }).join("/");
            if (this.lastatt !== "" && this.lastatt !== undefined) {
              this.counterService.backupdatainput[this.counterService.position] = this.counterService.backupdatainput[this.counterService.position] + "/" +this.lastatt;
            }
            (uniq[0].ref._containerInstance._config.data);
            this.counterService.backupdatainput[this.counterService.position+'subexp']=Array.prototype.map.call(backupxpath, function (item) {
              if (item.value !== "" && item.value !== undefined) {
                return item.path + "[" + item.value + "]"
              } else {
                if (item.value !== undefined) {
                  return item.path + item.value
                } else {
                  return item.path
                }
              }
            }).join("/");
            if (this.lastatt !== "" && this.lastatt !== undefined) {
              this.counterService.backupdatainput[this.counterService.position+'subexp'] = this.counterService.backupdatainput[this.counterService.position+'subexp'] + "/" + this.lastatt;
            }
          }


        }
      }
    })
      // this.dialogRef = this.dialog.open(ConditionComponent, )
      let obj = {
        ref: dialogRef,
        name: dialogRef.id,
        refdata:dialogRef.componentInstance.data
      }
      this.counterService.instanceList.push(obj)
      // this.modalService.open(modal, { windowClass: "condition_modal", backdrop: 'static', keyboard: false, });
    }
    }
    else{
      if (this.counterService.position === "left") {
        input.leftsubcondition=[];
        input.leftsubexp="";
       }
        if (this.counterService.position === "right") {
         input.rightsubcondition=[];
         input.rightsubexp="";
       }
       this.toastr.warning(
         "Please enter the value",
         "",
         {
           timeOut: 4000,
           positionClass: "toast-bottom-right",
         }
       );
    }
  }


  onItemDrop(e: any, obj: any) {
    let path = this.getDraggedPath(e);
    let pathss;
    // if (value.type !== '') {
    //   pathss = this.getPath(this.xmldata, value.type, '')
    // } else {
    //   pathss = this.findPropPath(this.xmldata, value.filename).split('/').join(".")
    // }
    path = path.split("/")
    this.removeuniq(path)
    path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
    path = path.join("/");
    obj.value = path

    // let bui = this.recompose(this.xmldata, pathss, path)
    // setTimeout(() => {
    //   if (this.backup) {
    //     this.backup.higlight = false;
    //   } else {
    //     this.backup = JSON.parse(JSONstringify(value);
    //   }
    // }, 1000);
  }
  removeuniq(array) {
    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].includes("_")) {
          let nameuniq = array[i].split("_")[1]
          if (nameuniq !== "AddCondition" && nameuniq !== "RepeatableNode" && nameuniq !== "Variable" && nameuniq !== "NodeIdentifier") {
            array[i] = array[i].split("_")[0]
          }
        }
      }
    }
  }
  onItemDropOnInput(e: any, input) {

    let path = this.getDraggedPath(e);
    path = path.split("/")
    this.removeuniq(path)
    path = path.filter(va => va !== "0" && va !== "1" && va !== "2" && va !== 'attr' && va !== '_NodeIdentifier')
    path = path.join("/");
    let type = e.nativeEvent.target.title;

    switch (type) {
      case 'left':
        input.left = path
        break;
      case 'right':
        input.right = path
        break;
      default:
        input.value = path;
        break;
    }

  }

  recompose(obj, string, val) {
    if (string) {
      let parts;
      if (typeof string === 'string') {
        parts = string.split('.');
      } else {
        parts = string
      }
      var newObj = obj[parts[0]];
      if (parts[1]) {
        parts.splice(0, 1);
        var newString = parts.join('.');
        return this.recompose(newObj, newString, val);
      }
      obj[parts[0]] = val
      return obj;
    }
  }
  getDraggedPath(e: any) {
    let path;
    if (e) {
      if (e.dragData.type) {
        path = this.getPath(this.xmldata, e.dragData.type, '')
      } else {
        path = this.findPropPath(this.xmldata, e.dragData.filename).split('/')
      }
      for (let i = 0; i < path.length; i++) {
        if (path[i] === "attr") {
          path.splice(i, 1);
          path[path.length - 1] = "@" + path[path.length - 1]
        }
      }
      path = path.join("/");
    }
    return path;
  }

  findPropPath(obj, name) {
    for (var prop in obj) {
      if (prop == name) {
        return name;
      } else if (typeof obj[prop] == "object") {
        var result = this.findPropPath(obj[prop], name);
        if (result) {
          return prop + "/" + result;
        }
      }
    }
    return null; // Not strictly needed, but good style
  }

  getPath(obj, value, path) {
    if (typeof obj !== "object") {
      return false;
    }
    for (var key in obj) {
      if (obj[key]) {
        var t = path;
        var v = obj[key];
        var newPath = path ? path.slice() : [];
        newPath.push(key);
        if (v === value) {
          return newPath;
        } else if (typeof v !== "object") {
          newPath = t;
        }
        var res = this.getPath(v, value, newPath);
        if (res) {
          return res;
        }
      }
    }
    return false;
  }

  public getCount() {
    return this.counterService.count
  }
  public incCount() {
    return this.counterService.count += 1;
  }
  addcondition(data: any, array: any, index: any): void {
    let selectedCondition = data.value;
    if (selectedCondition != "select") {
      array[index].conditiontype = selectedCondition;
    }
    if (selectedCondition) {
      if (selectedCondition != "count" && selectedCondition != "select") {
        if (
          selectedCondition.toLowerCase() == "starts with" ||
          selectedCondition.toLowerCase() == "not starts with" ||
          selectedCondition.toLowerCase() == "contains" ||
          selectedCondition.toLowerCase() == "not contains"
        ) {
          array[index].startsWithorContainsCondition = true;
          array[index].notCondition = false;
        } else {
          array[index].notCondition = true;
          array[index].startsWithorContainsCondition = false;
        }
      } else {
        array[index].notCondition = false;
        array[index].startsWithorContainsCondition = false;
      }
    }
  }

  saveCondition_sub(array, ind, xpath, indee) {
    this.conditionresult = '';
    var parentandOr = "";
    parentandOr = array[0].operator;

    let addParentCondition = false;
    if (array[0].conditions.length > 0) {
      addParentCondition = true;
    }
    else if (array.length > 2) {
      addParentCondition = true;
    }
    var count = 1;
    array.forEach((element) => {
      let andOr = "";
      andOr = element.operator;
      let isAddedgroup = false;
      if (element.hasOwnProperty('isAddedgroup')) {
        isAddedgroup = element.isAddedgroup;
      }
      this.generateCondition(element.conditions, andOr, isAddedgroup);
      if (addParentCondition && count < array.length) {
        this.conditionresult += " " + parentandOr + " ";
      }
      count++;
    });
    if (this.conditionresult !== "") {
     xpath[indee].value = this.conditionresult
    }
    // var isFoundKey=false;
        // if( this.data.input.length>0 && !this.data.isnew ) {
        //   var count=1;
        //   this.data.input.forEach(element => {
        //     for (const [key, value] of Object.entries(element)){
        //       if(element[key]!=undefined){
        //         element[key]=JSON.stringify(array);
        //         value["conditionvalue"]=this.conditionresult;
        //         // isFoundKey=true;
        //         this.data.backupxpath[indee].value="#"+(indee+1);
        //       }
        //     }
        //     count++;
        //   });

        // }
        // if(this.data.isnew){
          var index="#" + (indee+1);
          var parameter=this.createJson(index);
          var conditionArray=this.createJson("condition");
          conditionArray["condition"]=JSON.stringify(array);
          conditionArray["conditionvalue"]=this.conditionresult;
          parameter["#" + (indee+1)]=conditionArray;
          this.data.input[indee] = parameter;
          this.data.backupxpath[indee].value="#"+(indee+1);
        // }
  }
  createJson(objectname) {
    let json = `{"${objectname}":{}}`;
    return JSON.parse(json);
  }
  generateCondition(array, andOr, isGroupadded) {
    var count = 1;
    array.forEach((ele) => {
      if (ele.notCondition) {
        this.conditionresult += "(";
      }
      else if (count == 1 && isGroupadded && array.length > 1) {
        this.conditionresult += "(";
      }
      if (ele.startsWithorContainsCondition) {
        if (ele.conditiontype.toLowerCase() == "starts with") {
          this.conditionresult += "starts-with(" + ele.left + "," + ele.right + ")";
        }
        else if (ele.conditiontype.toLowerCase() == "not starts with") {
          this.conditionresult += "not(starts-with(" + ele.left + "," + ele.right + "))";
        }
        else if (ele.conditiontype.toLowerCase() == "contains") {
          this.conditionresult += "contains(" + ele.left + "," + ele.right + ")";
        }
        else if (ele.conditiontype.toLowerCase() == "not contains") {
          this.conditionresult += "not(contains(" + ele.left + "," + ele.right + "))";
        }
      }
      else if (ele.notCondition) {
        if (ele.conditiontype.toLowerCase() == "not exists") {
          this.conditionresult += "not(" + ele.left + ")";
        } else {
          this.conditionresult += "(" + ele.left + ")";
        }
      }
      else if (ele.conditiontype.toLowerCase() == "count") {
        this.conditionresult += "count(" + ele.left + ")" + " " + ele.oper + " " + ele.right;
      }
      else {
        this.conditionresult += ele.left + " " + ele.oper + " " + ele.right;
      }

      if (array.length >= 2 && count == array.length - 1) {
        this.conditionresult += " " + andOr + " ";
      }
      if (count == array.length && isGroupadded && array.length > 1) {
        this.conditionresult += ")";
      }
      else if (ele.notCondition) {
        this.conditionresult += ")";
      }
      count++;
    });
  }
}
