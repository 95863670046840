// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Btn_confirm:focus {
  background-color: #ffffff !important;
  color: black !important;
  border: 1px solid;
}

.Btn_confirm:hover {
  background-color: #ffffff !important;
  color: black !important;
  border: 1px solid;
}

.Template_dropDown {
  height: 32px;
  border-radius: 5px;
  padding-inline: 1%;
  position: relative;
  top: 1%;
  background-color: white;
  margin-left: 1px;
  margin-right: 1%;
  margin-block: 1%;
}

::ng-deep.Template_dropDownDialog .mat-form-field-infix {
  border: 0 !important;
  height: 33px !important;
  font-size: 13px;
}

::ng-deep.Template_dropDownDialog .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 1%;
  margin: 1%;
}

::ng-deep.Template_dropDownDialog .mat-form-field-label-wrapper {
  top: -1px !important;
}

::ng-deep.Template_dropDownDialog .mat-select-trigger {
  min-width: 10vw;
}`, "",{"version":3,"sources":["webpack://./src/app/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACE,uBAAA;EACA,iBAAA;AACN;;AACE;EACE,oCAAA;EACE,uBAAA;EACA,iBAAA;AAEN;;AAAE;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,OAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AAGJ;;AADE;EACE,oBAAA;EACA,uBAAA;EACA,eAAA;AAIJ;;AAFE;EACE,WAAA;EACA,UAAA;AAKJ;;AAHE;EACE,oBAAA;AAMJ;;AAJE;EACE,eAAA;AAOJ","sourcesContent":[".Btn_confirm:focus{\r\n    background-color: #ffffff !important;\r\n      color: black !important;\r\n      border: 1px solid;\r\n  }\r\n  .Btn_confirm:hover{\r\n    background-color: #ffffff !important;\r\n      color: black !important;\r\n      border: 1px solid;\r\n  }\r\n  .Template_dropDown{\r\n    height: 32px;\r\n    border-radius: 5px;\r\n    padding-inline: 1%;\r\n    position: relative;\r\n    top: 1%;\r\n    background-color: white;\r\n    margin-left: 1px;\r\n    margin-right: 1%;\r\n    margin-block: 1%;\r\n  }\r\n  ::ng-deep.Template_dropDownDialog  .mat-form-field-infix{\r\n    border: 0 !important;\r\n    height: 33px !important;\r\n    font-size: 13px;\r\n  }\r\n  ::ng-deep.Template_dropDownDialog .mat-form-field-appearance-outline .mat-form-field-wrapper{\r\n    padding: 1%;\r\n    margin: 1%;\r\n  }\r\n  ::ng-deep.Template_dropDownDialog .mat-form-field-label-wrapper{\r\n    top:-1px !important;\r\n  }\r\n  ::ng-deep.Template_dropDownDialog  .mat-select-trigger {\r\n    min-width: 10vw;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
