// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action_btn {
  width: 42px;
  height: 42px;
  line-height: 32px;
  background-color: #1590a2;
  color: white;
  font-size: 17px;
}

.vendor_form_field {
  width: 100%;
}

.vendorconnectivity_chiplist {
  width: 100%;
}

.contact_card_min {
  width: 33%;
}

.contact_card {
  width: 20%;
}

.account_circle {
  font-size: 14px;
  height: auto;
  width: auto;
  margin-left: -1px;
  margin-right: 4px;
}

.form-control {
  font-size: 12px;
}

.custom-font {
  font-size: 12px;
}

::ng-deep.Entityautocomplete .autocomplete-container .suggestions-container ul {
  max-height: 112px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/contactmodule/contactmodule.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAAY,YAAA;EAAa,iBAAA;EAAkB,yBAAA;EAA0B,YAAA;EAAa,eAAA;AAMtF;;AAHA;EACI,WAAA;AAMJ;;AAHA;EACI,WAAA;AAMJ;;AAJA;EACE,UAAA;AAOF;;AALA;EACE,UAAA;AAQF;;AALA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;AAQF;;AAJA;EACE,eAAA;AAOF;;AAJA;EACE,eAAA;AAOF;;AAJA;EACC,4BAAA;AAOD","sourcesContent":[".action_btn{\r\n    width: 42px;height: 42px;line-height: 32px;background-color: #1590a2;color: white;font-size: 17px;\r\n        }\r\n\r\n.vendor_form_field{\r\n    width: 100%;\r\n}\r\n\r\n.vendorconnectivity_chiplist{\r\n    width: 100%;\r\n  }\r\n.contact_card_min{\r\n  width: 33%;\r\n}\r\n.contact_card{\r\n  width: 20%;\r\n}\r\n\r\n.account_circle{\r\n  font-size: 14px;\r\n  height: auto;\r\n  width: auto;\r\n  margin-left: -1px;\r\n  margin-right: 4px;\r\n\r\n}\r\n\r\n.form-control {\r\n  font-size: 12px; \r\n}\r\n\r\n.custom-font {\r\n  font-size: 12px; \r\n}\r\n\r\n::ng-deep.Entityautocomplete .autocomplete-container .suggestions-container ul{\r\n max-height:112px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
