import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthorizeService } from './authorize.service';
import { CommonService } from './common.service';
// import { UserSession } from './user.session';
// import { environment } from '../../../environments/environment'

@Injectable()//{ providedIn: 'root' }
export class AuthGuard  {
  constructor(private service: CommonService,private authService:AuthorizeService,
    private router: Router,private route: ActivatedRoute) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Promise<boolean> {
    let isAuthenticated = sessionStorage.getItem('sessionObjectlogin')

    if (!this.authService.getCookie(window.location.hostname)) {
      isAuthenticated = '';
    }

    if(route.queryParams.Input)
    {
      isAuthenticated="true";
    }
    else if (!isAuthenticated) {
      this.router.navigate(['/login']);
    }
    else {
      let locationarray = state.url.split("/")
      if (locationarray.length > 0 && locationarray[locationarray.length - 1] !== 'login') {
        let rolemenusarray = JSON.parse(sessionStorage.rolewithmenus);
        let rolemenus
        if (rolemenusarray.length > 0) {
          rolemenus = rolemenusarray.filter(role => role.RoleName == sessionStorage.SelectedUserRole)
          if (rolemenus.length > 0) {
            if (rolemenus !== undefined) {
              let access = rolemenus[0].menuslist.filter(role => role.MenuName == locationarray[locationarray.length - 1])
              if (access.length == 0) {
                this.router.navigate(['/login']);
                isAuthenticated = ''
                sessionStorage.removeItem('sessionObjectlogin');
                sessionStorage.removeItem('rolewithmenus');
                sessionStorage.removeItem('selectedRole');
                this.service.changeMessage(false);
              }
            }
          }
        }

      }
    }
    return isAuthenticated !== '' || isAuthenticated !== undefined ? true : false;
  }
}
