import { Component, OnInit, Input } from '@angular/core';
import { FileViewExplorerComponent } from 'src/app/file-view-explorer/file-view-explorer.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-consolidateviewfile',
  templateUrl: './consolidateviewfile.component.html',
  styleUrls: ['./consolidateviewfile.component.scss']
})
export class ConsolidateviewfileComponent implements OnInit {
  @Input() backupfileArray: any;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private ngxUiLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
  }

  selectFile(response) {
    this.viewFile(response)
  }

  //view the downloand file 
  viewFile(response) {
    this.ngxUiLoaderService.start();
    let namemodal = {
      name: response.filename,
      fullfile: response.filecontent,
      type: response.fileformat
    }
    const modalRef = this.modalService.open(FileViewExplorerComponent
      , {
        windowClass: "myCustomModalViewfilehl7Viewer",
        backdrop: "static",
        keyboard: false
      });
    modalRef.componentInstance.fileData = namemodal;
    modalRef.componentInstance.fileType = response.fileformat;
    this.ngxUiLoaderService.stop();
    modalRef.result.then(() => { });
  }

  closepopupdata() {
    this.activeModal.close();
  }
}
