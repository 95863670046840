import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {  environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  VASpec: any;
  config: NgxUiLoaderConfig;
  constructor(private http: HttpClient,
    private ngxUiLoaderService: NgxUiLoaderService) {

    this.config = this.ngxUiLoaderService.getDefaultConfig();
  }
  validate(datastring: string, segment: any, validatetype: any) {
    switch (validatetype.segtype) {
      case 'DOB':
        return this.dobvalidate(datastring, segment, validatetype);
        break;
      default:
        return this.emptycheck(datastring, segment, validatetype);
        break;
    }
  }


  dobvalidate(datastring: string, segment: any, validatetype: any) {
    let response = "";
    if (datastring.length == 0) {
      return response = "Mandatory";
    }

    if (datastring.length == 8) {
      let d = new Date(datastring.slice(0, 4) + '/' + datastring.slice(4, 6) + '/' + datastring.slice(6, 8));
      if (d.getDate().toString() == "NaN") {
        response = "yyyymmdd";
      }
      else {
        response = "";
      }
    }
    else {
      response = "yyyymmdd";
    }
    return response;
  }

  emptycheck(datastring: string, segment: any, validatetype: any) {
    let response = "Mandatory";
    if (validatetype.segtype == "NOTEMPTY") {
      response = datastring == '' ? "Mandatory" : "";
    }
    // if (response == "") {
    //     if (validatetype.segcomponent.length > 0) {
    //         validatetype.segcomponent.forEach((element: any) => {
    //             let res = this.emptycheck(datastring, segment, element.segtype);
    //             if (res == "") {

    //             }
    //         });
    //     }
    // }
    return response;
  }

  selectCasetypedata(dataitem: any) {

    return this.http.get<any>(dataitem.hl7samplefilepath)
  }


  async getfilecontent(fileid: any) {
    const headers = {
      'x-va-transaction-id': environment.headers.transaction,
      'x-va-hash': environment.headers.hash,
      'x-va-senderagent-id': environment.headers.senderagent,
      'Content-Type': 'application/text charset = utf-8'
    };
    return await this.http.get<any>(environment.filedownloadurl + fileid, { headers, responseType: 'text' as 'json' })
      .pipe(map(result => result));

  }
  async getfilecontentV4(fileid: any) {
    const headers = {
      'x-va-transaction-id': environment.headersv4.transaction,
      'x-va-hash': environment.headersv4.hash,
      'x-va-senderagent-id': environment.headersv4.senderagent
    };

    var postbody={
      "id":fileid
    }

    return await this.http.post<any>(environment.filedownloadurlv4 , postbody, { headers , responseType: 'text' as 'json'  })
    .pipe(map(result => result));


  }




  private updatessostatus = new BehaviorSubject(false);
  servicessostatus = this.updatessostatus.asObservable();

  updatessoStatus(update: boolean) {
    this.updatessostatus.next(update)
  }


}
