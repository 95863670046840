// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_style {
  display: block;
  font-size: 12px !important;
}

.mat-expansion-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border-radius: inherit;
  height: 40px !important;
}

::ng-deep.mat-expansion-indicator {
  position: relative !important;
  right: 1% !important;
  top: -4px !important;
}

.w700_text {
  font-size: 12px !important !important;
}

.wraptext_highlight {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90% !important;
  display: inline-block;
}

.wraptext_headertext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90% !important;
  display: inline-block;
}

.material-icons-custom {
  font-size: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/vbjobsdisplay/vbjobsdisplay.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,0BCHO;ADEX;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,UAAA;EACA,sBAAA;EACA,uBAAA;AAFJ;;AAKE;EACE,6BAAA;EACA,oBAAA;EACA,oBAAA;AAFJ;;AAKE;EACE,qCAAA;AAFJ;;AAIE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,qBAAA;AADJ;;AAGA;EACI,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,0BAAA;AAAJ","sourcesContent":["\r\n@import '../../variables.scss';\r\n.label_style{\r\n    display: block;\r\n    font-size: $fontsize;\r\n  }\r\n\r\n  \r\n.mat-expansion-panel-header {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    padding: 0;\r\n    border-radius: inherit;\r\n    height: 40px !important;\r\n  }\r\n  \r\n  ::ng-deep.mat-expansion-indicator{\r\n    position: relative !important;\r\n    right: 1% !important;\r\n    top: -4px !important;\r\n  }\r\n\r\n  .w700_text{\r\n    font-size:$fontsize !important;\r\n  }\r\n  .wraptext_highlight{\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    max-width: 90% !important;\r\n    display: inline-block;\r\n}\r\n.wraptext_headertext{\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    max-width: 90% !important;\r\n    display: inline-block;\r\n}\r\n\r\n.material-icons-custom {\r\n    font-size: 18px !important;\r\n}\r\n\r\n","$btn-color: #17a0b4 !important;\r\n$fontsize: 12px !important;\r\n$btntextcolor: white !important;\r\n$btnHeight: 28px !important;\r\n$inputHeight:30px !important;\r\n$inputfontsize:11px !important;\r\n$primary:#004bff;\r\n// $modalHeaderTitleFont: ;\r\n@mixin modalheaderTtext {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
