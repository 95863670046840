import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-data-validation',
  templateUrl: './data-validation.component.html',
  styleUrls: ['./data-validation.component.scss']
})
export class DataValidationComponent implements OnInit {
 reponseList: any = []
  reponseListBackup: any = [];
  FilertData: any = []
  @Input() inputDataInModalComponent: any;
  @Input() CallingComponent: any;
  isBulkValidation:boolean= false;
  isResponseListBackup: boolean = false;
  search = ''
  IsCheckBox = false;
  constructor(private ngactive: NgbActiveModal) { }

  ngOnInit() {
    this.getInputDataForModalPopUp();
    console.log("inputDataInModalComponent:"+this.inputDataInModalComponent);
  }

  ngOnChanges(){
    let res :any;
    if(this.CallingComponent !== undefined && this.CallingComponent == 'Interface'){
      this.isBulkValidation = true;
    }
  let count=0
    res=this.inputDataInModalComponent;
    if(res !== undefined && res !==''){
    let resopne = res;
    for (let i = 0; i < resopne.length; i++) {
      if(resopne[i].ResponseBody.startsWith("[")){
        resopne[i].ResponseBody = JSON.parse(resopne[i].ResponseBody)
        for (let j = 0; j < resopne[i].ResponseBody.length; j++) {
          resopne[i].ResponseBody[j]['fieldName'] = this.getFieldName(resopne[i].ResponseBody[j]['Field']);
        }
      }else{
        count++
        resopne[i].ResponseBody=[]
      }
    }
    if(count==resopne.length)
    {
      this.reponseList =[]
      this.reponseListBackup =[]
      this.isResponseListBackup = false;
    }else{
      this.reponseList = resopne;
    this.reponseListBackup = JSON.parse(JSON.stringify(this.reponseList));
    this.isResponseListBackup = true;
    }
}else{
  this.reponseList =[]
  this.reponseListBackup =[]
  this.isResponseListBackup = false;
}
    console.log("inputDataInModalComponent:"+this.inputDataInModalComponent);
  }

  getInputDataForModalPopUp() {
    if(this.inputDataInModalComponent !== undefined && this.inputDataInModalComponent.length>0)
    {
      let res = this.inputDataInModalComponent[0].ResponseBody;
      let resopne = JSON.parse(res);
      for (let i = 0; i < resopne.length; i++) {
        resopne[i].ResponseBody = JSON.parse(resopne[i].ResponseBody)
        for (let j = 0; j < resopne[i].ResponseBody.length; j++) {
          resopne[i].ResponseBody[j]['fieldName'] = this.getFieldName(resopne[i].ResponseBody[j]['Field']);
        }
      }
      this.reponseList = resopne;
      this.reponseListBackup = JSON.parse(JSON.stringify(this.reponseList));
    }
   
  }

  isJSON(str) {
    
    if ( /^\s*$/.test(str) ) return false;
    str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
    str = str.replace(/"[^"\\\n\r]"|true|false|null|-?\d+(?:\.\d)?(?:[eE][+\-]?\d+)?/g, ']');
    str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
    return (/^[\],:{}\s]*$/).test(str);
  }

  onCheckboxChange(event: any) {

    if (event.checked) {
      this.IsCheckBox = true;
      if (this.search !== '') {
        this.filterdata(this.search)
      }
      else {
        this.FilertData = this.reponseList.filter(ele => ele.Status == "Failed");
        this.reponseList = this.FilertData.map((element) => {
          return { ...element, ResponseBody: element.ResponseBody.filter((subElement) => subElement.Status === "Failed") }
        })
      }
    } else {
      this.IsCheckBox = false;
      this.filterdata(this.search);
    }
  }

  closeModal() {
    this.ngactive.close()
  }

  getFieldName(filedValue: string) {
    let convertedValue: any = '';
    if (filedValue === "DOB") {
      filedValue = "DateOfBirth";
    }
    convertedValue = filedValue.replace(/([A-Z])/g, ' $1').trim()
    return convertedValue;
  }
  filterdata(event) {
    let dataEvent = "";

    if (event.target == undefined) {
      dataEvent = event
    }
    else {
      dataEvent = event.target.value
    }
    if (dataEvent !== '') {
      let searchtxt = dataEvent.toLowerCase()
      let filderdat = [];
      for (let i = 0; i < this.reponseListBackup.length; i++) {
        let obj = {}
        let data = '';
        if (this.IsCheckBox == true) {
          data = this.reponseListBackup[i].ResponseBody.filter(va => (va.fieldName.toLowerCase().includes(searchtxt) || va.InputValue.toLowerCase().includes(searchtxt) || va.OutputValue.toLowerCase().includes(searchtxt)) && (va.Status.toLowerCase() == 'failed'))
          if (data.length > 0) {
            obj = JSON.parse(JSON.stringify(this.reponseListBackup[i]))
            obj['ResponseBody'] = data;
            filderdat.push(obj);
          }
        }
        else {
          if (this.reponseListBackup[i].StatusMessage.toLowerCase().includes(searchtxt)) {
            obj = JSON.parse(JSON.stringify(this.reponseListBackup[i]));
            filderdat.push(obj);
          }
          else {
            data = this.reponseListBackup[i].ResponseBody.filter(va => va.fieldName.toLowerCase().includes(searchtxt) || va.InputValue.toLowerCase().includes(searchtxt) || va.OutputValue.toLowerCase().includes(searchtxt))
          }
          if (data.length > 0) {
            obj = JSON.parse(JSON.stringify(this.reponseListBackup[i]))
            obj['ResponseBody'] = data;
            filderdat.push(obj);
          }
        }
       
      }
      this.reponseList = filderdat;
    } else {
      if (this.IsCheckBox == true) {
        this.FilertData = this.reponseList.filter(ele => ele.Status == "Failed");
        this.reponseList = this.FilertData.map((element) => {
          return { ...element, ResponseBody: element.ResponseBody.filter((subElement) => subElement.Status === "Failed") }
        })
      }
      else {
        this.reponseList = JSON.parse(JSON.stringify(this.reponseListBackup))
      }
    }
  }
}
